import { Constant } from '@/modules/core/charts/am5/charts.constants';
import { dispatches, getters } from '@/modules/core/app/helpers/store';
import { useButton } from '@/modules/core/charts/am5/base/composables/useButton';
import { omitNils } from '@/modules/core/app/utils/ObjectUtil';
import { UseTooltips } from '@/modules/core/charts/am5/pie/composables/useTooltips';

export function useDrillDown(context) {
  const { applyLabelFormatWithCurrency } = UseTooltips(context);
  let chartData = [];

  /**
   * Based on a click event use the target to apply that selected drill-down
   * @param ev click event
   * @param id
   */
  function changePieDrillDown(ev, id) {
    const { dataItem } = ev.target;
    // slice series uses the formatted log date as a label so it's preformatted
    // comparison is also a separate chart so not applicable
    const preFormatted = dataItem.dataContext?.categoryField === Constant.LOG_DATE;
    let category = dataItem.dataContext.metric;

    const categoryField = dataItem.dataContext?.categoryField
      ? dataItem.dataContext.categoryField
      : dataItem.component.get(Constant.CATEGORY_FIELD);
    let title = '';

    const widgetConfig = getters.dashboardDrilldown.getWidgetConfig(id);

    const { grouped } = widgetConfig.widget.metadata.data_columns;

    if (categoryField === Constant.PIE_METRIC) {
      const formattedLogDate = preFormatted
        ? dataItem.dataContext?.category
        : dataItem.get(Constant.FORMATTED + Constant.LOG_DATE);
      const formattedLogDateComparison = dataItem.get(
        Constant.FORMATTED + Constant.LOG_DATE + Constant.COMPARISON
      );
      if (category) {
        title = category;
      } else {
        category = `${formattedLogDate}${
          formattedLogDateComparison ? `|${formattedLogDateComparison}` : ''
        }`;
        if (formattedLogDate) {
          title += `${formattedLogDate}${
            formattedLogDateComparison ? ` ${Constant.VS} ${formattedLogDateComparison}` : ''
          }`;
        } else {
          title += formattedLogDateComparison;
        }
      }
    }

    dispatches.dashboardDrilldown.setDrilldownConfig({
      id,
      ...setGroupByParams(dataItem.dataContext, grouped, widgetConfig),
      title,
    });
    dispatches.dashboardDrilldown.reloadWidget(id);
  }

  function setGroupByParams({ rawMetric }, grouped, { currentGroupByIndex }) {
    return omitNils({
      category: { [grouped[currentGroupByIndex].field]: rawMetric },
    });
  }

  // This function used to call from series inorder to reset chartData via Event Bus and normal behaviour
  function callFromSeries(legend) {
    chartData = [];
    drillDown(legend);
  }

  // This function used to call within the click events it's necessary to create two functions
  function callFromDrillDown(legend) {
    drillDown(legend);
  }
  function drillDown(legend) {
    const { config } = context();
    const { id, metadata } = config.value.widgetData;
    if (!config.value.isInlineDrillDown) {
      const canDrillDownFurther = getters.dashboardDrilldown.canDrillDown(id);
      if (canDrillDownFurther) drillDownEvents(id, legend, metadata);
    } else {
      drillDownEvents(id, legend, metadata);
    }
  }

  function drillDownEvents(id, legend, metadata) {
    const { chart, config } = context();
    const { applyPieBackButtonInitialClick, applyDrillDownTitleAndButton } = useButton(context);
    const widgetConfig = getters.dashboardDrilldown.getWidgetConfig(id);

    chart.value.series.each((series) => {
      series.slices.template.setAll({
        cursorOverStyle: Constant.POINTER,
      });

      applyDrillDownTitleAndButton(id);

      // slice charts need the click event on a per-slice case instead of per-series
      series.slices.each((slice) => {
        slice.events.on(Constant.CLICK, (ev) => {
          const { dataItem } = ev.target;
          const { index, isMetric, metric } = dataItem.dataContext;

          chartData = config.value.classicDrillDownBehaviour
            ? generateChartDataUpdated(index)
            : generateChartData(index);
          if (isMetric && metadata.data_columns.selected.length > 1) {
            series.data.setAll(chartData);
            // apply label format to the series
            applyLabelFormatWithCurrency(series);
            if (config.value.legend.active) legend.data.setAll(series.dataItems);
            applyPieBackButtonInitialClick(id, metric);
            if (!config.value.isInlineDrillDown) callFromDrillDown(legend);
          } else if (
            !config.value.isInlineDrillDown &&
            metadata.data_columns.grouped.length - 1 !== widgetConfig.currentGroupByIndex &&
            metric !== Constant.OTHERS
          ) {
            // apply label format to the series
            applyLabelFormatWithCurrency(series);
            changePieDrillDown(ev, id);
          }
        });
      });
    });
  }

  function generateChartDataUpdated(index) {
    const setData = setActualData();
    const firstClick = !chartData.length > 0;

    for (let i = 0; i < setData.length; i++) {
      if (i === index) {
        setData[i].isClicked = true;
        for (let x = 0; x < setData[i].subs.length; x++) {
          setData[i].subs[x].isClicked = true;
          chartData.push(setData[i].subs[x]);
        }
      }

      if (firstClick) chartData.push(setData[i]);
    }
    for (let j = 0; j < chartData.length; j++) {
      if (chartData[j].isMetric === true && chartData[j].index === index) {
        chartData.splice(j, 1);
      }
    }
    return chartData;
  }
  function generateChartData(index) {
    const setData = setActualData();
    chartData = [];
    for (let i = 0; i < setData.length; i++) {
      if (i === index) {
        for (let x = 0; x < setData[i].subs.length; x++) {
          chartData.push(setData[i].subs[x]);
        }
      }
    }
    return chartData;
  }
  function setActualData() {
    const { config } = context();
    return config.value.comparisonEnabled
      ? config.value.data.priorPeriodData
      : config.value.data.currentPeriodData;
  }

  return {
    drillDown,
    setActualData,
    callFromSeries,
  };
}
