import angular from 'angular';
import moment from 'moment';

angular.module('exportbuilder.dashboard.services')
    .factory('ExportBuilderProposalsModalUIService', ExportBuilderProposalsModalUIService)
    .factory('ExportBuilderProposalsService', ExportBuilderProposalsService)
    .factory('HelloSignService', HelloSignService);

/**
 * @ngInject
 */
function ExportBuilderProposalsModalUIService(
    UIFactory
) {

    let uiState = {
        isActive: false
    };

    return {
        getState: getState,
        setIsActive: setIsActive,
        getIsActive: getIsActive,
        getRecipientDefaultSpec: getRecipientDefaultSpec,
    };

    function getState() {
        return uiState;
    }

    function setIsActive(value) {
        uiState.isActive = value;
        if (!value) {
            UIFactory.hideModal('export-builder-proposals-modal');
        }
    }

    function getIsActive() {
        return uiState.isActive
    }

    function getRecipientDefaultSpec(proposalId) {
        return {
            proposal_id: proposalId,
            to_email: null,
            cc_emails: null,
            from_email: null,
            email_subject: null,
            email_body: 'Hello,\n\nHope you are doing well.\nWe have completed our review of your needs and are pleased to submit the enclosed proposal for your review. We have tried our level best to ensure that it adequately addresses all your requirements. Should an element be missing  or you would like us to explore another option, please feel free to contact me.\nLooking forward to working with you and your team.',
            due_date: moment().format('YYYY-MM-DD')
        }
    }
}

/**
 * @ngInject
 */
function ExportBuilderProposalsService(
    ExportBuilderResource,
    ExportBuilderProposalsModalUIService,
    HelloSignService,
    AppFactory,
    UIFactory,
    gettextCatalog,
    PubSub,
    $ExportBuilderProposalsModalEvents,
    HelloSignConstants
) {
    return {
        sendProposal: sendProposal,
        getProposal: getProposal,
        freezeProposal: freezeProposal,
        getFileUrl: getFileUrl,
        updateProposal: updateProposal
    };

    async function sendProposal(recipient, reportId, addToContacts) {
        const user = AppFactory.getUser();
        const HelloSign = initializeHelloSign(user);
        const claimUrl = await ExportBuilderResource.sendProposal({'recipient_data': recipient, 'report_id': reportId, 'add_to_contacts': addToContacts});
        openHelloSignIframe(claimUrl, user, recipient, HelloSign);
    }

    function openHelloSignIframe(claimUrl, user, recipient, HelloSign) {
        ExportBuilderProposalsModalUIService.setIsActive(false);
        const helloSignParams = getHelloSignParams(claimUrl, user, recipient);
        HelloSign.open(helloSignParams);
    }

    function initializeHelloSign(user) {
        const { HelloSign } = window;
        const HelloSignClientId = HelloSignService.getHelloSignClientId(user);
        HelloSign.init(HelloSignClientId);
        return HelloSign
    }

    function getProposal(reportId) {
        return ExportBuilderResource.getProposal(reportId);
    }

    function freezeProposal(proposalId) {
        return ExportBuilderResource.freezeProposal(proposalId);
    }

    function getFileUrl(signatureRequestId) {
        return ExportBuilderResource.getFileUrl(signatureRequestId);
    }

    function updateProposal(helloSignResponse, recipient) {
        return ExportBuilderResource.updateProposal({
            hellosign_response: helloSignResponse,
            recipient_data: recipient
        });
    }


    function getHelloSignParams(claimUrl, user, recipient) {
        return {
            url: claimUrl,
            allowCancel: true,
            skipDomainVerification: user.isLocalInstance,
            messageListener: async (eventData) => {
                if (eventData.event === HelloSignConstants.EVENT_SIGNATURE_REQUEST_SENT && eventData.success) {
                    updateProposal(eventData, recipient).then(function (proposal) {
                        PubSub.emit($ExportBuilderProposalsModalEvents.PROPOSAL_SENT_SUCCESSFULLY, proposal);
                        UIFactory.notify.showSuccess(gettextCatalog.getString('Proposal was sent successfully'));
                    })
                        .catch( function () {
                            PubSub.emit($ExportBuilderProposalsModalEvents.PROPOSAL_SENDING_FAILED);
                            UIFactory.notify.showError(gettextCatalog.getString('There was an error sending the proposal.'));
                        });
                }
            }
        };
    }
}

/**
 *
 * @param HelloSignClientIds
 * @param HelloSignConstants
 * @returns {{loadHelloSignScript: loadHelloSignScript, getHelloSignClientId: getHelloSignClientId}}
 * @constructor
 */
function HelloSignService(
    HelloSignClientIds,
    HelloSignConstants,
) {
    return  {
        getHelloSignClientId: getHelloSignClientId,
        loadHelloSignScript: loadHelloSignScript
    };

    function getHelloSignClientId(user) {
        if (user.isProductionServer) {
            return HelloSignClientIds.PRODUCTION;
        } else if(user.isLocalInstance) {
            return HelloSignClientIds.DEVELOPMENT;
        } else {
            return HelloSignClientIds.STAGING;
        }
    }

    function loadHelloSignScript() {
        const nodes = document.querySelectorAll(`script[src='${HelloSignConstants.HELLOSIGN_CDN_URL}']`);
        if (!nodes || nodes.length === 0) {
            const helloSignCdn = document.createElement('script');
            helloSignCdn.setAttribute('src', HelloSignConstants.HELLOSIGN_CDN_URL);
            helloSignCdn.setAttribute('nonce', HelloSignConstants.HELLOSIGN_NOUNCE);
            helloSignCdn.setAttribute('type', 'text/javascript');
            document.head.appendChild(helloSignCdn);
        }
    }
}