import { GaugeChartConfig } from '@/modules/core/charts/models/GaugeChartConfig';
import SerialWidgetRenderService from '@/modules/ta/widget/services/types/SerialWidgetRenderService';
import NumeralService from '@/modules/core/app/services/NumeralService';
import { PlotType } from '@/modules/ta/widget/widget.constants';
import { ColumnFormat } from '@/modules/core/app/constants/data.constants';
import { Constant } from '@/modules/core/charts/am5/charts.constants';

export default class GaugeChartWidgetRenderService extends SerialWidgetRenderService {
  /**
   * @var {Object}
   */
  periodData;

  /**
   * @var {boolean}
   */
  isComparison;

  /**
   * @var {ApiColumn}
   */
  firstNonGroupedColumn;

  /**
   * @var {ApiColumn}
   */
  secondNonGroupedColumn;

  /**
   * @param config
   * @returns {GaugeChartConfig}
   */
  initConfig(config) {
    return new GaugeChartConfig(config);
  }

  /**
   * @returns {null}
   */
  getChartSeries() {
    return null;
  }

  /**
   * @returns {string}
   */
  getFirstColumnLabelText() {
    return this.isComparison
      ? `Per Comparison \n    ${this.firstNonGroupedColumn.label}`
      : `Per ${this.firstNonGroupedColumn.label}`;
  }

  /**
   * @returns {string}
   */
  getSecondColumnLabelText() {
    return this.isComparison
      ? `Comparison ${this.secondNonGroupedColumn.label}`
      : `${this.secondNonGroupedColumn.label}`;
  }

  getSecondColumnValue() {
    if (this.secondNonGroupedColumn) {
      return this.getColumnValue(this.secondNonGroupedColumn);
    }

    return this.getColumnValue(this.firstNonGroupedColumn);
  }

  getSecondColumnFormat() {
    if (this.secondNonGroupedColumn) {
      return this.secondNonGroupedColumn.format;
    }

    return ColumnFormat.FORMAT_INTEGER;
  }

  /**
   * @returns {string}
   */
  getFormattedMetricLabel(showPercent = false) {
    const percentValue = `(${NumeralService.getPercentageOf(
      this.getSecondColumnValue(),
      this.getColumnValue(this.firstNonGroupedColumn),
      2
    )}%)`;
    const value = this.getSecondColumnValue();

    return `${NumeralService.formatValue(value, this.getSecondColumnFormat())} ${
      this.getDrawOptions().show_label_percent && showPercent ? percentValue : ''
    }`;
  }

  /**
   * @returns {{xPosition: string, yPosition: string, fontSize: number, text: string, align: string}}
   */
  getFirstColumnLabel() {
    return {
      ...this.getLabelDefaultOptions(),
      text: this.getFirstColumnLabelText(),
      xPosition: '50%',
      yPosition: '80%',
      align: 'middle',
      fontSize: 14,
    };
  }

  /**
   * @returns {{xPosition: string, yPosition: (string), fontSize: number, text: string, align: string}}
   */
  getSecondColumnLabel() {
    if (this.secondNonGroupedColumn) {
      return {
        ...this.getLabelDefaultOptions(),
        text: this.getSecondColumnLabelText(),
        xPosition: '50%',
        yPosition: this.hasComparison() ? '4%' : '0%',
        align: 'middle',
        fontSize: 16,
        fill: this.getChartPalette(1),
      };
    }

    return this.getDummyLabel();
  }

  /**
   * @returns {{xPosition: string, yPosition: string, fontSize: number, text: *, align: string}}
   */
  getMetricValueLabel() {
    return {
      ...this.getLabelDefaultOptions(),
      text: this.getFormattedMetricLabel(true),
      xPosition: '50%',
      yPosition: '70%',
      align: 'middle',
      fontSize: 14,
      fill: this.getChartPalette(1),
    };
  }

  getMultiAxisLabels() {
    return this.getLabels();
  }

  getDummyLabel() {
    return {
      ...this.getLabelDefaultOptions(),
      text: i18n.$t('Please select another metric to display the gauge chart'),
      xPosition: '50%',
      yPosition: '80%',
      align: Constant.MIDDLE,
      fontSize: 14,
    };
  }

  /**
   * @returns {*[]}
   */
  getLabels() {
    if (this.isMultiAxis()) {
      return this.getMultiAxisLabels();
    }

    const labels = [this.getFirstColumnLabel(), this.getSecondColumnLabel()];

    // Label that show if only there is a comparison between two charts
    if (this.hasComparison()) {
      labels.push(this.getPeriodLabel());
    }

    // Label that show only if Draw Option Show Label is enabled
    if (this.getDrawOptions().show_labels) {
      labels.push(this.getMetricValueLabel());
    }
    return labels;
  }

  /**
   * Not currently used for Gauge Widgets
   * @returns {{}}
   */
  getCategoryAxisProperties() {
    return {};
  }

  /**
   * Not currently used for Gauge Widgets
   * @returns {{}}
   */
  getCategoryAxisItem() {
    return {};
  }

  /**
   * @param column
   * @returns {*}
   */
  getColumnValue(column) {
    return this.periodData[column.field] > 0 ? this.periodData[column.field] : 0;
  }

  /**
   * @returns {*}
   */
  getChartData() {
    return this.currentPeriodData;
  }

  isMultiAxis() {
    return false;
  }

  getMultiAxisRanges() {
    return [];
  }

  /**
   * @returns {{endValue: *, fillOpacity: number, tooltipEnabled: boolean, fill: *, value: number, tooltipHTML: string, zIndex: number}[]}
   */
  getAxisRanges() {
    if (this.isMultiAxis()) {
      return this.getMultiAxisRanges();
    }

    const firstColumnValue = this.getColumnValue(this.firstNonGroupedColumn);
    const secondColumnValue = this.getSecondColumnValue();
    const secondColumnFormat = this.getSecondColumnFormat();

    return [
      {
        value: 0,
        endValue: secondColumnValue,
        zIndex: 0,
        fillOpacity: this.isComparison ? 0.6 : 1,
        fill: this.getChartPalette(1), // Need to skip first color to account for max/first selected column
        labelText: this.getDrawOptions().show_labels ? 0 : null,
        tooltipContent: `${this.getFirstColumnLabelText()}: ${secondColumnValue}`,
        tooltipDisabled: !this.getDrawOptions().has_tooltip,
        format: secondColumnFormat,
      },
      {
        value: secondColumnValue,
        endValue: firstColumnValue,
        zIndex: -1,
        fillOpacity: this.isComparison ? 0.6 : 1,
        fill: '#eee',
        tooltipDisabled: true,
        format: this.firstNonGroupedColumn.format,
      },
      {
        value: firstColumnValue,
        zIndex: -1,
        fillOpacity: this.isComparison ? 0.6 : 1,
        fill: '#eee',
        labelText: this.getDrawOptions().show_labels
          ? `${NumeralService.formatValue(firstColumnValue, this.firstNonGroupedColumn.format)}`
          : null,
        tooltipDisabled: true,
      },
    ];
  }

  /**
   * @param column
   * @returns {{}}
   */
  getValueAxisProperties() {
    const max = this.getColumnValue(this.firstNonGroupedColumn);
    // has to be very large number that is bigger than max value;
    const minGridDistance = 1000000;
    return {
      min: 0,
      max,
      maxDefined: max,
      strictMinMax: true,
      minGridDistance,
      showLabels: false,
    };
  }

  /**
   * @returns {{}[]}
   */
  getChartValueAxis() {
    return [this.getValueAxis()];
  }

  setPeriodData() {
    [this.periodData] = this.isComparison ? this.priorPeriodData : this.currentPeriodData;
  }

  setFirstAndSecondNonGroupedColumn() {
    [this.firstNonGroupedColumn, this.secondNonGroupedColumn] = this.nonGroupedColumns;
  }

  /**
   * @param isComparison
   */
  setIsComparison(isComparison) {
    this.isComparison = isComparison;
  }

  /**
   * @returns {{any}}
   */
  getChartLegend() {
    return {
      active: false,
    };
  }

  /**
   * @param metadata
   */
  setup(metadata) {
    super.setup();
    this.setIsComparison(metadata.isComparison);
    this.setPeriodData();
    this.setFirstAndSecondNonGroupedColumn();
  }

  /**
   * @returns {{}}
   */
  getChartConfigProperties() {
    if (!this.periodData) {
      return {};
    }

    const { draw_options } = this.getWidget().metadata;

    let v2Properties = {};
    if (draw_options.plot_type && draw_options.plot_type !== PlotType.CLASSIC) {
      v2Properties = {
        ...super.getChartConfigProperties(),
        hasTooltip: draw_options.has_tooltip,
        plotType: draw_options.plot_type,
        fillType: draw_options.fill_type,
        gradientColor: draw_options.gradient_color,
        gaugeHandType: draw_options.gauge_hand_type,
        gaugeShowValue: draw_options.gauge_show_value,
        gaugeThickness:
          draw_options.plot_type === PlotType.GAUGE_V2
            ? draw_options.gauge_thickness
            : draw_options.gauge_multi_thickness,
        showTicks: draw_options.show_ticks,
        handColor: draw_options.hand_color,
        gaugeRange: draw_options.gauge_range,
        gaugeRotation: draw_options.gauge_rotation,
        grainDensity: draw_options.grain_density,
        gaugeGradientTo: draw_options.gauge_gradient_to,
        gaugeHandCustomColor: draw_options.gauge_hand_custom_color,
        showLabel: draw_options.show_labels,
        backgroundColor: draw_options.background_color,
        gaugeGradientTarget: draw_options.gauge_gradient_target,
        showShapeShadow: draw_options.show_shape_shadow,
        bigNumberPosition: draw_options.gauge_big_number_position,
        hasLegend: draw_options.has_legend,
      };
    }

    return {
      valueAxis: this.getChartValueAxis(),
      legend: this.getChartLegend(),
      axisRanges: this.getAxisRanges(),
      labels: this.getLabels(),
      innerRadius: '75%',
      startAngle: 180,
      endAngle: 360,
      ...v2Properties,
    };
  }
}
