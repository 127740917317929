import angular from 'angular';
import $ from 'jquery';
import moment from 'moment';

import exportBuilderProposalsModalHtmlUrl from './exportbuilder.proposals.modal.html';

angular.module('exportbuilder.dashboard.components')
    .component('exportBuilderProposalsModal', {
        bindings: {
        },
        templateUrl: exportBuilderProposalsModalHtmlUrl,
        controller: ExportBuilderProposalsModal,
        controllerAs: 'vm'
    });

/**
 * @ngInject
 */
function ExportBuilderProposalsModal(
    PubSub,
    UIFactory,
    ExportBuilderProposalsModalUIService,
    ExportBuilderProposalsService,
    $ExportBuilderProposalsModalEvents,
    ReportStudioTemplateDataService,
    $stateParams,
    ClientFactory,
    UserFactory,
    AppFactory,
    gettextCatalog,
    UserType,
    HelloSignService
) {
    var vm = this;

    vm.serviceState = ExportBuilderProposalsModalUIService.getState();

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.onClose = onClose;
    vm.sendProposal = sendProposal;
    vm.getUsers = getUsers;
    vm.showSendButton = showSendButton;
    vm.setProposal = setProposal;
    vm.toggleAddToContacts = toggleAddToContacts;
    vm.checkForUserEmail = checkForUserEmail;
    vm.send = send;
    vm.enableCcEmail = enableCcEmail;
    vm.onCcEmailSelected = onCcEmailSelected;
    vm.onCcEmailRemoved = onCcEmailRemoved;

    function $onInit() {
        vm.report = ReportStudioTemplateDataService.getReport();
        vm.isAddToContactsOn = false;
        vm.isAddToContactsToggleEnabled = true;
        vm.isCheckingForEmail = false;
        vm.to_email = null;
        vm.showCc = false;
        vm.cc_emails = [];
        vm.users = [];
        vm.from_email = null;
        vm.today = moment().format('YYYY-MM-DD');
        _registerEvents();
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    function onClose() {
        ExportBuilderProposalsModalUIService.setIsActive(false);
    }

    function _onOpen() {
        ExportBuilderProposalsModalUIService.setIsActive(true);
    }

    function _registerEvents() {
        PubSub.on($ExportBuilderProposalsModalEvents.OPEN, _onOpen);
        PubSub.on($ExportBuilderProposalsModalEvents.SET_PROPOSAL, function(proposal) {
            vm.setProposal(proposal);
        });
    }

    function setProposal(proposal) {
        HelloSignService.loadHelloSignScript();
        vm.proposal = proposal;
        if (vm.report.type && vm.proposal) {
            vm.recipient = ExportBuilderProposalsModalUIService.getRecipientDefaultSpec(vm.proposal.id);
            vm.selectedClient = null;
            ClientFactory.getData({id: vm.proposal.client_id}).then((clients) => {
                vm.selectedClient = clients[0];
                vm.clients = clients;
                vm.recipient.email_subject = vm.report.title + ' Proposal';
                UserFactory.getData({
                    all: true,
                    user_type: UserType.CLIENT,
                    client_id: vm.selectedClient.id
                }).then(response => {
                    vm.clientContacts = response;
                });
                UserFactory.getData({
                    all: true,
                    user_type: UserType.SUPERADMIN
                }).then(response => {
                    vm.users = response;
                    vm.from_email = response.filter(user => user.id === vm.proposal.owner_id)[0];
                });
            });
        }
    }

    function _unregisterEvents() {
        PubSub.off($ExportBuilderProposalsModalEvents.OPEN, _onOpen)
    }

    function sendProposal() {
        vm.isCheckingForEmail = true;
        if (vm.isAddToContactsOn) {
            UserFactory.getData({
                all: true,
                email: vm.recipient.to_email.email,
                user_type: UserType.CLIENT
            }).then((response) => {
                if (response.length) {
                    vm.isCheckingForEmail = false;
                    vm.isAddToContactsOn = false;
                    const toastrMessage = vm.recipient.to_email.email + ' cannot be added to contacts. Since it is already assigned to the client ' + response[0].client_name;
                    UIFactory.notify.showError(gettextCatalog.getString(toastrMessage));
                } else {
                    vm.send();
                }
            });
        } else {
            vm.send();
        }
    }

    async function send() {
        vm.recipient.cc_emails = '';
        vm.recipient.from_email = '';
        vm.recipient.to_email = '';
        _.each(vm.cc_emails, cc_email => {
            if (vm.recipient.cc_emails) {
                vm.recipient.cc_emails = `${vm.recipient.cc_emails},${cc_email.email}`;
            } else {
                vm.recipient.cc_emails = `${cc_email.email}`;
            }
        });
        vm.recipient.to_email = vm.to_email.email;
        vm.recipient.from_email = vm.from_email.email;
        await ExportBuilderProposalsService.sendProposal(
            vm.recipient,
            vm.report.id,
            vm.isAddToContactsOn
        );
        vm.isCheckingForEmail = false;
    }
    
    function getUsers(email){
        vm.found = false;
        if (email) {
            vm.found = vm.clientContacts.find(function(user) { return user.email === email; });
            if (!vm.found) {
                vm.new_user = {'email' : email};
                vm.clientContacts.unshift(vm.new_user);
            }
        }
        return vm.clientContacts;
    }

    function showSendButton() {
        return vm.to_email && vm.from_email;
    }

    function toggleAddToContacts() {
        vm.isAddToContactsOn = !vm.isAddToContactsOn;
    }

    function checkForUserEmail(selectedUser) {
        vm.clientContacts.splice(vm.clientContacts.indexOf(selectedUser), 1);
        if (vm.recipient.to_email) {
            vm.clientContacts.push(vm.recipient.to_email);
        }
        vm.recipient.to_email = selectedUser;
        if (selectedUser.id) {
            vm.isAddToContactsToggleEnabled = false;
            vm.isAddToContactsOn = false;

        } else {
            vm.isAddToContactsToggleEnabled = true;
        }
    }

    function enableCcEmail() {
        vm.showCc = true;
    }

    function onCcEmailSelected(selectedEmail) {
        vm.clientContacts.splice(vm.clientContacts.indexOf(selectedEmail), 1);
    }

    function onCcEmailRemoved(selectedEmail) {
        vm.clientContacts.push(selectedEmail);
    }

}