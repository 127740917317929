'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('exportbuilder.resources', [])
    .factory('ExportBuilderResource', ExportBuilderResource)
    .factory('ExportBuilderTagResource', ExportBuilderTagResource)
    .factory('ExportBuilderPageResource', ExportBuilderPageResource)
    .factory('ExportElementResource', ExportElementResource)
    .factory('ExportBuilderFilterResource', ExportBuilderFilterResource)
    .factory('ReportStudioTemplateNetworkService', ReportStudioTemplateNetworkService);

/**
 * @ngInject
 */
function ExportBuilderResource(
    $http,
    Restangular,
    AppConfig,
    ReportStudioTemplateNetworkService,
    DateRangeFactory
) {
    var exportBuilders = Restangular.all('reportstudio');
    var proposals = Restangular.all('proposals');

    return {
        copy: copy,
        save: save,
        createBlankReport: createBlankReport,
        saveFullReport: saveFullReport,
        setFilter: setFilter,
        unsetFilter: unsetFilter,
        getFilter: getFilter,
        updateField: updateField,
        createThumbnail: createThumbnail,
        batchAddElements: batchAddElements,
        batchUpdateElements: batchUpdateElements,
        batchDeleteElements: batchDeleteElements,
        createNewPage: createNewPage,
        copyPage: copyPage,
        deletePage: deletePage,
        batchUpdatePages: batchUpdatePages,
        remove: remove,
        getList: getList,
        getDesignTemplates: getDesignTemplates,
        getLibraryTemplates: getLibraryTemplates,
        getUserLogo: getUserLogo,
        get: get,
        saveDateRange: saveDateRange,
        updateThumbnail: updateThumbnail,
        deleteSoftDeletedElement: deleteSoftDeletedElement,
        exportReportAsTemplate: exportReportAsTemplate,
        mergeDesignTemplate: mergeDesignTemplate,
        mergeCustomDesignTemplate: mergeCustomDesignTemplate,
        publish: publish,
        getProposal: getProposal,
        freezeProposal: freezeProposal,
        sendProposal: sendProposal,
        preloadLiveWidgets: preloadLiveWidgets,
        getFileUrl: getFileUrl,
        updateProposal: updateProposal,
        getWidgetFilterSetId: getWidgetFilterSetId,
        setIsFavorite: setIsFavorite
    };

    function copy(report, overrides) {
        overrides = overrides || {};
        var promise = exportBuilders
            .one(report.id)
            .all('copy')
            .post(overrides)
            .then(function (json) {
                return json.plain()
            });
        ReportStudioTemplateNetworkService.track(promise);
        return promise;
    }

    /**
     * Save an export builder (POST)
     * @param model
     * @param options
     * @returns {*|JQueryXHR|IHttpPromise<T>}
     */
    function save(model, options) {
        options = options || {ignoreLoadingBar: true};
        if (model && model.id) {
            var promise = exportBuilders
                .all(model.id)
                .withHttpConfig(options)
                .post(model)
                .then(function (json) {
                    return json.plain ? json.plain(): json;
                });
            ReportStudioTemplateNetworkService.track(promise);
            return promise;
        }
        else {
            var promise = exportBuilders
                .withHttpConfig(options)
                .post(model)
                .then(function (json) {
                    return json.plain()
                });
            ReportStudioTemplateNetworkService.track(promise);
            return promise;
        }
    }

    /**
     *
     * @param model
     * @returns {*}
     */
    function createBlankReport(model) {
        var promise = exportBuilders
            .all('createblank')
            .post(model)
            .then(function (json) {
                return json.plain()
            });
        ReportStudioTemplateNetworkService.track(promise);
        return promise;
    }

    function saveFullReport(report) {
        var promise = exportBuilders
            .all(report.id)
            .all('complete')
            .post(report);
        ReportStudioTemplateNetworkService.track(promise);
        return promise;
    }

    function setFilter(filter) {
        var promise = exportBuilders
            .all('setfilter')
            .post(filter)
            .then(json => {
                return json.plain();
            });
        ReportStudioTemplateNetworkService.track(promise);
        return promise;
    }

    function unsetFilter() {
        var promise = exportBuilders
            .all('clearfilter')
            .post();
        ReportStudioTemplateNetworkService.track(promise);
        return promise;
    }

    function getWidgetFilterSetId(widgetId) {
        var promise = exportBuilders
            .all('getwidgetfiltersetid')
            .one(widgetId)
            .get()
            .then(function (json) {
                return json ? json.plain() : null;
            });
        return promise;
    }

    function getFilter() {
        var promise = exportBuilders
            .one('filter')
            .get()
            .then(function (json) {
                return json ? json.plain() : null;
            });
        ReportStudioTemplateNetworkService.track(promise);
        return promise;
    }

    /**
     * Save an export builder specific field (POST)
     * @param id
     * @param field
     * @param value
     * @param options
     * @returns {*|JQueryXHR|IHttpPromise<T>}
     */
    function updateField(id, field, value, options) {
        options = options || {ignoreLoadingBar: true};
        var model = {};
        model[field] = value;
        var promise = exportBuilders
            .all(id)
            .withHttpConfig(options)
            .post(model);
        ReportStudioTemplateNetworkService.track(promise);
        return promise;
    }

    /**
     * Ask the server to generate a thumbnail screenshot of the report (POST)
     * @param id
     * @param options
     * @returns {*|JQueryXHR|IHttpPromise<T>}
     */
    function createThumbnail(id, options) {
        options = options || {ignoreLoadingBar: true};
        var promise = exportBuilders
            .all('thumbnail')
            .all(id)
            .withHttpConfig(options)
            .post();
        ReportStudioTemplateNetworkService.track(promise);
        return promise;
    }



    /**
     * Batch add elements of a report
     * @param report
     * @param elements
     * @returns {*}
     */
    function batchAddElements(report, elements) {
        var promise = exportBuilders
            .all(report.id)
            .all('exportelements')
            .all('batchadd')
            .withHttpConfig({ignoreLoadingBar: true})
            .post(elements)
            .then(function () {
                return 'success';
            });
        ReportStudioTemplateNetworkService.track(promise);
        return promise;
    }

    /**
     * Batch update elements of a report
     * @param report
     * @param elements
     * @returns {*}
     */
    function batchUpdateElements(report, elements) {
        var promise = exportBuilders
            .all(report.id)
            .all('exportelements')
            .all('batchupdate')
            .withHttpConfig({ignoreLoadingBar: true})
            .post(elements)
            .then(function () {
                return 'success';
            });
        ReportStudioTemplateNetworkService.track(promise);
        return promise;
    }

    /**
     * Batch update elements of a report
     * @param report
     * @param elements
     * @returns {*}
     */
    function batchUpdatePages(report, pages) {
        var promise = exportBuilders
            .all(report.id)
            .all('exportpages')
            .all('batchupdate')
            .withHttpConfig({ignoreLoadingBar: true})
            .post(pages)
            .then(function () {
                return 'success';
            });
        ReportStudioTemplateNetworkService.track(promise);
        return promise;
    }

    /**
     * Batch update elements of a report
     * @param report
     * @param elements
     * @returns {*}
     */
    function batchDeleteElements(report, elements) {
        var promise = exportBuilders
            .all(report.id)
            .all('exportelements')
            .all('batchdelete')
            .withHttpConfig({ignoreLoadingBar: true})
            .post(elements)
            .then(function () {
                return 'success';
            });
        ReportStudioTemplateNetworkService.track(promise);
        return promise;
    }

    function createNewPage(report, newPage) {
        var promise = exportBuilders
            .all(report.id)
            .all('exportpages')
            .post(newPage)
            .then(function (json) {
                return json;
            });
        ReportStudioTemplateNetworkService.track(promise);
        return promise;
    }

    function copyPage(report, page, overrides) {
        var promise = exportBuilders
            .all(report.id)
            .all('exportpages')
            .all(page.id)
            .all('copy')
            .post(overrides)
            .then(function (json) {
                return json.plain ? json.plain() : json;
            });
        ReportStudioTemplateNetworkService.track(promise);
        return promise;
    }

    function deletePage(report, page) {
        var promise = exportBuilders
            .all(report.id)
            .all('exportpages')
            .one(page.id)
            .remove()
            .then(function () {
                return 'success';
            });
        ReportStudioTemplateNetworkService.track(promise);
        return promise;
    }

    /**
     * Save an export builder (POST)
     * @param model
     * @param options
     * @returns {*|JQueryXHR|IHttpPromise<T>}
     */
    function remove(model, options) {
        options = options || {ignoreLoadingBar: true};
        var promise = exportBuilders
            .one(model.id)
            .withHttpConfig(options)
            .remove()
            .then(function (result) {
                return result;
            });
        ReportStudioTemplateNetworkService.track(promise);
        return promise;
    }

    /**
     * @param queryParams
     * @returns {angular.IPromise<T>}
     */
    function getList(queryParams) {
        var promise = exportBuilders
            .getList(_.extend({all: true}, queryParams))
            .then(function (json) {
                return json.plain();
            });
        ReportStudioTemplateNetworkService.track(promise);
        return promise;
    }

    /**
     * @param queryParams
     * @returns {angular.IPromise<T>}
     */
    function getDesignTemplates() {
        return exportBuilders
            .all('templates')
            .one('design')
            .get()
            .then(function (json) {
                return json.plain();
            });
    }

    /**
     * @param queryParams
     * @returns {angular.IPromise<T>}
     */
    function getLibraryTemplates() {
        return exportBuilders
            .all('library')
            .one('templates')
            .get({all: true})
            .then(function (json) {
                return json.plain();
            });
    }

    /**
     *
     * @param userId
     * @returns {*}
     */
    function getUserLogo(userId) {
        var promise = exportBuilders
            .all('userlogo')
            .one(`${userId}`)
            .get()
            .then(json => json.plain ? json.plain() : json);
        ReportStudioTemplateNetworkService.track(promise);
        return promise;
    }

    /**
     * Return an export builders from it's id (GET)
     * @param ID Int
     * @param options
     * @returns {*}
     */
    function get(id) {
        var promise = exportBuilders
            .one(`${id}`)
            .get({all: true})
            .then(function (json) {
                return json.plain()
            });
        ReportStudioTemplateNetworkService.track(promise);
        return promise;
    }
    
    /**
     * Updates session date range
     * @param model
     * @returns {*}
     */
    function saveDateRange(model) {
        return $http.post(AppConfig.MAGE_URL + 'index/timefilter', model);
    }

    /**
     * updates the thumbnail of the report
     */
    function updateThumbnail(report) {
        var promise = exportBuilders
            .all(report.id)
            .one('updatethumbnail')
            .withHttpConfig({ignoreLoadingBar: true})
            .get()
            .then(function (updatedReport) {
                return updatedReport.plain();
            });
        ReportStudioTemplateNetworkService.track(promise);
        return promise;
    }

    /**
     * updates the thumbnail of the report
     */
    function deleteSoftDeletedElement(report) {
        var promise = exportBuilders
            .all(report.id)
            .one('exportelements')
            .one('clearsoftdeletes')
            .withHttpConfig({ignoreLoadingBar: true})
            .get();
        return promise;
    }

    /**
     * Make a custom report template out of a report
     */
    function exportReportAsTemplate(report) {
        var promise = exportBuilders
            .all(report.id)
            .one('createtemplate')
            .withHttpConfig({ignoreLoadingBar: true})
            .get()
            .then(json => json.plain());
        return promise;
    }

    function mergeDesignTemplate(reportId, designTemplateId) {
        var promise = exportBuilders
            .all(`${reportId}`)
            .all('merge')
            .all('design')
            .one(`${designTemplateId}`)
            .withHttpConfig({ignoreLoadingBar: true})
            .post();
        return promise;
    }

    function mergeCustomDesignTemplate(reportId, designTemplateId) {
        var promise = exportBuilders
            .all(`${reportId}`)
            .all('merge')
            .all('custom')
            .one(`${designTemplateId}`)
            .withHttpConfig({ignoreLoadingBar: true})
            .post();
        return promise;
    }
    
    function publish(model) {
        const report_id = model.report_id;
        delete model.report_id;
        const promise = exportBuilders
            .all('library')
            .all('publish')
            .all(`${report_id}`)
            .withHttpConfig({ignoreLoadingBar: true})
            .post(model)
            .then(function (reportId) {
                return reportId;
            });
        ReportStudioTemplateNetworkService.track(promise);
        return promise;
    }
    
    /**
     *
     * @param reportId
     * @returns {*}
     */
    function getProposal(reportId) {
        return Restangular
            .one('proposals', 'getproposal')
            .get({report_id: reportId});
    }
    
    /**
     *
     * @param proposalId
     * @returns {*}
     */
    function freezeProposal(proposalId) {
        return proposals
            .all('freeze')
            .post({proposal_id: proposalId})
    }
    
    /**
     *
     * @param queryParams
     * @returns {*}
     */
    function sendProposal(queryParams) {
        return proposals
            .all('send')
            .post(queryParams);
    }

    /**
     *
     * @param reportId
     * @returns {*}
     */
    function preloadLiveWidgets(reportId) {
        return Restangular.one('preloadlivewidgets', reportId).get({daterange: DateRangeFactory.buildDateQueryParam()});
    }

    /**
     *
     * @param signatureRequestId
     * @returns {*}
     */
    function getFileUrl(signatureRequestId) {
        return Restangular
            .one('proposals', 'getfileurl')
            .get({signature_request_id: signatureRequestId});
    }

    /**
     *
     * @param queryParams
     * @returns {*}
     */
    function updateProposal(queryParams) {
        return proposals
            .all('updateproposal')
            .post(queryParams);
    }

    /**
     * @param reportId
     * @param value
     */
    function setIsFavorite(reportId, value) {
        return exportBuilders.one(reportId).one('updateisfavorite').one(value).put();
    }
}

/**
 * @ngInject
 */
function ExportBuilderTagResource(
    Restangular
) {
    const reportStudio = Restangular.all('reportstudio');
    
    return {
        getData: getData
    };
    
    /**
     * Get data for this resource
     * @param queryParams
     * @returns {*|ICollectionPromise<any>|ICollectionPromise<T>|{method, params, headers}}
     */
    async function getData(queryParams) {
        queryParams = queryParams || {};
        queryParams.all = true;
        const response = await reportStudio.all('tags').getList(queryParams);
        return response.plain();
    }
}

/**
 * @ngInject
 */
function ExportBuilderPageResource(
    Restangular,
    ReportStudioTemplateNetworkService
) {
    var reportStudio = Restangular.all('reportstudio');

    return {
        batchUpdate: batchUpdate,
        update: update
    };

    /**
     * Updates a list of pages
     * @param report
     * @param pages
     * @returns {*}
     */
    function batchUpdate(report, pages) {
        var promise = reportStudio
            .all(report.id)
            .all('exportpages')
            .all('batchupdate')
            .withHttpConfig({ignoreLoadingBar: true})
            .post(pages)
            .then(function () {
                return 'success';
            });
        ReportStudioTemplateNetworkService.track(promise);
        return promise;
    }

    /**
     * Updates a page
     * @param report
     * @param page
     * @returns {*}
     */
    function update(report, page) {
        var promise = reportStudio
            .all(report.id)
            .all('exportpages')
            .all(page.id)
            .withHttpConfig({ignoreLoadingBar: true})
            .post(page)
            .then(function () {
                return 'success';
            });
        ReportStudioTemplateNetworkService.track(promise);
        return promise;
    }
}

/**
 * @ngInject
 */
function ExportElementResource(
    $q,
    Restangular,
    WidgetFactory,
    ReportStudioTemplateNetworkService
) {
    var exportelements = Restangular.all('exportelements');
    var exportbuilders = Restangular.all('reportstudio');

    return {
        createElement: createElement,
        save: save,
        copy: copy,
        saveElementWidget: saveElementWidget,
        batchCreate: batchCreate,
        remove: remove,
        getExportElements: getExportElements
    };

    function createElement(report, newModel) {
        var model = {
            report_id: report.id,
            widget_id: newModel.widget.id,
            height: newModel.height,
            width: newModel.width,
            metadata: {draw_options: newModel.widget.metadata.draw_options},
            x_position: newModel.x_position,
            y_position: newModel.y_position,
            z_index: newModel.z_index,
            page_index: newModel.page_index,
            type: newModel.type
        };
        var promise = exportelements.all(report.id).post(model).then(function (json) {
            return json.plain();
        });
        ReportStudioTemplateNetworkService.track(promise);
        return promise;
    }

    /**
     * Save an export element (POST)
     * @param report
     * @param model
     * @param options
     * @returns {*|JQueryXHR|IHttpPromise<T>}
     */
    function save(report, model, options) {
        options = _.assign({ignoreLoadingBar: true}, options);

        var promise = null;
        if (model.id) {
            promise = exportelements
                .all(model.id)
                .withHttpConfig(options)
                .post(model)
                .then(function (json) {
                    return json.plain ? json.plain() : json;
                });
        } else {
            promise = exportbuilders
                .all(report.id)
                .all('exportelements')
                .withHttpConfig(options)
                .post(model)
                .then(function (json) {
                    return json.plain ? json.plain() : json;
                });
        }

        ReportStudioTemplateNetworkService.track(promise);
        return promise;
    }

    function copy(model, overrides) {
        var promise = exportelements
            .all(model.id)
            .all('copy')
            .post(overrides)
            .then(function (json) {
                return json.plain ? json.plain() : json;
            });

        ReportStudioTemplateNetworkService.track(promise);
        return promise;
    }

    /**
     * Updates widget element
     */
    function saveElementWidget(element) {
        var promise = WidgetFactory.save(element.widget);
        ReportStudioTemplateNetworkService.track(promise);
        return promise;
    }

    /**
     * Batch creates export elements in to a builder
     * @param report
     * @param pages
     */
    function batchCreate(report, pages) {
        var promise = exportbuilders
            .all(report.id)
            .all('batchUpload')
            .post(pages).then(function (response) {
                return response;
            });
        ReportStudioTemplateNetworkService.track(promise);
        return promise;
    }

    /**
     * Save an export element (POST)
     * @param model
     * @returns {*|JQueryXHR|IHttpPromise<T>}
     */
    function remove(model, options) {
        options = _.assign({ignoreLoadingBar: true}, options);
        var promise = exportelements
            .all(model.id)
            .withHttpConfig(options)
            .remove()
            .then(function (result) {
                return result;
            });
        ReportStudioTemplateNetworkService.track(promise);
        return promise;
    }

    /**
     * Return a list of export elements (GET)
     * @param layoutId
     * @returns {*}
     */
    function getExportElements(layoutId) {
        var promise = exportbuilders
            .all(layoutId)
            .all('exportelements')
            .getList()
            .then(function (json) {
                return json.plain()
            });
        ReportStudioTemplateNetworkService.track(promise);
        return promise;
    }

}

/**
 * @ngInject
 */
function ExportBuilderFilterResource(
    $q,
    Restangular,
    ReportStudioTemplateNetworkService
) {
    const filtersRoute = Restangular.all('reportstudio');

    return {
        getGlobalFiltersOptions: getGlobalFiltersOptions,
        getGlobalFilters: getGlobalFilters,
        saveGlobalFilters: saveGlobalFilters,
        clearGlobalFilters: clearGlobalFilters,
        getSingleGlobalFilter: getSingleGlobalFilter
    };

    function getGlobalFiltersOptions(reportId) {
        const promise = filtersRoute
            .all(reportId)
            .one('filters')
            .one('options')
            .withHttpConfig({ignoreLoadingBar: true})
            .get()
            .then(json =>  json.plain());
        ReportStudioTemplateNetworkService.track(promise);
        return promise;
    }

    function getGlobalFilters($report) {
        const promise = filtersRoute
            .all($report.id)
            .all('filters')
            .getList()
            .then(json =>  json.plain());
        ReportStudioTemplateNetworkService.track(promise);
        return promise;
    }

    function saveGlobalFilters($report, filters) {
        const promise = filtersRoute
            .all($report.id)
            .all('filters')
            .post(filters)
            .then(json => json ? json.plain() : null);
        ReportStudioTemplateNetworkService.track(promise);
        return promise;
    }

    function clearGlobalFilters($report) {
        const promise = filtersRoute
            .one($report.id)
            .one('filters')
            .one('clear')
            .get()
            .then(json => json ? json.plain() : null);
        ReportStudioTemplateNetworkService.track(promise);
        return promise;
    }

    function getSingleGlobalFilter($report, model) {
        let promise;
        if (model.data_view && model.field) {
            promise = getFieldGlobalFilter($report, model);
        } else {
            promise = getGlobalFilter($report, model);
        }
        ReportStudioTemplateNetworkService.track(promise);
        return promise;
    }

    function getGlobalFilter($report, model) {
        return filtersRoute
            .one($report.id)
            .one('filters')
            .one('options')
            .one(model.type)
            .one(model.id)
            .get()
            .then(json => json ? json.plain() : null);
    }

    function getFieldGlobalFilter($report, model) {
        return filtersRoute
            .one($report.id)
            .one('filters')
            .one('options')
            .one(model.type)
            .one(model.id)
            .one(model.data_view)
            .one(model.field)
            .get()
            .then(json => json ? json.plain() : null)
    }

}

/**
 * To Keep track of network requests within Report Studio Builder
 * @ngInject
 */
function ReportStudioTemplateNetworkService(

) {
    var _state = {
        counter: 0
    };

    return {
        getState: getState,
        getCount: getCount,
        track: track,
        increment: increment,
        decrement: decrement
    };

    /**
     * Returns parent object for automatic data binding
     * @returns {{counter: number}}
     */
    function getState() {
        return _state
    }

    /**
     * Getter for counter number only
     * @returns {number}
     */
    function getCount() {
        return _state.counter
    }

    /**
     * Tracks a network promise to keep counter up-to-date
     * @param promise
     * @returns {*}
     */
    function track(promise) {
        increment();
        promise
            .then(function () {
                decrement();
            })
            .catch(function () {
                decrement();
            });
        return promise;
    }

    /**
     * Increases internal counter
     */
    function increment() {
        _state.counter++;
    }

    /**
     * Decreases internal counter
     */
    function decrement() {
        _state.counter--;
    }
}
