export const ReportStudioPathName = {
  REPORTSTUDIO: 'reportstudio',
  REPORTSTUDIO_TEMPLATES: 'reportstudio:templates',
};

export const ExportBuilderNewReportContext = {
  DASHBOARD: 'ExportBuilderNewReportContext:DASHBOARD',
  REPORTSTUDIO: 'ExportBuilderNewReportContext:REPORTSTUDIO',
};

export const ExportBuilderAdminNewReportModalConstants = {
  MODAL_ID: 'new-report-modal',
};

export const PageSpecifications = {
  WIDTH: 1360, // arbitrary size
  RATIO: 110 / 85, // Letter page ratio
  INNER_MARGINS: 1, // % of the height or width
  BOTTOM_MARGIN: 40, // margin between each page, in px
};

export const PageFormatConstants = {
  STANDARD: 'standard',
  WIDESCREEN: 'widescreen',
};
export const ReportTemplateType = {
  TYPE_CUSTOM: 'custom',
  TYPE_DATA: 'data',
  TYPE_REPORT: 'report',
  TYPE_DESIGN: 'design',
  TYPE_PROPOSAL: 'proposal',
};

export const PageOrientation = {
  TYPE_LANDSCAPE: 'landscape',
  TYPE_PORTRAIT: 'portrait',
};

export const PageGridConstants = {
  RATIO: 85 / 110,
  X: 60,
  Y: 48,
  MINIMUM_PIXEL_HEIGHT: 20,
  MINIMUM_PIXEL_WIDTH: 20,
};

export const ExportBuilderPanelConstants = {
  RATIO: 85 / 110,
  X: 60,
  Y: 48,
  MINIMUM_PIXEL_HEIGHT: 20,
  MINIMUM_PIXEL_WIDTH: 20,
};

export const ExportType = {
  TYPE_EXPORT_PDF: 'export_pdf',
  TYPE_EXPORT_PPT: 'export_ppt',
  TYPE_SCHEDULED_REPORT_PDF: 'scheduled_report_pdf',
};

export const ExportBuilderDashboardConstants = {
  DELAY_REPORT: 1000,
};

export const ZIndexProps = {
  BASE: 1, // lowest z-index
  STEP: 1, // step between each z-index
};

export const ExportBuilderPrefixes = {
  WIDGETID: 'widgetID-',
  ITEMID: 'itemID-',
};

export const ReportElementTypes = {
  WIDGET: 'item-type-widget',
  IMAGE: 'item-type-image',
  SHAPE: 'item-type-shape',
  ICON: 'item-type-icon',
  TEXT: 'item-type-text',
  COLOR: 'item-type-color',
  MACRO: 'item-type-macro',
};

export const ReportElementMacroTypes = {
  DATE_RANGE: 'macro-date-range',
  COMPANY_LOGO: 'macro-company-logo',
  RECIPIENT_NAME: 'macro-recipient-name',
  PAGE_TITLE: 'macro-page-title',
  PAGE_NUMBER: 'macro-page-number',
  CREATED_ON: 'macro-created-on',
};

export const PageLayerPanel = {
  WIDTH: 250,
};

export const ReportElementShapeTypes = {
  SQUARE: 'shape-square',
  CIRCLE: 'shape-circle',
};

export const BuilderPanel = {
  WIDTH: 280,
  ROW_HEIGHT: 30,
  STATIC_PANE_HEIGHT: 320,
  STATIC_PANE_HEADER_HEIGHT: 26,
};

export const ReportElementBaseOptions = {
  MIRROR_X: 'mirror_x',
  MIRROR_Y: 'mirror_y',
  OPACITY: 'opacity',
  BACKGROUND_COLOR: 'background_color',
  BORDER_RADIUS: 'border_radius',
  SHADOW_COLOR: 'shadow_color',
  SHADOW_BLUR: 'shadow_blur',
  SHADOW_X: 'shadow_x',
  SHADOW_Y: 'shadow_y',
  BORDER_COLOR: 'border_color',
  BORDER_WIDTH: 'border_width',
  BORDER_TYPE: 'border_type',
  BORDER_OPACITY: 'border_opacity',
};

export const ReportElementDesignOptions = {
  SHOW_ON_EVER_PAGE: 'show_on_every_page',
};

export const ReportElementIconDesignOptions = {
  ICON_COLOR: 'icon_color',
};

export const ReportElementShapeDesignOptions = {
  BORDER_RADIUS: 'border_radius',
  BACKGROUND_COLOR: 'background_color',
};

export const ReportElementImageDesignOptions = {
  FILL_MODE: 'fill_mode',
};

export const ReportElementImageSupportedFillModes = {
  ASPECT_FIT: 'aspect_fit',
  ASPECT_FILL: 'aspect_fill',
  STRETCH: 'stretch',
};

export const ReportPageDesignOptions = {
  BACKGROUND_COLOR: 'background_color',
  OPACITY: 'opacity',
};

export const ReportElementTextDesignOptions = {
  TEXT_TITLE: 'text',
  TEXT_ALIGNMENT: 'text_align',
  VERTICAL_ALIGNMENT: 'vertical_alignment',
  FONT_SIZE: 'font_size',
  FONT_FAMILY: 'font_family',
  FONT_STYLE: 'font_style',
  FONT_WEIGHT: 'font_weight',
  TEXT_PADDING: 'text_padding',
  TEXT_COLOR: 'text_color',
  TEXT_BOLD: 'text_bold',
  TEXT_ITALIC: 'text_italic',
  TEXT_UNDERLINE: 'text_underline',
  TEXT_STRIKETHROUGH: 'text_strikethrough',
  TEXT_OPACITY: 'text_opacity',
  TEXT_SHADOW_COLOR: 'text_shadow_color',
  TEXT_SHADOW_BLUR: 'text_shadow_blur',
  TEXT_SHADOW_X: 'text_shadow_x',
  TEXT_SHADOW_Y: 'text_shadow_y',
  TEXT_EMPTY_PLACEHOLDER: 'Title',
};

export const ReportElementDesignOptionConstants = {
  TEXT_ALIGNMENT: {
    LEFT: 'left',
    RIGHT: 'right',
    CENTER: 'center',
    JUSTIFIED: 'justified',
  },
  VERTICAL_ALIGNMENT: {
    TOP: 'flex-start',
    CENTER: 'center',
    BOTTOM: 'flex-end',
  },
  BORDER_TYPE: {
    SOLID: 'solid',
    DASHED: 'dashed',
    DOTTED: 'dotted',
    DOUBLE: 'double',
    GROOVE: 'groove',
    RIDGE: 'ridge',
    INSET: 'inset',
    OUTSET: 'outset',
  },
};

export const ReportElementTableDesignOptions = {
  TABLE_ROWS: 'table_rows',
  TABLE_COLUMNS: 'table_columns',
  TEXT_TITLE: 'text',
  TABLE_DATA: 'table_data',
  TABLE_HEADERS: {
    ITEM: 'Item',
    PRICE: 'Price',
    QUANTITY: 'Quantity',
    SUB_TOTAL: 'Sub-Total',
    TOTAL: 'Total',
  },
  FONT_SIZE: 'font_size',
  CURRENCY_CODE: 'currency_code',
};

export const MenuType = {
  PAGE: 'page',
  PAGE_PREVIEW: 'page_preview',
  ELEMENT: 'element',
};

export const ReportStudioRoute = {
  REPORTSTUDIO: 'reportstudio',
  REPORTS: 'reports',
};

export const ReportStudioSegmentEvents = {
  REPORT_STUDIO_SET_FILTER_EVENT: 'ReportStudioSetFilterEvent',
  REPORT_STUDIO_CLEAR_FILTER_EVENT: 'ReportStudioClearFilterEvent',
  REPORT_STUDIO_SET_DATE_FILTER_EVENT: 'ReportStudioSetDateFilterEvent',
  REPORT_STUDIO_TOGGLE_COMPARISON_DATA_EVENT: 'ReportStudioToggleComparisonDataEvent',
  REPORT_STUDIO_TOGGLE_FAVORITE_EVENT: 'ReportStudioToggleFavoriteEvent',
};

export const ReportStudioCreatedFrom = {
  FREEHAND: 'freehand',
  DESIGN_TEMPLATE: 'design_template',
  REPORT_TEMPLATE: 'report_template',
};

export const ReportStudioMixinConstants = {
  THUMBNAIL_SESSION_KEY_PREFIX: 'reportstudio-template-thumbnail-',
  THUMBNAIL_NOT_FOUND_MESSAGE: 'Thumbnail not found for the Report Studio template id -',
};
