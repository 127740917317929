'use strict';
import angular from 'angular';
import $ from 'jquery';
import './tapdbviz/tapdbviz.resources';
import './tapdbviz/tapdbviz.components';
import './taptester/taptester.resources';
import './taptester/taptester.components';

import tapDbVizHtmlUrl from './tapdbviz/tapdbviz.html';
import tapTesterHtmlUrl from './taptester/taptester.html';

angular.module('core.taplabs', [
    'core.tapdbviz.resources',
    'core.tapdbviz.components',
    'core.taptester.resources',
    'core.taptester.components',
])

    .config(['$stateProvider', function ($stateProvider) {
        $stateProvider
            .state('tapdbviz', {
                parent:'layout',
                url: '/tapdbviz',
                templateUrl: tapDbVizHtmlUrl,
                controllerAs: 'vm',
                controller: 'TapDbVizController',
                resolve: {
                    tableNames: function(TapDbVizResource) {
                        return TapDbVizResource.getTableNames();
                    }
                }
            })
            .state('taptester', {
                parent:'layout',
                url: '/taptester',
                templateUrl: tapTesterHtmlUrl,
                controllerAs: 'vm',
                controller: 'TapTesterController'
            })
    }]);