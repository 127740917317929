/* eslint-disable tap/no-raw-text-js */
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import axios from 'axios';
import moment from 'moment';
import { CustomTranslationListDataService } from '@/modules/core/customtranslation/services/CustomTranslationListDataService';
import { getters } from '@/modules/core/app/helpers/store';
import { AppModule } from '@/modules/core/app/constants/app.constants';
import { CACHE_ID, LocaleMap, Locales } from '@/locales/locales.constants';
import en_US from './locales/translations/en_US.json';
import dateTimeFormats from './locales/DateTimeFormats.json';
import numberFormats from './locales/NumberFormats.json';

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: Locales.EN_US, // our default language that is preloaded
  fallbackLocale: Locales.EN_US,
  messages: { en_US },
  dateTimeFormats,
  numberFormats,
  silentFallbackWarn: true,
  silentTranslationWarn: true,
  formatFallbackMessages: true,
  pluralizationRules: {
    /**
     * @param choice {number} a choice index given by the input to $tc: `$tc('path.to.rule', choiceIndex)`
     * @param choicesLength {number} an overall amount of available choices
     * @returns a final choice index to select plural word by
     */
    ru(choice, choicesLength) {
      // this === VueI18n instance, so the locale property also exists here

      if (choice === 0) {
        return 0;
      }

      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;

      if (choicesLength < 4) {
        return !teen && endsWithOne ? 1 : 2;
      }
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }

      return choicesLength < 4 ? 2 : 3;
    },
  },
});

// This helps in using same method name across the app in vue and js files
i18n.$tc = i18n.tc;
// Wrapper function to support Custom Translations which may include plurals
i18n.$t = function (text, values = {}) {
  return i18n.tc(text, 1, values);
};

i18n.__translate = i18n._translate;
i18n._translate = function _translate(
  messages,
  locale,
  fallback,
  key,
  host,
  interpolateMode,
  args
) {
  if (locale === '--') {
    // eslint-disable-next-line tap/no-raw-text-js
    return '!!!Vue.js string was translated!!!';
  }
  return this.__translate(messages, locale, fallback, key, host, interpolateMode, args);
};

window.i18n = i18n;

function setI18nLanguage(lang) {
  try {
    i18n.locale = lang;
    moment.locale(lang);
    axios.defaults.headers.common['Accept-Language'] = lang;
    document.querySelector('html').setAttribute('lang', lang);
  } catch (e) {
    Logger.log(e.message, Logger.LEVEL_ERROR);
  }
  return lang;
}

async function importPredefinedTranslations(lang) {
  switch (lang) {
    case Locales.FR:
    case Locales.FR_CA:
      return (
        await import(/* webpackChunkName: "locales/fr_CA" */ `./locales/translations/fr_CA.json`)
      ).default;
    case Locales.JA:
    case Locales.JA_JP:
      return (
        await import(/* webpackChunkName: "locales/ja_JP" */ './locales/translations/ja_JP.json')
      ).default;
    case Locales.TEST:
      return {};
    default:
      return en_US;
  }
}

/**
 * Format Custom Translations to vue-i18n readable format
 *
 * @param data
 * @returns {{}}
 */
function formatCustomTranslations(data) {
  const result = {};
  if (Array.isArray(data)) {
    data.forEach((translation) => {
      if (translation.locale) {
        if (!result[translation.locale]) {
          result[translation.locale] = {};
        }
        result[translation.locale][translation.key] = Array.isArray(translation.value)
          ? translation.value.join(' | ')
          : translation.value;
      }
    });
  }
  return result;
}

function generateCacheKey(locales) {
  return `${CACHE_ID}-${AppModule.CUSTOM_TRANSLATIONS}_${locales.sort().join('_')}`;
}

/**
 * Fetches custom translations
 */
async function importCustomTranslations(locales = []) {
  const service = new CustomTranslationListDataService();
  const data = await service.getData({ locale: locales ? locales.join(',') : undefined });
  const customTranslations = formatCustomTranslations(data ?? []);

  // eslint-disable-next-line no-restricted-globals
  const cache = await getI18nCacheObject();
  if (cache) {
    cache.put(generateCacheKey(locales), new Response(JSON.stringify(customTranslations)));
  } else {
    localStorage.setItem(generateCacheKey(locales), JSON.stringify(customTranslations));
  }
  return customTranslations;
}

async function getI18nCacheObject() {
  // eslint-disable-next-line no-restricted-globals
  const cacheAvailable = 'caches' in self;
  if (cacheAvailable) {
    return caches.open(CACHE_ID);
  }
  return Promise.resolve(undefined);
}

async function getCustomTranslations(locales) {
  if (!Array.isArray(locales)) {
    locales = [locales];
  }
  const cache = await getI18nCacheObject();
  let data;
  if (cache) {
    data = await cache.match(generateCacheKey(locales));
    data = data ? await data.json() : data;
  } else {
    data = JSON.parse(localStorage.getItem(generateCacheKey(locales)));
  }
  return !data ? importCustomTranslations(locales) : data;
}

/**
 * Gets locales including fallback locales
 *
 * @param locale
 * @returns {*[]}
 */
function getLocales(locale) {
  const locales = [locale];
  while (locale.lastIndexOf('_') !== -1) {
    locale = locale.substring(0, locale.lastIndexOf('_'));
    locales.push(locale);
  }
  return locales;
}

export async function resetI18n(locale) {
  locale = LocaleMap[locale] ?? locale;
  if (locale) {
    const locales = getLocales(locale);
    const predefinedTranslationsRequests = [];
    locales.forEach((loc) => {
      predefinedTranslationsRequests.push(importPredefinedTranslations(loc));
    });

    let customTranslations = {};
    if (getters.session.isModuleAvailable(AppModule.CUSTOM_TRANSLATIONS)) {
      customTranslations = await getCustomTranslations(locales);
    }

    const localeData = {};
    const predefinedTranslationsResult = await Promise.all(predefinedTranslationsRequests);
    predefinedTranslationsResult.forEach((predefinedTranslations, index) => {
      localeData[locales[index]] = predefinedTranslations;
    });
    Object.keys(customTranslations).forEach((loc) => {
      localeData[loc] = { ...localeData[loc], ...customTranslations[loc] };
    });
    locales.forEach((loc) => {
      i18n.setLocaleMessage(loc, localeData[loc]);
    });
    setI18nLanguage(locale);
  }
}

export const clearI18nCache = async () => {
  const cache = await getI18nCacheObject();
  if (cache) {
    const keys = await caches.keys();
    keys.forEach((key) => {
      if (key.startsWith(CACHE_ID)) {
        caches.delete(key);
      }
    });
  } else {
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith(CACHE_ID)) {
        localStorage.removeItem(key);
      }
    });
  }
};
