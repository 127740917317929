'use strict';
import angular from 'angular';
import _ from 'lodash';
import executiveSummaryPanelHtmlUrl from './executivesummarypanel.html';

angular.module('executivesummarypanel.components', [])
    .component('dashboardExecutiveSummaryPanel', {
        templateUrl: executiveSummaryPanelHtmlUrl,
        controller: ExecutiveSummaryPanelController,
        controllerAs: 'vm'
    });

/**
 *
 * @ngInject
 */
function ExecutiveSummaryPanelController(
    $element,
    ExecutiveSummaryFactory,
    UIExecutiveSummaryPanelFactory,
    UIExecutiveSummaryModalFactory,
    AppFactory,
    DesignFactory,
    PageFactory,
    PubSub,
    $SlidePanelEvents,
    $timeout,
    ExportBuilderDashboardService,
    ExportBuilderElementActionService
) {
    var vm = this;
    vm.$onInit = $onInit;
    vm.$postLink = $postLink;
    vm.$onDestroy = $onDestroy;
    vm.registerEvents = registerEvents;
    vm.canUserEdit = canUserEdit;
    vm.getItems = getItems;
    vm.getCurrentPageOrLayoutTitle = getCurrentPageOrLayoutTitle;
    vm.createSummary = createSummary;
    vm.closePanel = closePanel;
    vm.getPanelIsShowing = getPanelIsShowing;
    vm.getIsLoading = getIsLoading; // ES loadUIExecutiveSummaryPanelFactorying summary items

    vm.isReport = false;

    async function $onInit() {
        vm.panel = UIExecutiveSummaryPanelFactory;
        vm.summary = ExecutiveSummaryFactory.getExecutiveSummary();
        vm.page = DesignFactory.getCurrentPage();
        vm.isReport = _.isEmpty(vm.page);
        if (!vm.isReport) {
            vm.saveExecutiveSummary = async () => {
                if (window.isNUI) {
                    PubSub.emit('SegmentEvents', {
                        event: 'ExecutiveSummaryLegacyEvent',
                        payload: {
                            dashboard_id: vm.page.id,
                            dashboard_name: vm.page.title,
                            executive_summary_enabled: vm.page.executive_summary_enabled
                        }
                    });
                }

                ExecutiveSummaryFactory.setStateProperty('isLoading', true);
                await PageFactory.setEnableSummary(vm.page.id, vm.page.executive_summary_enabled);
                $timeout(() => {
                    ExecutiveSummaryFactory.setStateProperty('isLoading', false);
                });
            };
        } else {
            vm.report = ExportBuilderDashboardService.getBuilder().report;
            vm.reportPage = ExportBuilderDashboardService.getBuilder().currentPage;

            vm.saveExecutiveSummary = async () => {
                if (window.isNUI) {
                    PubSub.emit('SegmentEvents', {
                        event: 'ExecutiveSummaryLegacyEvent',
                        payload: {
                            report_id: vm.report.id,
                            report_page_id: vm.reportPage.id,
                            report_page_name: vm.reportPage.title,
                            executive_summary_enabled: vm.reportPage.executive_summary_enabled
                        }
                    });
                }

                ExecutiveSummaryFactory.setStateProperty('isLoading', true);
                vm.reportPage.executive_summary_enabled
                    ? await ExportBuilderElementActionService.setEnableExecutiveSummary(vm.reportPage)
                    : await ExportBuilderElementActionService.unsetEnableExecutiveSummary(vm.reportPage);
                $timeout(() => {
                    ExecutiveSummaryFactory.setStateProperty('isLoading', false);
                });
            };
        }
        registerEvents();
    }

    function $postLink() {
        $element.appendTo(document.body);
    }

    function $onDestroy() {
        PubSub.on($SlidePanelEvents.CLOSE_ALL, _onSlidePanelClose);
        vm.panel.closePanel();
        $element.remove();
    }

    function registerEvents() {
        PubSub.on($SlidePanelEvents.CLOSE_ALL, _onSlidePanelClose);
    }

    function getItems(){
        return ExecutiveSummaryFactory.getItems();
    }

    /**
     * Returns the title of page or layout
     * depending on if it's report studio or dashboard
     *
     * @returns {*}
     */
    function getCurrentPageOrLayoutTitle() {
        if (vm.isReport) {
            vm.reportPage = ExportBuilderDashboardService.getBuilder().currentPage;
            if (!vm.reportPage.is_executive_summary_page) {
                closePanel();
            }

            return ExportBuilderDashboardService.getBuilder().currentPage.title;
        }
        return DesignFactory.getCurrentLayout().title;
    }

    async function createSummary(){
        const summary = ExecutiveSummaryFactory.getExecutiveSummary();
        if (!summary.id) {
            if (vm.isReport) {
                summary.report_id = summary?.report_id ? summary.report_id : vm.report.id;
                summary.report_page_id = summary?.report_page_id ? summary.report_page_id : vm.reportPage.id;
            }
            const { id } = await ExecutiveSummaryFactory.save(summary);
            summary.id = id;
        }
        vm.summary = summary;
        var currentEditingIndex = ExecutiveSummaryFactory.getItems().length;
        UIExecutiveSummaryModalFactory.initModal(currentEditingIndex);
    }

    function closePanel(){
        return UIExecutiveSummaryPanelFactory.closePanel();
    }

    function canUserEdit() {
        return AppFactory.getUser().canManageDashboards;
    }

    function getPanelIsShowing() {
        return UIExecutiveSummaryPanelFactory.panel.isShowing;
    }

    function getIsLoading() {
        return ExecutiveSummaryFactory.getStateProperty('isLoading');
    }

    /**
     * Callback from close all event of SlidePanel
     * @param sourcePanel
     * @private
     */
    function _onSlidePanelClose(sourcePanel) {
        if (sourcePanel !== vm.panel.panelId) {
            vm.panel.closePanel();
        }
    }
}
