'use strict';
import angular from 'angular';

export const WidgetBuilderConstants = {
    DATA_TAB_INDEX: 0,
    STYLES_TAB_INDEX: 1,
    LIBRARY_TAB_INDEX: 2,
    NEW_WIDGET_ID: 'NEW_WIDGET_ID', // Temp ID for widget being added
    PREVIEW_CHART: 'preview-chart',
    SITE_AUDITOR: 'siteauditor',
    DONUT_SUM_KEY: 'pie_donut_sum',
    FORMAT_BOOL: 'bool',
};

export const BenchmarkSupportedServices = ['34', '39', '36', '194', '61'];
export const BenchmarkDrawOptions = ['benchmark_line_style', 'benchmark_line_type', 'benchmark_bullets'];

export const $WidgetBuilderEvents = {
    INIT_PANEL: '$WidgetBuilderEvents:INIT_PANEL',
    UPDATE_WIDGET_TYPE: '$WidgetBuilderEvents:UPDATE_WIDGET_TYPE',
    INIT_DATA_TAB: '$WidgetBuilderEvents:INIT_DATA_TAB',
    CHANGE_ALL_FILTER_ASSOCIATION: '$WidgetbuilderEvents:CHANGE_ALL_FILTER_ASSOCIATION',
    CLOSE_LIVE_FILTERS_SECTION: '$WidgetBuilderEvents:CLOSE_LIVE_FILTERS_SECTION',
    CLOSE_LIVE_FORMULAS_SECTION: '$WidgetBuilderEvents:CLOSE_LIVE_FORMULAS_SECTION',
    INIT_SETTINGS_TAB: '$WidgetBuilderEvents:INIT_SETTINGS_TAB',
    UPDATE_COLUMN_VALUE_STATES: '$WidgetBuilderEvents:UPDATE_COLUMN_VALUE_STATES',
    UPDATE_COLUMN_SELECTED_VALUES: '$WidgetBuilderEvents:UPDATE_COLUMN_SELECTED_VALUES',
    UPDATE_COLUMN_SELECTIONS: '$WidgetBuilderEvents:UPDATE_COLUMN_SELECTIONS',
    RESET_DATA_TAB: '$WidgetBuilderEvents:RESET_DATA_TAB',
    INIT_STYLES_TAB: '$WidgetBuilderEvents:INIT_STYLES_TAB',
    RESET_STYLES_TAB: '$WidgetBuilderEvents:RESET_STYLES_TAB',
    UPDATE_STYLES: '$WidgetBuilderEvents:UPDATE_STYLES',
    INIT_LIBRARY_TAB: '$WidgetBuilderEvents:INIT_LIBRARY_TAB',
    RESET_LIBRARY_TAB: '$WidgetBuilderEvents:RESET_LIBRARY_TAB',
    ADD_WIDGET: '$WidgetBuilderEvents:ADD_WIDGET',
    UPDATE_WIDGET: '$WidgetBuilderEvents:UPDATE_WIDGET',
    DID_SAVE_WIDGET: '$WidgetBuilderEvents:DID_SAVE_WIDGET',
    SWAP_WIDGET: '$WidgetBuilderEvents:SWAP_WIDGET',
    REMOVE_WIDGET: '$WidgetBuilderEvents:REMOVE_WIDGET',
    CLEAR_FILTERS: '$WidgetBuilderEvents:CLEAR_FILTERS',
    CLEAR_LIVE_FORMULAS: '$WidgetBuilderEvents:CLEAR_LIVE_FORMULAS',
    UPDATE_LIVE_FORMULAS: '$WidgetBuilderEvents:UPDATE_LIVE_FORMULAS',
    UPDATE_WIDGET_TYPE_WITHOUT_PLOT: '$WidgetBuilderEvents:UPDATE_WIDGET_TYPE_WITHOUT_PLOT',
    SET_DATE_RANGE_FROM_PROBE: '$WidgetBuilderEvents:SET_DATE_RANGE_FROM_PROBE',
    RS_SWITCH_DATA_TAB: '$WidgetBuilderEvents:RS_SWITCH_DATA_TAB',
};

angular.module('widget.builder.constants', [])
    .constant('WidgetBuilderConstants', WidgetBuilderConstants)
    .constant('$WidgetBuilderEvents', $WidgetBuilderEvents);