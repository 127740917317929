// Currently, Backend uses only language codes for locale. We can remove this once we upgrade to have country code in locale
export const LocaleMap = {
  fr: 'fr_CA',
  en: 'en_US',
  ja: 'ja_JP',
};

export const Locales = {
  FR: 'fr',
  FR_CA: 'fr_CA',
  EN: 'en',
  EN_US: 'en_US',
  JA: 'ja',
  JA_JP: 'ja_JP',
  TEST: 'TEST',
};

export const CACHE_ID = 'tapclicks-i18n';
