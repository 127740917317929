import SliceChartWidgetRenderService from '@/modules/ta/widget/services/types/SliceChartWidgetRenderService';
import { FunnelChartConfig } from '@/modules/core/charts/models/FunnelChartConfig';
import { PyramidChartConfig } from '@/modules/core/charts/models/PyramidChartConfig';
import { PictorialChartConfig } from '@/modules/core/charts/models/PictorialChartConfig';
import { SeriesType } from '@/modules/core/charts/chart.constants';

export default class FunnelChartWidgetRenderService extends SliceChartWidgetRenderService {
  /**
   * @param config
   * @returns {FunnelChartConfig}
   */
  initConfig(config) {
    const chartConfig = {
      [SeriesType.PYRAMID]: PyramidChartConfig,
      [SeriesType.PICTORIAL]: PictorialChartConfig,
      [SeriesType.FUNNEL]: FunnelChartConfig,
    };

    return new chartConfig[this.getSeriesType()](config);
  }

  /**
   * @returns {string}
   */
  getSeriesType() {
    const { plot_type } = this.getDrawOptions();

    switch (plot_type) {
      case SeriesType.PYRAMID:
        return SeriesType.PYRAMID;
      case SeriesType.PICTORIAL:
        return SeriesType.PICTORIAL;
      default:
        return SeriesType.FUNNEL;
    }
  }

  getDrawOptions() {
    return super.getDrawOptions();
  }

  /**
   * @returns {number}
   */
  getPyramidHeight() {
    const drawOptions = this.getDrawOptions();
    const neckHeight = drawOptions.neck_height > 200 ? 200 : drawOptions.neck_height;
    return 200 - neckHeight;
  }

  /**
   * @returns {{}}
   */
  getSeries() {
    const { is_rotated, neck_width } = this.getDrawOptions();
    const neckWidth = `${Math.floor(neck_width / 6)}%`;
    const [topWidth, bottomWidth] = is_rotated ? [neckWidth, '90%'] : ['90%', neckWidth];
    return {
      ...super.getSeries(),
      topWidth,
      bottomWidth,
      pyramidHeight: this.getPyramidHeight(),
    };
  }

  /**
   * Return the column format (used in labels / tooltips / legend)
   * @returns {*[]}
   */
  getDataFormats() {
    return this.nonGroupedColumns.map((column) => column.format);
  }

  /**
   * Base config that is used during chart generation
   * @returns {{data: *}}
   */
  getChartConfigProperties() {
    const drawOptions = this.getDrawOptions();

    return {
      ...super.getChartConfigProperties(),
      dataFormats: this.getDataFormats(),
      plotType: drawOptions.plot_type,
      showLegend: drawOptions.has_legend,
      isRotated: drawOptions.is_rotated,
      isNormalized: drawOptions.is_normalized,
      showLabels: drawOptions.show_labels,
      showLabelNames: drawOptions.show_label_names,
      showLabelPercent: drawOptions.show_label_percent,
      showLabelValues: drawOptions.show_label_values,
      hasTooltip: drawOptions.has_tooltip,
      otherSlicePct: drawOptions.other_percent,
      labelPct: drawOptions.label_percent,
      neckHeight: drawOptions.neck_height,
      neckWidth: drawOptions.neck_width,
      backgroundGradient: drawOptions.background_gradient,
      gradientColor: drawOptions.gradient_color,
      fillType: drawOptions.fill_type,
      smoothLines: drawOptions.is_smoothed_line,
      grainDensity: drawOptions.grain_density,
      invertMetrics: drawOptions.invert_metrics,
      orderMetrics: drawOptions.order_metrics,
      chartPalette: this.getFullChartPalette(),
      isComparison: this.hasComparison(),
      pictorialOptions: drawOptions.v2_pictorial_options,
    };
  }
}
