/* eslint-disable max-classes-per-file */

import Enum from '@/modules/core/app/models/abstracts/Enum';

export class WidgetType extends Enum {
  static TYPE_BIGNUMBER = 'bignumber';

  static TYPE_DATAGRID = 'datagrid';

  static TYPE_TOPPERFORMER = 'topperformer';

  static TYPE_BARCHART = 'barchart';

  static TYPE_BUBBLECHART = 'bubblechart';

  static TYPE_COMBINATIONCHART = 'combinationchart';

  static TYPE_LINECHART = 'linechart';

  static TYPE_PIECHART = 'piechart';

  static TYPE_GEOCHART = 'geochart';

  static TYPE_GAUGECHART = 'gaugechart';

  static TYPE_FUNNELCHART = 'funnelchart';

  static TYPE_MEDIA = 'media';

  static TYPE_ACCOUNTMANAGER = 'accountmanager';

  static TYPE_GOAL = 'goal';

  static TYPE_GOALCHART = 'goalchart';

  static TYPE_CHATGPT = 'chatgpt';

  static TYPE_EXECUTIVESUMMARY = 'executivesummary';
}

export class WidgetState extends Enum {
  static STATE_LOADING = 'loading';

  static STATE_BUILDING = 'building';

  static STATE_NEEDS_RELOAD = 'needsReload';

  // There are no records AT ALL in the system (exno campaigns)
  static STATE_NO_RECORDS = 'noRecords';

  // There are records in the system, but none returned for the given filters/dateranges
  static STATE_NO_DATA = 'noData';

  // We have records but they are all null or 0s
  static STATE_ALL_EMPTY_DATA = 'allEmptyData';

  // There may be data but there are no columns to display them with
  static STATE_NO_COLUMNS = 'noColumns';

  // Means the widget is not connected for whoever is in context of the dashboard
  static STATE_INACTIVE = 'inactive';

  static STATE_HAS_ERROR = 'hasError';

  // Too many data to display in the widget
  static STATE_TOO_MANY_DATA = 'tooManyData';

  static STATE_NO_DATA_ACTIONABLE = 'noDataActionable';

  static STATE_INCOMPLETE_BUILDER_CONFIGURATION = 'inCompleteBuilderConfiguration';

  static STATE_DONE = false;
}

export class TimeGrouping extends Enum {
  static GROUPING_YEARLY = 'yearly';

  static GROUPING_MONTHLY = 'monthly';

  static GROUPING_WEEKLY = 'weekly';

  static GROUPING_DAILY = 'daily';

  static GROUPING_HOURLY = 'hourly';

  static GROUPING_MONTH_OF_YEAR = 'monthofyear';

  static GROUPING_DAY_OF_MONTH = 'dayofmonth';

  static GROUPING_DAY_OF_WEEK = 'dayofweek';

  static GROUPING_HOUR_OF_DAY = 'hourofday';

  static GROUPING_DYNAMIC = 'dynamic';

  static GROUPING_QUARTERLY = 'quarterly';

  static GROUPING_NON_ISO_WEEK = 'nonisoweek';

  static GROUPING_HOURLY_ADVERTISER = 'hourlystatsadvertiser';

  static GROUPING_HOURLY_AUDIENCE = 'hourlystatsaudience';
}

export class WeeklyStartDay extends Enum {
  static SUNDAY = 'sunday';

  static MONDAY = 'monday';
}

// Widget data draw options
export class DrawOption extends Enum {
  // Type the draw options applies to
  static TYPE_ALL = 'widget_all'; // Applies to look and feel of all widgets

  static TYPE_WIDGET = 'widget_type'; // Applies to how data is displayed for that widget type

  static TYPE_REPORT = 'report'; // Applies to how report widgets and other elements are displayed in export builder
  // Draw option location

  static LOCATION_DASHBOARD = 'dashboard';

  static LOCATION_EXPORT = 'export';
  // All widget options

  static SHOW_BACKGROUND = 'show_background';

  static BORDER_COLOR = 'border_color';

  static SHOW_BORDERS = 'show_borders';

  static SHOW_SHADOW = 'show_shadow';

  static SHOW_TITLE = 'show_title';

  static SHOW_HEADER = 'show_header';

  static SHOW_DATA_SOURCE = 'show_data_source';

  static ENABLE_GRID_THEME = 'grid_theme';

  static SHOW_PAGE_HEADER = 'show_page_header';

  static SHOW_WIDGETS_WITH_NO_DATA = 'show_widgets_with_no_data';

  static SHOW_PAGE_FOOTER = 'show_page_footer';

  static IS_DARK_MODE = 'is_dark_mode';

  static SHOW_COMPARISON_ARROWS = 'show_comparison_arrows';

  static DEFAULT_COMPARISON_ARROW_COLOR = 'default_comparison_arrow_color';
  // Widget type options

  static CIRCLE_SIZE = 'circle_size';

  static FONT_SIZE = 'font_size';

  static REDUCE_NUMBER = 'reduce_number';

  static CENTER_NUMBERS = 'center_numbers';

  static CIRCLE_NUMBER = 'circle_number';

  static PLOT_TYPE = 'plot_type';

  static HAS_LEGEND = 'has_legend';

  static IS_ROTATED = 'is_rotated';

  static HAS_VALUE_SCROLLER = 'has_value_scroller';

  static IS_HAND_DRAWN = 'is_hand_drawn';

  static IS_Y_AXIS_MOVED = 'is_y_axis_moved';

  static IS_NORMALIZED = 'is_normalized';

  static IS_ZERO_SCALED = 'is_zero_scaled';

  static SHOW_LABELS = 'show_labels';

  static SHOW_LABEL_NAMES = 'show_label_names';

  static SHOW_LABEL_VALUES = 'show_label_values';

  static SHOW_LABEL_PERCENT = 'show_label_percent';

  static SHOW_METRIC_LABELS = 'show_metric_labels';

  static OPTION_SHOW_VALUES_ON_SHAPES = 'show_values_on_shapes';

  static DATE_FORMAT_TYPE = 'date_format_type';

  static NECK_HEIGHT = 'neck_height';

  static NECK_WIDTH = 'neck_width';

  static DEPTH = 'depth';

  static ANGLE = 'angle';

  static HIDE_GRID_LINES = 'hide_grid_lines';

  static OTHER_PERCENT = 'other_percent';

  static LABEL_PERCENT = 'label_percent';

  static START_ANGLE = 'start_angle';

  static INNER_RADIUS = 'inner_radius';

  static HAS_BULLETS = 'has_bullets';

  static HAS_TOOLTIP = 'has_tooltip';

  static IS_SMOOTHED_LINE = 'is_smoothed_line';

  static SHOW_SAMPLE_DATA = 'show_sample_data';

  static FORCE_SAMPLE_DATA = 'force_sample_data';

  static SHOW_EMPTY_DATES = 'show_empty_dates';

  static IS_SOLID_GAUGE = 'is_solid_gauge';
  // Datagrid

  static DISPLAY_LENGTH = 'display_length';

  static REPORT_DISPLAY_LENGTH = 'report_display_length';

  static GRID_PAGINATE = 'grid_paginate';

  static GRID_IS_RESPONSIVE = 'grid_is_responsive';

  static GRID_COLLAPSE_IN_MODAL = 'grid_collapse_in_modal';

  static SHOW_TOTAL_ROW = 'show_total_row';

  static GRID_TOTAL_ROW_BOTTOM = 'grid_total_row_bottom';

  static GRID_FULL_IMAGE_SIZE = 'grid_full_image_size';

  static GRID_PREVIEW_AS_IFRAME = 'grid_preview_as_iframe';

  static GRID_IFRAME_CAPTURE_DELAY = 'grid_iframe_capture_delay';

  static PIVOT_GRID = 'pivot_grid';

  static WRAP_TEXT_NAME = 'wrap_text_name';

  static STYLIZED_EXCEL = 'stylized_excel';

  // Toggle Types

  static TOGGLE_TYPE_SHOW = 'show';

  static TOGGLE_TYPE_HIDE = 'hide';

  static TOGGLE_TYPE_ENABLE = 'enable';

  static TOGGLE_TYPE_DISABLE = 'disable';
}

export const WidgetBuilderConstants = {
  DATA_TAB_INDEX: 0,
  STYLES_TAB_INDEX: 1,
  LIBRARY_TAB_INDEX: 2,
  NEW_WIDGET_ID: -1, // Temp ID for widget being added
  PREVIEW_CHART: 'preview-chart',
};

export const WidgetBuilderPreviewState = {
  COMPLETE_FOR_PREVIEW: false,
  MISSING_DATA_SOURCE: 'missing_data_source',
  MISSING_DATA_VIEW: 'missing_data_view',
  MISSING_SELECTED_METRICS: 'missing_selected_metrics',
  MISSING_GROUPED_METRICS: 'missing_grouped_metrics',
};

export const WidgetBuilderUIEvent = {
  DID_SELECT_WIDGET_TEMPLATE: 'WidgetBuilderUIEvent:did_select_widget_template',
  DID_SELECT_WIDGET_TYPE: 'WidgetBuilderUIEvent:did_select_widget_type',
  CLOSE_PANEL: 'WidgetBuilderUIEvent:close_panel',
};

export const WidgetBuilderEvent = {
  CANCELLED: 'WidgetBuilderEvent:cancelled',
  DID_SELECT_WIDGET_TYPE: 'WidgetBuilderEvent:did_select_widget_type',
  DID_CREATE_CALCULATION: 'WidgetBuilderEvent:did_create_calculation',
  SAVED: 'WidgetBuilderEvent:saved',
  RELOAD_WIDGET: 'WidgetBuilderEvent:reload_widget',
  STARTED: 'WidgetBuilderEvent:started',
};

export const WidgetEvent = {
  DUPLICATED: 'WidgetEvent:duplicated',
  CONTENT_REF: 'WidgetEvent:content_ref',
  RELOAD_WIDGET: 'reload-widget',
  RELOAD_CHART: 'reload-chart',
};

export const MediaWidgetEvent = {
  UPDATE_MEDIA_WIDGET_CONTENT: 'MediaWidgetEvent:update-media-widget-content',
};

export const WidgetSize = {
  BASE_HEIGHT: 80, // px
  DEFAULT_HEIGHT: 5, // grid units
  MIN_WIDTH: 3, // grid units
  MIN_HEIGHT: 1, // grid units
  MAX_WIDTH: 12, // grid units
  PREVIEW_HEIGHT: 5, // grid units
  MAX_HEIGHT: 14, // grid units
};

export const PlotType = {
  CLUSTERED: 'clustered',
  LINE: 'line',
  LINE_V2: 'line(v2)',
  STACKED: 'regular',
  FULL_STACKED: '100%',
  DEEP_STACKED: '3d',
  AREA: 'area',
  AREA_V2: 'area(v2)',
  HEAT_MAP: 'heat',
  BUBBLE_MAP: 'bubble',
  DEFAULT: 'default',
  CLASSIC: 'classic',
  FUNNEL: 'funnel',
  PYRAMID: 'pyramid',
  STACKED_V2: 'stacked(v2)',
  CLUSTERED_V2: 'clustered(v2)',
  FULL_STACKED_V2: 'full_stacked(v2)',
  RADIAL_HISTOGRAM_V2: 'radial_histogram(v2)',
  RADIAL_BAR_V2: 'radial_bar(v2)',
  LAYERED_V2: 'layered(v2)',
  LOLLIPOP_V2: 'lollipop(v2)',
  GAUGE_V2: 'gauge(v2)',
  MULTI_AXIS_GAUGE: 'gauge_multi_axis(v2)',
};

export class WidgetDataProbeState extends Enum {
  static IDLE = 'idle';

  static LOADING = 'loading';

  static NO_DATA = 'no_data';

  static SUCCESS = 'success';
}

export const DateFormatType = {
  NO_YEAR: 'noYear',
  DEFAULT: 'default',
};

export const WidgetData = {
  MAX_RESULTS: 1000,
};

export const ApiDataFields = {
  ROW_GROUPING_COUNT: 'row_grouping_count',
  TOTAL_CLIENT_COUNT: 'total_client_count',
};

export const WidgetDatePeriodFormat = {
  YEAR: 'YYYY',
  MONTH: 'MMM YYYY',
  MONTH_WITHOUT_YEAR: 'MMM',
  DATE: 'MMM DD YYYY',
  DATE_WITHOUT_YEAR: 'MMM DD',
  HOUR: 'hh:mm a MMM DD, YYYY',
  HOUR_WITHOUT_YEAR: 'hh:mm a MMM DD',
  MONTH_OF_YEAR: 'MMMM',
  DAY_OF_MONTH: 'Do',
  DAY_OF_WEEK: 'dddd',
  HOUR_OF_DAY: 'hhA',
};

export const DrawOptionLocation = {
  DASHBOARD: 'dashboard',
  EXPORT: 'export',
};

export class WidgetFilterConditionType extends Enum {
  static EQ = 'eq';

  static GT = 'gt';

  static LT = 'lt';

  static BTW = 'btw';

  static IN = 'in';

  static NIN = 'nin';
}

export const WidgetFilterConditions = [
  { key: WidgetFilterConditionType.EQ, value: 'Equal', is_metric: true },
  { key: WidgetFilterConditionType.GT, value: 'Greater than', is_metric: true },
  { key: WidgetFilterConditionType.LT, value: 'Less than', is_metric: true },
  { key: WidgetFilterConditionType.BTW, value: 'Between', is_metric: true },
  { key: WidgetFilterConditionType.IN, value: 'Contains', is_metric: false },
  { key: WidgetFilterConditionType.NIN, value: 'Not Contains', is_metric: false },
];

export const WidgetFilterAssociation = {
  AND: 'and',
  OR: 'or',
};

export const WidgetBuilderTab = {
  DATA_TAB: 0,
  ADVANCED_OPTION_TAB: 1,
  STYLE_TAB: 2,
};

export const WidgetTypeGrouping = {
  DATASOURCED: 'datasourced',
  CHART: 'chart',
  ADMIN: 'admin',
  PREMADE: 'premade',
  SERIES: 'series',
  DISPLAY: 'display',
};

export const WidgetBackgroundColorOptions = {
  DATASOURCE: 'datasource',
  THEME: 'theme',
  CUSTOM: 'custom',
};

export const WidgetBackgroundGradientColorOptions = {
  SOLID: 'solid',
  LINEAR: 'linear',
  LINEAR_Y: 'linear-y',
  RADIAL: 'radial',
};

export const HandTypes = {
  NEEDLE: 'needle',
  POINTER: 'pointer',
  TRIANGLE: 'triangle',
  NONE: 'none',
  LINE: 'line',
};

export const GradientTargets = {
  EVERYTHING: 'everything',
  HAND: 'hand',
  METRIC: 'metric',
  BAND: 'band',
  HAND_METRIC: 'hand_metric',
  HAND_BAND: 'hand_band',
  METRIC_BAND: 'metric_band',
};

export const WidgetPlotTypeOptions = {
  DEFAULT: 'default',
  MULTI_METRIC: 'multi_metric',
  COMPARISON: 'comparison',
  SPARKLINE: 'sparkline',
};

export const WidgetComparisonOptions = {
  MONTH_OVER_MONTH: 'month_over_month',
  QUARTER_OVER_QUARTER: 'quarter_over_quarter',
  YEAR_OVER_YEAR: 'year_over_year',
};

export const WidgetSparklineOptions = {
  LAST_3_MONTHS: 'last_3_months',
  LAST_6_MONTHS: 'last_6_months',
  YEAR_TO_DATE: 'year_to_date',
};

export const BenchmarkLineStyle = {
  DASHED: 'dashed',
  DOTTED: 'dotted',
  SOLID: 'solid',
  NONE: 'none',
};

export const WidgetEvents = {
  FRAME_ENDED: 'frame-ended',
};

export const WidgetConfig = {
  WIDGET: 'widget',
  STATE: 'state',
};
