import angular from 'angular';

import {
    ReportElementTypes
} from 'coreModules/exportbuilder/exportbuilder.constants'
import {$WidgetBuilderEvents} from "coreModules/design/widget/builder/widget.builder.constants";
import {DrawOption} from "coreModules/design/layout/drawoptionpanel/drawoptionpanel.constants";

angular.module('exportbuilder.dashboard.services')
    .factory('ExportBuilderElementActionService', ExportBuilderElementActionService)
    .factory('ExportBuilderElementMutatorService', ExportBuilderElementMutatorService);

/**
 * @ngInject
 */
function ExportBuilderElementActionService(
    ExportBuilderElementMutatorService
) {

    return {
        addNewItem: addNewItem,

        setImage: setImage,
        setImageFillMode: setImageFillMode,

        setMirrorXElement: setMirrorXElement,
        setMirrorYElement: setMirrorYElement,
        setOpacity: setOpacity,

        setTextAlignment: setTextAlignment,
        setBackgroundColor: setBackgroundColor,
        setTextTitle: setTextTitle,
        setTextVerticalAlignment: setTextVerticalAlignment,
        setTextSize: setTextSize,
        setTextColor: setTextColor,
        setTextOpacity: setTextOpacity,
        setTextShadowX: setTextShadowX,
        setTextShadowY: setTextShadowY,
        setTextShadowBlur: setTextShadowBlur,
        setTextShadowColor: setTextShadowColor,
        setPadding: setPadding,
        toggleTextBold: toggleTextBold,
        toggleTextItalic: toggleTextItalic,
        toggleTextUnderline: toggleTextUnderline,
        toggleTextStrikethrough: toggleTextStrikethrough,
        setFontFamily: setFontFamily,

        makeItemOnEveryPage: makeItemOnEveryPage,
        setSnapToGrid: setSnapToGrid,
        setToBackground: setToBackground,
        setBorderRadius: setBorderRadius,
        setShadowX: setShadowX,
        setShadowY: setShadowY,
        setShadowBlur: setShadowBlur,
        setShadowColor: setShadowColor,
        setBorderColor: setBorderColor,
        setBorderWidth: setBorderWidth,
        setBorderType: setBorderType,
        setBorderOpacity: setBorderOpacity,
        setAspectRatio: setAspectRatio,

        setShapeBackgroundColor: setShapeBackgroundColor,

        toggleReportWidgetDrawOption: toggleReportWidgetDrawOption,
        toggleWidgetDrawOption: toggleWidgetDrawOption,

        setIconColor: setIconColor,

        setDelete: setDelete,

        setCoordinates: setCoordinates,
        setFitToPage: setFitToPage,

        setMoveY: setMoveY,
        setMoveX: setMoveX,

        setColorPalette: setColorPalette,
        setFontSize: setFontSize,

        setPageBackgroundColor: setPageBackgroundColor,
        setPageTitle: setPageTitle,
        reorderPagesIndeces: reorderPagesIndeces,
        reorderItemsZIndeces: reorderItemsZIndeces,

        setZIndexForElement: setZIndexForElement,
        setElementToBack: setElementToBack,
        setElementToFront: setElementToFront,

        setCopyInPlace: setCopyInPlace,
        setCopyOnPreviousPage: setCopyOnPreviousPage,
        setCopyOnNextPage: setCopyOnNextPage,
        setPaste: setPaste,
        setCut: setCut,

        setAddPageAtIndex: setAddPageAtIndex,
        setDeletePageAtIndex: setDeletePageAtIndex,
        setDuplicatePage: setDuplicatePage,

        setPageAsCoverPage: setPageAsCoverPage,
        setPageAsBackPage: setPageAsBackPage,
        setEnableExecutiveSummary: setEnableExecutiveSummary,

        unsetPageAsCoverPage: unsetPageAsCoverPage,
        unsetPageAsBackPage: unsetPageAsBackPage,
        unsetEnableExecutiveSummary: unsetEnableExecutiveSummary,

        setEditWidget: setEditWidget,
        setEditWidgetFromBuildMode: setEditWidgetFromBuildMode,
        saveEditWidgetFromBuildMode: saveEditWidgetFromBuildMode,
        saveEditWidgetFromSpecialEdit: saveEditWidgetFromSpecialEdit,
        setWidgetDateRange: setWidgetDateRange,
        setWidgetFilters: setWidgetFilters,
        deleteWidgetFilter: deleteWidgetFilter,
        addTableRow: addTableRow,
        removeTableRow: removeTableRow,
        addTableColumn: addTableColumn,
        removeTableColumn: removeTableColumn,
        moveRowUp: moveRowUp,
        moveRowDown: moveRowDown,
        moveColumnLeft: moveColumnLeft,
        moveColumnRight: moveColumnRight,
        setCurrency: setCurrency,
        assignDatasourcesToPages: assignDatasourcesToPages
    };

    function setCurrency(currency){
        ExportBuilderElementMutatorService.setCurrency(currency);
    }

    function setAspectRatio(value){
        ExportBuilderElementMutatorService.setAspectRatio(value);
    }

    function addTableRow(rowIndex) {
        ExportBuilderElementMutatorService.addTableRow(rowIndex);
    }

    function removeTableRow(rowIndex) {
        ExportBuilderElementMutatorService.removeTableRow(rowIndex);
    }

    function moveRowUp(rowIndex) {
        ExportBuilderElementMutatorService.moveRowUp(rowIndex);
    }

    function moveRowDown(rowIndex) {
        ExportBuilderElementMutatorService.moveRowDown(rowIndex);
    }

    function addTableColumn(columnIndex) {
        ExportBuilderElementMutatorService.addTableColumn(columnIndex);
    }

    function removeTableColumn(columnIndex) {
        ExportBuilderElementMutatorService.removeTableColumn(columnIndex);
    }

    function moveColumnLeft(columnIndex) {
        ExportBuilderElementMutatorService.moveColumnLeft(columnIndex);
    }

    function moveColumnRight(columnIndex) {
        ExportBuilderElementMutatorService.moveColumnRight(columnIndex);
    }

    function addNewItem(element, params, widget) {
        params = params || {};
        ExportBuilderElementMutatorService.addNewItem(element, params, widget)
    }

    function setImage(url) {
        ExportBuilderElementMutatorService.setImage(url);
    }

    function setImageFillMode(value) {
        ExportBuilderElementMutatorService.setImageFillMode(value);
    }

    function setMirrorXElement(value) {
        ExportBuilderElementMutatorService.setMirrorXElement(value)
    }

    function setMirrorYElement(value) {
        ExportBuilderElementMutatorService.setMirrorYElement(value)
    }

    function setOpacity(value) {
        ExportBuilderElementMutatorService.setOpacity(value)
    }

    function setTextAlignment(value) {
        ExportBuilderElementMutatorService.setTextAlignment(value)
    }

    function setBackgroundColor(value) {
        ExportBuilderElementMutatorService.setBackgroundColor(value)
    }

    function setTextVerticalAlignment(value) {
        ExportBuilderElementMutatorService.setTextVerticalAlignment(value)
    }

    function setTextTitle(value) {
        ExportBuilderElementMutatorService.setTextTitle(value)
    }

    function setTextSize(value) {
        ExportBuilderElementMutatorService.setTextSize(value)
    }

    function setTextColor(value) {
        ExportBuilderElementMutatorService.setTextColor(value)
    }

    function setTextOpacity(value) {
        ExportBuilderElementMutatorService.setTextOpacity(value)
    }

    function setTextShadowX(value) {
        ExportBuilderElementMutatorService.setTextShadowX(value)
    }

    function setTextShadowY(value) {
        ExportBuilderElementMutatorService.setTextShadowY(value)
    }

    function setTextShadowBlur(value) {
        ExportBuilderElementMutatorService.setTextShadowBlur(value)
    }

    function setTextShadowColor(value) {
        ExportBuilderElementMutatorService.setTextShadowColor(value)
    }

    function setPadding(value) {
        ExportBuilderElementMutatorService.setPadding(value)
    }

    function toggleTextBold(value) {
        ExportBuilderElementMutatorService.toggleTextBold(value)
    }

    function toggleTextItalic(value) {
        ExportBuilderElementMutatorService.toggleTextItalic(value)
    }

    function toggleTextUnderline(value) {
        ExportBuilderElementMutatorService.toggleTextUnderline(value)
    }

    function toggleTextStrikethrough(value) {
        ExportBuilderElementMutatorService.toggleTextStrikethrough(value)
    }

    function setFontFamily(value) {
        ExportBuilderElementMutatorService.setFontFamily(value)
    }

    function makeItemOnEveryPage(value) {
        ExportBuilderElementMutatorService.makeItemOnEveryPage(value)
    }

    function setSnapToGrid(value) {
        ExportBuilderElementMutatorService.setSnapToGrid(value)

    }

    function setToBackground(value) {
        ExportBuilderElementMutatorService.setToBackground(value)
    }

    function setBorderRadius(value) {
        ExportBuilderElementMutatorService.setBorderRadius(value)

    }

    function setShadowX(value) {
        ExportBuilderElementMutatorService.setShadowX(value)
    }

    function setShadowY(value) {
        ExportBuilderElementMutatorService.setShadowY(value)
    }

    function setShadowBlur(value) {
        ExportBuilderElementMutatorService.setShadowBlur(value)
    }

    function setShadowColor(value) {
        ExportBuilderElementMutatorService.setShadowColor(value)
    }

    function setBorderColor(value) {
        ExportBuilderElementMutatorService.setBorderColor(value)
    }

    function setBorderWidth(value) {
        ExportBuilderElementMutatorService.setBorderWidth(value)
    }

    function setBorderType(value) {
        ExportBuilderElementMutatorService.setBorderType(value)
    }

    function setBorderOpacity(value) {
        ExportBuilderElementMutatorService.setBorderOpacity(value)
    }

    function setShapeBackgroundColor(value) {
        ExportBuilderElementMutatorService.setShapeBackgroundColor(value);
    }

    function toggleReportWidgetDrawOption(drawOptionKey, value) {
        ExportBuilderElementMutatorService.toggleReportWidgetDrawOption(drawOptionKey, value);
    }

    function toggleWidgetDrawOption(drawOptionKey, value) {
        ExportBuilderElementMutatorService.toggleWidgetDrawOption(drawOptionKey, value);
    }

    function setIconColor(value) {
        ExportBuilderElementMutatorService.setIconColor(value);
    }

    function setDelete() {
        ExportBuilderElementMutatorService.setDelete();
    }

    function setCoordinates(data) {
        ExportBuilderElementMutatorService.setCoordinates(data);
    }

    function setFitToPage() {
        ExportBuilderElementMutatorService.setFitToPage();
    }

    function setMoveY(direction, multiplier) {
        ExportBuilderElementMutatorService.setMoveY(direction, multiplier);
    }

    function setMoveX(direction, multiplier) {
        ExportBuilderElementMutatorService.setMoveX(direction, multiplier);
    }

    function setColorPalette(value) {
        ExportBuilderElementMutatorService.setColorPalette(value);
    }

    function setFontSize(value) {
        ExportBuilderElementMutatorService.setFontSize(value);
    }

    function setPageBackgroundColor(page, value) {
        ExportBuilderElementMutatorService.setPageBackgroundColor(page, value);
    }

    function setPageTitle(page, value) {
        ExportBuilderElementMutatorService.setPageTitle(page, value);
    }

    function reorderPagesIndeces(originalIndeces, pages) {

        let isSameOrder = true;
        _.each(pages, function (page) {
            if (originalIndeces[page.id] !== page.page_index) {
                isSameOrder = false;
                return false;
            }
        });

        !isSameOrder && ExportBuilderElementMutatorService.reorderPagesIndeces(originalIndeces, pages);
    }

    function reorderItemsZIndeces(originalIndeces, elements) {

        let isSameOrder = true;
        _.each(elements, function (element) {
            if (originalIndeces[element.id] !== element.z_index) {
                isSameOrder = false;
                return false;
            }
        });

        !isSameOrder && ExportBuilderElementMutatorService.reorderItemsZIndeces(originalIndeces, elements);
    }

    function setElementToBack(element) {
        ExportBuilderElementMutatorService.setElementToBack(element);
    }

    function setElementToFront(element) {
        ExportBuilderElementMutatorService.setElementToFront(element);
    }

    function setZIndexForElement(element, step) {
        ExportBuilderElementMutatorService.setZIndexForElement(element, step);
    }

    function setCopyInPlace(element) {
        ExportBuilderElementMutatorService.setCopyInPlace(element);
    }

    function setCopyOnPreviousPage(element) {
        ExportBuilderElementMutatorService.setCopyOnPreviousPage(element);
    }

    function setCopyOnNextPage(element) {
        ExportBuilderElementMutatorService.setCopyOnNextPage(element);
    }

    function setPaste(elementId) {
        ExportBuilderElementMutatorService.setPaste(elementId);
    }

    function setCut(elementId) {
        ExportBuilderElementMutatorService.setCut(elementId);
    }

    function setAddPageAtIndex(index, isExecSummaryPage = false, widgetBuilderModel = {}) {
        return ExportBuilderElementMutatorService.setAddPageAtIndex(index, isExecSummaryPage, widgetBuilderModel);
    }

    function setDeletePageAtIndex(index) {
        return ExportBuilderElementMutatorService.setDeletePageAtIndex(index);
    }

    function setDuplicatePage(pageId) {
        return ExportBuilderElementMutatorService.setDuplicatePage(pageId);
    }

    function setPageAsCoverPage(page) {
        ExportBuilderElementMutatorService.setPageAsCoverPage(page);
    }

    function setPageAsBackPage(page) {
        return ExportBuilderElementMutatorService.setPageAsBackPage(page);
    }

    function setEnableExecutiveSummary(page) {
        return ExportBuilderElementMutatorService.setEnableExecutiveSummary(page);
    }

    function unsetPageAsCoverPage(page) {
        ExportBuilderElementMutatorService.unsetPageAsCoverPage(page);
    }

    function unsetPageAsBackPage(page) {
        return ExportBuilderElementMutatorService.unsetPageAsBackPage(page);
    }

    function unsetEnableExecutiveSummary(page) {
        return ExportBuilderElementMutatorService.unsetEnableExecutiveSummary(page);
    }

    function setEditWidget(widget) {
        return ExportBuilderElementMutatorService.setEditWidget(widget);
    }

    function setEditWidgetFromBuildMode(widget) {
        return ExportBuilderElementMutatorService.setEditWidgetFromBuildMode(widget);
    }

    function saveEditWidgetFromBuildMode(widget) {
        return ExportBuilderElementMutatorService.saveEditWidgetFromBuildMode(widget);
    }

    function saveEditWidgetFromSpecialEdit(widget) {
        return ExportBuilderElementMutatorService.saveEditWidgetFromSpecialEdit(widget);
    }

    function setWidgetDateRange(widget) {
        return ExportBuilderElementMutatorService.setWidgetDateRange(widget);
    }

    function setWidgetFilters(widget) {
        return ExportBuilderElementMutatorService.setWidgetFilters(widget);
    }

    function deleteWidgetFilter(element) {
        return ExportBuilderElementMutatorService.deleteWidgetFilter(element);
    }

    function assignDatasourcesToPages(pages) {
        return ExportBuilderElementMutatorService.assignDatasourcesToPages(pages);
    }
}

/**
 * @ngInject
 */
function ExportBuilderElementMutatorService(
    ExportBuilderDashboardItemService,
    ExportBuilderDashboardService,
    ReportStudioTemplateDataService,
    ExportBuilderDashboardUtilService,
    ExportBuilderDashboardPageService,
    UndoRedoService,
    WidgetUtilService,
    ExportBuilderUndoModelFactory,
    WidgetBuilderService,
    PubSub,
    DrawOption,
    ChartPlotType,
    WidgetType,
    $ExportBuilderDashboardEvents,
    UIFactory
) {

    return {
        performGenericMutation: performGenericMutation,

        setImage: setImage,
        setImageFillMode: setImageFillMode,

        setMirrorXElement: setMirrorXElement,
        setMirrorYElement: setMirrorYElement,
        setOpacity: setOpacity,

        assignDatasourcesToPages: assignDatasourcesToPages,

        setTextAlignment: setTextAlignment,
        setBackgroundColor: setBackgroundColor,
        setTextTitle: setTextTitle,
        setTextSize: setTextSize,
        setTextVerticalAlignment: setTextVerticalAlignment,
        setTextColor: setTextColor,
        setTextOpacity: setTextOpacity,
        setTextShadowX: setTextShadowX,
        setTextShadowY: setTextShadowY,
        setTextShadowBlur: setTextShadowBlur,
        setTextShadowColor: setTextShadowColor,
        setPadding: setPadding,
        toggleTextBold: toggleTextBold,
        toggleTextItalic: toggleTextItalic,
        toggleTextUnderline: toggleTextUnderline,
        toggleTextStrikethrough: toggleTextStrikethrough,
        setFontFamily: setFontFamily,

        makeItemOnEveryPage: makeItemOnEveryPage,
        setSnapToGrid: setSnapToGrid,
        setToBackground: setToBackground,
        setBorderRadius: setBorderRadius,
        setShadowX: setShadowX,
        setShadowY: setShadowY,
        setShadowBlur: setShadowBlur,
        setShadowColor: setShadowColor,
        setBorderColor: setBorderColor,
        setBorderWidth: setBorderWidth,
        setBorderType: setBorderType,
        setBorderOpacity: setBorderOpacity,
        setAspectRatio: setAspectRatio,

        toggleReportWidgetDrawOption: toggleReportWidgetDrawOption,
        toggleWidgetDrawOption: toggleWidgetDrawOption,
        setShapeBackgroundColor: setShapeBackgroundColor,

        setIconColor: setIconColor,

        setDelete: setDelete,

        addNewItem: addNewItem,
        setCoordinates: setCoordinates,
        setFitToPage: setFitToPage,

        setMoveY: setMoveY,
        setMoveX: setMoveX,

        setColorPalette: setColorPalette,
        setFontSize: setFontSize,

        setPageBackgroundColor: setPageBackgroundColor,
        setPageTitle: setPageTitle,
        reorderPagesIndeces: reorderPagesIndeces,
        reorderItemsZIndeces: reorderItemsZIndeces,

        setZIndexForElement: setZIndexForElement,
        setElementToBack: setElementToBack,
        setElementToFront: setElementToFront,

        setCopyInPlace: setCopyInPlace,
        setCopyOnPreviousPage: setCopyOnPreviousPage,
        setCopyOnNextPage: setCopyOnNextPage,
        setPaste: setPaste,
        setCut: setCut,

        setAddPageAtIndex: setAddPageAtIndex,
        setDeletePageAtIndex: setDeletePageAtIndex,
        setDuplicatePage: setDuplicatePage,

        setPageAsCoverPage: setPageAsCoverPage,
        setPageAsBackPage: setPageAsBackPage,
        setEnableExecutiveSummary: setEnableExecutiveSummary,

        unsetPageAsCoverPage: unsetPageAsCoverPage,
        unsetPageAsBackPage: unsetPageAsBackPage,
        unsetEnableExecutiveSummary: unsetEnableExecutiveSummary,

        setEditWidget: setEditWidget,
        setEditWidgetFromBuildMode: setEditWidgetFromBuildMode,
        saveEditWidgetFromBuildMode: saveEditWidgetFromBuildMode,
        saveEditWidgetFromSpecialEdit: saveEditWidgetFromSpecialEdit,
        setWidgetDateRange: setWidgetDateRange,
        setWidgetFilters: setWidgetFilters,
        deleteWidgetFilter: deleteWidgetFilter,
        addTableRow: addTableRow,
        removeTableRow: removeTableRow,
        addTableColumn: addTableColumn,
        removeTableColumn: removeTableColumn,
        moveRowUp: moveRowUp,
        moveRowDown: moveRowDown,
        moveColumnLeft: moveColumnLeft,
        moveColumnRight: moveColumnRight,
        setCurrency: setCurrency
    };

    function assignDatasourcesToPages(pages) {
        let singleRunMutation = ExportBuilderUndoModelFactory.getMutationModel();
        singleRunMutation.setSingleRun(true);
        singleRunMutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    PubSub.emit($ExportBuilderDashboardEvents.ON_PAGE_ASSIGNMENT, true);
                    return ExportBuilderDashboardService.assignDatasourcesToPages(pages);
                }
            )
        );
        UndoRedoService.addMutation(singleRunMutation);
    }

    function setAspectRatio(value) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        let oldValue = angular.copy(ExportBuilderDashboardService.getReport().format);
        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    ExportBuilderDashboardService.setAspectRatio(value);
                },
                function () {
                    ExportBuilderDashboardService.setAspectRatio(oldValue);
                }
            )
        );
        UndoRedoService.addMutation(mutation);
    }

    function setCurrency(currencySymbol) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();

        let tableElement = ExportBuilderDashboardService.getBuilder().elements[0];
        let data = tableElement.metadata.design_options.currency_code;
        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    ExportBuilderDashboardItemService.setCurrency(tableElement, currencySymbol);
                },
                function () {
                    ExportBuilderDashboardItemService.setCurrency(tableElement, data);
                }
            )
        );
        UndoRedoService.addMutation(mutation);
    }

    function addTableRow(rowIndex) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();

        let tableElement = ExportBuilderDashboardService.getBuilder().elements[0];
        let data = null;

        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    ExportBuilderDashboardItemService.addTableRow(tableElement, rowIndex, data);
                },
                function () {
                    data = tableElement.metadata.design_options.table_data[rowIndex].columns;
                    ExportBuilderDashboardItemService.removeTableRow(tableElement, rowIndex);
                }
            )
        );
        UndoRedoService.addMutation(mutation);
    }

    function removeTableRow(rowIndex) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();

        let tableElement = ExportBuilderDashboardService.getBuilder().elements[0];

        let data = tableElement.metadata.design_options.table_data[rowIndex].columns;

        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    ExportBuilderDashboardItemService.removeTableRow(tableElement, rowIndex);
                },
                function () {
                    ExportBuilderDashboardItemService.addTableRow(tableElement, rowIndex, data);
                }
            )
        );
        UndoRedoService.addMutation(mutation);
    }

    function addTableColumn(columnIndex) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        let data = [];
        let tableElement = ExportBuilderDashboardService.getBuilder().elements[0];

        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    ExportBuilderDashboardItemService.addTableColumn(tableElement, columnIndex, data);
                },
                function () {
                    tableElement.metadata.design_options.table_data.forEach((rows) => {
                        data.push(rows.columns[columnIndex]);
                    });
                    ExportBuilderDashboardItemService.removeTableColumn(tableElement, columnIndex);
                }
            )
        );
        UndoRedoService.addMutation(mutation);
    }

    function removeTableColumn(columnIndex) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        let tableElement = ExportBuilderDashboardService.getBuilder().elements[0];
        let data = [];
        tableElement.metadata.design_options.table_data.forEach((rows) => {
            data.push(rows.columns[columnIndex]);
        });
        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    ExportBuilderDashboardItemService.removeTableColumn(tableElement, columnIndex);
                },
                function () {
                    ExportBuilderDashboardItemService.addTableColumn(tableElement, columnIndex, data);
                }
            )
        );
        UndoRedoService.addMutation(mutation);
    }

    function moveRowUp(rowIndex) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        let tableElement = ExportBuilderDashboardService.getBuilder().elements[0];

        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    ExportBuilderDashboardItemService.moveRowUp(tableElement, rowIndex);
                },
                function () {
                    ExportBuilderDashboardItemService.moveRowDown(tableElement, rowIndex - 1);
                }
            )
        );
        UndoRedoService.addMutation(mutation);
    }

    function moveRowDown(rowIndex) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        let tableElement = ExportBuilderDashboardService.getBuilder().elements[0];

        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    ExportBuilderDashboardItemService.moveRowDown(tableElement, rowIndex);
                },
                function () {
                    ExportBuilderDashboardItemService.moveRowUp(tableElement, rowIndex + 1);
                }
            )
        );
        UndoRedoService.addMutation(mutation);
    }

    function moveColumnLeft(columnIndex) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        let tableElement = ExportBuilderDashboardService.getBuilder().elements[0];

        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    ExportBuilderDashboardItemService.moveColumnLeft(tableElement, columnIndex);
                },
                function () {
                    ExportBuilderDashboardItemService.moveColumnRight(tableElement, columnIndex - 1);
                }
            )
        );
        UndoRedoService.addMutation(mutation);
    }

    function moveColumnRight(columnIndex) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        let tableElement = ExportBuilderDashboardService.getBuilder().elements[0];

        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    ExportBuilderDashboardItemService.moveColumnRight(tableElement, columnIndex);
                },
                function () {
                    ExportBuilderDashboardItemService.moveColumnLeft(tableElement, columnIndex + 1);
                }
            )
        );
        UndoRedoService.addMutation(mutation);
    }

    function setImage(url) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();

        let imageElement = ExportBuilderDashboardService.getBuilder().elements[0];
        let oldValue = imageElement.metadata.image_url;

        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    ExportBuilderDashboardItemService.setImage(imageElement, url);
                    PubSub.emit($ExportBuilderDashboardEvents.REFRESH_ITEM_NEEDED + imageElement.id);
                },
                function () {
                    ExportBuilderDashboardItemService.setImage(imageElement, oldValue);
                    PubSub.emit($ExportBuilderDashboardEvents.REFRESH_ITEM_NEEDED + imageElement.id);
                }
            )
        );
        UndoRedoService.addMutation(mutation);
    }

    function setImageFillMode(value) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        _.each(ExportBuilderDashboardService.getBuilder().elements, function (element) {
            let oldValue = element.metadata.design_options.fill_mode;
            mutation.addAction(
                ExportBuilderUndoModelFactory.getActionModel(
                    function () {
                        ExportBuilderDashboardItemService.setImageFillMode(element, value);
                        ExportBuilderDashboardService.updateCurrentElementSelection();
                    },
                    function () {
                        ExportBuilderDashboardItemService.setImageFillMode(element, oldValue);
                        ExportBuilderDashboardService.updateCurrentElementSelection();
                    }
                )
            );
        });
        UndoRedoService.addMutation(mutation);
    }

    function setMirrorXElement(value) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        _.each(ExportBuilderDashboardService.getBuilder().elements, function (element) {
            let align = element.metadata.design_options.mirror_x;
            mutation.addAction(
                ExportBuilderUndoModelFactory.getActionModel(
                    function () {
                        ExportBuilderDashboardItemService.setMirrorXElement(element, value);
                        ExportBuilderDashboardService.updateCurrentElementSelection();
                    },
                    function () {
                        ExportBuilderDashboardItemService.setMirrorXElement(element, align);
                        ExportBuilderDashboardService.updateCurrentElementSelection();
                    }
                )
            );
        });
        UndoRedoService.addMutation(mutation);
    }

    function setMirrorYElement(value) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        _.each(ExportBuilderDashboardService.getBuilder().elements, function (element) {
            let align = element.metadata.design_options.mirror_y;
            mutation.addAction(
                ExportBuilderUndoModelFactory.getActionModel(
                    function () {
                        ExportBuilderDashboardItemService.setMirrorYElement(element, value);
                        ExportBuilderDashboardService.updateCurrentElementSelection();
                    },
                    function () {
                        ExportBuilderDashboardItemService.setMirrorYElement(element, align);
                        ExportBuilderDashboardService.updateCurrentElementSelection();
                    }
                )
            );
        });
        UndoRedoService.addMutation(mutation);
    }

    function setOpacity(value) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        _.each(ExportBuilderDashboardService.getBuilder().elements, function (element) {
            let oldValue = element.metadata.base_options.opacity;
            mutation.addAction(
                ExportBuilderUndoModelFactory.getActionModel(
                    function () {
                        ExportBuilderDashboardItemService.setOpacity(element, value);
                    },
                    function () {
                        ExportBuilderDashboardItemService.setOpacity(element, oldValue);
                    }
                )
            );
        });
        UndoRedoService.addMutation(mutation);
    }

    function setTextAlignment(value) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        _.each(ExportBuilderDashboardService.getBuilder().elements, function (element) {
            let align = element.metadata.design_options.text_align;
            mutation.addAction(
                ExportBuilderUndoModelFactory.getActionModel(
                    function () {
                        ExportBuilderDashboardItemService.setTextAlignment(element, value);
                        ExportBuilderDashboardService.updateCurrentElementSelection();
                    },
                    function () {
                        ExportBuilderDashboardItemService.setTextAlignment(element, align);
                        ExportBuilderDashboardService.updateCurrentElementSelection();
                    }
                )
            );
        });
        UndoRedoService.addMutation(mutation);
    }

    function setBackgroundColor(value) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        _.each(ExportBuilderDashboardService.getBuilder().elements, function (element) {
            let color = element.metadata.base_options.background_color;
            mutation.addAction(
                ExportBuilderUndoModelFactory.getActionModel(
                    function () {
                        ExportBuilderDashboardItemService.setBackgroundColor(element, value);
                    },
                    function () {
                        ExportBuilderDashboardItemService.setBackgroundColor(element, color);
                    }
                )
            );
        });
        UndoRedoService.addMutation(mutation);
    }

    function setTextTitle(value) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        _.each(ExportBuilderDashboardService.getBuilder().elements, function (element) {
            let text = element.metadata.design_options.text;
            mutation.addAction(
                ExportBuilderUndoModelFactory.getActionModel(
                    function () {
                        ExportBuilderDashboardItemService.setTextTitle(element, value);
                    },
                    function () {
                        ExportBuilderDashboardItemService.setTextTitle(element, text);
                    }
                )
            );
        });
        UndoRedoService.addMutation(mutation);
    }

    function setTextSize(value) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        _.each(ExportBuilderDashboardService.getBuilder().elements, function (element) {
            let fontSize = element.metadata.design_options.font_size;
            mutation.addAction(
                ExportBuilderUndoModelFactory.getActionModel(
                    function () {
                        ExportBuilderDashboardItemService.setTextSize(element, value);
                    },
                    function () {
                        ExportBuilderDashboardItemService.setTextSize(element, fontSize);
                    }
                )
            );
        });
        UndoRedoService.addMutation(mutation);
    }

    function setTextVerticalAlignment(value) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        _.each(ExportBuilderDashboardService.getBuilder().elements, function (element) {
            let verticalAlign = element.metadata.design_options.vertical_alignment;
            mutation.addAction(
                ExportBuilderUndoModelFactory.getActionModel(
                    function () {
                        ExportBuilderDashboardItemService.setTextVerticalAlignment(element, value);
                        ExportBuilderDashboardService.updateCurrentElementSelection();
                    },
                    function () {
                        ExportBuilderDashboardItemService.setTextVerticalAlignment(element, verticalAlign);
                        ExportBuilderDashboardService.updateCurrentElementSelection();
                    }
                )
            );
        });
        UndoRedoService.addMutation(mutation);
    }

    function setTextColor(value) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        _.each(ExportBuilderDashboardService.getBuilder().elements, function (element) {
            let text_color = element.metadata.design_options.text_color;
            mutation.addAction(
                ExportBuilderUndoModelFactory.getActionModel(
                    function () {
                        ExportBuilderDashboardItemService.setTextColor(element, value);
                    },
                    function () {
                        ExportBuilderDashboardItemService.setTextColor(element, text_color);
                    }
                )
            );
        });
        UndoRedoService.addMutation(mutation);
    }

    function setTextOpacity(value) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        _.each(ExportBuilderDashboardService.getBuilder().elements, function (element) {
            let oldValue = element.metadata.design_options.text_opacity;
            mutation.addAction(
                ExportBuilderUndoModelFactory.getActionModel(
                    function () {
                        ExportBuilderDashboardItemService.setTextOpacity(element, value);
                    },
                    function () {
                        ExportBuilderDashboardItemService.setTextOpacity(element, oldValue);
                    }
                )
            );
        });
        UndoRedoService.addMutation(mutation);
    }

    function setTextShadowX(value) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        _.each(ExportBuilderDashboardService.getBuilder().elements, function (element) {
            let oldValue = element.metadata.design_options.text_shadow_x;
            mutation.addAction(
                ExportBuilderUndoModelFactory.getActionModel(
                    function () {
                        ExportBuilderDashboardItemService.setTextShadowX(element, value);
                    },
                    function () {
                        ExportBuilderDashboardItemService.setTextShadowX(element, oldValue);
                    }
                )
            );
        });
        UndoRedoService.addMutation(mutation);
    }

    function setTextShadowY(value) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        _.each(ExportBuilderDashboardService.getBuilder().elements, function (element) {
            let oldValue = element.metadata.design_options.text_shadow_y;
            mutation.addAction(
                ExportBuilderUndoModelFactory.getActionModel(
                    function () {
                        ExportBuilderDashboardItemService.setTextShadowY(element, value);
                    },
                    function () {
                        ExportBuilderDashboardItemService.setTextShadowY(element, oldValue);
                    }
                )
            );
        });
        UndoRedoService.addMutation(mutation);
    }

    function setTextShadowBlur(value) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        _.each(ExportBuilderDashboardService.getBuilder().elements, function (element) {
            let oldValue = element.metadata.design_options.text_shadow_blur;
            mutation.addAction(
                ExportBuilderUndoModelFactory.getActionModel(
                    function () {
                        ExportBuilderDashboardItemService.setTextShadowBlur(element, value);
                    },
                    function () {
                        ExportBuilderDashboardItemService.setTextShadowBlur(element, oldValue);
                    }
                )
            );
        });
        UndoRedoService.addMutation(mutation);
    }

    function setTextShadowColor(value) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        _.each(ExportBuilderDashboardService.getBuilder().elements, function (element) {
            let oldValue = element.metadata.design_options.text_shadow_color;
            mutation.addAction(
                ExportBuilderUndoModelFactory.getActionModel(
                    function () {
                        ExportBuilderDashboardItemService.setTextShadowColor(element, value);
                    },
                    function () {
                        ExportBuilderDashboardItemService.setTextShadowColor(element, oldValue);
                    }
                )
            );
        });
        UndoRedoService.addMutation(mutation);
    }

    function setPadding(value) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        _.each(ExportBuilderDashboardService.getBuilder().elements, function (element) {
            let oldValue = element.metadata.design_options.text_shadow_color;
            mutation.addAction(
                ExportBuilderUndoModelFactory.getActionModel(
                    function () {
                        ExportBuilderDashboardItemService.setPadding(element, value);
                    },
                    function () {
                        ExportBuilderDashboardItemService.setPadding(element, oldValue);
                    }
                )
            );
        });
        UndoRedoService.addMutation(mutation);
    }

    function toggleTextBold(value) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        _.each(ExportBuilderDashboardService.getBuilder().elements, function (element) {
            let oldValue = element.metadata.design_options.text_bold;
            mutation.addAction(
                ExportBuilderUndoModelFactory.getActionModel(
                    function () {
                        ExportBuilderDashboardItemService.toggleTextBold(element, value);
                        ExportBuilderDashboardService.updateCurrentElementSelection();
                    },
                    function () {
                        ExportBuilderDashboardItemService.toggleTextBold(element, oldValue);
                        ExportBuilderDashboardService.updateCurrentElementSelection();
                    }
                )
            );
        });
        UndoRedoService.addMutation(mutation);
    }

    function toggleTextItalic(value) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        _.each(ExportBuilderDashboardService.getBuilder().elements, function (element) {
            let oldValue = element.metadata.design_options.text_italic;
            mutation.addAction(
                ExportBuilderUndoModelFactory.getActionModel(
                    function () {
                        ExportBuilderDashboardItemService.toggleTextItalic(element, value);
                        ExportBuilderDashboardService.updateCurrentElementSelection();
                    },
                    function () {
                        ExportBuilderDashboardItemService.toggleTextItalic(element, oldValue);
                        ExportBuilderDashboardService.updateCurrentElementSelection();
                    }
                )
            );
        });
        UndoRedoService.addMutation(mutation);
    }

    function toggleTextUnderline(value) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        _.each(ExportBuilderDashboardService.getBuilder().elements, function (element) {
            let oldValue = element.metadata.design_options.text_underline;
            mutation.addAction(
                ExportBuilderUndoModelFactory.getActionModel(
                    function () {
                        ExportBuilderDashboardItemService.toggleTextUnderline(element, value);
                        ExportBuilderDashboardService.updateCurrentElementSelection();
                    },
                    function () {
                        ExportBuilderDashboardItemService.toggleTextUnderline(element, oldValue);
                        ExportBuilderDashboardService.updateCurrentElementSelection();
                    }
                )
            );
        });
        UndoRedoService.addMutation(mutation);
    }

    function toggleTextStrikethrough(value) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        _.each(ExportBuilderDashboardService.getBuilder().elements, function (element) {
            let oldValue = element.metadata.design_options.text_strikethrough;
            mutation.addAction(
                ExportBuilderUndoModelFactory.getActionModel(
                    function () {
                        ExportBuilderDashboardItemService.toggleTextStrikethrough(element, value);
                        ExportBuilderDashboardService.updateCurrentElementSelection();
                    },
                    function () {
                        ExportBuilderDashboardItemService.toggleTextStrikethrough(element, oldValue);
                        ExportBuilderDashboardService.updateCurrentElementSelection();
                    }
                )
            );
        });
        UndoRedoService.addMutation(mutation);
    }

    function setFontFamily(value) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        _.each(ExportBuilderDashboardService.getBuilder().elements, function (element) {
            let oldValue = element.metadata.design_options.font_family;
            mutation.addAction(
                ExportBuilderUndoModelFactory.getActionModel(
                    function () {
                        ExportBuilderDashboardItemService.setFontFamily(element, value);
                    },
                    function () {
                        ExportBuilderDashboardItemService.setFontFamily(element, oldValue);
                    }
                )
            );
        });
        UndoRedoService.addMutation(mutation);
    }

    function makeItemOnEveryPage(value) {
        performGenericMutation(
            value,
            ExportBuilderDashboardItemService.makeItemOnEveryPage,
            ExportBuilderDashboardItemService.makeItemOnEveryPage,
            function oldValue(element) {
                return element.show_on_every_page;
            }
        )
    }

    function setSnapToGrid(value) {
        performGenericMutation(
            value,
            ExportBuilderDashboardItemService.setSnapToGrid,
            ExportBuilderDashboardItemService.setSnapToGrid,
            function oldValue(element) {
                return element.snap_to_grid;
            }
        )
    }

    function setToBackground(value) {
        performGenericMutation(
            value,
            ExportBuilderDashboardItemService.setToBackground,
            ExportBuilderDashboardItemService.setToBackground,
            element => element.metadata.set_as_background
        );
    }

    function setBorderRadius(value) {
        performGenericMutation(
            value,
            ExportBuilderDashboardItemService.setBorderRadius,
            ExportBuilderDashboardItemService.setBorderRadius,
            function oldValue(element) {
                return element.metadata.base_options.border_radius;
            }
        )
    }

    function setShadowX(value) {
        performGenericMutation(
            value,
            ExportBuilderDashboardItemService.setShadowX,
            ExportBuilderDashboardItemService.setShadowX,
            function oldValue(element) {
                return element.metadata.base_options.shadow_x
            }
        )
    }

    function setShadowY(value) {
        performGenericMutation(
            value,
            ExportBuilderDashboardItemService.setShadowY,
            ExportBuilderDashboardItemService.setShadowY,
            function oldValue(element) {
                return element.metadata.base_options.shadow_y
            }
        )
    }

    function setShadowBlur(value) {
        performGenericMutation(
            value,
            ExportBuilderDashboardItemService.setShadowBlur,
            ExportBuilderDashboardItemService.setShadowBlur,
            function oldValue(element) {
                return element.metadata.base_options.shadow_blur
            }
        )
    }


    function setShadowColor(value) {
        performGenericMutation(
            value,
            ExportBuilderDashboardItemService.setShadowColor,
            ExportBuilderDashboardItemService.setShadowColor,
            function oldValue(element) {
                return element.metadata.base_options.shadow_color
            }
        )
    }

    function setBorderColor(value) {
        performGenericMutation(
            value,
            ExportBuilderDashboardItemService.setBorderColor,
            ExportBuilderDashboardItemService.setBorderColor,
            function oldValue(element) {
                return element.metadata.base_options.border_color
            }
        )
    }

    function setBorderWidth(value) {
        performGenericMutation(
            value,
            ExportBuilderDashboardItemService.setBorderWidth,
            ExportBuilderDashboardItemService.setBorderWidth,
            function oldValue(element) {
                return element.metadata.base_options.border_width
            }
        )
    }

    function setBorderType(value) {
        performGenericMutation(
            value,
            ExportBuilderDashboardItemService.setBorderType,
            ExportBuilderDashboardItemService.setBorderType,
            function oldValue(element) {
                return element.metadata.base_options.border_type
            }
        )
    }

    function setBorderOpacity(value) {
        performGenericMutation(
            value,
            ExportBuilderDashboardItemService.setBorderOpacity,
            ExportBuilderDashboardItemService.setBorderOpacity,
            function oldValue(element) {
                return element.metadata.base_options.border_opacity
            }
        )
    }

    function toggleReportWidgetDrawOption(drawOptionKey, value) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        let oldValue = ExportBuilderDashboardService.getReport().metadata.draw_options[drawOptionKey].value;
        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    ExportBuilderDashboardService.toggleReportWidgetDrawOption(drawOptionKey, value);
                },
                function () {
                    ExportBuilderDashboardService.toggleReportWidgetDrawOption(drawOptionKey, oldValue);
                }
            )
        );
        UndoRedoService.addMutation(mutation);
    }

    function toggleWidgetDrawOption(drawOptionKey, value) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        _.each(ExportBuilderDashboardService.getBuilder().elements, function (element) {
            let oldValue = element.widget.metadata.draw_options[drawOptionKey].value;
            mutation.addAction(
                ExportBuilderUndoModelFactory.getActionModel(
                    function () {
                        ExportBuilderDashboardItemService.toggleWidgetDrawOption(element, drawOptionKey, value);
                        ExportBuilderDashboardService.updateCurrentElementSelection();
                    },
                    function () {
                        ExportBuilderDashboardItemService.toggleWidgetDrawOption(element, drawOptionKey, oldValue);
                        ExportBuilderDashboardService.updateCurrentElementSelection();
                    }
                )
            );
        });
        UndoRedoService.addMutation(mutation);
    }

    function setShapeBackgroundColor(value) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        _.each(ExportBuilderDashboardService.getBuilder().elements, function (element) {
            let oldValue = element.metadata.design_options.background_color;
            mutation.addAction(
                ExportBuilderUndoModelFactory.getActionModel(
                    function () {
                        ExportBuilderDashboardItemService.setShapeBackgroundColor(element, value);
                        ExportBuilderDashboardService.updateCurrentElementSelection();
                    },
                    function () {
                        ExportBuilderDashboardItemService.setShapeBackgroundColor(element, oldValue);
                        ExportBuilderDashboardService.updateCurrentElementSelection();
                    }
                )
            );
        });
        UndoRedoService.addMutation(mutation);
    }

    function setIconColor(value) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        _.each(ExportBuilderDashboardService.getBuilder().elements, function (element) {
            let oldValue = element.metadata.design_options.icon_color;
            mutation.addAction(
                ExportBuilderUndoModelFactory.getActionModel(
                    function () {
                        ExportBuilderDashboardItemService.setIconColor(element, value);
                        ExportBuilderDashboardService.updateCurrentElementSelection();
                    },
                    function () {
                        ExportBuilderDashboardItemService.setIconColor(element, oldValue);
                        ExportBuilderDashboardService.updateCurrentElementSelection();
                    }
                )
            );
        });
        UndoRedoService.addMutation(mutation);
    }

    function setDelete() {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();

        let page = ExportBuilderDashboardService.getBuilder().currentPage;

        let elementsToDelete = _.map(angular.copy(ExportBuilderDashboardService.getBuilder().elements), function (element) {
            element.focused = false;
            element.isEditing = false;
            return element;
        });

        let elementToAdd = _.map(angular.copy(ExportBuilderDashboardService.getBuilder().elements), function (element) {
            element.focused = false;
            element.isEditing = false;
            return element;
        });

        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    return ExportBuilderDashboardPageService.deleteElements(elementsToDelete);
                },
                function () {
                    // ExportBuilderDashboardService.clearSelection();
                    return ExportBuilderDashboardPageService.addElements(elementToAdd, page.id);
                }
            )
        );
        UndoRedoService.addMutation(mutation);
    }

    function addNewItem(element, params, widget, noSave = false) {
        let page = ExportBuilderDashboardService.getBuilder().currentPage;

        let elementToDelete = null;
        let mutationSingleRun = ExportBuilderUndoModelFactory.getMutationModel();
        mutationSingleRun.setSingleRun(true);
        mutationSingleRun.setIsAsync(true);
        mutationSingleRun.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    let data = ExportBuilderDashboardService.addNewItem(element, params, widget, true, noSave);
                    data.promise.then(function (element) {
                        elementToDelete = element;
                    });
                    return data.promise;
                }
            )
        );

        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        mutation.setDelayed(true);
        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    // ExportBuilderDashboardService.clearSelection();
                    return ExportBuilderDashboardPageService.addElements([elementToDelete], page.id);
                },
                function () {
                    return ExportBuilderDashboardPageService.deleteElements([elementToDelete]);
                }
            )
        );

        UndoRedoService.addMutation(mutationSingleRun);
        UndoRedoService.addMutation(mutation);
    }

    function setCoordinates(data) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        let report = ExportBuilderDashboardService.getReport();
        _.each(ExportBuilderDashboardService.getBuilder().elements, function (element) {

            //TODO: @dannyyassine move this logic inside the ExportBuilderDashboardItemService.setCoordinates
            let change = {};
            if (!data.is_percent && element.snap_to_grid) {
                change.left = data.left / report.getPageWidth() * 100;
                change.top = data.top / report.getPageHeight() * 100;
                if (!_.isUndefined(data.width)) {
                    change.width = data.width / 100 * report.getPageWidth();
                }
                if (!_.isUndefined(data.height)) {
                    change.height = data.height / 100 * report.getPageHeight();
                }
            } else if (data.is_percent && !element.snap_to_grid) {
                change.left = report.getPageWidth() * (data.left / 100);
                change.top = report.getPageHeight() * (data.top / 100);
                if (!_.isUndefined(data.width)) {
                    change.width = data.width;
                }
                if (!_.isUndefined(data.height)) {
                    change.height = data.height;
                }
            } else {
                change = data;
                if (!element.snap_to_grid) {
                    if (!_.isUndefined(data.width)) {
                        change.width = data.width / 100 * report.getPageWidth();
                    }
                    if (!_.isUndefined(data.height)) {
                        change.height = data.height / 100 * report.getPageHeight();
                    }
                    if (!_.isUndefined(data.height) && !_.isUndefined(data.width)) {
                        if (data.left !== 0) {
                            change.left = report.getPageWidth() * (data.left / 100);
                        }
                        if (data.top !== 0) {
                            change.top = report.getPageHeight() * (data.top / 100);
                        }
                    }
                }
            }

            let oldValue = {
                left: -(change.left),
                top: -(change.top)
            };

            if (!_.isUndefined(change.width)) {
                oldValue.width = -(change.width);
            }
            if (!_.isUndefined(change.height)) {
                oldValue.height = -(change.height);
            }

            mutation.addAction(
                ExportBuilderUndoModelFactory.getActionModel(
                    function () {
                        ExportBuilderDashboardItemService.setCoordinates(element, change);
                    },
                    function () {
                        ExportBuilderDashboardItemService.setCoordinates(element, oldValue);
                    }
                )
            );
        });
        UndoRedoService.addMutation(mutation);
    }

    function setFitToPage() {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        _.each(ExportBuilderDashboardService.getBuilder().elements, function (element) {
            let oldValue = {
                x_position: element.x_position,
                y_position: element.y_position,
                width: element.width,
                height: element.height
            };
            mutation.addAction(
                ExportBuilderUndoModelFactory.getActionModel(
                    function () {
                        ExportBuilderDashboardItemService.sizeToPage(element);
                    },
                    function () {
                        ExportBuilderDashboardItemService.forceDimensions(element, oldValue);
                    }
                )
            );
        });
        UndoRedoService.addMutation(mutation);
    }

    function setMoveY(direction, multiplier) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        _.each(ExportBuilderDashboardService.getBuilder().elements, function (element) {
            let oldValue = {
                direction : -(direction)
            };
            if (multiplier) {
                oldValue.multiplier = multiplier
            }
            mutation.addAction(
                ExportBuilderUndoModelFactory.getActionModel(
                    function () {
                        ExportBuilderDashboardItemService.moveY(element, direction, multiplier);
                    },
                    function () {
                        ExportBuilderDashboardItemService.moveY(element, oldValue.direction, oldValue.multiplier);
                    }
                )
            );
        });
        UndoRedoService.addMutation(mutation);
    }

    function setMoveX(direction, multiplier) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        _.each(ExportBuilderDashboardService.getBuilder().elements, function (element) {
            let oldValue = {
                direction : -(direction)
            };
            if (multiplier) {
                oldValue.multiplier = multiplier
            }
            mutation.addAction(
                ExportBuilderUndoModelFactory.getActionModel(
                    function () {
                        ExportBuilderDashboardItemService.moveX(element, direction, multiplier);
                    },
                    function () {
                        ExportBuilderDashboardItemService.moveX(element, oldValue.direction, oldValue.multiplier);
                    }
                )
            );
        });
        UndoRedoService.addMutation(mutation);
    }

    function setColorPalette(value) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        let oldValue = angular.copy(ExportBuilderDashboardService.getReport().metadata.chart_palette);
        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    ExportBuilderDashboardService.setColorPalette(value);
                    ExportBuilderDashboardUtilService.reRenderWidgets();
                },
                function () {
                    ExportBuilderDashboardService.setColorPalette(oldValue);
                    ExportBuilderDashboardUtilService.reRenderWidgets();
                }
            )
        );
        UndoRedoService.addMutation(mutation);
    }

    function setFontSize(value) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        let oldValue = angular.copy(ExportBuilderDashboardService.getReport().metadata.font_size);
        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    ExportBuilderDashboardService.setFontSize(value);
                    ExportBuilderDashboardUtilService.reRenderWidgets();
                },
                function () {
                    ExportBuilderDashboardService.setFontSize(oldValue);
                    ExportBuilderDashboardUtilService.reRenderWidgets();
                }
            )
        );
        UndoRedoService.addMutation(mutation);
    }

    function setPageBackgroundColor(page, value) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        let oldValue = page.metadata.design_options.background_color;
        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    ExportBuilderDashboardPageService.setBackgroundColor(page, value);
                },
                function () {
                    ExportBuilderDashboardPageService.setBackgroundColor(page, oldValue);
                }
            )
        );
        UndoRedoService.addMutation(mutation);
    }

    function setPageTitle(page, value) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        let oldValue = page.title;
        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    ExportBuilderDashboardPageService.setTitle(page, value);
                },
                function () {
                    ExportBuilderDashboardPageService.setTitle(page, oldValue);
                }
            )
        );
        UndoRedoService.addMutation(mutation);
    }

    function reorderPagesIndeces(originalIndeces) {
        let originalIndecesCopy = angular.copy(originalIndeces);

        let singleRunMutation = ExportBuilderUndoModelFactory.getMutationModel();
        singleRunMutation.setSingleRun(true);
        singleRunMutation.setIsAsync(true);
        singleRunMutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    return ExportBuilderDashboardService.reorderPagesIndeces(ExportBuilderDashboardService.getReport().pages);
                }
            )
        );

        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        mutation.setDelayed(true);
        mutation.setIsAsync(true);
        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    _captureChanges();
                    return ExportBuilderDashboardService.reorderPagesIndeces(ExportBuilderDashboardService.getReport().pages);
                },
                function () {
                    _captureChanges();
                    return ExportBuilderDashboardService.reorderPagesIndeces(ExportBuilderDashboardService.getReport().pages);
                }
            )
        );

        /**
         * Capture current order, apply old order
         * @private
         */
        function _captureChanges() {
            let tempCopy = _.reduce(ExportBuilderDashboardService.getReport().pages, function (accum, page) {
                accum[page.id] = page.page_index;
                return accum;
            }, {});
            _.each(ExportBuilderDashboardService.getReport().pages, function (page) {
                page.page_index = originalIndecesCopy[page.id];
            });
            originalIndecesCopy = tempCopy;
        }

        UndoRedoService.addMutation(singleRunMutation);
        UndoRedoService.addMutation(mutation);
    }

    function reorderItemsZIndeces(originalIndeces, elements) {
        let originalIndecesCopy = angular.copy(originalIndeces);
        let page = ExportBuilderDashboardService.getBuilder().currentPage;

        let singleRunMutation = ExportBuilderUndoModelFactory.getMutationModel();
        singleRunMutation.setSingleRun(true);
        singleRunMutation.setIsAsync(true);
        singleRunMutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    return ExportBuilderDashboardService.reorderItemsZIndeces(elements, page);
                }
            )
        );

        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        mutation.setDelayed(true);
        mutation.setIsAsync(true);
        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    _captureChanges(elements);
                    return ExportBuilderDashboardService.reorderItemsZIndeces(elements, page);
                },
                function () {
                    _captureChanges(elements);
                    return ExportBuilderDashboardService.reorderItemsZIndeces(elements, page);
                }
            )
        );

        /**
         * Capture current order, apply old order
         * @private
         */
        function _captureChanges(elements) {
            let tempCopy = _.reduce(elements, function (accum, element) {
                accum[element.id] = element.z_index;
                return accum;
            }, {});
            _.each(elements, function (element) {
                element.z_index = originalIndecesCopy[element.id];
            });
            originalIndecesCopy = tempCopy;
        }

        UndoRedoService.addMutation(singleRunMutation);
        UndoRedoService.addMutation(mutation);
    }

    function setElementToBack(element) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        let page_id = element.page_id || ExportBuilderDashboardService.getBuilder().currentPage.id;
        let oldZIndex = element.z_index;

        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    ExportBuilderDashboardItemService.sendToBack(page_id, element);
                },
                function () {
                    ExportBuilderDashboardItemService.sendToFront(page_id, element);
                }
            )
        );
        UndoRedoService.addMutation(mutation);
    }

    function setElementToFront(element) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        let page_id = element.page_id || ExportBuilderDashboardService.getBuilder().currentPage.id;
        let oldZIndex = element.z_index;

        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    ExportBuilderDashboardItemService.sendToFront(page_id, element);
                },
                function () {
                    ExportBuilderDashboardItemService.sendToBack(page_id, element);
                }
            )
        );
        UndoRedoService.addMutation(mutation);
    }

    function setZIndexForElement(element, step) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        let page_id = element.page_id || ExportBuilderDashboardService.getBuilder().currentPage.id;
        let newZIndex = element.z_index + step;
        let oldZIndex = element.z_index;

        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    ExportBuilderDashboardItemService.setZIndexValueForElement(page_id, element, newZIndex);
                },
                function () {
                    ExportBuilderDashboardItemService.setZIndexValueForElement(page_id, element, oldZIndex);
                }
            )
        );
        UndoRedoService.addMutation(mutation);
    }

    function setCopyInPlace(element) {
        let page_id = element.page_id || ExportBuilderDashboardService.getBuilder().currentPage.id;
        let page = ExportBuilderDashboardPageService.getPageFromId(page_id);
        let newElement = null;

        let singleRunMutation = ExportBuilderUndoModelFactory.getMutationModel();
        singleRunMutation.setSingleRun(true);
        singleRunMutation.setIsAsync(true);
        singleRunMutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    try {
                        let data = ExportBuilderDashboardService.copyItemToPage(element, page.page_index, page.page_index, true);
                        UIFactory.notify.showSuccess('Element copied');
                        if (data.item.snap_to_grid) {
                            ExportBuilderDashboardItemService.moveX(data.item, 1);
                            ExportBuilderDashboardItemService.moveY(data.item, 1);
                        } else {
                            ExportBuilderDashboardItemService.moveX(data.item, 20);
                            ExportBuilderDashboardItemService.moveY(data.item, 20);
                        }
                        data.promise.then(function (savedElement) {
                            newElement = savedElement;
                        });
                        return data.promise;
                    } catch (e) {
                        UIFactory.notify.showWarning(e.message);
                    }
                }
            )
        );

        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        mutation.setDelayed(true);
        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    // ExportBuilderDashboardService.clearSelection();
                    return ExportBuilderDashboardPageService.addElements([newElement], page.id);
                },
                function () {
                    return ExportBuilderDashboardPageService.deleteElements([newElement]);
                }
            )
        );

        UndoRedoService.addMutation(singleRunMutation);
        UndoRedoService.addMutation(mutation);
    }

    function setCopyOnPreviousPage(element) {
        let page_id = element.page_id || ExportBuilderDashboardService.getBuilder().currentPage.id;
        let page = ExportBuilderDashboardPageService.getPageFromId(page_id);
        let previousPageIndex = ExportBuilderDashboardService.getPreviousDesiredPageIndex();
        previousPageIndex = previousPageIndex === undefined ? -1 : previousPageIndex;
        let newElement = null;

        let singleRunMutation = ExportBuilderUndoModelFactory.getMutationModel();
        singleRunMutation.setSingleRun(true);
        singleRunMutation.setIsAsync(true);
        singleRunMutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    try {
                        let data = ExportBuilderDashboardService.copyItemToPage(element, page.page_index, previousPageIndex, true);
                        data.promise.then(function (savedElement) {
                            newElement = savedElement;
                        });
                        UIFactory.notify.showSuccess('Element copied');
                        return data.promise;
                    } catch (e) {
                        UIFactory.notify.showWarning(e.message);
                    }
                }
            )
        );

        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        mutation.setDelayed(true);
        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    // ExportBuilderDashboardService.clearSelection();
                    let previousPage = ExportBuilderDashboardPageService.getPageAtIndex(previousPageIndex);
                    return ExportBuilderDashboardPageService.addElements([newElement], previousPage.id);
                },
                function () {
                    return ExportBuilderDashboardPageService.deleteElements([newElement]);
                }
            )
        );

        UndoRedoService.addMutation(singleRunMutation);
        UndoRedoService.addMutation(mutation);
    }

    function setCopyOnNextPage(element) {
        let page_id = element.page_id || ExportBuilderDashboardService.getBuilder().currentPage.id;
        let page = ExportBuilderDashboardPageService.getPageFromId(page_id);
        let nextPageIndex = ExportBuilderDashboardService.getNextDesiredPageIndex();
        nextPageIndex = nextPageIndex === undefined ? ExportBuilderDashboardService.getBuilder().report.pages.length : nextPageIndex;
        let newElement = null;

        let singleRunMutation = ExportBuilderUndoModelFactory.getMutationModel();
        singleRunMutation.setSingleRun(true);
        singleRunMutation.setIsAsync(true);
        singleRunMutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    try {
                        let data = ExportBuilderDashboardService.copyItemToPage(element, page.page_index, nextPageIndex, true);
                        UIFactory.notify.showSuccess('Element copied');
                        data.promise.then(function (savedElement) {
                            newElement = savedElement;
                        });
                        return data.promise;
                    } catch (e) {
                        UIFactory.notify.showWarning(e.message);
                    }
                }
            )
        );

        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        mutation.setDelayed(true);
        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    // ExportBuilderDashboardService.clearSelection();
                    let nextPage = ExportBuilderDashboardPageService.getPageAtIndex(nextPageIndex);
                    return ExportBuilderDashboardPageService.addElements([newElement], nextPage.id);
                },
                function () {
                    return ExportBuilderDashboardPageService.deleteElements([newElement]);
                }
            )
        );

        UndoRedoService.addMutation(singleRunMutation);
        UndoRedoService.addMutation(mutation);
    }

    function setPaste(element) {
        let page = ExportBuilderDashboardService.getBuilder().currentPage;
        let newElement = null;

        let singleRunMutation = ExportBuilderUndoModelFactory.getMutationModel();
        singleRunMutation.setSingleRun(true);
        singleRunMutation.setIsAsync(true);
        singleRunMutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    try {
                        let data = ExportBuilderDashboardService.copyItemToPage(element, page.page_index, page.page_index, true);
                        data.promise.then(function (savedElement) {
                            newElement = savedElement;
                        });
                        return data.promise;
                    } catch (e) {
                        UIFactory.notify.showWarning(e.message);
                    }
                }
            )
        );

        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        mutation.setDelayed(true);
        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    // ExportBuilderDashboardService.clearSelection();
                    return ExportBuilderDashboardPageService.addElements([newElement], page.id);
                },
                function () {
                    // ExportBuilderDashboardService.clearSelection();
                    return ExportBuilderDashboardPageService.deleteElements([newElement]);
                }
            )
        );

        UndoRedoService.addMutation(singleRunMutation);
        UndoRedoService.addMutation(mutation);
    }

    function setCut(element) {
        let page = ExportBuilderDashboardService.getBuilder().currentPage;

        UndoRedoService.addMutation(
            ExportBuilderUndoModelFactory.getMutationWithAction(
                function redo() {
                    // ExportBuilderDashboardService.clearSelection();
                    return ExportBuilderDashboardPageService.deleteElements([element]);
                },
                function undo() {
                    // ExportBuilderDashboardService.clearSelection();
                    return ExportBuilderDashboardPageService.addElements([element], page.id);
                }
            )
        )
    }

    function setAddPageAtIndex(index, isExecSummaryPage = false, widgetBuilderModel = {}) {
        let newPage = null;

        let singleRunMutation = ExportBuilderUndoModelFactory.getMutationModel();
        singleRunMutation.setSingleRun(true);
        singleRunMutation.setIsAsync(true);
        singleRunMutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    PubSub.emit($ExportBuilderDashboardEvents.ON_PAGE_ADDING, true);
                    return ExportBuilderDashboardService.createNewPageAtIndex(index, false, isExecSummaryPage, widgetBuilderModel)
                        .then(function (page) {
                            newPage = page;
                        })
                        .finally(function () {
                            PubSub.emit($ExportBuilderDashboardEvents.ON_PAGE_ADDING, false);
                        });
                }
            )
        );

        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        mutation.setDelayed(true);
        mutation.setIsAsync(true);
        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    return ExportBuilderDashboardService.addPageAtIndex(newPage, newPage.page_index);
                },
                function () {
                    return ExportBuilderDashboardService.deletePageAtIndex(newPage.page_index);
                }
            )
        );

        UndoRedoService.addMutation(singleRunMutation);
        UndoRedoService.addMutation(mutation);
    }

    function setDeletePageAtIndex(index) {
        let page = ExportBuilderDashboardPageService.getPageAtIndex(index);

        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        mutation.setIsAsync(true);
        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    return ExportBuilderDashboardService.deletePageAtIndex(index);
                },
                function () {
                    return ExportBuilderDashboardService.addPageAtIndex(page, page.page_index);
                }
            )
        );

        UndoRedoService.addMutation(mutation);
    }

    function setDuplicatePage(pageId) {
        let pageToDelete = null;
        let singleRunMutation = ExportBuilderUndoModelFactory.getMutationModel();
        singleRunMutation.setSingleRun(true);
        singleRunMutation.setIsAsync(true);
        singleRunMutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    PubSub.emit($ExportBuilderDashboardEvents.ON_PAGE_ADDING, true);
                    return ExportBuilderDashboardPageService.copyPage(pageId)
                        .then(function (newPage) {
                            pageToDelete = newPage;
                        })
                        .finally(function () {
                            PubSub.emit($ExportBuilderDashboardEvents.ON_PAGE_ADDING, false);
                        });
                }
            )
        );

        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        mutation.setIsAsync(true);
        mutation.setDelayed(true);
        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    return ExportBuilderDashboardService.addPageAtIndex(pageToDelete, pageToDelete.page_index);
                },
                function () {
                    return ExportBuilderDashboardService.deletePageAtIndex(pageToDelete.page_index);
                },
            )
        );

        UndoRedoService.addMutation(singleRunMutation);
        UndoRedoService.addMutation(mutation);
    }

    function setPageAsCoverPage(page) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        mutation.setIsAsync(true);
        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    return ExportBuilderDashboardPageService.setPageAsCoverPage(page);
                },
                function () {
                    return ExportBuilderDashboardPageService.unsetPageAsCoverPage(page);
                }
            )
        );

        UndoRedoService.addMutation(mutation);
    }

    function setPageAsBackPage(page) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        mutation.setIsAsync(true);
        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    return ExportBuilderDashboardPageService.setPageAsBackPage(page);
                },
                function () {
                    return ExportBuilderDashboardPageService.unsetPageAsBackPage(page);
                }
            )
        );

        UndoRedoService.addMutation(mutation);
    }

    /**
     * Sets page as executive summary page
     * @param page
     */
    function setEnableExecutiveSummary(page) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        mutation.setIsAsync(true);
        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    return ExportBuilderDashboardPageService.setEnableExecutiveSummary(page);
                },
                function () {
                    return ExportBuilderDashboardPageService.unsetEnableExecutiveSummary(page);
                }
            )
        );

        UndoRedoService.addMutation(mutation);
    }

    function unsetPageAsCoverPage(page) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        mutation.setIsAsync(true);
        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    return ExportBuilderDashboardPageService.unsetPageAsCoverPage(page);
                },
                function () {
                    return ExportBuilderDashboardPageService.setPageAsCoverPage(page);
                }
            )
        );

        UndoRedoService.addMutation(mutation);
    }

    function unsetPageAsBackPage(page) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        mutation.setIsAsync(true);
        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    return ExportBuilderDashboardPageService.unsetPageAsBackPage(page);
                },
                function () {
                    return ExportBuilderDashboardPageService.setPageAsBackPage(page);
                }
            )
        );

        UndoRedoService.addMutation(mutation);
    }

    /**
     * Unsets page as executive summary page
     * @param page
     */
    function unsetEnableExecutiveSummary(page) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        mutation.setIsAsync(true);
        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    return ExportBuilderDashboardPageService.unsetEnableExecutiveSummary(page);
                },
                function () {
                    return ExportBuilderDashboardPageService.setEnableExecutiveSummary(page);
                }
            )
        );

        UndoRedoService.addMutation(mutation);
    }

    /**
     * EDITS FROM WIDGET BUILD MODE IS NOT YET SUPPORTED
     */
    function setEditWidgetFromBuildMode(widgetData) {
        WidgetBuilderService.setIsEditing(true);

        const isSampleData = WidgetBuilderService.getIsActive()
                                 ? widgetData.metadata.draw_options[DrawOption.FORCE_SAMPLE_DATA]
                                 : ReportStudioTemplateDataService.getReport().is_preview;
        let oldValue = WidgetBuilderService.restoreHasLiveIntegration();

        if (widgetData.type === WidgetType.BIGNUMBER) {
            if ((widgetData.metadata.draw_options[DrawOption.PLOT_TYPE] !== ChartPlotType.DEFAULT
                && widgetData.metadata.draw_options[DrawOption.PLOT_TYPE] !== ChartPlotType.CLASSIC) || widgetData.metadata.draw_options[DrawOption.CIRCLE_NUMBER] == true) {
                widgetData.metadata.draw_options[DrawOption.WRAP_NUMBER] = true;
            }
        }


        let updatedElement = ExportBuilderDashboardService.updateItemWidget(widgetData);

        if ( oldValue !== widgetData.has_live_integration || widgetData.has_live_integration) {
            updatedElement.emit_event = true;
        }

        if (isSampleData) {
            updatedElement.widget.metadata.draw_options[DrawOption.FORCE_SAMPLE_DATA] = true;
        } else {
            delete updatedElement.widget.metadata.draw_options[DrawOption.FORCE_SAMPLE_DATA];
        }
        ExportBuilderDashboardService.delayUpdateWidgetItem(updatedElement);

        if (!updatedElement.emit_event) {
            PubSub.emit($ExportBuilderDashboardEvents.REFRESH_ITEM_NEEDED + updatedElement.id, updatedElement);
        }
    }

    function setEditWidget(widgetData) {
        WidgetBuilderService.setIsEditing(true);

        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        let oldWidgetData = angular.copy(ReportStudioTemplateDataService.getWidgetElementFromWidgetId(widgetData.id).widget);
        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    let updatedElement = ExportBuilderDashboardService.updateItemWidget(widgetData);
                    PubSub.emit($ExportBuilderDashboardEvents.REFRESH_ITEM_NEEDED + updatedElement.id);
                    ExportBuilderDashboardService.delayUpdateWidgetItem(updatedElement);
                },
                function () {
                    let updatedElement = ExportBuilderDashboardService.updateItemWidget(oldWidgetData);
                    PubSub.emit($ExportBuilderDashboardEvents.REFRESH_ITEM_NEEDED + updatedElement.id);
                    ExportBuilderDashboardService.delayUpdateWidgetItem(updatedElement);
                }
            )
        );
        UndoRedoService.addMutation(mutation);
    }

    function saveEditWidgetFromSpecialEdit(widgetData) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        let oldWidgetData = angular.copy(ExportBuilderDashboardService.getWidgetModelForSpecialEdit());
        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    let updatedElement = ExportBuilderDashboardService.updateItemWidget(widgetData);
                    PubSub.emit($ExportBuilderDashboardEvents.REFRESH_ITEM_NEEDED + updatedElement.id);
                    ExportBuilderDashboardService.delayUpdateWidgetItem(updatedElement);
                },
                function () {
                    let updatedElement = ExportBuilderDashboardService.updateItemWidget(oldWidgetData);
                    PubSub.emit($ExportBuilderDashboardEvents.REFRESH_ITEM_NEEDED + updatedElement.id);
                    ExportBuilderDashboardService.delayUpdateWidgetItem(updatedElement);
                }
            )
        );
        UndoRedoService.addMutation(mutation);
    }

    /**
     * EDITS FROM WIDGET BUILD MODE IS NOT YET SUPPORTED
     */
    function saveEditWidgetFromBuildMode(widgetData) {
        WidgetBuilderService.setIsEditing(true);

        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        let oldWidgetData = angular.copy(WidgetBuilderService.restoreOriginalWidgetModel());
        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    let updatedElement = ExportBuilderDashboardService.updateItemWidget(widgetData);
                    PubSub.emit($ExportBuilderDashboardEvents.REFRESH_ITEM_NEEDED + updatedElement.id, updatedElement);
                    ExportBuilderDashboardService.delayUpdateWidgetItem(updatedElement);
                },
                function () {
                    let updatedElement = ExportBuilderDashboardService.updateItemWidget(oldWidgetData);
                    PubSub.emit($ExportBuilderDashboardEvents.REFRESH_ITEM_NEEDED + updatedElement.id, updatedElement);
                    ExportBuilderDashboardService.delayUpdateWidgetItem(updatedElement);
                }
            )
        );
        UndoRedoService.addMutation(mutation);
    }

    function setWidgetDateRange(widgetData) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        let oldWidgetData = angular.copy(ReportStudioTemplateDataService.getWidgetElementFromWidgetId(widgetData.id).widget);
        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    let updatedElement = ExportBuilderDashboardService.updateItemWidget(widgetData);
                    PubSub.emit($ExportBuilderDashboardEvents.REFRESH_ITEM_NEEDED + updatedElement.id);
                    ExportBuilderDashboardService.delayUpdateWidgetItem(updatedElement);
                },
                function () {
                    let updatedElement = ExportBuilderDashboardService.updateItemWidget(oldWidgetData);
                    PubSub.emit($ExportBuilderDashboardEvents.REFRESH_ITEM_NEEDED + updatedElement.id);
                    ExportBuilderDashboardService.delayUpdateWidgetItem(updatedElement);
                }
            )
        );
        UndoRedoService.addMutation(mutation);
    }

    function setWidgetFilters(widget) {

        const element = ReportStudioTemplateDataService.getWidgetElementFromWidgetId(widget.id);
        let oldWidget = angular.copy(element.widget);
        const oldMetadata = oldWidget.metadata;
        const filter_set_id = widget.metadata.filter_set_id;

        let singleRunMutation = ExportBuilderUndoModelFactory.getMutationModel();
        singleRunMutation.setSingleRun(true);
        singleRunMutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    element.widget.metadata = widget.metadata;
                    ExportBuilderDashboardService.emptyWidgetCache(element.widget);
                    ExportBuilderDashboardService.delayUpdateWidgetItem(element);
                    PubSub.emit($ExportBuilderDashboardEvents.REFRESH_ITEM_NEEDED + element.id);
                }
            )
        );

        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        mutation.setDelayed(true);
        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    element.widget.metadata = widget.metadata;
                    element.widget.metadata.filter_set_id = oldWidget.metadata.filter_set_id;

                    PubSub.emit($ExportBuilderDashboardEvents.SET_LOADING_STATE + element.id, true);
                    return ExportBuilderDashboardItemService.updateWidgetFilter(element, true)
                        .then(() => {
                            PubSub.emit($ExportBuilderDashboardEvents.REFRESH_ITEM_NEEDED + element.id);
                        })
                        .finally(() => {
                            PubSub.emit($ExportBuilderDashboardEvents.SET_LOADING_STATE + element.id, false);
                        });
                },
                function () {
                    element.widget.metadata = oldMetadata;
                    element.widget.metadata.filter_set_id = filter_set_id;
                    PubSub.emit($ExportBuilderDashboardEvents.SET_LOADING_STATE + element.id, true);
                    return ExportBuilderDashboardItemService.updateWidgetFilter(element, true)
                        .then(() => {
                            PubSub.emit($ExportBuilderDashboardEvents.REFRESH_ITEM_NEEDED + element.id);
                        })
                        .finally(() => {
                            PubSub.emit($ExportBuilderDashboardEvents.SET_LOADING_STATE + element.id, false);
                        });
                }
            )
        );

        UndoRedoService.addMutation(singleRunMutation);
        UndoRedoService.addMutation(mutation);
    }

    function deleteWidgetFilter(element) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();

        const oldFilterData = {
            name: element.widget.metadata.dynamic.filter_set_name,
            filters: element.widget.metadata.dynamic.filters,
        };

        mutation.setIsAsync(true);
        mutation.addAction(
            ExportBuilderUndoModelFactory.getActionModel(
                function () {
                    PubSub.emit($ExportBuilderDashboardEvents.SET_LOADING_STATE + element.id, true);
                    return ExportBuilderDashboardItemService.removeWidgetFilter(element)
                        .then(() => {
                            let updatedElement = ExportBuilderDashboardService.updateItemWidget(element.widget);
                            PubSub.emit($ExportBuilderDashboardEvents.ON_GLOBAL_FILTER_APPLY);
                            PubSub.emit($ExportBuilderDashboardEvents.REFRESH_ITEM_NEEDED + updatedElement.id);
                        })
                        .finally(() => {
                            PubSub.emit($ExportBuilderDashboardEvents.SET_LOADING_STATE + element.id, false);
                        });
                },
                function () {
                    element.widget.metadata.dynamic.filter_set_name = oldFilterData.name;
                    element.widget.metadata.dynamic.filters = oldFilterData.filters;
                    PubSub.emit($ExportBuilderDashboardEvents.SET_LOADING_STATE + element.id, true);
                    return ExportBuilderDashboardItemService.updateWidgetFilter(element, true)
                        .then(() => {
                            PubSub.emit($ExportBuilderDashboardEvents.ON_GLOBAL_FILTER_APPLY);
                            let updatedElement = ExportBuilderDashboardService.updateItemWidget(element.widget);
                            PubSub.emit($ExportBuilderDashboardEvents.REFRESH_ITEM_NEEDED + updatedElement.id);
                        })
                        .finally(() => {
                            PubSub.emit($ExportBuilderDashboardEvents.SET_LOADING_STATE + element.id, false);
                        });
                }
            )
        );
        UndoRedoService.addMutation(mutation);
    }

    /**
     * Generic function to apply a mutation to an element inside the ReportStudio builder
     * @param value         Property value to change
     * @param newValueFn    Function to call when perfomring a 'do' or 'redo'
     * @param oldValueFn    Function to call when performing an 'undo'
     * @param getOldValue   Function to capture the old value of the element for 'redo'
     */
    function performGenericMutation(value, newValueFn, oldValueFn, getOldValue) {
        let mutation = ExportBuilderUndoModelFactory.getMutationModel();
        let elements = ExportBuilderDashboardService.getBuilder().elements;

        _.each(elements, function (element) {
            let oldValue = getOldValue(element);
            mutation.addAction(
                ExportBuilderUndoModelFactory.getActionModel(
                    function () {
                        newValueFn.call(undefined, element, value);
                    },
                    function () {
                        oldValueFn.call(undefined, element, oldValue);
                    }
                )
            );
        });
        UndoRedoService.addMutation(mutation);
    }
}