import { ValueAxis } from '@amcharts/amcharts5/xy';
import { AxisFormatters, Constant } from '@/modules/core/charts/am5/charts.constants';
import { getChartConfig } from '@/modules/core/charts/am5/bar/config/chartconfig';
import { omitNils } from '@/modules/core/app/utils/ObjectUtil';
import { ColumnFormat } from '@/modules/core/app/constants/data.constants';
import { useLabels } from '@/modules/core/charts/am5/base/composables/series/useLabels';

export function useValueAxis(context) {
  const { root, config } = context();
  const { preFixCurrency } = useLabels(context);

  function createValueAxis(props, renderer) {
    const {
      isSparkLine,
      isFullStacked,
      isZeroScaled,
      isLogScaled,
      isNormalizedData,
      isRadialHistogram,
    } = config.value;
    const { axisOptions } = getChartConfig({
      isFullStacked,
      isZeroScaled,
      isLogScaled,
      isNormalizedData,
      isRadialHistogram,
    });
    const axis = ValueAxis.new(
      root.value,
      omitNils({
        max: props.max,
        extraMin: props.extraMin,
        extraMax: props.extraMax,
        strictMinMax: props.strictMinMax ?? false,
        ...axisOptions,
        extraTooltipPrecision: 1,
        renderer,
        ...getFormatter(props.dataItemFormat),
      })
    );

    if (isSparkLine) {
      renderer.grid.template.set(Constant.VISIBLE, false);
      renderer.labels.template.set(Constant.VISIBLE, false);
      axis.cursorTooltipEnabled = false;
    }
    return axis;
  }

  function getFormatter(selectedFormat) {
    // if this is normalized then we need to use the selectedFormat
    const formatType = getDataFormat(selectedFormat);
    const formatObject = AxisFormatters[formatType];

    if (formatType === ColumnFormat.FORMAT_CURRENCY) {
      formatObject.numberFormat = preFixCurrency(formatObject.numberFormat);
    }

    return formatObject;
  }

  /**
   * All this so we don't display an axis with 2000% on
   * @returns {string}
   */
  function getDataFormat(defaultFormat) {
    if (config.value.isNormalizedData) {
      return defaultFormat;
    }

    const { series } = config.value;
    const formatTypes = [];
    if (!series) {
      return defaultFormat;
    }

    series.forEach((xySeries) => {
      formatTypes.push(xySeries.dataItemFormat);
    });

    // if everything matches then just return what they are
    const allTheSame = formatTypes.every((value) => value === formatTypes[0]);
    if (allTheSame) {
      return formatTypes[0];
    }

    // if they're different and include percentage then return the first one that's not percentage
    if (formatTypes.includes(ColumnFormat.FORMAT_PERCENT)) {
      return formatTypes.find((value) => value !== ColumnFormat.FORMAT_PERCENT);
    }

    // default to the format that comes first
    return formatTypes[0];
  }

  return {
    createValueAxis,
  };
}
