import { p50 } from '@amcharts/amcharts5';
import { AxisRendererCircular, AxisRendererRadial } from '@amcharts/amcharts5/radar';
import { Constant } from '@/modules/core/charts/am5/charts.constants';
import { useAxes } from '@/modules/core/charts/am5/base/composables/axis/useAxes';
import { omitNils } from '@/modules/core/app/utils/ObjectUtil';

export function useRadialAxes(context) {
  const { root, chart, config } = context();
  const { configureRenderer, configureAxis, getAxis } = useAxes(context);

  function createRadialAxes() {
    const { categoryAxis, valueAxis } = config.value;
    if (config.value.isRadialBar) {
      createXAxes(valueAxis);
      createYAxes(categoryAxis);
    } else {
      createXAxes(categoryAxis);
      createYAxes(valueAxis);
    }
  }

  function createXAxes(axes) {
    axes.forEach((axisProps) => {
      const axis = createRadialXAxes(axisProps);
      chart.value.xAxes.push(axis);
    });
  }

  function createYAxes(axes) {
    axes.forEach((axisProps) => {
      const axis = createRadialYAxes(axisProps);
      chart.value.yAxes.push(axis);
    });
  }

  function createRadialXAxes(axisProps) {
    const renderer = AxisRendererCircular.new(
      root.value,
      omitNils({
        minGridDistance: 40,
        opposite: axisProps.opposite,
        minorGridEnabled: true,
      })
    );
    const axis = getAxis(axisProps, renderer);
    axis.set(Constant.USER_DATA, axisProps);
    configureRenderer(renderer, axisProps);
    configureAxis(axis, axisProps);
    renderer.labels.template.setAll({
      textType: Constant.ADJUSTED, // circular , radial, adjusted
    });
    if (config.value.isRadialBar) {
      renderer.labels.template.setAll({
        radius: 10,
        maxPosition: 0.98,
      });
    }
    return axis;
  }

  function createRadialYAxes(axisProps) {
    const renderer = AxisRendererRadial.new(
      root.value,
      omitNils({
        minGridDistance: 30,
        opposite: axisProps.opposite,
        inversed: config.value.isRotated,
        minorGridEnabled: true,
      })
    );
    const axis = getAxis(axisProps, renderer);
    axis.set(Constant.USER_DATA, axisProps);
    configureRenderer(renderer, axisProps);
    configureAxis(axis, axisProps);
    if (config.value.isRadialHistogram) {
      renderer.labels.template.set(Constant.CENTER_X, p50);
    }
    return axis;
  }

  return {
    createRadialAxes,
  };
}
