import { percent, color } from '@amcharts/amcharts5';
import { PieChartConstant, Constant } from '@/modules/core/charts/am5/charts.constants';
import { getShadowSettings } from '@/modules/core/charts/am5/charts.helper';

export function getPieChartConfig({
  comparisonEnabled,
  roundedCorner,
  showColumnShadow,
  showGrainyPattern,
  grainyPatternSize,
  grainyPatternDensity,
  gradientColor,
  pieColorPicker,
  plotType,
  isExporting,
}) {
  const labelComparisonProps = {
    text: comparisonEnabled ? PieChartConstant.PRIOR_PERIOD : Constant.CURRENT_PERIOD,
    fontSize: PieChartConstant.LABEL_FONT_SIZE,
    fontWeight: PieChartConstant.LABEL_FONT_WEIGHT,
    textAlign: Constant.CENTER,
    x: percent(50),
    centerX: percent(50),
    paddingTop: 0,
    paddingBottom: 0,
  };

  const pieRoundedCorners = {
    cornerRadius: roundedCorner,
  };
  let shadowOptions = {};
  if (showColumnShadow) {
    shadowOptions = getShadowSettings(isExporting);
  }
  let grainyPatternOptions = {};
  if (showGrainyPattern) {
    grainyPatternOptions = {
      size: grainyPatternSize,
      maxOpacity: 0.2,
      density: grainyPatternDensity,
      colors: [color(0x000000)],
    };
  }
  const radialProps = {
    stops: [
      {
        brighten: -0.8,
      },
      {
        brighten: -0.8,
      },
      {
        brighten: -0.5,
      },
      {
        brighten: 0,
      },
      {
        brighten: -0.5,
      },
    ],
  };
  let pieLinearGradientColor = gradientColor;
  if (plotType === PieChartConstant.PLOT_V2_PIE) pieLinearGradientColor = pieColorPicker;

  const linearProps = {
    stops: [
      {},
      {
        color: color(pieLinearGradientColor),
      },
    ],
  };
  return {
    labelComparisonProps,
    pieRoundedCorners,
    shadowOptions,
    grainyPatternOptions,
    radialProps,
    linearProps,
  };
}
