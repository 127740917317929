'use strict';

import angular from 'angular';
import { AppConstants, AppConfig } from '../../shared/scripts/app.constants';

angular.module('navmain.services', [])
    .service('NavMainFactory', NavMainFactory);

/**
 * @ngInject
 */
function NavMainFactory() {
    var navItems = [];

    var navMainMenuKeyIconMap = {
        dashboards: 'icon icomoon-dashboard',
        managedashboards: 'icon icon-sliders',
        autoleads: 'icon icon-car',
        channeloverview: 'icon icon-th-large',
        datasources: 'icon icomoon-connect',
        managedatasources: 'icon icomoon-plug',
        analyticsreporting: 'icon icomoon-report',
        analyticsadministration: 'icon icomoon-settings',
        quickreport: 'icon icomoon-report',
        reportscheduler: 'icon icon-calendar',
        reportingtemplates: 'icon icon-clipboard',
        reportstudio: 'icon icomoon-brush-pen',
        analyticsclients: 'icon icon-briefcase',
        goals: 'icon icomoon-location-target',
        clientgroups: 'icon icon-users',
        clusters: 'icon icon-sitemap',
        analyticsintegrations: 'icon icomoon-plug',
        users: 'icon icon-user',
        roles: 'icon icomoon-role',
        leads: 'icon icomoon-share',
        industrybenchmark: 'icon icomoon-share',
        endcustomers: 'icon icomoon-group',
        dataprofiles: 'icon icomoon-profile',
        smartconnector: 'icon icomoon-plug',
        categories: 'icon icon-th-large',
        smartcampaigns: 'icon icomoon-data-widget',
        manageproducts: 'icon icomoon-tag',
        calculations: 'icon icon-calculator',
        marginrules: 'icon icon-usd',
        campaigns: 'icon icon-suitcase',
        connectionstatus: 'icon icomoon-plug',
        dashboardlibrary: 'icon icomoon-blank-dashboard',
        sectionlibrary: 'icon icon-columns',
        widgetlibrary: 'icon icomoon-widget-library',
        managereportstudiolibrary: 'icon icon-paint-brush',
        dashboardtags: 'icon icon-tags',
        sectiontags: 'icon icon-tags',
        widgettags: 'icon icon-tags',
        executivesummary: 'icon icon-newspaper-o',
        managealerts: 'icon icon-bell-o',
        tags: 'icon icon-tag',
        tagvalues: 'icon icon-tags',
        billing: 'icon icon-usd',
        preferences: 'icon icon-cogs',
        tapdbvisualizer: 'icon icon-database',
        taptester: 'icon icon-flask',

        orders: 'icon icomoon-arrows',
        createorder: 'icon icon-plus',
        openorders: 'icon icomoon-arrows',
        detailedorders: 'icon icomoon-arrows',
        inventorycalendar: 'icon icon-calendar',
        ioclients: 'icon icon-users',
        clients: 'icon icon-users',
        creativelibrary: 'icon icon-image',
        pixelmanager: 'icon icon-tags',
        workflow: 'icon icomoon-numbered-list',
        mytasks: 'icon icon-tasks',
        myqueues: 'icon icomoon-numbered-list',
        queuemanager: 'icon icomoon-settings',
        useravailability: 'icon icon-users',
        notifications: 'icon icon-bell-o',
        notificationscurrent: 'icon icon-clock-o',
        notificationsarchived: 'icon icon-history',
        ioreporting: 'icon icomoon-report',
        ioreportingorders: 'icon icomoon-arrows',
        lineitems: 'icon icon-arrows-h',
        ioreportingtasks: 'icon icon-tasks',
        master: 'icon icon-reorder',
        orderhistory: 'icon icon-th',
        lineitemhistory: 'icon icon-th',
        slotbookings: 'icon icon-calendar',
        ioadministration: 'icon icomoon-settings',
        iointegrations: 'icon icomoon-plug',
        products: 'icon icomoon-tag',
        queues: 'icon icomoon-numbered-list',
        ioadministrationtasks: 'icon icon-tasks',
        formcontent: 'icon icon-newspaper-o',
        ioadministrationworkflows: 'icon icon-code-fork',
        workflowsteps: 'icon icon-code-fork',
        rules: 'icon icon-gavel',
        ordertaskforms: 'icon icon-sitemap',
        nightlystatus: 'icon icon-calendar',
        exceptionlogs: 'icon icon-calendar',
        statusmanagement: 'icon icon-wrench',
        cancellationreasons: 'icon icon-wrench',
        calendarslots: 'icon icon-calendar',
        jobgrouplist: 'icon icon-calendar',
        serviceconnections: 'icon icon-gears',
        buysheetconf: 'icon icon-print',
        emailsoverview: 'icon icon-envelope',
    };

    var navMainMenuKeyStateRefMap = {
        managedashboards: 'managedashboards',
        managedatasources: 'service.manage',
        autoleads: 'autoleads',
        channeloverview: 'categoriesoverview',
        quickreport: 'generatereport',
        reportscheduler: 'scheduledreports',
        reportingtemplates: 'reportingtemplates',
        reportstudio: 'exportbuilder',
        analyticsclients: 'clients',
        clientgroups: 'clientgroups',
        goals: 'goals',
        leads: 'leads',
        industrybenchmark: 'industrybenchmark',
        emailsoverview: 'emailsoverview',
        endcustomers: 'endcustomers',
        clusters: 'clusters',
        analyticsintegrations: 'integrations',
        users: 'users',
        roles: 'roles',
        dataprofiles: 'reportingprofiles',
        smartconnector: 'importwizard',
        categories: 'categories',
        smartcampaigns: 'smartcampaigns',
        manageproducts: 'manageproducts',
        calculations: 'calculations',
        marginrules: 'marginrules',
        campaigns: 'campaigns',
        connectionstatus: 'connectionstatus',
        dataloadstatus: 'dataloadstatus',
        dashboardlibrary: 'dashboardlibrary',
        sectionlibrary: 'sectionlibrary',
        widgetlibrary: 'widgetlibrary',
        managereportstudiolibrary: 'managereportstudiolibrary',
        dashboardtags: 'dashboardtags',
        sectiontags: 'sectiontags',
        widgettags: 'widgettags',
        executivesummary: 'executivesummary',
        managealerts: 'managealerts',
        tags: 'tags',
        tagvalues: 'tagvalues',
        billing: 'billing',
        preferences: 'preferences',
        tapdbvisualizer: 'tapdbviz',
        taptester: 'taptester',
        dataexporter: 'dataexporter',

        inventorycalendar: 'inventoryCalendar',
        creativelibrary: 'creativeLibrary',
        pixelmanager: 'pixelManager',
        mytasks: 'myTasks',
        myqueues: 'myQueues',
        queuemanager: 'queueManager',
        useravailability: 'userAvailability',
        notificationscurrent: 'notificationsCurrent',
        notificationsarchived: 'notificationsArchived',
        ioclients: 'ioclients',

        ioreportingorders: 'reportOrders',
        lineitems: 'lineItemReports',
        ioreportingtasks: 'tasksReports',
        master: 'masterReport',
        orderhistory: 'orderHistoryReport',
        lineitemhistory: 'lineItemHistoryReport',
        slotbookings: 'slotBookingReport',
        iointegrations: 'integrations',
        products: 'products',
        queues: 'adminQueues',
        ioadministrationtasks: 'adminTasks',
        formcontent: 'lookupTypes',
        ioadministrationworkflows: 'workflows',
        workflowsteps: 'stepGroups',
        rules: 'ruleSet',
        ordertaskforms: 'formList',
        nightlystatus: 'nightlyStatus',
        exceptionlogs: 'exceptionLogs',
        statusmanagement: 'managementTools',
        calendarslots: 'calendarSlots',
        jobgrouplist: 'scheduledJobs',
        serviceconnections: 'ioServiceConnections',
        buysheetconf: 'buySheetConfig',
        servicelookup: 'servicelookup',
    };

    return {
        setNavItems: setNavItems,
        getNavItems: getNavItems,
        getMenuIcon: getMenuIcon,
        getMenuStateRef: getMenuStateRef,
        getMenuStateHRef: getMenuStateHRef,
        getForceNUI: getForceNUI
    };

    /**
     * @param items
     */
    function setNavItems(items) {
        navItems = items;
    }

    /**
     * @returns {Array}
     */
    function getNavItems() {
        return navItems;
    }

    function getMenuIcon(menuKey) {
        return navMainMenuKeyIconMap[menuKey];
    }

    function getMenuStateRef(menuKey, isIORoute) {
        if (menuKey === 'createorder')
            return isIORoute ? 'addOrder' : 'io_addOrder';
        if (menuKey === 'openorders')
            return isIORoute ? 'orders' : 'io_orders';
        if (menuKey === 'detailedorders')
            return isIORoute ? 'detailedOrders' : 'io_detailedOrders';
        if (menuKey === 'cancellationreasons')
            return isIORoute ? 'cancelReasons' : 'io_cancelReasons';
        return navMainMenuKeyStateRefMap[menuKey];
    }

    function getMenuStateHRef(menuKey) {
        if (menuKey === 'clients')
            return AppConfig.NUI_PATH + '#/clients';
        return null;
    }

    function getForceNUI(menuKey) {
        return menuKey === 'clients';
    }
}