'use strict';
import angular from 'angular';

angular.module('core.tapdbviz.resources', [])
    .factory('TapDbVizResource', TapDbVizResource);

function TapDbVizResource(
    Restangular
) {
    var taplabs = Restangular.all('taplabs');
    var tapdbviz = taplabs.all('tapdbviz');

    /**
     * @returns {*}
     */
    function getTableNames() {
        return tapdbviz.one('tablenames').get();
    }

    /**
     * @param tableName
     * @returns {*}
     */
    function getDashboard(tableName) {
        return tapdbviz.one('dash', tableName).get();
    }

    /**
     * @param queryParams
     * @param dataSource
     * @returns {*}
     */
    function getData(queryParams, dataSource) {
        queryParams.datatable = true;
        return tapdbviz.one('data', dataSource.table_name).get(queryParams);
    }

    return {
        getTableNames: getTableNames,
        getDashboard: getDashboard,
        getData: getData,
    }
}