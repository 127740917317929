'use strict';
import angular from 'angular';

angular.module('core.taptester.resources', [])
    .factory('TapTesterResource', TapTesterResource);

function TapTesterResource(
    Restangular
) {
    var taptester = Restangular.all('taptester');
    var suite = taptester.one('suite');

    function executeTest() {
        return suite.one('precommit').get();
    }

    return {
        executeTest: executeTest
    }
}