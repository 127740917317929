'use strict';
import angular from 'angular';
import moment from 'moment';
import _ from 'lodash';
import {WeeklyStartDay} from 'coreModules/design/widget/design.widget.constants';

angular.module('daterange.services', [])

    .factory('DateRangeFactory', DateRangeFactory);

function DateRangeFactory(
    Restangular,
    MomentDateFormat,
    AppFactory,
    RelativeDateRange,
    gettextCatalog,
    LanguageService
) {
    var resource = Restangular.all('enums').one('relativedateranges');

    var relativeDateRanges = {};
    var relativeDateRangesByTextDisplay = {};

    return {
        resource: resource,
        setRelativeDateRanges: setRelativeDateRanges,
        getRelativeDateRange: getRelativeDateRange,
        getRelativeDateRangeByTextDisplay: getRelativeDateRangeByTextDisplay,
        getDefaultOptions: getDefaultOptions,
        getComparisonDefaultOptions: getComparisonDefaultOptions,
        setDatePicker: setDatePicker,
        destroyDatePicker: destroyDatePicker,
        setComparisonDatePicker: setComparisonDatePicker,
        getDateRangeFromRelativeRange: getDateRangeFromRelativeRange,
        getDatePickerRelativeRanges: getDatePickerRelativeRanges,
        getComparisonDatePickerRelativeRanges: getComparisonDatePickerRelativeRanges,
        calculatePeriod: calculatePeriod,
        normalizeDate: normalizeDate,
        buildDateQueryParam: buildDateQueryParam,
        getDateRangePickerLocale: getDateRangePickerLocale
    };

    /**
     * Sets the relativeDateRanges var to be exposed externally
     * @param values
     * @returns {*}
     */
    function setRelativeDateRanges(values) {
        relativeDateRanges = AppFactory.arrayToMemoizedObj(values, 'key');
        _.each(values, function(value) {
            relativeDateRangesByTextDisplay[value.label] = value.key;
        });
        relativeDateRangesByTextDisplay[gettextCatalog.getString('Custom Range')] = RelativeDateRange.CUSTOM;
        // Needed for legacy dashboards
        relativeDateRangesByTextDisplay['Custom'] = RelativeDateRange.CUSTOM;
    }

    /**
     * Get a specific relative date range by id
     * @param id
     * @returns {T|*}
     */
    function getRelativeDateRange(id) {
        return relativeDateRanges[id] || relativeDateRanges[RelativeDateRange.DEFAULT];
    }

    /**
     * Uses the range text sent back by the date picker to resolve the range constant
     * @private
     * @param displayRange (ex: 'Last 14 Days')
     * @returns {*}
     */
    function getRelativeDateRangeByTextDisplay(displayRange) {
        return relativeDateRangesByTextDisplay[displayRange];
    }

    /**
     *
     * @returns {{fromLabel: *, toLabel: *, cancelLabel: *, firstDay: number, applyLabel: *, format: (MomentDateFormat|*), customRangeLabel: *, monthNames: *}}
     */
    function getDateRangePickerLocale() {
        return {
            format: LanguageService.getDisplayDateFormat(),
            applyLabel: gettextCatalog.getString('Apply'),
            cancelLabel: gettextCatalog.getString('Cancel'),
            customRangeLabel: gettextCatalog.getString('Custom Range'),
            fromLabel: gettextCatalog.getString('From'),
            toLabel: gettextCatalog.getString('To'),
            monthNames: moment.months(),
            firstDay: _isDefaultWeeklyStartDayMonday()
        };
    }

    /**
     * Returns the default option for a daterange picker
     * @param startDate
     * @param endDate
     * @returns {{}}
     */
    function getDefaultOptions(startDate, endDate) {
        var currentDateRange = AppFactory.getDateRange();
        return {
            startDate: startDate || currentDateRange.formatted.start,
            endDate: endDate || currentDateRange.formatted.end,
            opens: _getOpenDirection(),
            autoApply: _shouldAutoApply(),
            buttonClasses: 'btn',
            applyClass: 'btn-primary mr5',
            alwaysShowCalendars: true,
            showCustomRangeLabel: true,
            linkedCalendars: false,
            showDropdowns: true,
            minDate: moment().subtract(15, 'years'),
            locale: getDateRangePickerLocale(),
            ranges: getDatePickerRelativeRanges()
        };
    }

    /**
     * Returns the default options for a comparison daterange picker
     * @param startDate
     * @param endDate
     * @returns {{}}
     */
    function getComparisonDefaultOptions(startDate, endDate) {
        var comparisonDateRange = AppFactory.getComparisonDateRange();
        return {
            startDate: startDate || comparisonDateRange.formatted.start,
            endDate: endDate || comparisonDateRange.formatted.end,
            opens: _getOpenDirection(),
            buttonClasses: 'hidden',
            alwaysShowCalendars: true,
            showCustomRangeLabel: true,
            linkedCalendars: false,
            showDropdowns: true,
            autoApply: true,
            minDate: moment().subtract(15, 'years'),
            locale: getDateRangePickerLocale(),
            ranges: getComparisonDatePickerRelativeRanges()
        };
    }

    /**
     * If the first day of the week is monday, return 1; otherwise, return 0 for sunday.
     * @returns {number}
     * @private
     */
    function _isDefaultWeeklyStartDayMonday() {
        let isMonday = AppFactory.getUser().getDefaultWeeklyStartDay();
        return (isMonday === WeeklyStartDay.MONDAY) ? 1 : 0;
    }

    /**
     * Open in different directions based on window size
     * @returns {*}
     * @private
     */
    function _getOpenDirection() {
        if (window.innerWidth < 768) {
            return 'center';
        }
        else if (window.innerWidth < 1024) {
            return 'right';
        }
        else {
            return 'left';
        }
    }

    /**
     * In small screens, always auto apply date selection
     * @returns {boolean}
     * @private
     */
    function _shouldAutoApply() {
        return window.innerWidth < 768;
    }

    /**
     *
     * @param dateOptions
     * @param comparisonDateOptions
     */
    function setDatePicker(
        dateOptions,
        comparisonDateOptions,
        $dateRangePicker,
        $comparisonDateRangePicker,
        setDateRangeFn
    ) {
        // Make sure to remove any DOM added daterangepickers
        destroyDatePicker($dateRangePicker);

        //Provide startDate and endDate as moment instances instead of plain strings for easy the localization
        //as recommended at https://www.daterangepicker.com/#options

        dateOptions.startDate = moment(dateOptions.startDate);
        dateOptions.endDate = moment(dateOptions.endDate);

        $dateRangePicker.daterangepicker(dateOptions, function (start, end, range) {
            var relativeDateRange = !_.isNil(range) ? getRelativeDateRangeByTextDisplay(range) : RelativeDateRange.CUSTOM;
            var comparisonDateRange = AppFactory.getComparisonDateRange();
            var comparisonDate = {
                start: moment.unix(comparisonDateRange.start).utc(),
                end: moment.unix(comparisonDateRange.end).utc()
            };

            // Update comparison dates if comparison is not enabled
            // or a relative date range is selected
            // or if we've exceeded the comparison date range upper bound
            if (!comparisonDateRange.enabled
                || relativeDateRange !== RelativeDateRange.CUSTOM
                || start < getDateRangePickerInstance($comparisonDateRangePicker).endDate) {
                comparisonDate = calculatePeriod(
                    start,
                    end,
                    relativeDateRange,
                    RelativeDateRange.PRIOR_PERIOD
                );
                comparisonDateOptions.startDate = comparisonDate.start;
                comparisonDateOptions.endDate = comparisonDate.end;
            }

            // Update comparison date ranges
            comparisonDateOptions.ranges = getComparisonDatePickerRelativeRanges(start, end, relativeDateRange);

            // Always set the comparisonDatePicker to update ranges and maxDate
            setComparisonDatePicker(
                dateOptions,
                comparisonDateOptions,
                $dateRangePicker,
                $comparisonDateRangePicker,
                setDateRangeFn
            );

            var date = normalizeDate(start, end);
            setDateRangeFn.call(
                this,
                date.start,
                date.end,
                comparisonDate.start,
                comparisonDate.end,
                relativeDateRange
            );
        });

        if (dateOptions.dateRangePickerId) {
            $dateRangePicker
                .data('daterangepicker')
                .container
                .attr('id', dateOptions.dateRangePickerId);
        }
    }

    /**
     */
    function getDateRangePickerInstance($picker) {
        if ($picker) {
            return $picker.data('daterangepicker');
        }
    }

    /**
     *
     */
    function destroyDatePicker($picker) {
        if (getDateRangePickerInstance($picker)) {
            getDateRangePickerInstance($picker).remove();
        }
    }

    /**
     *
     * @param dateOptions
     * @param comparisonDateOptions
     */
    function setComparisonDatePicker(
        dateOptions,
        comparisonDateOptions,
        $dateRangePicker,
        $comparisonDateRangePicker,
        setDateRangeFn
    ) {
        // Make sure to remove any DOM added daterangepickers
        destroyDatePicker($comparisonDateRangePicker);

        $comparisonDateRangePicker.daterangepicker(comparisonDateOptions, function (comparisonStart, comparisonEnd, range) {
            var comparisonRelativeDateRange = !_.isNil(range) ? getRelativeDateRangeByTextDisplay(range) : RelativeDateRange.CUSTOM;
            var currentDateRange = AppFactory.getDateRange();
            var currentStartDate = moment.unix(currentDateRange.start).utc();
            var currentEndDate = moment.unix(currentDateRange.end).utc();
            var currentDate = normalizeDate(dateOptions.startDate, dateOptions.endDate);
            var comparisonDate = normalizeDate(comparisonStart, comparisonEnd);
            var relativeDateRange = AppFactory.getCurrentRelativeDateRange();

            // Update comparison dates if a comparison relative date range is selected
            if (comparisonRelativeDateRange !== RelativeDateRange.CUSTOM) {
                comparisonDate = calculatePeriod(currentStartDate, currentEndDate, relativeDateRange, comparisonRelativeDateRange);
            }
            // If we've exceeded the current date range lower bound
            else if (comparisonEnd > getDateRangePickerInstance($dateRangePicker).startDate) {
                var period = calculatePeriod(comparisonDate.start, comparisonDate.end, relativeDateRange, RelativeDateRange.FUTURE_PERIOD);
                dateOptions.startDate = period.start;
                dateOptions.endDate = period.end;
                currentDateRange.start = period.start.unix();
                currentDateRange.end = period.end.unix();

                // Set the datePicker to update lower bound
                setDatePicker(
                    dateOptions,
                    comparisonDateOptions,
                    $dateRangePicker,
                    $comparisonDateRangePicker,
                    setDateRangeFn
                );
            }

            setDateRangeFn.call(
                this,
                moment.unix(currentDateRange.start).utc(),
                moment.unix(currentDateRange.end).utc(),
                comparisonDate.start,
                comparisonDate.end,
                relativeDateRange
            );
        });

        if (comparisonDateOptions.compareDateRangePickerId) {
            $comparisonDateRangePicker
                .data('daterangepicker')
                .container
                .attr('id', comparisonDateOptions.compareDateRangePickerId);
        }
    }

    /**
     * @param range
     * @returns {{start: Moment, end: Moment}}
     */
    function getDateRangeFromRelativeRange(range) {
        range = getRelativeDateRange(range).key;

        var startDate = moment(relativeDateRanges[range].start);
        var endDate = moment(relativeDateRanges[range].end);

        // If DEFAULT, make sure to use current dashbaord date (since it may have been updated in the frontend)
        if (range === RelativeDateRange.DEFAULT) {
            var currentDateRange = AppFactory.getDateRange();
            startDate = moment.unix(currentDateRange.start).utc();
            endDate = moment.unix(currentDateRange.end).utc();
        }

        return {
            start: startDate,
            end: endDate
        };
    }

    /**
     * @param range
     * @returns {{start: Moment, end: Moment}}
     */
    function getPriorDateRangeFromRelativeRange(range) {
        range = getRelativeDateRange(range).key;

        var comparisonStartDate = relativeDateRanges[range].comparison_start;
        var comparisonEndDate = relativeDateRanges[range].comparison_end;

        // If DEFAULT, make sure to use current dashbaord date (since it may have been updated in the frontend)
        if (range === RelativeDateRange.DEFAULT) {
            // Need to calculate default comparison start and end date
            var currentDateRange = getDateRangeFromRelativeRange(range);

            if (_isMonthDateRange(currentDateRange.start, currentDateRange.end)) {
                const priorPeriod = _getMonthPriorPeriod(currentDateRange.start, currentDateRange.end);
                comparisonStartDate = priorPeriod.start;
                comparisonEndDate = priorPeriod.end;
            } else {
                var diff = moment(currentDateRange.end).diff(moment(currentDateRange.start), 'days')+1;
                comparisonStartDate = moment.utc(currentDateRange.start.format(MomentDateFormat.ISO)).subtract(diff, 'days').format(MomentDateFormat.ISO);
                comparisonEndDate = moment.utc(currentDateRange.end.format(MomentDateFormat.ISO)).subtract(diff, 'days').format(MomentDateFormat.ISO);
            }
        }

        return {
            start: moment(comparisonStartDate),
            end: moment(comparisonEndDate)
        };
    }

    /**
     * @param startDate
     * @param endDate
     * @returns {{}}
     */
    function getDatePickerRelativeRanges() {
        var ranges = {};
        ranges[relativeDateRanges[RelativeDateRange.DEFAULT].label] = _.values(getDateRangeFromRelativeRange(RelativeDateRange.DEFAULT));
        ranges[relativeDateRanges[RelativeDateRange.TODAY].label] = _.values(getDateRangeFromRelativeRange(RelativeDateRange.TODAY));
        ranges[relativeDateRanges[RelativeDateRange.YESTERDAY].label] = _.values(getDateRangeFromRelativeRange(RelativeDateRange.YESTERDAY));
        ranges[relativeDateRanges[RelativeDateRange.TOMORROW].label] = _.values(getDateRangeFromRelativeRange(RelativeDateRange.TOMORROW));
        ranges[relativeDateRanges[RelativeDateRange.LAST_7_DAYS].label] = _.values(getDateRangeFromRelativeRange(RelativeDateRange.LAST_7_DAYS));
        ranges[relativeDateRanges[RelativeDateRange.LAST_14_DAYS].label] = _.values(getDateRangeFromRelativeRange(RelativeDateRange.LAST_14_DAYS));
        ranges[relativeDateRanges[RelativeDateRange.LAST_30_DAYS].label] = _.values(getDateRangeFromRelativeRange(RelativeDateRange.LAST_30_DAYS));
        ranges[relativeDateRanges[RelativeDateRange.LAST_90_DAYS].label] = _.values(getDateRangeFromRelativeRange(RelativeDateRange.LAST_90_DAYS));
        ranges[relativeDateRanges[RelativeDateRange.LAST_180_DAYS].label] = _.values(getDateRangeFromRelativeRange(RelativeDateRange.LAST_180_DAYS));
        ranges[relativeDateRanges[RelativeDateRange.LAST_WEEK].label] = _.values(getDateRangeFromRelativeRange(RelativeDateRange.LAST_WEEK));
        ranges[relativeDateRanges[RelativeDateRange.LAST_MONTH].label] = _.values(getDateRangeFromRelativeRange(RelativeDateRange.LAST_MONTH));
        ranges[relativeDateRanges[RelativeDateRange.LAST_3_MONTHS].label] = _.values(getDateRangeFromRelativeRange(RelativeDateRange.LAST_3_MONTHS));
        ranges[relativeDateRanges[RelativeDateRange.LAST_QUARTER].label] = _.values(getDateRangeFromRelativeRange(RelativeDateRange.LAST_QUARTER));
        ranges[relativeDateRanges[RelativeDateRange.LAST_6_MONTHS].label] = _.values(getDateRangeFromRelativeRange(RelativeDateRange.LAST_6_MONTHS));
        ranges[relativeDateRanges[RelativeDateRange.LAST_12_MONTHS].label] = _.values(getDateRangeFromRelativeRange(RelativeDateRange.LAST_12_MONTHS));
        ranges[relativeDateRanges[RelativeDateRange.LAST_YEAR].label] = _.values(getDateRangeFromRelativeRange(RelativeDateRange.LAST_YEAR));
        ranges[relativeDateRanges[RelativeDateRange.LAST_13_MONTHS].label] = _.values(getDateRangeFromRelativeRange(RelativeDateRange.LAST_13_MONTHS));
        ranges[relativeDateRanges[RelativeDateRange.YEAR_TO_DATE].label] = _.values(getDateRangeFromRelativeRange(RelativeDateRange.YEAR_TO_DATE));
        ranges[relativeDateRanges[RelativeDateRange.THIS_WEEK].label] = _.values(getDateRangeFromRelativeRange(RelativeDateRange.THIS_WEEK));
        ranges[relativeDateRanges[RelativeDateRange.THIS_MONTH].label] = _.values(getDateRangeFromRelativeRange(RelativeDateRange.THIS_MONTH));
        ranges[relativeDateRanges[RelativeDateRange.THIS_MONTH_EXCLUDE_TODAY].label] = _.values(getDateRangeFromRelativeRange(RelativeDateRange.THIS_MONTH_EXCLUDE_TODAY));
        ranges[relativeDateRanges[RelativeDateRange.THIS_QUARTER].label] = _.values(getDateRangeFromRelativeRange(RelativeDateRange.THIS_QUARTER));
        ranges[relativeDateRanges[RelativeDateRange.THIS_YEAR].label] = _.values(getDateRangeFromRelativeRange(RelativeDateRange.THIS_YEAR));
        ranges[relativeDateRanges[RelativeDateRange.NEXT_7_DAYS].label] = _.values(getDateRangeFromRelativeRange(RelativeDateRange.NEXT_7_DAYS));
        ranges[relativeDateRanges[RelativeDateRange.NEXT_30_DAYS].label] = _.values(getDateRangeFromRelativeRange(RelativeDateRange.NEXT_30_DAYS));
        ranges[relativeDateRanges[RelativeDateRange.NEXT_WEEK].label] = _.values(getDateRangeFromRelativeRange(RelativeDateRange.NEXT_WEEK));
        ranges[relativeDateRanges[RelativeDateRange.NEXT_MONTH].label] = _.values(getDateRangeFromRelativeRange(RelativeDateRange.NEXT_MONTH));
        ranges[relativeDateRanges[RelativeDateRange.NEXT_YEAR].label] = _.values(getDateRangeFromRelativeRange(RelativeDateRange.NEXT_YEAR));

        return ranges;
    }

    /**
     *  Sets priorPeriod and priorYear date ranges.
     *  If these date ranges are beyond the comparison date
     *  range limits, they will not display in the date picker
     * @param startDate
     * @param endDate
     * @param relativeRange
     * @returns {{}}
     */
    function getComparisonDatePickerRelativeRanges(startDate, endDate, relativeRange) {
        var currentDateRange = AppFactory.getDateRange();
        startDate = startDate || currentDateRange.formatted.start;
        endDate = endDate || currentDateRange.formatted.end;
        relativeRange = relativeRange || currentDateRange.relativeRange;
        var priorPeriod = calculatePeriod(
            startDate,
            endDate,
            relativeRange,
            RelativeDateRange.PRIOR_PERIOD
        );

        var priorYear = calculatePeriod(
            startDate,
            endDate,
            relativeRange,
            RelativeDateRange.PRIOR_YEAR
        );
        
        // These get overriden on the fly
        var ranges = {};
        ranges[relativeDateRanges[RelativeDateRange.PRIOR_PERIOD].label] = [priorPeriod.start, priorPeriod.end];
        ranges[relativeDateRanges[RelativeDateRange.PRIOR_YEAR].label] = [priorYear.start, priorYear.end];
        return ranges;
    }


    /**
     * Calculates a comparison period based on the length of a given date range
     * @param startDate
     * @param endDate
     * @param relativeDateRange
     * @param comparisonRelativeRange
     * @returns {{start: Moment, end: Moment}}
     */
    function calculatePeriod(
        startDate,
        endDate,
        relativeDateRange,
        comparisonRelativeRange
    ) {
        var range = {};
        var numDaysDiff = 0;

        switch (comparisonRelativeRange) {

            case RelativeDateRange.PRIOR_PERIOD:

                if (relativeDateRange === RelativeDateRange.DEFAULT) {
                    relativeDateRange = AppFactory.getCurrentRelativeDateRange();
                }

                if (relativeDateRange === RelativeDateRange.CUSTOM) {
                    numDaysDiff = moment(endDate).diff(startDate, 'days');
                    range.start = moment(startDate).subtract(numDaysDiff + 1, 'days');
                    range.end = moment(startDate).subtract(1, 'day');
                }
                else {
                    range = getPriorDateRangeFromRelativeRange(relativeDateRange);
                }
                break;

            case RelativeDateRange.FUTURE_PERIOD:
                numDaysDiff = moment(endDate).diff(startDate, 'days');
                range.start = moment.utc(moment(endDate).add(1, 'day'));
                range.end = moment.utc(moment(endDate).add(numDaysDiff + 1, 'days'));
                break;

            case RelativeDateRange.PRIOR_YEAR:
                range.start = moment(startDate).subtract(1, 'year');
                range.end = moment(endDate).subtract(1, 'year');
                break;

            default:
                break;
        }

        return {
            start: moment(range.start),
            end: moment(range.end)
        };
    }

    /**
     * This will remove the time component of a Moment object
     * Useful since date picker will automatically add time to/format date selection.
     * @param start
     * @param end
     * @returns {{start: *, end: *}}
     */
    function normalizeDate(start, end) {
        return {
            start: moment(start).startOf('day'),
            end: moment(end).startOf('day')
        }
    }


    /**
     * Builds a query string for datarange query param in ISO (ex: ?daterange=2014-01-01|2015-12-12)
     * @param dateRange
     * @param comparisonDateRange
     * @param forceComparison
     * @param isWidgetDateRangeOverride
     * @returns {*}
     */
    function buildDateQueryParam(dateRange, comparisonDateRange, forceComparison, isWidgetDateRangeOverride) {

        if (_.isNull(AppFactory.getDateRange().start)) {
            return null;
        }

        // Use dashboard date range if no override provided
        if (_.isNil(dateRange)) {
            dateRange = AppFactory.getDateRange();
            dateRange.start = moment.unix(dateRange.start).utc().format(MomentDateFormat.ISO);
            dateRange.end = moment.unix(dateRange.end).utc().format(MomentDateFormat.ISO);
        }
        if (_.isNil(comparisonDateRange)) {
            comparisonDateRange = AppFactory.getComparisonDateRange();
            comparisonDateRange.start = moment.unix(comparisonDateRange.start).utc().format(MomentDateFormat.ISO);
            comparisonDateRange.end = moment.unix(comparisonDateRange.end).utc().format(MomentDateFormat.ISO);
        }

        var dateQuery = dateRange.start + '|' + dateRange.end;

        if ((comparisonDateRange.enabled && !isWidgetDateRangeOverride) || forceComparison) {
            dateQuery += ',' + comparisonDateRange.start + '|' + comparisonDateRange.end;
        }

        return dateQuery;
    }

    /**
     * Check if current date range is a month long
     * @param start
     * @param end
     * @returns {boolean}
     * @private
     */
    function _isMonthDateRange(start, end) {
        let startDate = angular.copy(start);
        let endDate = angular.copy(end);
        return startDate.format('M') === endDate.format('M') // same month
            && startDate.format('D') === '1' // first day
            && endDate.format('D') === endDate.endOf('month').format('D') // end of month
    }

    /**
     * Get month from dates
     * @param start
     * @param end
     * @private
     */
    function _getMonthPriorPeriod(start, end) {
        let previousMonth = end.subtract(1,'months');
        let firtDayOfMonth = previousMonth.startOf('month');
        let lastDayOfMonth = angular.copy(firtDayOfMonth).endOf('month');

        return {
            start: firtDayOfMonth,
            end: lastDayOfMonth
        }
    }

}
