import { BaseModel } from '@/modules/core/app/models/abstracts/BaseModel';

export class UserListSettings extends BaseModel {
  /**
   * @var {string}
   */
  id;

  /**
   * @var {string}
   */
  user_id;

  /**
   * @var {string}
   */
  list_id;

  /**
   * @var {boolean}
   */
  list_view;

  /**
   * @param model
   */
  constructor(model = {}) {
    super();
    this.id = model.id;
    this.user_id = model.user_id;
    this.list_id = model.list_id;
    this.list_view = model.list_view;
  }
}
