import { Constant } from '@/modules/core/charts/am5/charts.constants';
import { useLegend as useGenericLegend } from '@/modules/core/charts/am5/base/composables/useLegend';
import { useLegend as useLineSeriesLegend } from '@/modules/core/charts/am5/line/composables/useLegend';
import { Label } from '@amcharts/amcharts5';

export function useLegend(context) {
  const { root, chart, config } = context();
  const { generateInitialLegend } = useGenericLegend(context);
  const { addLegendEvents } = useLineSeriesLegend(context);

  function createLegend() {
    const legend = generateInitialLegend({}, true);
    addLegendEvents(legend, restoreAllSeries, applyGreyOutOptions);
    legend.data.setAll(chart.value.series.values);
    const groupByField = config.value.groupByFieldName;
    const groupByLabel = i18n.$t('Group By');
    legend.children.push(
      Label.new(root.value, {
        // eslint-disable-next-line tap/no-raw-text-js
        text: `[bold]${groupByLabel}:[/] ${groupByField}`,
        populateText: true,
        paddingLeft: 0,
        fontSize: 12,
      })
    );
  }

  function applyGreyOutOptions(series) {
    series.bulletsContainer.children.each((bullet) => {
      bullet.children.getIndex(0).set(Constant.FILL_OPACITY, 0.1);
      bullet.children.getIndex(1)?.set(Constant.FILL_OPACITY, 0.1);
    });
  }

  function restoreAllSeries() {
    chart.value.series.each((series) => {
      const seriesProps = series.get(Constant.USER_DATA);
      series.bulletsContainer.children.each((bullet) => {
        bullet.children.getIndex(0).set(Constant.FILL_OPACITY, seriesProps.fillOpacity);
        bullet.children.getIndex(1)?.set(Constant.FILL_OPACITY, 1);
      });
    });
  }

  return {
    createLegend,
  };
}
