import { ChartDrawOption } from '@/modules/ta/widget/models/drawoptions/ChartDrawOption';
import { isNil } from 'lodash';
import {
  GradientTargets,
  HandTypes,
  PlotType,
  WidgetBackgroundGradientColorOptions,
} from '@/modules/ta/widget/widget.constants';
import { Constant, GaugeBigNumberPosition } from '@/modules/core/charts/am5/charts.constants';

export class GaugeChartDrawOption extends ChartDrawOption {
  /**
   * @var {boolean}
   */
  has_tooltip;

  /**
   * @var {boolean}
   */
  show_labels;

  /**
   * @var {string}
   */
  plot_type;

  /**
   * @var {string}
   */
  fill_type;

  /**
   * @var {string}
   */
  gradient_color;

  /**
   * @var {Number}
   */
  gauge_thickness;

  /**
   * @var {Number}
   */
  gauge_multi_thickness;

  /**
   * @var {string}
   */
  gauge_hand_type;

  /**
   * @var {boolean}
   */
  gauge_show_value;

  /**
   * @var {boolean}
   */
  show_ticks;

  /**
   * @var {string}
   */
  hand_color;

  /**
   * @var {Number}
   */
  gauge_range;

  /**
   * @var {string}
   */
  gauge_gradient_to;

  /**
   * @var {boolean}
   */
  gauge_hand_custom_color;

  /**
   * @var {string}
   */
  gauge_gradient_target;

  /**
   * @var {boolean}
   */
  show_shape_shadow;

  /**
   * @var {string}
   */
  gauge_big_number_position;

  constructor(model = {}) {
    super(model);

    this.has_tooltip = model.has_tooltip ?? true;
    this.has_legend = model.has_legend ?? false;
    this.show_labels = isNil(model.show_labels) ? true : model.show_labels;
    this.plot_type = isNil(model.plot_type) ? PlotType.CLASSIC : model.plot_type;
    this.fill_type = model.fill_type || WidgetBackgroundGradientColorOptions.SOLID;
    this.gradient_color = isNil(model.gradient_color) ? Constant.GREY : model.gradient_color;
    this.gauge_thickness = isNil(model.gauge_thickness) ? 75 : model.gauge_thickness;
    this.gauge_multi_thickness = isNil(model.gauge_multi_thickness)
      ? 35
      : model.gauge_multi_thickness;
    this.gauge_hand_type = isNil(model.gauge_hand_type) ? HandTypes.NONE : model.gauge_hand_type;
    this.gauge_show_value = isNil(model.gauge_show_value) ? true : model.gauge_show_value;
    this.show_ticks = isNil(model.show_ticks) ? true : model.show_ticks;
    this.gauge_range = isNil(model.gauge_range) ? 180 : model.gauge_range;
    this.hand_color = isNil(model.hand_color) ? Constant.GREY : model.hand_color;
    this.grain_density = isNil(model.grain_density) ? 0 : model.grain_density;
    this.gauge_gradient_to = isNil(model.gauge_gradient_to)
      ? Constant.GREY
      : model.gauge_gradient_to;
    this.background_color = isNil(model.background_color) ? Constant.GREY : model.background_color;
    this.gauge_hand_custom_color = model.gauge_hand_custom_color ?? false;
    this.show_shape_shadow = model.show_shape_shadow ?? false;
    this.gauge_gradient_target = isNil(model.gauge_gradient_target)
      ? GradientTargets.METRIC
      : model.gauge_gradient_target;
    this.gauge_big_number_position = isNil(model.gauge_big_number_position)
      ? GaugeBigNumberPosition.NONE
      : model.gauge_big_number_position;
  }
}
