import { XYChartConfig } from '@/modules/core/charts/am5/base/models/XYChartConfig';

/**
 * @class BarChartConfig
 * @extends XYChartConfig
 */
export class BarChartConfig extends XYChartConfig {
  /**
   * @var {Boolean}
   */
  showMetricLabels;

  /**
   * @var {Boolean}
   */
  isYAxisMoved;

  /**
   * @var {String}
   */
  hoverColor;

  /**
   * @var {Boolean}
   */
  hasTooltip;

  /**
   * @var {Boolean}
   */
  isStacked;

  /**
   * @var {Boolean}
   */
  isFullStacked;

  /**
   * @var {Boolean}
   */
  isLayered;

  /**
   * @var {Boolean}
   */
  isNormalizedData;

  /**
   * @var {Number}
   */
  roundedCorners;

  /**
   * @var {Boolean}
   */
  showColumnShadows;

  /**
   * @var {string}
   */
  fillType;

  /**
   * @var {string}
   */
  gradientColor;

  /**
   * @var {Boolean}
   */
  lollipopChart;

  /**
   * @var {Boolean}
   */
  isZeroScaled;

  /**
   * @var {Boolean}
   */
  isLogScaled;

  /**
   * @var {Boolean}
   */
  isCurvedColumns;

  /**
   * @var {Boolean}
   */
  isClustered;

  /**
   * @var {Boolean}
   */
  isRadialHistogram;

  /**
   * @var {Boolean}
   */
  isRadialBar;

  /**
   * @var {Number}
   */
  radialInnerRadius;

  constructor(model = {}) {
    super(model);
    this.showMetricLabels = model.showMetricLabels;
    this.isZeroScaled = model.isZeroScaled;
    this.isYAxisMoved = model.isYAxisMoved;
    this.hoverColor = model.hoverColor;
    this.hasTooltip = model.hasTooltip;
    this.isStacked = model.isStacked;
    this.isFullStacked = model.isFullStacked;
    this.isLayered = model.isLayered;
    this.isNormalizedData = model.isNormalizedData;
    this.roundedCorners = model.roundedCorners;
    this.showColumnShadows = model.showColumnShadows;
    this.fillType = model.fillType;
    this.gradientColor = model.gradientColor;
    this.lollipopChart = model.lollipopChart;
    this.isLogScaled = model.isLogScaled;
    this.isCurvedColumns = model.isCurvedColumns;
    this.isClustered = model.isClustered;
    this.isRadialHistogram = model.isRadialHistogram;
    this.radialInnerRadius = model.radialInnerRadius;
    this.isRadialBar = model.isRadialBar;
  }
}
