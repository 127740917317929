import { PieChart } from '@amcharts/amcharts5/percent';
import { percent } from '@amcharts/amcharts5';
import { usePieSeries } from '@/modules/core/charts/am5/pie/composables/usePieSeries';
import { omitNils } from '@/modules/core/app/utils/ObjectUtil';
import { PieChartConstant } from '@/modules/core/charts/am5/charts.constants';

export function usePieChart(context) {
  const { root, chart, config } = context();

  const { createAllSeries } = usePieSeries(context);
  function initPieChart() {
    createPieChart();
    createAllSeries();
    root.value.numberFormatter.setAll({
      numberFormat: '#,###.##',
    });
  }
  function createPieChart() {
    const pieRadius = config.value.showLabels ? setPieRadius() : { radius: percent(80) };
    chart.value = root.value.container.children.push(
      PieChart.new(root.value, {
        pinchZoomX: true,
        pinchZoomY: true,
        layout: root.value.verticalLayout,
        ...defaultInnerRadius(),
        startAngle: config.value.startAngle,
        endAngle: config.value.endAngle,
        ...pieRadius,
      })
    );
  }
  function defaultInnerRadius() {
    if (
      config.value.plotType === PieChartConstant.PLOT_DONUT &&
      config.value.innerRadius < PieChartConstant.DEFAULT_INNER_RADIUS
    ) {
      return omitNils({
        innerRadius: PieChartConstant.DEFAULT_INNER_RADIUS,
      });
    }
    if (config.value.plotType === PieChartConstant.PLOT_V2_PIE) {
      return omitNils({
        innerRadius: PieChartConstant.ZERO,
      });
    }
    return omitNils({
      innerRadius: config.value.innerRadius,
    });
  }

  /* Setting up radius of pie chart while we resize a pie chart widget */
  function setPieRadius() {
    const { width } = config.value.widgetData;
    const { pieSemiCircleAngle } = config.value;

    let radius = percent(config.value.radius);
    switch (true) {
      case width === 3:
        radius = percent(10);
        break;
      case width > 3 && width <= 5:
        radius = pieSemiCircleAngle ? percent(15) : percent(20);
        break;
      case width >= 6 && width <= 7:
        radius = pieSemiCircleAngle ? percent(28) : percent(40);
        break;
      case width >= 8 && width <= 10:
        radius = pieSemiCircleAngle ? percent(40) : percent(45);
        break;
      case width >= 11 && width <= 12:
        radius = pieSemiCircleAngle ? percent(40) : percent(60);
        break;
      default:
        break;
    }
    return omitNils({ radius });
  }

  return {
    chart,
    initPieChart,
  };
}
