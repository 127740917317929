'use strict';
import angular from 'angular';
import _ from 'lodash';
import $ from 'jquery';
import swal from 'bootstrap-sweetalert';

angular.module('core.category.ctrls', [])
    .controller('CategoryListController', CategoryListController)
    .controller('CategoryController', CategoryController)
    .controller('CategoryDataViewController', CategoryDataViewController)
    .controller('CategoryMetricController', CategoryMetricController);

/**
 * @ngInject
 */
function CategoryListController(
    $rootScope,
    $scope,
    AppFactory,
    // ui-router resolve data
    data,
    metadata
) {
    $scope.hasCategoryManagement = $rootScope.user.availableFeatures.contains('category_management');
    $scope.hasCategories = $rootScope.user.availableFeatures.contains('categories');
    $scope.dtOptions = {};
    $scope.dtOptions.cloneable = $scope.hasCategoryManagement;
    $scope.dtOptions.editable = $scope.hasCategoryManagement;
    $scope.dtOptions.data = data;
    $scope.dtOptions.columns = metadata.plain();
    $scope.dtOptions.entityName = 'categories';
    $scope.dtOptions.itemName = 'channel';
    $scope.dtOptions.iActions = 3;
    $scope.dtOptions.customRenders = {};
    $scope.dtOptions.customRenders['name'] = function (data, type, full) {
        var result =
            '<div class="service-square service-square-16" style="background-color:' + full.color + '"><div class="icon ' +
            (full.custom_icon ? full.custom_icon : 'serviceicon-default') + '"></div></div>' + data;
        if (full.has_invalid_content) {
            result +=
                '<span class="bad-category icon icon-exclamation-circle has-tooltip" title="'+
                'This channel contains at least one invalid metric or data view"></span>';
        }
        return result;
    };
    $scope.dtOptions.customRenders['service_ids'] = function (data) {
        var result = '';

        // copy the key inside each value
        _.each(data, function(service, service_id) {
            data[service_id].service_id =  service_id;
        });

        // order the data (the keys are lost)
        data = _.orderBy(data, ['is_connected'], ['desc']);

        // build the list of services
        _.each(data, function(service) {
            var className = '';
            var title = service.name;
            var color = service.custom_color || service.color;
            var service_id = service.service_id;
            if (service.is_connected) {
                result += '<a href="#/service/details/' + service_id + '" class="no-link-formatting">';
            }
            else {
                className = 'disconnected';
                title += ' (not connected)';
            }

            result +=
                '<div class="has-tooltip service-square service-square-32 '+className+'" title="'+title+'" style="background-color:'+color+'">' +
                '<div class="icon serviceicon-' + service.icon +'"></div>' +
                '</div>';

            if (service.is_connected) {
                result += '</a>';
            }
        });

        return result;
    };

    $scope.dtOptions.actionCallbacks = {};
    $scope.dtOptions.actionCallbacks.copy = copyActionCallback;
    $scope.dtOptions.actionCallbacks.delete = deleteActionCallback;

    $scope.message = 'This feature functionality is only available in Elite.';

    function copyActionCallback() {
        AppFactory.refreshCategories();
    }

    function deleteActionCallback() {
        AppFactory.refreshCategories();
    }
}

//
// Category controller
//
CategoryController.$inject = ['$rootScope', '$scope', '$http', '$state', 'category', 'metrics', 'metricMetadata', 'dataViews', 'dataViewMetadata', 'AppFactory', 'PubSub', '$timeout', '$q'];
function CategoryController($rs, $scope, $http, $state, category, metrics, metricMetadata, dataViews, dataViewMetadata, AppFactory, PubSub, $timeout, $q) {
    $scope.showCategory = true;
    $scope.dataViewSet = true;
    var model = $.extend({}, category != null ? category.plain() : {});
    var isNewModel = !Object.size(model);
    $scope.predefined = model.is_predefined;
    const url = getRoute();
    $scope.entity = {
        nameKey: 'name',
        idKey: 'id',
        pageTitle: '',
        redrawServiceNavOnSave: true,
        action: 'categories' + (isNewModel ? '' : '/' + model.id),
        redirection_on_new: `${url}/:id`,
        callback: 'onSave'
    };
    $scope.entity.pageTitle = isNewModel ? 'New Channel' : (model.is_predefined ? 'Predefined: ' + model[$scope.entity.nameKey]  : 'Edit ' + model[$scope.entity.nameKey]);
    $scope.isMetricModuleAvailable = $scope.util.isFeatureAvailable('category_management');
    $scope.dtOptions = genDataTableOptions();
    $scope.dvOptions = genDataViewDataTableOptions();
    $scope.message = 'Create a channel to unlock the channel field and data view features.';
    $scope.tooltip = {};
    $scope.tooltip.autoCreateMetrics = 'Automatically create channel fields for all fields that match between data sources';
    $scope.model = $.extend(model, genModel());
    $scope.prebuildModel = prebuildModel;
    $scope.state = $state;
    $scope.new_service_added = false;
    $scope.genStylesColorWhite = genStylesColorWhite($scope.model.color);
    AppFactory.setPageTitle($scope.entity.pageTitle);

    $scope.onBackPressed = function () {
        window.location.hash = '#/categories';
    };

    $scope.onSave = function (json, model) {
        PubSub.emit('SegmentEvents', { event: 'CategorySaveEvent',
            payload: { isCreated: isNewModel, id: isNewModel ? json.data.id : model.id }
        });
        $state.reload();
    }

    $q.resolve(AppFactory.asyncRefreshServices()).then(function() {
        $scope.serviceSelectOptions = {data: genServiceSelectData(AppFactory.getConnectedServices()), width: '100%', multiple: true, placeholder: 'Select Data Sources', includeServiceIcons: true};
    });
    var connectedServices = AppFactory.getConnectedServices();
    $scope.serviceSelectOptions = {data: genServiceSelectData(connectedServices), width: '100%', multiple: true, placeholder: 'Select Data Sources', includeServiceIcons: true};

    $scope.dvOptions.customRenders = {};
    $scope.dvOptions.customRenders['category_metrics'] = function(data, type, full){
        var links = '<ul>';
        links += '<li><div class="add-metric-height"><p><small>Add Field</small></p><a ' +
            'class="action no-link-formatting main-title-action has-tooltip"  href="#' + url + '/' +
            model.id + '/metrics/?dataview=' + full.id + '" title data-original-title="Add metric">' +
            '<span class="icon icomoon-add"></span></a></div></li>';
        links += _.map(data, function(metric){

            var html = '';
            if (metric.category_metric_id) {
                html = '<li>';

                var error_message;
                if (metric.empty) {
                    error_message = 'The channel field is empty';
                }
                else if (metric.invalid)
                {
                    error_message = metric.invalid;
                }
                else
                {
                    error_message = '';
                }

                if (error_message.length > 0) {
                    html +=
                        '<span class="bad-category-metric icon icon-exclamation-circle has-tooltip" title="'+ error_message +
                        '"></span>';
                }

                if (metric.can_be_edited) {
                    html +=
                        '<a class="no-link-formatting category-metrics" href="' + '#' + url + '/' + model.id +
                        '/metrics/' + metric.category_metric_id + '?dataview=' + full.id + '">' + metric.name + '</a>';
                }
                else {
                    html += metric.name;
                }
                let relationships = '';
                
                if (window.isNUI) {
                    let relationshipURL = '#/relationships/category_metrics/' + metric.category_metric_id + '?category=' + metric.category_id + '&dataview=' + full.id;
                    let [iconDisabled, relationshipTitle] = metric.can_be_deleted ? ['show-disabled', 'No Relationships'] : ['', 'View Relationships'];

                    relationships = '<a class="metric-row no-link-formatting has-tooltip category-metrics ' + iconDisabled + '" title="' + relationshipTitle + '" href="' + relationshipURL + '" target="_blank"><span class="icon icomoon-preview-file"></span></a>';
                }

                if (metric.can_be_deleted) {
                    html +=
                    relationships + '<a class="metric-row delete-action no-link-formatting has-tooltip category-metrics" data-url="categories/' + model.id + '/metrics/' + metric.category_metric_id + '" data-name="' + metric.name + '" data-is-angular="true" title="" data-original-title="Delete metric"><span class="icon icomoon-trash"></span></a>';

                }
                else {
                    html +=
                    relationships + '<a class="metric-row no-link-formatting has-tooltip category-metrics show-disabled" data-id="' + metric.category_metric_id + '" data-metric="' + metric.name + '" data-category-id="' + metric.category_id + '" title="'+metric.can_be_deleted_tooltip+'"><span class="icon icomoon-trash"></span></a>';
                }

                html += '</li>';
            }

            return html;
        }).join('');
        links += '</ul>';
        return links
    };

    /**
     * Color picker callback
     * @param color
     */
    $scope.onColorChange = function (color) {
        $scope.model.color = color;
        genStylesColorWhite(color);
    };

    $scope.dvOptions.customRenders['name'] = function(data, type, full){
        var warning_message;
        if (full.invalid || full.empty) {
            warning_message =
                '<span class="bad-category-dataview icon icon-exclamation-circle has-tooltip" title="'+
                'This data view is empty or has at least one invalid service data view"></span>';
        }
        else {
            warning_message = '';
        }
        return warning_message + data;
    };

    $scope.dvOptions.fnCreatedRow = function(row, raw, id){
        
        $(row).find('a.delete-modal').click(function() {
            $scope.showCategoryInUseModal($(this));
        });

        $(row).find('a.delete-action').click(function() {
            var entityName = $(this).data('name');
            var item = $(this).closest('li').length ? $(this).closest('li') : $(this).closest('tr');
            var deleteUrl = $rs.util.apiUrl($(this).data('url'));
            swal({
                    title: 'Are you sure?',
                    text: 'You will delete <b>' + entityName + '</b>',
                    html: true,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, delete it',
                    cancelButtonText: 'No',
                    closeOnConfirm: false,
                    closeOnCancel: true,
                    showLoaderOnConfirm: true
                },
                function(isConfirm) {
                    if (isConfirm) {
                        return $http.delete(deleteUrl).success(function (json) {
                            if (window.isNUI) {
                                PubSub.emit('SegmentEvents', { event: 'CategorySaveEvent',
                                    payload: { isCreated: isNewModel, id: isNewModel ? json.data.id : model.id }
                                });
                            }
                            swal('Deleted!', entityName + ' has been deleted', 'success');
                            item.remove();
                            $state.reload();
                        });
                    }
                });
        });

        $(row).find("a.copy-action").click(function() {
            var entityName = raw.name;
            var item = $(this).closest("li");
            var copyUrl = $rs.util.apiUrl('categories/' + raw.category_id + '/dataviews/copy/' + raw.id);
            swal({
                    title: 'Copy:',
                    text: 'This will copy <b>' + entityName + '</b>',
                    html: true,
                    type: 'info',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, copy.',
                    cancelButtonText: 'No',
                    closeOnConfirm: false,
                    closeOnCancel: true,
                    showLoaderOnConfirm: true
                },
                function(isConfirm) {
                    if (isConfirm) {
                        return $http.get(copyUrl).success(function (json) {
                            if (window.isNUI) {
                                PubSub.emit('SegmentEvents', { event: 'CategorySaveEvent',
                                    payload: { isCreated: isNewModel, id: isNewModel ? json.data.id : model.id }
                                });
                            }
                            swal('Copied!', entityName + ' has been copied.', 'success');
                            $state.go('categories.dataviews', {categoryId: raw.category_id, dataviewId: json.data})
                        });
                    }
                });
        });
    }

    $scope.showCategoryInUseModal = function(element) {
        let metric_id = $(element).data('id');
        let metric_name = $(element).data('metric');
        let category_id = $(element).data('category-id');

        $('#metric-metricinuse-modal .modal-title .metricinuse-title').text(metric_name);
        $('#metric-metricinuse-modal .modal-body .metricinuse-content').html('<div class="loading-wheel"></div>');
        $('#metric-metricinuse-modal').modal('show');


        let requestMetricUsage = $http.get('/server/api/categories/' + category_id + '/metrics/usage/' + metric_id);
        let requestCategoryUsage = $http.get('/server/api/categories/usage/' + category_id + '?all=true');
        
        $q.all([requestMetricUsage, requestCategoryUsage])
            .then(function successCallback(results) {

                let categoryMetricUsageData = results[0].data.data;
                let categoryUsageData = results[1].data.data;

                let widgetIds = []
                angular.forEach(categoryMetricUsageData.id.design.data, function (widget) {
                    widgetIds.push(widget.id);
                });

                let calculationIds = []
                angular.forEach(categoryMetricUsageData.id.calculation.data, function (calculation) {
                    calculationIds.push(calculation.id);
                });
                
                let dataExporterIds = []
                angular.forEach(categoryMetricUsageData.id.dataexporter.data, function (dataExporter) {
                    dataExporterIds.push(dataExporter.id);
                });
                if (widgetIds.length > 0 || calculationIds.length > 0  || dataExporterIds.length > 0) {
                    var widgets = { ...categoryUsageData.widget.data, ...categoryUsageData.reportstudio.data },
                        widgets_total = categoryMetricUsageData.id.design.metadata?.total,
                        calculations = categoryUsageData.calculation.data,
                        calculations_total = categoryMetricUsageData.id.calculation.metadata?.total,                        
                        dataExporters = categoryUsageData.dataexporter.data,
                        dataExporters_total = categoryMetricUsageData.id.dataexporter.metadata?.total,
                        diff_total = 0,
                        htmlContent = '';

                    // Widgets :            
                    var filteredWidgets = {};
                    for (var key in widgets) {
                        for (var key in widgets) {
                            if (widgetIds.includes(key) && Object.keys(filteredWidgets).length < 3) {
                                filteredWidgets[key] = widgets[key];
                            }
                        }
                    }
                    if (filteredWidgets && Object.keys(filteredWidgets).length !== 0) {
                        htmlContent += '<h6 class="mt10">';
                        if (Object.keys(filteredWidgets).length > 1) {
                            htmlContent += 'Widgets';
                        }
                        else {
                            htmlContent += 'Widget';
                        }
                        htmlContent += ' that use this metric:</h6><ul class="mt5 mb5">';

                        angular.forEach(filteredWidgets, function (widget) {
                            if (widget.url) {
                                htmlContent += '<li class="mb5"><a href="'+widget.url+'" target="_blank">' + widget.name + '</a></li>';
                            }
                            else {
                                htmlContent += '<li class="mb5">' + widget.title + ' (this widget has been removed from the dashboard, please remove it from the Library)</li>';
                            }
                        });
                        if (widgets_total > 3) {
                            diff_total = widgets_total - 3;
                            htmlContent += '<li class="total-diff mb5">And '+ diff_total + ' more...</li>';
                        }
                        htmlContent += '</ul>';
                    }

                    // Calculations :
                    var filteredCalculations = {};
                    for (var key in calculations) {
                        if (calculationIds.includes(key) && Object.keys(filteredCalculations).length < 3) {
                            filteredCalculations[key] = calculations[key];
                        }
                    }
                    if (filteredCalculations && Object.keys(filteredCalculations).length !== 0) {
                        htmlContent += '<h6 class="mt10">';
                        if (Object.keys(calculations).length > 1) {
                            htmlContent += 'Calculations';
                        }
                        else {
                            htmlContent += 'Calculation';
                        }
                        htmlContent += ' that use this metric:</h6><ul class="mt5 mb5">';

                        angular.forEach(filteredCalculations, function (calculation) {                        
                            htmlContent += '<li class="mb5"><a href="#/calculations/' + calculation.id + '" target="_blank">' + calculation.name +'</a></li>';
                        });
                        if (calculations_total > 3) {
                            diff_total = calculations_total - 3;
                            htmlContent += '<li class="total-diff mb5">And '+ diff_total + ' more...</li>';
                        }
                        htmlContent += '</ul>';
                    }

                    // Data Exporters :
                    var filteredDataExporters = {};
                    for (var key in dataExporters) {
                        if (dataExporterIds.includes(key) && Object.keys(filteredDataExporters).length < 3) {
                            filteredDataExporters[key] = dataExporters[key];
                        }
                    }
                    if (filteredDataExporters && Object.keys(filteredDataExporters).length !== 0) {
                        htmlContent += '<h6 class="mt10">';
                        if (Object.keys(dataExporters).length > 1) {
                            htmlContent += 'Data Exporters';
                        }
                        else {
                            htmlContent += 'Data Exporter';
                        }
                        htmlContent += ' that use this metric:</h6><ul class="mt5 mb5">';

                        angular.forEach(filteredDataExporters, function (dataExporter) {                        
                            htmlContent += '<li class="mb5"><a href="#/dataexporters/' + dataExporter.id + '" target="_blank">' + dataExporter.name +'</a></li>';
                        });
                        if (dataExporters_total > 3) {
                            diff_total = dataExporters_total - 3;
                            htmlContent += '<li class="total-diff mb5">And '+ diff_total + ' more...</li>';
                        }
                        htmlContent += '</ul>';
                    }
                    if (htmlContent == '') {
                        htmlContent = '<p>Please contact your account manager if you can\'t remove this metric (msg: 1)</p>';
                    };
                    $('#metric-metricinuse-modal .modal-body .metricinuse-content').html(htmlContent);

                }
            })
            .catch(function(error) {                
                console.error('Error in one of the requests:', error); // keep error in logs
                $('#metric-metricinuse-modal .modal-body .metricinuse-content').html('<p>Please contact your account manager if you can\'t remove this metric (msg: 2)</p>');
            });
    }

    function genStylesColorWhite(color) {
      if (color === "#ffffff") {
        $scope.dashedBorder = "3px #000 dashed";
        $scope.textColor = "#000 !important";
        $scope.border = "solid 1px";
      } else {
        $scope.dashedBorder = "";
        $scope.textColor = "";
        $scope.border = "";
      }
    }

    function genDataViewDataTableOptions() {
        return {
            categoryId: $state.params.id ? $state.params.id : '',
            data: dataViews.plain(),
            columns: dataViewMetadata.plain(),
            entityName: 'categories',
            entityIdName:'category_id',
            childEntityName: 'dataviews',
            editable: !$scope.predefined,
            iActions: 3,
            isParentChildEntity: true,
            itemName: 'data view'
        }
    }

    /**
     * Returns the route URL based on NUI or legacy
     * @returns {string}
     */
    function getRoute() {
        return window.isNUI ? '/categories' : '/categories/detail';
    }

    function genDataTableOptions() {
        return {
            categoryId: $state.params.id ? $state.params.id : '',
            data: metrics,
            columns: metricMetadata.plain(),
            entityName: 'categories',
            entityIdName:'category_id',
            childEntityName: 'metrics',
            editable: !$scope.predefined,
            iActions: 1,
            isParentChildEntity: true,
            itemName: 'metric'
        };
    }

    function genServices(model) {
        var services = [];
        angular.forEach(model.service_ids, function(service, key) {
            // used in date boundaries
            services.push({id: key, text: service.name, icon: service.icon, color: service.color});
        });
        services.sort((serviceA, serviceB) => serviceA.text.localeCompare(serviceB.text));
        return services;
    }

    //Generates the model that is used to save the form fields
    function genModel() {
        $scope.original_services = genServices(model);
        return {
            color: isNewModel ? '#3498db' : model.color,
            id: isNewModel ? null : model.id,
            name: isNewModel ? '' : model.name,
            services: $scope.original_services,
            auto_create_metrics: true
        };
    }

    function genServiceSelectData(services) {
        return $.map(services, function(service) {
            // used in the multi slect drop down
            return {id: service.id, text: service.name, icon: service.has_custom_icon ? service.icon : "serviceicon-" + service.icon, color: service.color};
        });
    }

    function prebuildModel(model) {
        var serviceArray = [];
        angular.forEach(model.services, function(service){
            serviceArray.push('service_' + service.id);
        });
        model.service_ids = serviceArray.join(',');
    }

    $scope.$watch('model.services', function(nV, oV) {
        if(model.id && nV) {
            if (_.isEqual(nV, $scope.original_services)) {
                $scope.new_service_added = false;
            } else if (nV.length > oV.length) {
                $scope.new_service_added = true;
            }

            $scope.boundariesTableOptions = genBoundariesTableOptions();
            updateBoundariesModel();
            setDatePickerOptions();
        }
    });

    // Table data
    $scope.boundariesTableOptions = genBoundariesTableOptions();

    // Create or update table data
    function genBoundariesTableOptions() {
        const newboundaries = [];
        angular.forEach($scope.model.services, function (service, index) {
            const boundaryWithDate = $scope.model.boundaries !== null ? $scope.model.boundaries.find((obj) => obj.service_id == service.id) : null;
            let endingDate = "";
            let startingDate = "";

            if (boundaryWithDate?.end_date) {
                endingDate = isValidDateFormat(boundaryWithDate.end_date) ? boundaryWithDate.end_date : formatDateFromEpoch(boundaryWithDate.end_date);
            }

            if (boundaryWithDate?.start_date) {
                startingDate = isValidDateFormat(boundaryWithDate.start_date) ? boundaryWithDate.start_date : formatDateFromEpoch(boundaryWithDate.start_date);
            }

            let serviceIcon = "serviceicon-default";
            if (service.icon) {
                if (service.icon.includes("-") || service.icon.includes("custom")) {
                    serviceIcon = service.icon;
                } else {
                    serviceIcon = "serviceicon-" + service.icon;
                }
            }

            newboundaries.push({
                end_date: endingDate,
                service_id: service.id,
                service: {
                    color: service.color,
                    icon: serviceIcon,
                    text: service.text,
                    oddOrEven: index % 2 === 0 ? "even" : "odd",
                },
                start_date: startingDate,
            });
        });
        return newboundaries;
    }

    // Update the model from table data
    function updateBoundariesModel() {
        $timeout(function () {
            $scope.model.boundaries = $scope.boundariesTableOptions
                // remove service info
                .map(({ service, ...rest }) => ({ ...rest,
                    //replace empty dates with null and convert to epoch format if needed
                    start_date: rest.start_date ? (isValidDateFormat(rest.start_date) ? formatDateInUTC(rest.start_date) : rest.start_date) : null,
                    end_date: rest.end_date ? (isValidDateFormat(rest.end_date) ? formatDateInUTC(rest.end_date) : rest.end_date) : null
                }))
                //filter out rows where both dates are null
                .filter((obj) => obj.start_date !== null || obj.end_date !== null);
            //if object is empty insert null in db
            $scope.model.boundaries = $scope.model.boundaries.length === 0 ? null : $scope.model.boundaries;
        });
    }

    // Update one date in boundariesTableOptions
    function setBoundaryDate(row) {
        const selectedDate = $(row).val();
        const newEpochDate = isValidDateFormat(selectedDate) ? formatDateInUTC(selectedDate): null;
        const dateAttribute = $(row).prop("name");
        const serviceId = $(row).attr("service_id");

        angular.forEach($scope.boundariesTableOptions, function (option) {
            if (option.service_id === serviceId) {
                option[dateAttribute] = newEpochDate;
            }
        });

        updateBoundariesModel();
    }

    function formatDateFromEpoch(epochTime) {
        // Convert epoch time to milliseconds
        const date = new Date(epochTime * 1000);
        //we only support en-US format for now
        return date.toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
            timeZone: "UTC"
        });
    }

    // make sure to return UTC 
    function formatDateInUTC(dateString) {
        // do this the long way to preserve UTC from any local timezone. 
        const dateParts = dateString.split(" ");
        const month = getMonthNumber(dateParts[0]);
        const day = parseInt(dateParts[1].replace(",", ""));
        const year = parseInt(dateParts[2]);

        return Date.UTC(year, month, day) / 1000;
    }

    function getMonthNumber(month) {
        //we only support en-US format for now
        const monthNames = [
          "Jan", "Feb", "Mar", "Apr", "May", "Jun",
          "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        return monthNames.indexOf(month);
      }

    function isValidDateFormat(dateStr) {
        const dateFormatRegex = /^[A-Za-z]{3}\s\d{1,2},\s\d{4}$/; // match our Date format
        return dateFormatRegex.test(dateStr);
    }

    // On page load set table collapsed state
    $scope.folded = $scope.model.boundaries === null;
    $timeout(function () {
        if ($scope.folded) {
            $(".main-content").find("i.iconfold").removeClass("icon-chevron-down").addClass("icon-chevron-up");
        } else {
            $(".main-content").find("i.iconfold").removeClass("icon-chevron-up").addClass("icon-chevron-down");
        }
    });

    // Toggle boundaries table collapsed state
    $scope.toggleFold = function () {
        if ($scope.folded) {
            $(".main-content").find("i.iconfold").removeClass("icon-chevron-up").addClass("icon-chevron-down");
        } else {
            $(".main-content").find("i.iconfold").removeClass("icon-chevron-down").addClass("icon-chevron-up");
        }
        $scope.folded = !$scope.folded;
    };

    function setDatePickerOptions() {
        $timeout(function () {
            const datePickerOptions = {};
            datePickerOptions.dateFormat = "M d, yy";
            datePickerOptions.onSelect = function () {
                setBoundaryDate(this); // modify the model manually
            };

            $(".start_date").each(function () {
                $.core.main.initDatePicker($(this), datePickerOptions);
            });

            $(".end_date").each(function () {
                $.core.main.initDatePicker($(this), datePickerOptions);
            });

            $(".hasDatepicker").on("keydown", function (e) {
                e.preventDefault();
            });

            $(".hasDatepicker").on("keyup", function (e) {
                if (e.key === "Backspace" || e.key === "Delete") {
                    $(this).datepicker("setDate", "");
                    setBoundaryDate(this); // modify the model manually
                }
            });

            $(".hasDatepicker").on("change", function () {
                if (isValidDateFormat($(this).val())) {
                    setBoundaryDate(this); // modify the model manually
                }
            });
        });
    }

    setDatePickerOptions();

}


//
// Category data view controller
//
CategoryDataViewController.$inject = ['$scope', '$state', 'category', 'categoryDataView', 'categoryDataViews', 'AppFactory', 'PubSub'];
function CategoryDataViewController($scope, $state, category, categoryDataView, categoryDataViews, AppFactory, PubSub) {
    $scope.categoryDataViews = genServices(categoryDataViews.plain());
    var categoryDataView = categoryDataView ? categoryDataView.plain() : null;
    var isNewModel = categoryDataView ? 0 : 1;
    $scope.model = categoryDataView ? categoryDataView : genModel();

    // if services is an empty list, it's seen as an array, and needs to converted as pure object, else the addDataView function
    // would not behave as intended
    if (_.isEmpty($scope.model.services)) {
        $scope.model.services = {};
    }
    var removedObjects = [];
    $scope.entity = {
        nameKey: 'name',
        idKey: 'id',
        pageTitle: '',
        parentTitle: '',
        action: $state.params.dataviewId ?
            'categories/' + $state.params.categoryId + '/dataviews/' + $state.params.dataviewId :
            'categories/' + $state.params.categoryId + '/dataviews',
        callback: 'onSave'
    };
    $scope.entity.parentTitle = category.name;
    $scope.entity.pageTitle = isNewModel ? category.name + ': New Data View' : category.name + ': ' + 'Edit ' + $scope.model[$scope.entity.nameKey];
    $scope.categoryServices = category.service_ids;
    $scope.serviceSelectOptions = {data: [{}], disabled: true, width: '50%', multiple: true, placeholder: 'Select Data Sources'};
    $scope.state = $state;
    $scope.values = genValues(category);

    AppFactory.setPageTitle($scope.entity.pageTitle);

    $scope.addDataView = function (serviceId, selectedDataView) {
        if(selectedDataView && !$scope.hasDataView(serviceId, selectedDataView)) {
            var serviceData = {service_id: serviceId, dataview: selectedDataView.dataview, dataview_name: selectedDataView.text};
            if ($scope.model.services[serviceId]) {
                $scope.model.services[serviceId].push(serviceData);
            } else {
                $scope.model.services[serviceId] = [serviceData];
            }
        }
    }

    $scope.onSave = function (json, model) {
        if (window.isNUI && !json.error) {
            PubSub.emit('SegmentEvents', { event: 'CategorySaveEvent', payload: { id: model.id } });
        }
    }

    $scope.deleteDataView = function (serviceId, dataView) {
        _.remove($scope.model.services[serviceId], function(serviceDataView) {
            return serviceDataView.dataview === dataView;
        });

        if(!$scope.model.services[serviceId].length) {
            $scope.model.services[serviceId] = null;
        }
    }

    $scope.hasDataView = function(serviceId, dataView) {
        return _.find($scope.model.services[serviceId], function(serviceDataView) {
            return dataView === undefined || serviceDataView.dataview === dataView;
        }) !== undefined;
    }

    function genModel() {
        return {
            id: null,
            category_id: category.id,
            name: '',
            services: {}
        };
    }

    function genValues(data) {
        var services = [];
        angular.forEach(data.service_ids, function(service, key) {
            services.push({id: key, text: service.name});
        });
        return services;
    }

    function genServices(data) {
        var services = {};
        angular.forEach(data, function(service) {
            services[service.service_id] = _.map(service.dataviews, function(dataview, key){
                return {id: key, text: dataview.name, dataview_name: dataview.name, dataview: dataview.extended_id};
            });
        });
        return services;
    }

}


//
// Category metric controller
//
CategoryMetricController.$inject = ['$scope', '$stateParams', '$state', 'category', 'categoryDataView', 'metric', 'metricColumns', 'attributeColumns', 'dataTypes', 'AppFactory', 'PubSub'];
function CategoryMetricController($scope, $stateParams, $state, category, categoryDataView, metric, metricColumns, attributeColumns, dataTypes, AppFactory, PubSub) {
    var model = $.extend({}, metric ? metric.plain() : {});
    var isNewModel = !Object.size(model);
    var removedObjects = [];
    $scope.entity = {
        nameKey: 'name',
        idKey: 'category_metric_id',
        pageTitle: '',
        parentTitle: '',
        action: $state.params.metricId ?
            'categories/' + $state.params.categoryId + '/metrics/' + $state.params.metricId :
            'categories/' + $state.params.categoryId + '/metrics',
        callback: 'onSave'
    };
    $scope.entity.parentTitle = category.name;
    $scope.entity.pageTitle = isNewModel ? category.name + ': New Field' : category.name + ': ' + 'Edit ' + model[$scope.entity.nameKey];
    $scope.model = genModel();
    $scope.addActiveMetric = addActiveMetric;
    $scope.attributeColumns = filterIntoRemovedObjects(attributeColumns.plain());
    $scope.categoryMetric = metric ? metric.plain() : {};
    $scope.categoryServices = categoryDataView.services;
    $scope.dataTypes = dataTypes;
    $scope.existsInActiveMetrics = existsInActiveMetrics;
    $scope.metricColumns = filterIntoRemovedObjects(metricColumns.plain());
    $scope.removeActiveMetric = removeActiveMetric;
    $scope.serviceDataViewHasColumns = serviceDataViewHasColumns;
    $scope.serviceSelectOptions = {data: [{}], disabled: true, width: '50%', multiple: true, placeholder: 'Select Data Sources'};
    $scope.state = $state;
    $scope.numericTypes = null;
    $scope.isNumericType = isNumericType;

    AppFactory.setPageTitle($scope.entity.pageTitle);

    // copy service names
    _.forEach($scope.categoryServices, function(data_views, service_id) {
        if (category.service_ids[service_id] && $scope.categoryServices[service_id]) {
            _.forEach(data_views, function(data_view) {
                data_view.service_name = category.service_ids[service_id].name;
            });
        }
    });

    $scope.onSave = function (json, model) {
        if (window.isNUI && !json.error) {
            PubSub.emit('SegmentEvents', { event: 'CategorySaveEvent', payload: { id: model.id } });
        }
    }

    //Handles the resetting of the metrics model when user changes back and forth from text data_type
    $scope.$watch('model.data_type', function(nV, oV){
        if(nV !== oV) {
            if($scope.isNumericType(nV) !== $scope.isNumericType(oV)) {
                $scope.model.metrics = [];
            }
        }
    });

    //Handles adding available metric columns to the active column
    //used on template with ng-click
    //Should return either an updated model.metric array or the same array if the metric already exists
    function addActiveMetric(serviceId, dataView, selectedColumn, metrics) {
        if (selectedColumn) {
            const columnType = !$scope.isNumericType($scope.model.data_type) ? 'attributeColumns' : 'metricColumns';
            const index = $scope[columnType][serviceId][dataView].indexOf(selectedColumn);
            $scope[columnType][serviceId][dataView][index].disabled = true;

            selectedColumn.metricIndex = index > -1 ? index : $scope.attributeColumns[serviceId].indexOf(selectedColumn);
            removedObjects.push(selectedColumn);
            $scope[columnType][serviceId][dataView].splice($scope[columnType][serviceId][dataView].indexOf(selectedColumn),1);

            if(selectedColumn && !existsInActiveMetrics(serviceId, dataView, selectedColumn)) {
                metrics.push({service_id: serviceId, data_view: dataView, metric_name: selectedColumn.label, metric_value: selectedColumn.field});
            }
        }
        return metrics;
    }

    //Checks the category Metric services for column existence
    function existsInActiveMetrics(serviceId, dataView, selectedColumn){
        let exists = false;
        _.forEach($scope.model.metrics, function(column){
            if(column.service_id === serviceId
                && column.data_view === dataView
                && column.metric_value === selectedColumn.field) {
                exists = true;
            }
        });
        return exists;
    }

    function filterIntoRemovedObjects(columnObjects){
        _.forEach($scope.model.metrics, function(metric) {
            _.forEach(columnObjects, function(dataViews, service_id) {
                if(metric.service_id === service_id) {
                    _.forEach(dataViews, function(columns, dataView) {
                        for(let i = 0; i < columns.length; i++) {
                            if(metric.data_view === dataView && metric.metric_value === columns[i].field) {
                                columns[i].disabled = true;
                                columns[i].metricIndex = i;
                                removedObjects.push(columns[i]);
                            }
                        }
                    });
                }
            });
        });

        return _.mapValues(columnObjects, function(dataViews) { //remove active from available
            return _.mapValues(dataViews, function(column) {
                return _.reject(column, 'disabled');
            });
        });
    }

    //Generates the model that is used to save the form fields
    function genModel() {
        let _metrics = [];
        if (!isNewModel) {
            angular.forEach(model.metrics, function(metric) {
                _metrics.push({service_id: metric.service_id, data_view: metric.data_view, metric_name: metric.metric_name, metric_value: metric.metric_value, invalid: metric.invalid});
            });
        }
        return {
            category_metric_id: isNewModel ? null : model.category_metric_id,
            category_id: category.id,
            name: isNewModel ? '' : model.name,
            dataview_id: $stateParams.dataview ? $stateParams.dataview : null,
            data_type: isNewModel ? null : model.data_type.key,
            metrics: _metrics
        };

    }

    //Handles the removal of active Metric columns
    function removeActiveMetric(serviceId, dataView, removedColumn, metrics) {
        const columnType = !$scope.isNumericType($scope.model.data_type) ? 'attributeColumns' : 'metricColumns';

        const removedObject = _.first(_.remove(removedObjects, {'field': removedColumn.metric_value}));

        if (!_.isEmpty(removedObject)) {
            const existingColumn = _.find($scope[columnType][serviceId][dataView], {'field': removedObject.field});

            if (!_.isEmpty(existingColumn)) {
                existingColumn.disabled = false;
            } else {
                removedObject.disabled = false;
                $scope[columnType][serviceId][dataView].push(removedObject);
                $scope[columnType][serviceId][dataView].sort(function(a,b) {return a.label.localeCompare(b.label)});
            }
        }

        _.remove(metrics, function(column) {
            return column.metric_value === removedColumn.metric_value && dataView === column.data_view && serviceId === column.service_id
        });

        return metrics;
    }

    //Checks for the existence of the column in the services
    function serviceDataViewHasColumns(serviceId, dataView) {
        let hasColumns = false;
        angular.forEach($scope.model.metrics, function(column) {
            if(column.service_id === serviceId && column.data_view === dataView) {
                hasColumns = true;
            }
        });
        return hasColumns;
    }

    //Tells if a type is a numeric one or not
    function isNumericType(type) {
        if ($scope.numericTypes === null) {
            $scope.numericTypes = AppFactory.arrayToMemoizedObj($scope.dataTypes.filter(function(item) {
                return item.is_numeric;
            }), 'key');
        }
        return $scope.numericTypes.hasOwnProperty(type);
    }
}
