import { Bullet, color, Label } from '@amcharts/amcharts5';
import { getChartConfig } from '@/modules/core/charts/am5/bar/config/chartconfig';
import { Constant, SeriesType } from '@/modules/core/charts/am5/charts.constants';
import { useXYAxes } from '@/modules/core/charts/am5/base/composables/axis/useXYAxes';
import { useNumberFormatter } from '@/modules/core/charts/am5/gauge/composables/useNumberFormatter';
import { XYSeries } from '@amcharts/amcharts5/xy';

export function useMetricLabels(context) {
  const { root, chart, config } = context();
  const { getXAxis } = useXYAxes(context);
  const { formatNumber } = useNumberFormatter(context);

  function createMetricLabels(createBenchmarkLabel = false) {
    chart.value.series.each((series) => {
      const { seriesType } = series.get(Constant.USER_DATA);
      const { isRotated, isStacked, isFullStacked, isRadialHistogram, isRadialBar } = config.value;
      const userData = series.get(Constant.USER_DATA);

      // ensure we're only adding the metric value bullet once - if it's coming in from
      // the benchmark it won't be a full XYSeries
      const { lineType } = userData;
      if (userData.constructor.name === XYSeries.name && createBenchmarkLabel) {
        return;
      }

      if (seriesType === SeriesType.COLUMN || isRadialHistogram || isRadialBar) {
        series.bullets.push(() => {
          const { labelProps, bulletProps } = getChartConfig({
            isRotated,
            isStacked,
            isFullStacked,
            isRadialHistogram,
            isRadialBar,
          });

          const graphics = Label.new(root.value, {
            ...labelProps,
            populateText: true,
            fill: color(lineType === Constant.BENCHMARK ? userData.labelFill : 0x000000),
          });

          if (config.value.isRadialHistogram) {
            const xAxis = getXAxis();
            graphics.adapters.add(Constant.ROTATION, (rotation, target) => {
              const angle = xAxis
                .get(Constant.RENDERER)
                .positionToAngle(
                  series.dataItems.indexOf(target.dataItem) / xAxis.dataItems.length
                );
              if (angle) {
                if (xAxis.dataItems.length <= 6) {
                  return angle + 130;
                }
                if (xAxis.dataItems.length <= 20) {
                  return angle + 110;
                }
                return angle + 90;
              }
              return rotation;
            });
          }

          const labelBullet = Bullet.new(root.value, {
            ...bulletProps,
            sprite: graphics,
          });

          labelBullet.get(Constant.SPRITE).text.adapters.add(Constant.TEXT, (text, target) => {
            const { dataItem } = target;
            const field = dataItem.component.get(
              isRotated || isRadialBar ? Constant.VALUE_X_FIELD : Constant.VALUE_Y_FIELD
            );
            const value = dataItem.dataContext[field];
            const seriesUserData = dataItem.component.get(Constant.USER_DATA);

            return formatNumber(value, seriesUserData.dataItemFormat);
          });
          return labelBullet;
        });
      }
    });
  }

  return {
    createMetricLabels,
  };
}
