'use strict';
import angular from 'angular';

import panelSettingsReportHtmlUrl from './panel.settings.report.html';
import panelSettingsPageHtmlUrl from './panel.settings.page.html';
import panelSettingsBaseHtmlUrl from './panel.settings.base.html';
import panelSettingsWidgetHtmlUrl from './panel.settings.widget.html';
import panelSettingsImageHtmlUrl from './panel.settings.image.html';
import panelSettingsShapeHtmlUrl from './panel.settings.shape.html';
import panelSettingsIconHtmlUrl from './panel.settings.icon.html';
import panelSettingsTextHtmlUrl from './panel.settings.text.html'
import panelSettingsTableHtmlUrl from './panel.settings.table.html';
import { CurrencyConstants } from 'coreModules/shared/scripts/app.constants';
import $ from 'jquery';

angular.module('exportbuilder.panel.components')
    .component('exportBuilderReportSettings', {
        bindings: {
            element: '<'
        },
        templateUrl: panelSettingsReportHtmlUrl,
        controller: ExportBuilderReportSettingsController,
        controllerAs: 'vm'
    })
    .component('exportBuilderPageSettings', {
        bindings: {
            page: '<'
        },
        templateUrl: panelSettingsPageHtmlUrl,
        controller: ExportBuilderReportPageSettingsController,
        controllerAs: 'vm'
    })
    .component('exportBuilderBaseElementSettings', {
        bindings: {
            element: '<'
        },
        templateUrl: panelSettingsBaseHtmlUrl,
        controller: ExportBuilderBaseElementSettingsController,
        controllerAs: 'vm'
    })
    .component('exportBuilderWidgetElementSettings', {
        bindings: {
            element: '<'
        },
        templateUrl: panelSettingsWidgetHtmlUrl,
        controller: ExportBuilderWidgetElementSettingsController,
        controllerAs: 'vm'
    })
    .component('exportBuilderImageElementSettings', {
        bindings: {
            element: '<'
        },
        templateUrl: panelSettingsImageHtmlUrl,
        controller: ExportBuilderImageElementSettingsController,
        controllerAs: 'vm'
    })
    .component('exportBuilderShapeElementSettings', {
        bindings: {
            element: '<'
        },
        templateUrl: panelSettingsShapeHtmlUrl,
        controller: ExportBuilderShapeElementSettingsController,
        controllerAs: 'vm'
    })
    .component('exportBuilderIconElementSettings', {
        bindings: {
            element: '<'
        },
        templateUrl: panelSettingsIconHtmlUrl,
        controller: ExportBuilderIconElementSettingsController,
        controllerAs: 'vm'
    })
    .component('exportBuilderTextElementSettings', {
        bindings: {
            element: '<'
        },
        templateUrl: panelSettingsTextHtmlUrl,
        controller: ExportBuilderTextElementSettingsController,
        controllerAs: 'vm'
    })
    .component('exportBuilderTableElementSettings', {
        bindings: {
            element: '<'
        },
        templateUrl: panelSettingsTableHtmlUrl,
        controller: ExportBuilderTableElementSettingsController,
        controllerAs: 'vm'
    });

/**
 * @ngInject
 */
function ExportBuilderReportSettingsController(
    ExportBuilderDashboardService
) {
    var vm = this;
    vm.$onInit = $onInit;

    function $onInit() {
        vm.report = ExportBuilderDashboardService.getReport();
    }
}

/**
 * @ngInject
 */
function ExportBuilderReportPageSettingsController(
    ExportBuilderElementActionService,
    ExportBuilderDashboardService
) {
    var vm = this;
    vm.onPageTitleChange = onPageTitleChange;
    vm.setEnableExecutiveSummary = setEnableExecutiveSummary;

    function onPageTitleChange() {
        ExportBuilderElementActionService.setPageTitle(ExportBuilderDashboardService.getBuilder().currentPage, vm.page.title);
    }

    function setEnableExecutiveSummary() {
        vm.page.executive_summary_enabled
            ? ExportBuilderElementActionService.setEnableExecutiveSummary(vm.page)
            : ExportBuilderElementActionService.unsetEnableExecutiveSummary(vm.page);
    }
}

/**
 * @ngInject
 */
function ExportBuilderBaseElementSettingsController(
    ExportBuilderElementActionService
) {
    var vm = this;
    vm.onShowEveryPageChange = onShowEveryPageChange;
    vm.onSnapToGridChange = onSnapToGridChange;
    vm.onSetBackgroundChange = onSetBackgroundChange;

    function onShowEveryPageChange() {
        ExportBuilderElementActionService.makeItemOnEveryPage(vm.element.show_on_every_page);
    }

    function onSnapToGridChange() {
        ExportBuilderElementActionService.setSnapToGrid(vm.element.snap_to_grid);
    }

    function onSetBackgroundChange() {
        ExportBuilderElementActionService.setToBackground(vm.element.metadata.set_as_background);
    }
}

/**
 * @ngInject
 */
function ExportBuilderWidgetElementSettingsController(
    ExportBuilderDashboardItemService
) {
    var vm = this;
    vm.toggleDrawOption = toggleDrawOption;

    function toggleDrawOption(drawOptionKey) {
        ExportBuilderDashboardItemService.toggleWidgetDrawOption(vm.element, drawOptionKey, vm.element.widget.metadata.draw_options[drawOptionKey].value);
    }
}

/**
 * @ngInject
 */
function ExportBuilderImageElementSettingsController(
    ExportBuilderDashboardItemService,
    ReportElementImageSupportedFillModes
) {
    var vm = this;
    vm.ReportElementImageSupportedFillModes = ReportElementImageSupportedFillModes;
    vm.onFillModeClicked = onFillModeClicked;

    function onFillModeClicked(fillMode) {
        ExportBuilderDashboardItemService.setImageFillMode(vm.element, fillMode);
    }
}

/**
 * @ngInject
 */
function ExportBuilderShapeElementSettingsController(
    ExportBuilderDashboardItemService
) {
    var vm = this;
}

/**
 * @ngInject
 */
function ExportBuilderIconElementSettingsController(
    PubSub,
    $ExportBuilderIconModalEvents
) {
    var vm = this;
    vm.onIconClicked = onIconClicked;

    function onIconClicked() {
        PubSub.emit($ExportBuilderIconModalEvents.OPEN_EDIT, vm.element.id);
    }

}

/**
 * @ngInject
 */
function ExportBuilderTextElementSettingsController(
    ExportBuilderElementActionService
) {
    var vm = this;

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;

    vm.onTextTitleChange = onTextTitleChange;

    function $onInit() {
    }

    function $onDestroy() {
    }

    function onTextTitleChange() {
        ExportBuilderElementActionService.setTextTitle(vm.element.metadata.design_options.text);
    }
}


function ExportBuilderTableElementSettingsController(
    ExportBuilderElementActionService,
    PubSub,
    ExportBuilderItemTableEvents
) {
    var vm = this;
    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;

    vm.addTableRow = addTableRow;
    vm.addTableColumn = addTableColumn;
    vm.removeTableRow = removeTableRow;
    vm.removeTableColumn = removeTableColumn;
    vm.moveRowUp = moveRowUp;
    vm.moveRowDown = moveRowDown;
    vm.moveColumnLeft = moveColumnLeft;
    vm.moveColumnRight = moveColumnRight;
    vm.onCurrencyChange = onCurrencyChange;

    function $onInit() {
        _registerEvents();
        vm.removeRow = false;
        vm.removeColumn = false;
        vm.showMoveUp = false;
        vm.showMoveDown = false;
        vm.showColumnLeft = false;
        vm.showColumnRight = false;
        vm.currencyConstants = CurrencyConstants;
        vm.element.metadata.design_options.currency_code = vm.element.metadata.design_options.currency_code || $.globals.utils.currencyCode;
        vm.selectedCurrencyCode = vm.element.metadata.design_options.currency_code;
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    function addTableRow() {
        ExportBuilderElementActionService.addTableRow(vm.element.metadata.design_options.table_rows);
    }

    function addTableColumn() {
        ExportBuilderElementActionService.addTableColumn(vm.element.metadata.design_options.table_columns - 1);
    }

    function onCurrencyChange(){
        ExportBuilderElementActionService.setCurrency(vm.selectedCurrencyCode);
    }

    function _setButtonsConfig(data) {
        vm.selectedColumn = data.selectedColumn;
        vm.selectedRow = data.selectedRow;
        vm.isPredefined = data.isPredefined;
        // checks for row and column remove
        if (vm.selectedRow === 0) {
            if (!vm.isPredefined) {
                vm.removeColumnId = vm.selectedColumn;
                vm.removeColumn = true;
            } else {
                vm.removeColumn = false;
            }
            vm.removeRow = false;
        } else {
            if (vm.selectedRow !== data.item.table_rows) {
                vm.removeRowId = vm.selectedRow;
                vm.removeRow = true;
                vm.removeColumn = false;
            } else {
                vm.removeRow = false;
                vm.removeColumn = false;
            }
        }

        //checks for move up and down
        if (vm.removeRow) {
            if (vm.removeRowId === 1 || data.item.table_rows === vm.removeRowId) {
                vm.showMoveUp = false;
            } else {
                vm.showMoveUp = true;
            }
            if (vm.removeRowId === data.item.table_rows - 1 || vm.selectedRow === 0) {
                vm.showMoveDown = false;
            } else {
                vm.showMoveDown = true;
            }
        }

        // checks for swap left and swap right
        if (vm.selectedRow === 0) {
            if (vm.selectedColumn === 0 || vm.selectedColumn === data.item.table_columns - 2 || vm.selectedColumn === data.item.table_columns - 1) {
                vm.showColumnRight = false;
            } else {
                vm.showColumnRight = true;
            }
            if (vm.selectedColumn === 0 || vm.selectedColumn === 1 || vm.selectedColumn === data.item.table_columns - 1) {
                vm.showColumnLeft = false;
            } else {
                vm.showColumnLeft = true;
            }
        }

    }

    function removeTableRow() {
        let tableData = vm.element.metadata.design_options;
        if (tableData.table_rows > 2 && vm.selectedRow !== tableData.table_rows) {
            ExportBuilderElementActionService.removeTableRow(vm.removeRowId);
        }
        disableTablePanelButtons();
    }

    function removeTableColumn() {
        let tableData = vm.element.metadata.design_options;
        if (tableData.table_columns > 2 && vm.selectedColumn !== tableData.table_columns) {
            ExportBuilderElementActionService.removeTableColumn(vm.removeColumnId);
        }
        disableTablePanelButtons();
    }

    function moveRowUp() {
        ExportBuilderElementActionService.moveRowUp(vm.removeRowId);
        PubSub.emit(ExportBuilderItemTableEvents.TABLE_FIELDS_DISABLE, {rowIndex: vm.selectedRow-1, columnIndex: vm.selectedColumn});
        disableTablePanelButtons();
    }

    function moveRowDown() {
        ExportBuilderElementActionService.moveRowDown(vm.removeRowId);
        PubSub.emit(ExportBuilderItemTableEvents.TABLE_FIELDS_DISABLE, {rowIndex: vm.selectedRow+1, columnIndex: vm.selectedColumn});
        disableTablePanelButtons();
    }

    function moveColumnLeft() {
        ExportBuilderElementActionService.moveColumnLeft(vm.selectedColumn);
        PubSub.emit(ExportBuilderItemTableEvents.TABLE_FIELDS_DISABLE, {rowIndex: vm.selectedRow, columnIndex: vm.selectedColumn-1});
        disableTablePanelButtons();
    }

    function moveColumnRight() {
        ExportBuilderElementActionService.moveColumnRight(vm.selectedColumn);
        PubSub.emit(ExportBuilderItemTableEvents.TABLE_FIELDS_DISABLE, {rowIndex: vm.selectedRow, columnIndex: vm.selectedColumn+1});
        disableTablePanelButtons();
    }

    function _registerEvents() {
        PubSub.on(ExportBuilderItemTableEvents.TABLE_BUTTONS_CONFIG, _setButtonsConfig);
    }

    function disableTablePanelButtons() {
        vm.removeRow = false;
        vm.removeColumn = false;
        vm.showMoveUp = false;
        vm.showMoveDown = false;
        vm.showColumnLeft = false;
        vm.showColumnRight = false;
    }

    function _unregisterEvents() {
        PubSub.off(ExportBuilderItemTableEvents.TABLE_BUTTONS_CONFIG + vm.element.id, _setButtonsConfig);
    }

}
