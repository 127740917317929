"use strict";
import angular from "angular";
import _ from "lodash";

angular
  .module("widget.slicechart.ctrls", [])

  .controller("SliceChartWidgetController", SliceChartWidgetController);

/**
 * @ngInject
 */
function SliceChartWidgetController(
  $scope,
  DrawOption,
  ChartPlotType,
  GradientTypes,
  PictorialTypes,
) {
  $scope.$on("chart:setMetadataDefaults", function (e, metadata) {
    setMetadataDefaults(metadata);
  });

  function setMetadataDefaults(metadata) {
    let drawOptions = {};
    drawOptions[DrawOption.HAS_LEGEND] = false;
    drawOptions[DrawOption.SHOW_LABELS] = true;
    drawOptions[DrawOption.SHOW_LABEL_NAMES] = true;
    drawOptions[DrawOption.SHOW_LABEL_VALUES] = true;
    drawOptions[DrawOption.SHOW_LABEL_PERCENT] = true;
    drawOptions[DrawOption.IS_NORMALIZED] = false;
    drawOptions[DrawOption.HAS_TOOLTIP] = true;
    drawOptions[DrawOption.DEPTH] = 0;
    drawOptions[DrawOption.ANGLE] = 0;
    drawOptions[DrawOption.START_ANGLE] = 90;
    drawOptions[DrawOption.INNER_RADIUS] = 0;
    drawOptions[DrawOption.IS_HAND_DRAWN] = false;
    drawOptions[DrawOption.OTHER_PERCENT] = 2;
    drawOptions[DrawOption.BACKGROUND_GRADIENT] = GradientTypes.SOLID;
    drawOptions[DrawOption.PIE_GRADIENT_OPTIONS] = GradientTypes.SOLID;
    drawOptions[DrawOption.PLOT_TYPE] = ChartPlotType.CLASSIC;
    drawOptions[DrawOption.PIE_GRAIN_DENSITY] = 0;
    drawOptions[DrawOption.PIE_BORDER_STROKE] = false;
    drawOptions[DrawOption.SHOW_DONUT_SUM] = false;
    drawOptions[DrawOption.PIE_SEMICIRCLE_ANGLE] = false;
    drawOptions[DrawOption.PIE_ROTATION_ANGLE] = 0;
    drawOptions[DrawOption.PIE_VARIABLE_RADIUS] = false;
    drawOptions[DrawOption.CLASSIC_DRILL_DOWN_BEHAVIOUR] = false;
    metadata.draw_options.plot_type =
      metadata.draw_options.plot_type || ChartPlotType.CLASSIC;
    const isDefaultPlotType =
      metadata.draw_options.plot_type === ChartPlotType.DEFAULT ||
      metadata.draw_options.plot_type === "";
    if (isDefaultPlotType) {
      metadata.draw_options.plot_type = ChartPlotType.CLASSIC;
    }
    drawOptions[DrawOption.PICTORIAL_OPTIONS] = PictorialTypes.MALE;
    metadata.draw_options = _.assign(drawOptions, metadata.draw_options);
  }
}
