'use strict';
import angular from 'angular';
import _ from 'lodash';

angular.module('core.export.services', [])

    .service('ExportFactory', ExportFactory)
    .service('ImageExportLoadingFactory', ImageExportLoadingFactory);

function ImageExportLoadingFactory() {
    const widgets = {}, MAX_IMAGE_TRIES = 10;

    return {
        imagesAreLoaded: imagesAreLoaded,
        hashString: hashString,
    };

    /**
     * Returns a promise that resolves once all images in a widget are loaded
     * Or, if there's no images in the widget - just resolves
     * @param valueHash
     * @param images
     */
    function imagesAreLoaded(valueHash, images) {
        widgets[valueHash] = {
            imageList: {},
            interval: 0,
            iteration: 0,
        };

        return new Promise((resolve) => {
            if (!Array.isArray(images)) {
                return resolve();
            }

            if (images.length === 0) {
                return resolve();
            }

            const filteredImages = images.filter(image => !image.src.startsWith('data:'));
            if (filteredImages.length === 0) {
                return resolve();
            }

            // add custom onload for each image
            filteredImages.forEach((image) => {
                widgets[valueHash].imageList[image.src] = image.complete;
                if (!image.complete) {
                    image.addEventListener('load', () => handleImageLoad(image, valueHash));
                    image.addEventListener('error', () => handleImageLoad(image, valueHash));
                }
            });

            // we give up after MAX_IMAGE_TRIES attempts or when all images are loaded in the DOM
            return widgets[valueHash].interval = setInterval(() => {
                widgets[valueHash].iteration++;

                if (
                    Object.values(widgets[valueHash].imageList).every(e => e === true) ||
                    widgets[valueHash].iteration > MAX_IMAGE_TRIES
                ) {
                    clearInterval(widgets[valueHash].interval);
                    return resolve();
                }
            }, 1000);
        });
    }

    /**
     * Quickly convert what could be a massive HTML string into an int for identification
     * @param s
     * @returns {*}
     */
    function hashString(s) {
        s.split('').reduce((a,b) => (((a << 5) - a) + b.charCodeAt(0))|0, 0);
    }

    /**
     * Once the image has loaded we remove the event listener and update the map
     * @param image
     * @param valueHash
     */
    function handleImageLoad(image, valueHash) {
        widgets[valueHash].imageList[image.src] = true;
        image.removeEventListener('load', () => handleImageLoad(image));
        image.removeEventListener('error', () => handleImageLoad(image));
    }
}

/**
 * @ngInject
 */
function ExportFactory(
    $timeout,
    PageOrientation,
    WidgetType,
    WidgetSize,
    ExportType,
    DrawOption,
    DesignFactory,
    WidgetUtilService,
    ImageExportLoadingFactory
) {
    var backendVars;
    var appendedWidgets = [];
    var widgetReadyCounter = {
        count: 0,
        dataGridCount: 0,
        setNumWidgets: function(num) {
            this.count = num;
        },
        incRef: function() {
            this.count++;
        },
        decRef: function() {
            if (--this.count <= 0) {
                $timeout(function() {
                    setWidgetsReady();
                });
            }
        }
    };

    var chartReadyCounter = {
        count: 0,
        setNumWidgets: function(num) {
            this.count = num;
        },
        incRef: function() {
            this.count++;
        },
        decRef: function() {
            $timeout(() => {
                if (--this.count <= 0) {
                    setChartsReady();
                }
            });
        }
    };

    return {
        widgetReadyCounter: widgetReadyCounter,
        chartReadyCounter: chartReadyCounter,
        setVars: setVars,
        getVars: getVars,
        chartExists: chartExists,
        setChartsReady: setChartsReady,
        addAppendedWidgetToPage: addAppendedWidgetToPage,
        hasAppendedWidgetsOnPage: hasAppendedWidgetsOnPage,
        getAppendedWidgetsOnPage: getAppendedWidgetsOnPage,
        isAppendedWidgetToPage: isAppendedWidgetToPage,
        isAppendedWidgetToLayout: isAppendedWidgetToLayout,
        pageIsEmpty: pageIsEmpty,
        isScheduledReport: isScheduledReport,
        appendWidgetsToLayout: appendWidgetsToLayout,
        groupWidgets: groupWidgets,
        getIsExporting: getIsExporting,
        isPPTExport: isPPTExport,
        isAdobePPTExport: isAdobePPTExport,
        paginateOnceReady: paginateOnceReady,
        getMapboxToken: getMapboxToken,
    };

    /**
     * Resize events shouldn't take place until all images are loaded.  Bounce through
     * to test whether there's images present in the table
     *
     * @param el jQuery element of data widget
     */
    function paginateOnceReady(el) {
        const table = el.find('table.dataTable');
        const jqueryImages = table.find('td.image-preview img');
        const images = [];

        if (jqueryImages.length) {
            jqueryImages.each(function (index, image) {
                images.push($(image)[0]);
            });
        }

        // if the previous widget is a header then that will also need to be taken into account
        const widgetParent = el.parent();
        const previousElement = widgetParent.prev();
        const previousIsHeader = previousElement.hasClass('is-header-appended');
        if (previousIsHeader) {
            const headerImages = previousElement.find('img');
            headerImages.each(function(index, headerImage) {
                images.push($(headerImage)[0])
            })
        }

        if (!images.length) {
            adjustPageSpanHeights(el);
        }

        ImageExportLoadingFactory.imagesAreLoaded(table.html(), images).then(() => {
            adjustPageSpanHeights(el);
        });
    }

    /**
     * Paginated widgets can span multiple pages.  We need to work out how many rows of a table we
     * can fit on a page and split each table up into X tables so they don't span across page breaks
     *
     * This needs to take into account any media widgets that are being used as headers
     * @param el jQuery element of data widget
     */
    function adjustPageSpanHeights(el) {
        if (el.hasClass('table-processed')) {
            widgetReadyCounter.decRef();
            return;
        }

        // header title height is set in export.less
        const PAGE_HEIGHT = 1214, WIDGET_PADDING = 8, HEADER_TITLE = 80;

        const widgetIndex = el.data('widget-index');
        const widgetParent = el.parent();
        const widgetInner = el.children('.widget-inner').first();
        const previousElement = widgetParent.prev();
        const previousIsHeader = previousElement.hasClass('is-header-appended');
        const isFirst = widgetIndex === 0 || (widgetIndex === 1 && previousIsHeader);
        const widgetHeaderHeight = el.find('.widget-header').first().outerHeight(true) + (WIDGET_PADDING * 2);
        const isFinalWidget = widgetParent.hasClass('final-widget');
        let mediaWidgetHeaderHeight = 0, pageTitleHeight = WIDGET_PADDING, overflowPageMargin = WIDGET_PADDING * 2;
        let widgetElementHeight = el.outerHeight(true);

        // the very paginated datagrid will need to take into account the "SUMMARY GRIDS" title
        // it's always taking up space even if it's disabled from show
        if (isFirst) {
            pageTitleHeight = HEADER_TITLE + WIDGET_PADDING;
        }

        // any header element needs the height accounting for as well
        if (previousIsHeader) {
            mediaWidgetHeaderHeight = (previousElement.outerHeight(true) + (WIDGET_PADDING * 2));
        }

        let totalHeightOfElements = widgetElementHeight + mediaWidgetHeaderHeight + pageTitleHeight;

        // early-out if everything fits on the page
        if (totalHeightOfElements < PAGE_HEIGHT) {
            // make sure that we include a page break
            // it's a paginated table so it should be on it's own page so we can start the next calculations at 0
            if (!isFinalWidget) {
                el.addClass('multiple-page-span');
            }
            el.addClass('table-processed');

            widgetReadyCounter.decRef();
            return;
        }

        const table = el.find('table.dataTable');
        const tHeader = table.find('thead');
        const headerAtTop = table.find('thead.total-row')
        const tBody = table.find('tbody');
        const tFooter = table.find('tfoot');

        const tableHeights = {
            header: {elements: [], height: 0},
            body: {elements: [], height: 0},
            footer: {elements: [], height: 0},
        };

        // if the header is of length 2 then we have the total in there too.  that exists as a separate thead
        if (tHeader.length === 2) {
            // first one is the standard thead with tr/th for the columns
            addHeightsToList(tHeader.first(), tableHeights, 'header');

            tableHeights.header.height += headerAtTop.outerHeight(true);
        } else {
            tHeader.each(function() {
                addHeightsToList($(this), tableHeights, 'header');
            });
        }
        tFooter.each(function() {
            addHeightsToList($(this), tableHeights, 'footer');
        });
        addHeightsToList(tBody, tableHeights, 'body');

        // ensure the widths of each column remain consistent across the pages by taking a copy of the
        // initial widths from the rendered table
        const widths = [];
        table.find('tbody > tr').first().find('td').each(function() {
            widths.push($(this).width())
        });

        // where we start our calculations from depends on what there is on the page before the table rows
        // <PAGE TITLE>
        // <POSSIBLE HEADER WIDGET>
        // <HEADER FOR TABLE WIDGET>
        // <THEAD IN TABLE>
        let pageHeightRunningTotal = pageTitleHeight + mediaWidgetHeaderHeight + widgetHeaderHeight + tableHeights.header.height;
        let pageNumber = 1;
        const tableContents = [], widgetInners = [];

        // split the tbody into pages
        let pageOfRows = [];
        for (let i = 0; i < tableHeights.body.elements.length; i++) {
            const rowElement = tableHeights.body.elements[i];
            const rowHeight = rowElement.outerHeight(true);

            // if we will end up requiring a new page push it and create a new one
            if (pageHeightRunningTotal + rowHeight > PAGE_HEIGHT) {
                tableContents.push(pageOfRows);
                pageOfRows = [];

                // make sure we start at the initial page margin as 0 is the top of the
                // page but not the top of the _usable_ page
                pageHeightRunningTotal = overflowPageMargin;
            }

            pageHeightRunningTotal += rowHeight;
            pageOfRows.push(rowElement);
        }

        tableContents.push(pageOfRows);

        // start constructing the new widgets based on the tableContents
        let newInner, newTable;
        for (let i = 0; i < tableContents.length; i++) {
            const page = tableContents[i];
            if (newInner) {
                widgetInners.push({newInner, newTable});
            }

            // generate a copy of the table and remove all the contents
            ({newInner, newTable} = generateNewWidget(widgetInner, table));

            // add any table headers
            if (pageNumber === 1) {
                $('<thead/>').appendTo(newTable);
                for (let head = 0; head < tableHeights.header.elements.length; head++) {
                    tableHeights.header.elements[head].appendTo(newTable.find('thead'));
                }
                if (headerAtTop.length) {
                    headerAtTop.appendTo(newTable);
                }
            } else {
                newInner.find('widget-header').remove();
            }

            $('<tbody/>').appendTo(newTable);

            // then the body rows per page
            for (let body = 0; body < page.length; body++) {
                let rowElement = page[body];
                rowElement.children('td').each(function(index) {
                    $(this).css('width', widths[index] + 'px');
                });

                page[body].appendTo(newTable.find('tbody'));
            }

            pageNumber++;
        }

        // footer comes last - we need to know whether we can fit them as well
        if (tableHeights.footer.height > 0) {
            if (pageHeightRunningTotal + tableHeights.footer.height > PAGE_HEIGHT) {
                widgetInners.push({newInner, newTable});
                ({newInner, newTable} = generateNewWidget(widgetInner, table));
                newInner.find('widget-header').remove();
            }

            $('<tfoot class="total-row datagrid-old"/>').appendTo(newTable);

            for (let i = 0; i < tableHeights.footer.elements.length; i++) {
                let rowElement = tableHeights.footer.elements[i];
                rowElement
                    .css('width', widths[i] + 'px')
                    .appendTo(newTable.find('tfoot'));
            }
        }

        widgetInners.push({newInner, newTable});

        // append those widgets to the parent element
        for (let i = 0; i < widgetInners.length; i++) {
            ({newInner, newTable} = widgetInners[i]);

            newInner.find('.dataTables_wrapper table').remove();
            newTable.appendTo(newInner.find('.dataTables_wrapper'));

            const widget = $('<div class="design-widget multiple-page-span"/>');
            if (i === widgetInners.length - 1 && isFinalWidget) {
                widget.addClass('final-widget');
            }

            newInner.appendTo(widget);

            widget.appendTo(el.parent());
        }

        // now remove the emptied table we've been using as a template to leave our spanned tables
        el.parent().children('.design-widget').first().remove();
        el.addClass('table-processed');

        widgetReadyCounter.decRef();
    }

    /**
     * Shortcut function to clone the .widget-inner element and the table that goes inside it
     * @param widgetInner jquery element of div.widget-inner
     * @param table jquery element of table.dataTable
     * @returns {{newInner: *, newTable: *}}
     */
    function generateNewWidget(widgetInner, table) {
        const newInner = widgetInner.clone();

        newInner.find('widget-loader').remove();
        newInner.find('widget-loaded').remove();
        newInner.find('.clipped-notify-container').remove();

        let newTable = table.clone();
        newTable.find('thead').remove();
        newTable.find('tbody').remove();
        newTable.find('tfoot').remove();

        return {newInner, newTable};
    }

    /**
     * Generate the height map of each element of a table (thead/tbody/tfoot)
     * @param element
     * @param tableHeights
     * @param elementName
     */
    function addHeightsToList(element, tableHeights, elementName) {
        const childElement = elementName === 'footer' ? 'th' : 'tr';

        element.children(childElement).each(function() {
            tableHeights[elementName].elements.push($(this));
            tableHeights[elementName].height += $(this).outerHeight(true);
        });
    }

    /**
     * Class a page as empty when any widgets that should be appearing on it
     * have actually been "appended" to the end.  Used in export.page.html
     * to prevent blank pages being generated at the start of a report
     * @param layoutId
     * @returns {boolean}
     */
    function pageIsEmpty(layoutId) {
        const widgets = DesignFactory.getLayout(layoutId).widgets;
        let widgetCount = Object.values(widgets).length;
        _.each(widgets, function(widget) {
            if (isAppendedWidgetToPage(widget)) {
                widgetCount--;
            }
        });

        return widgetCount <= 0;
    }

    function setChartsReady() {
        window.chartsReady = true;
    }

    function setWidgetsReady() {
        window.widgetsReady = true;
    }

    function setVars(vars) {
        backendVars = vars;
    }

    function getVars() {
        return backendVars;
    }

    function getMapboxToken() {
        return backendVars.mapboxToken;
    }

    function chartExists(widgets) {
        var hasChart = false;
        _.forEach(widgets, function (widget) {
            if (WidgetUtilService.isChartWidget(widget.type))
            {
                var predefinedData = widget.metadata.dynamic.predefined_data;
                if (!_.isNull(predefinedData) && !_.isEmpty(predefinedData.data) && predefinedData.data !== 'error') {
                    hasChart = true;
                    return false; // break the forEach
                }
            }
        });
        return hasChart;
    }

    function isScheduledReport() {
        return backendVars.exportType !== ExportType.TYPE_SCHEDULED_REPORT_PDF;
    }

    /**
     * @returns {boolean}
     */
    function isPPTExport() {
        return getIsExporting() && backendVars.exportType === ExportType.TYPE_EXPORT_PPT;
    }

    /**
     * @returns {boolean}
     */
    function isAdobePPTExport() {
        return isPPTExport() && backendVars.adobe_ppt_convertor;
    }

    /**
     * Tell us if a widget cam be appended
     * @param widget
     * @returns {boolean}
     */
    function _canWidgetBeAppended(widget) {
        return backendVars.useReportOptions
            && backendVars.exportType !== ExportType.TYPE_EXPORT_WIDGET_PNG
            && widget.type === WidgetType.DATAGRID
            && widget.metadata.draw_options[DrawOption.GRID_PAGINATE];
    }

    /**
     * Helper function to add appended widget to dashboard
     * @param widget
     */
    function addAppendedWidgetToPage(widget) {
        appendedWidgets.push(widget);
    }

    /**
     * Tell us if it has appended widgets on widgetsAppendLevel
     * @returns {boolean}
     */
    function hasAppendedWidgetsOnPage() {
        return !_.isEmpty(appendedWidgets);
    }

    /**
     * Get appended widgets based on widgetsAppendLevel and page/layout id
     * @returns {Array|*}
     */
    function getAppendedWidgetsOnPage() {
        _.each(appendedWidgets, function(widget) {
            widget.layout_display_order = DesignFactory.getLayout(widget.layout_id).display_order;
        });
        return _.orderBy(appendedWidgets, [
            function(widget) { return widget.layout_display_order },
            function(widget) { return widget.display_order }
        ]);
    }

    /**
     * Append datagrid widgets to dashboard if it has pagination and append_exported_datagrids is set
     * @param widget
     * @returns {boolean}
     */
    function isAppendedWidgetToPage(widget) {
        return appendWidgetsToPage() && _canWidgetBeAppended(widget);
    }

    /**
     * Append datagrid widgets to section if it has pagination and append_exported_datagrids_to_layout is set
     * @param widget
     * @returns {boolean}
     */
    function isAppendedWidgetToLayout(widget) {
        return appendWidgetsToLayout() && _canWidgetBeAppended(widget);
    }

    /**
     * Tell us if we append widgets to dashboard
     * @returns {*|boolean}
     */
    function appendWidgetsToPage() {
        return DesignFactory.getCurrentPage().metadata.append_exported_datagrids;
    }

    /**
     * Tell us if we append widgets to section
     * @returns {*|boolean}
     */
    function appendWidgetsToLayout() {
        return DesignFactory.getCurrentPage().metadata.append_exported_datagrids_to_layout;
    }

    /**
     * Figures out how to maximum amount of widgets per page
     * @param widgets
     * @param orientation
     * @returns {Array}
     */
    function groupWidgets(widgets, orientation) {
        // This algorithm takes two steps to group widgets into pages:
        // First step, for the widgets that can fit into the current page, we store them as candidates
        // Second step, if we confirm by some conditions, we place widget candidates into the current page

        var ungroupedWidgets = widgets;

        var groupedWidgets = [];
        var currentGroup = [];

        var maxWidth = WidgetSize.MAX_WIDTH; // Width remains constant since it's a percentage
        var maxHeight = orientation === PageOrientation.TYPE_LANDSCAPE ? 14 : 18;

        var model;
        // model array = [col0, col1, col2..]
        // represents the matrix of one page
        //  col0      col1      col2
        //   1          1         1
        //   1          1         1
        //   1          1         1
        //   -1         -1        -1
        //   -1         -1        -1
        //   -1         -1        -1
        //   0          0         0

        // value 1 represents widgets get placed
        // value -1 represents we try to fit widget candidates in the page. Candidate are not actually placed, they are just placed 'virtually'
        // value 0 represents empty space


        // prevStartIndex is used for determine if we need to add widget candidates,
        // initialized as maxWidth
        var prevStartIndex = maxWidth;

        var virtualValue = -1;

        resetModel();

        var heights = getHeights();

        // this will run until all the widgets are grouped
        while (ungroupedWidgets.length > 0) {
            // widgetCandidates array stores the widget candidates that are in the same row
            var widgetCandidates = [];
            // widgetCandidateIndices array stores the indices of the candidates, and maintains widgetColStartIndex in ascending order
            var widgetCandidateIndices = [];
            _.forEach(ungroupedWidgets, function (widget, i) {
                var startIndexObj = getStartIndexObj(widget);
                var widgetColStartIndex = startIndexObj.widgetColStartIndex;
                var widgetRowStartIndex = startIndexObj.widgetRowStartIndex;
                var doubleCheckStartIndex;
                if (widgetColStartIndex != -1) {
                    // we need a new row
                    if (widgetColStartIndex <= prevStartIndex) {
                        // if a new row needed, we add widget candidates
                        addWidgetCandidates(widgetCandidates, widgetCandidateIndices);

                        // for test
                        // console.log('real', getHeightsFromBottom());

                        // reset
                        widgetCandidates = [];
                        widgetCandidateIndices = [];

                        // double check is needed because we need to confirm that after we add widget candidates,
                        // the current widget can still fit into the page
                        var doubleCheckStartIndexObj = getStartIndexObj(widget);
                        doubleCheckStartIndex = doubleCheckStartIndexObj.widgetColStartIndex;

                        // if widget can't fit after double check, stop iteration
                        if (doubleCheckStartIndex == -1) {
                            // see the graph near line 152
                            if (!hasEnoughRemainingWidth(widget)) {
                                addWidgetCandidates(widgetCandidates, widgetCandidateIndices);
                            }
                            return false;
                        }
                    }
                    widgetColStartIndex = _.isUndefined(doubleCheckStartIndex) ? widgetColStartIndex : doubleCheckStartIndex;
                    // Mark current widget as candidates
                    widgetCandidates.push(widget);
                    widgetCandidateIndices.push({
                        widgetRowStartIndex: widgetRowStartIndex, // row index
                        widgetColStartIndex: widgetColStartIndex, // column index
                        index: i // widget index
                    });
                    tryToFit(widgetCandidates, widgetCandidateIndices);
                    // for test
                    // console.log('model', model);
                    // console.log('virtual', getHeightsFromBottom());

                    prevStartIndex = widgetColStartIndex;
                    // if it is the last widget
                    if (i == ungroupedWidgets.length - 1) {
                        addWidgetCandidates(widgetCandidates, widgetCandidateIndices);
                    }

                }
                else {
                    // If current widget that can't fit into the page but it's width is smaller than remaining width after the last widget candidate,
                    // we don't want to push widget candidates into this page,
                    // instead we push the widget candidates into next page.
                    // - - - - - - - - - - - - - - - - - - -                                   ----------------------------------------  start of next page
                    // |      widget     |                 |                                    |      widget     |                 |
                    // |    candidates   |                 |                                    |    candidates   |                 |
                    // - - - - - - - - - -                 |                                    - - - - - - - - - -                 |
                    //                   |    current      |                      ---->                           |    current      |
                    //                   |     widget      |                                                      |     widget      |
                    //                   |                 |                                                      |                 |
                    //  ---------------------------------------- end of page                                      |                 |
                    //                   |                 |                                                      |                 |
                    //                   - - - - - - - - - -                                                      - - - - - - - - - -
                    if (!hasEnoughRemainingWidth(widget, widgetCandidates, widgetCandidateIndices)) {
                        addWidgetCandidates(widgetCandidates, widgetCandidateIndices);
                    }
                    return false;
                }
            });

            var needsWhileLoopBreak = cleanUp();
            if (needsWhileLoopBreak) {
                // something went wrong (widgets too big to fit with getStartIndexObj() logic), just push the remaining widgets to current group and break the loop
                _.forEach(ungroupedWidgets, function (widget, i) {
                    currentGroup.push(widget);
                    groupedWidgets.push(currentGroup);
                    currentGroup = [];
                });
                break;
            }
        }

        /**
         * this returns a "flattened" array that is the first available "height" ("0") of each column
         * @returns {Array}
         */
        function getHeights () {
            return _.map(model, function (column) {
                var height = -1;
                // If the first column is not available, we will not 'pack' the widget
                //
                // - - - - - - - - - - - - - - - - - - -
                // |  Placed        | x x Unusable x x
                // |  widget        | x x Empty x x x x
                // |  Order 1       | x x Space x x x x
                // - - - - - - - - - - - - - - - - - - -
                // |             Placed                |
                // |             widget                |
                // |             Order 2               |
                //  ---------------------------------------- end of page
                // |      Current      |
                // |      Widget       |
                // |      Order 3      |
                // - - - - - - - - - - -
                //

                // lastIndexOf counts backwards from the right to find the first element matching
                // indexOf returns the opposite - so the first appearance of a certain value
                const lastIndexOf = _.lastIndexOf(column, 0);
                const first0 = column.indexOf(0);

                if (lastIndexOf < maxHeight - 1) {
                    height = maxHeight;
                } else {
                    // we need to account for situations that have blank spaces caused by either:
                    // a) widgets with no data and the "hide empty widgets" option toggled
                    // b) multiple half-sized widgets with headers and an intentional blank space
                    // as a worked example say we want to add a widget of height 4 into
                    // a space that has a full width, height 1 widget following a blank space:
                    // 1 1 1 1 1 1 0 0 0 1 0 0 0 0
                    // previously this would use a combination of lastIndexOf and indexOf and end up
                    // returning the 3 0s between the 1s.  the height 4 widget "does not fit"
                    // so it creates a new group to denote a new page.  we want to be able
                    // to target the following 0s after the full-width 1 where it does fit
                    // so we take the remaining values after that first 0, check for any following 1s
                    // and then use the remaining 0s to figure out if there's enough space
                    // if there's not another 1 then we can just continue as normal
                    const remainingValues = column.slice(first0);
                    const additionalOne = _.lastIndexOf(remainingValues, 1);
                    const firstAvailableIndex = first0 + additionalOne + 1;

                    height = firstAvailableIndex > maxHeight ? maxHeight : firstAvailableIndex;

                    // if the height has worked out at 0 we need to check for instances where the
                    // widget would fit width-wise but because of a full-width media widget placed like above doesn't.
                    // this is achieved by checking where the widget candidate is placed and checking
                    // to see if it's all zeroes before that.  if it is we can then test the space
                    // following the -1 to see if it actually fits
                    // by default this would make the algorithm think it can fit when it can't and end
                    // up overlapping onto the next page
                    if (height === 0) {
                        const finalMinus = column.lastIndexOf('-1');
                        let finalMinusPosition = -1;
                        for (let i = column.length; i >= 0; i--) {
                            if (column[i] === -1) {
                                finalMinusPosition = i;
                                break;
                            }
                        }
                        if (finalMinusPosition !== -1) {
                            const precedingValues = column.slice(0, finalMinusPosition);
                            if (precedingValues.every(item => item === 0)) {
                                height = finalMinus + 1 > maxHeight ? maxHeight : finalMinusPosition + 1;
                            }
                        }
                    }
                }

                return height > -1 ? height : maxHeight;
            });
        }

        /**
         * This returns a "flattened" array that is the last available "height" ("0") of each column
         * @returns {Array}
         */
        function getHeightsFromBottom() {
            var heights = [];
            for (var col = 0; col < model.length; col++) {
                // Initialization
                heights.push(0);
                for (var row = model[0].length - 1; row >= 0; row--) {
                    if (model[col][row] != 0) {
                        heights[col] = row + 1;
                        break;
                    }
                }
            }
            return heights;
        }

        /**
         * cleans up widgets arrays and prepares for new group (if needed),
         * will return true or false, if there is a need for a break
         * @returns {boolean}
         */
        function cleanUp () {
            // gets rid of the widgets marked for removal
            prevStartIndex = maxWidth;
            ungroupedWidgets = _.compact(ungroupedWidgets);
            var needsNewGroup = true;
            // _.forEach(ungroupedWidgets, function ( widget, i) {
            //     // test if one of the remaining widgets can still fit inside current group
            //     if (getStartIndexObj(widget, true)) {
            //         needsNewGroup = false;
            //     }
            // });
            if (needsNewGroup) {
                // reset trackers/ groups
                groupedWidgets.push(currentGroup);
                resetModel();
                heights = getHeights();
                currentGroup = [];

                if (ungroupedWidgets.length > 0) {
                    // If a widget still cannot fit after resetting the different stores, we must break the while loop (something has gone wrong)
                    var breakWhileLoop = true;
                    _.forEach(ungroupedWidgets, function ( widget, i) {
                        // test if one of the remaining widgets can still fit inside current empty group
                        if (getStartIndexObj(widget, true).widgetColStartIndex != -1) {
                            breakWhileLoop = false;
                        }
                    });
                    if (breakWhileLoop) {
                        return true;
                    }
                }
            }
            return false;
        }

        /**
         * Get start Index Object including widgetColStartIndex and widgetRowStartIndex
         * @param widget
         * @param testing
         * @returns {number}
         */
        function getStartIndexObj (widget, testing) {
            // The trick is that with only start index of model column, we can know how widget candidate is placed,
            // since we can get the width and height and do some calculations
            heights = getHeights();
            // we grab the unique heights available for the widget to be placed
            var startIndexObj;
            var orderedHeights = _.orderBy(_.uniq(heights));

            for (var i = 0; i < orderedHeights.length; i++) {
                // test if the widget can fit at that height
                startIndexObj = checkStartIndexObj(widget, orderedHeights[i], testing);
                if (startIndexObj.widgetColStartIndex != -1) {
                    return startIndexObj;
                }
            }
            startIndexObj.widgetColStartIndex = -1;
            return startIndexObj;
        }

        /**
         * Check start Index Object
         * @param widget
         * @param heightTestVal
         * @param testing
         * @returns {*}
         */
        function checkStartIndexObj (widget, heightTestVal, testing) {

            var widgetWidth = parseInt(widget.width);
            var widgetHeight = parseInt(widget.height);


            var startIndexObj = {
                widgetRowStartIndex: heightTestVal, // row index
                widgetColStartIndex: -1 // column index
            };

            //would fit height-wise
            if (heightTestVal + widgetHeight > maxHeight) {
                return startIndexObj; // too tall at this testVal (would go off page)
            }

            //would fit width-wise
            var startIndex = calcStartIndex(heightTestVal);

            var widthAllowed = 0;

            var widgetColStartIndex = startIndex;

            for (var i = startIndex; i < heights.length; i++) {
                if (heights[i] <= heightTestVal) { // there's space for the widget at this index (width-wise)

                    var heightLimit;

                    // find the next occurrence of filled space ('1', widgets has been placed) starting from the heightTestVal index
                    var realHeightLimit = _.indexOf(model[i], 1, heightTestVal);

                    // find the next occurrence of filled space ('-1', widgets has not been actually placed but virtually placed)
                    // starting from the heightTestVal index
                    var virtualHeightLimit = _.indexOf(model[i], -1, heightTestVal);

                    if (virtualHeightLimit === -1 && realHeightLimit === -1) {
                        heightLimit = -1;
                    } else if (virtualHeightLimit === -1 || realHeightLimit === -1) {
                        heightLimit = virtualHeightLimit === -1 ? realHeightLimit : virtualHeightLimit;
                    } else {
                        heightLimit = Math.min(virtualHeightLimit, realHeightLimit);
                    }
                    // if there is no height limit
                    if (heightLimit == -1) {
                        widthAllowed++;
                    }
                    // if there is a height limit and..
                    else {
                        // if the widget is too tall at this value (would overlap another widget)
                        if (heightLimit - heights[i] < widgetHeight) {
                            widgetColStartIndex++;
                        }
                        // the widget can fit (height-wise)
                        else {
                            widthAllowed++;
                        }
                    }
                }
                else { // you've hit another widget
                    break;
                }
            }

            startIndexObj.widgetColStartIndex = widgetColStartIndex;

            if (widgetWidth > widthAllowed) {
                startIndexObj.widgetColStartIndex = -1; // too wide for this testVal
            }

            return startIndexObj;
        }

        /**
         * Calculate start index of widget
         * @param val
         * @returns {number}
         */
        function calcStartIndex (val) {
            // the "startIndex" begins at the first instance of the val
            var startIndex = _.indexOf(heights, val);
            var newStartIndex = startIndex;
            // we loop backwards from that index, and reset the newStartIndex, based off of the current index
            // why?
            //
            // XXYYYZZZZ
            // XX   ZZZZ
            //      ZZZZ
            //
            // for widgets X, Y, and Z, if testing for the index 5 (bottom corner of Z)
            for (var i = startIndex; i >= 0; i-- ) {
                if (heights[i] <= val) {
                    newStartIndex = i;
                }
            }
            return newStartIndex;
        }

        /**
         * Try to fit widget candidate
         * @param widgetCandidates
         * @param widgetCandidateIndices
         */
        function tryToFit(widgetCandidates, widgetCandidateIndices) {
            undoTryToFit();
            for (var i = 0; i < widgetCandidates.length; i++) {
                var widgetCandidate = widgetCandidates[i];
                var widgetCandidateIndex = widgetCandidateIndices[i];
                var newRowStartIndex = widgetCandidateIndex.widgetRowStartIndex;
                var newColStartIndex = widgetCandidateIndex.widgetColStartIndex;
                var widgetWidth = parseInt(widgetCandidate.width);
                var widgetHeight = parseInt(widgetCandidate.height);
                for (var col = 0; col < widgetWidth; col++) {
                    for (var row = 0; row < widgetHeight; row++) {
                        model[newColStartIndex + col][newRowStartIndex + row] = virtualValue;
                    }
                }

                newColStartIndex += widgetWidth;
            }
        }

        /**
         * Undo try to fit widget candidate
         */
        function undoTryToFit() {
            for (var i = 0; i < model.length; i++) {
                for (var j = 0; j < model[0].length; j++) {
                    if(model[i][j] == virtualValue) {
                        model[i][j] = 0;
                    }
                }
            }
        }

        /**
         * This creates an array of arrays (row with columns) to help the controller conceptualize what spaces are left for isotope to fit
         */
        function resetModel () {
            model = [];
            for (var i = 0; i < maxWidth; i++) {
                var column = [];
                for (var j = 0; j < maxHeight; j++ ) {
                    column.push(0);
                }
                // the columns are initially filled with 0's (empty)
                model.push(column);
            }
        }

        /**
         * @param widgetCandidates
         * @param widgetCandidateIndices
         */
        function addWidgetCandidates(widgetCandidates, widgetCandidateIndices) {
            undoTryToFit();
            for (var j = 0; j < widgetCandidates.length; j++) {
                var currentWidget = widgetCandidates[j];
                var indexObj = widgetCandidateIndices[j];
                /**
                 * Below condition checks that next widget should fit in the same group if the current widget is media (header) widget.
                 * If the next widget cannot fit in the same group, it will not add the current media widget,
                 * So, we have page break correctly RP-1299
                 */
                if (currentWidget.type === WidgetType.MEDIA && currentWidget.metadata.is_header && ungroupedWidgets[widgetCandidateIndices[0].index + 1]) {
                    // This needs to improve to cover all edge cases.
                    const currentAndNextWidgetHeight = currentWidget.height + ungroupedWidgets[widgetCandidateIndices[0].index + 1].height;
                    const currentGroupHeight = widgetCandidateIndices[0].widgetRowStartIndex;
                    // We should skip the check if it is start of the page (i.e. currentGroupHeight is 0).
                    if (currentGroupHeight !== 0 && currentGroupHeight + currentAndNextWidgetHeight > maxHeight) {
                        return;
                    }
                }
                fit(currentWidget, indexObj.widgetColStartIndex);
                ungroupedWidgets[indexObj.index] = null; // mark the widget for removal
                currentGroup.push(currentWidget);
            }
        }

        /**
         * Mark model as placed
         * @param widget
         * @param colIndex
         */
        function fit (widget, colIndex) {
            var widgetWidth = parseInt(widget.width);
            var widgetHeight = parseInt(widget.height);

            var rowIndex = _.indexOf(model[colIndex],0);
            for (var col = 0; col < widgetWidth; col++) {
                for (var row = 0; row < widgetHeight; row++) {
                    model[colIndex + col][rowIndex + row] = 1;
                }
            }
        }


        /**
         * @param widget
         * @param widgetCandidates
         * @param widgetCandidateIndices
         * @returns {boolean}
         */
        function hasEnoughRemainingWidth(widget, widgetCandidates, widgetCandidateIndices) {
            // If no candidates
            if (_.isUndefined(widgetCandidates) || _.isEmpty(widgetCandidates)) {
                return true;
            }
            var lastWidgetCandidate = widgetCandidates[widgetCandidates.length - 1];
            var lastWidgetCandidateIndex = widgetCandidateIndices[widgetCandidateIndices.length - 1].widgetColStartIndex;
            var widgetWidth = parseInt(widget.width);
            var remainingWidth = getRemainingWidth(lastWidgetCandidate, lastWidgetCandidateIndex);
            return widgetWidth > remainingWidth ? false : true;
        }

        /**
         * @param lastWidgetCandidates
         * @param lastWidgetCandidatesIndex
         * @returns {number}
         */
        function getRemainingWidth(lastWidgetCandidates, lastWidgetCandidatesIndex) {
            var maxWidth = WidgetSize.MAX_WIDTH;
            var rightEdge = lastWidgetCandidatesIndex + parseInt(lastWidgetCandidates.width) - 1;
            var heights = getHeightsFromBottom();
            var bottomEdge = heights[lastWidgetCandidatesIndex] - 1;
            var topEdge = bottomEdge - parseInt(lastWidgetCandidates.height) + 1;
            var remainingWidth = maxWidth;
            for (var row = topEdge; row <= bottomEdge; row++) {
                var curRemainingWidth = 0;
                for (var col = rightEdge + 1; col < maxWidth; col++) {
                    if (model[col][row] == 0) {
                        curRemainingWidth++;
                    }
                }
                remainingWidth = Math.min(remainingWidth, curRemainingWidth);
            }
            // for test
            // console.log('remain width',remainingWidth);

            return remainingWidth;
        }

        return groupedWidgets;

    }

    /**
     * Boolean flag to tell us if we are in exporting context
     * @returns {boolean}
     */
    function getIsExporting() {
        return !!backendVars;
    }
}
