import { ColumnFormat } from '@/modules/core/app/constants/data.constants';
import { valueSymbol } from '@/modules/core/charts/am5/charts.helper';
import {
  Constant,
  ELLIPSIS,
  LABEL_CUTOFFS,
  LABEL_PLACEHOLDERS,
  TOOLTIP_DURATION_FORMAT,
  ValueFormatters,
} from '@/modules/core/charts/am5/charts.constants';
import { DurationFormatter, NumberFormatter } from '@amcharts/amcharts5';
import { getters } from '@/modules/core/app/helpers/store';
import { AppModule } from '@/modules/core/app/constants/app.constants';
import { getNumberFormat } from '@/modules/core/charts/amChartHelper';
import { SeriesType } from '@/modules/core/charts/chart.constants';

export function useLabels(context) {
  const { root, config, chart } = context();

  /**
   * Apply labels to series / legend based on config
   * Show labels
   * Show label names
   * Show label values
   * Show label percent
   */
  function handleLabels(series) {
    // if there's no labels then hide the ticks
    if (!config.value.showLabels) {
      series.ticks.template.set(Constant.FORCE_HIDDEN, true);
    }
    const { width } = config.value.get(Constant.WIDGET);
    const { comparisonData } = config.value;

    const baseLabelSettings = {
      text: LABEL_PLACEHOLDERS.VALUE,
      oversizedBehavior: Constant.TRUNCATE,
      maxWidth: comparisonData ? width * 22 : 250,
    };
    let additionalSettings = {};

    // legend means we have a little less room to deal with
    if (config.value.legend.active) {
      additionalSettings = {
        ...additionalSettings,
        paddingTop: 0,
        paddingBottom: 5,
      };
    }

    series.labels.template.setAll({
      ...baseLabelSettings,
      ...additionalSettings,
    });

    formatLabelValues(series);
    hideLabels();
    hideOffScreenLabels();
  }

  /**
   * Apply the label formatter to the template adapters
   */
  function formatLabelValues(series) {
    series.labels.template.adapters.add(Constant.TEXT, (text, target) => {
      const { dataItem } = target;
      if (dataItem) {
        return generateLabelText(dataItem.dataContext);
      }

      return text;
    });
  }

  /**
   * Generate the actual label text
   * @param dataContext {dataItemFormat, name, value}
   * @param override when making the tooltip we always override and return the name
   * @returns {string}
   */
  function generateLabelText(dataContext, override = false) {
    const leftBracket = config.value.showLabelValues ? '(' : '';
    const rightBracket = config.value.showLabelValues ? ')' : '';
    const separator = ' : ';
    const category =
      override && dataContext.tooltipText
        ? LABEL_PLACEHOLDERS.TOOLTIP_TEXT
        : LABEL_PLACEHOLDERS.CATEGORY;

    let labelText = '';

    if (override || config.value.showLabels) {
      if (config.value.showLabelNames) {
        labelText = category;
      }

      // only show the separator when we're showing either the value / percent
      // if we're normalizing then we only show it if we're showing the value, % is disregarded
      if (
        config.value.showLabelNames &&
        (config.value.showLabelValues || config.value.showLabelPercent)
      ) {
        if (config.value.isNormalized) {
          if (config.value.showLabelValues) {
            labelText += separator;
          }
        } else {
          labelText += separator;
        }
      }

      if (config.value.showLabelValues) {
        labelText += getFormatterForType(dataContext.dataItemFormat);
      }

      // early out for normalized as we don't do the %
      if (config.value.isNormalized) {
        return labelText;
      }

      if (config.value.showLabelPercent) {
        labelText = `${labelText} ${leftBracket}${getFormatterForType(
          ColumnFormat.FORMAT_PERCENT
        )}${rightBracket}`;
      }
    }
    return labelText;
  }

  /**
   * Generate tooltip text nevertheless of label constraints
   * @param dataContext {dataItemFormat, name, value}
   * @returns {string}
   */
  function generateToolTipText(dataContext) {
    const separator = ' : ';
    let labelText =
      LABEL_PLACEHOLDERS.TOOLTIP_TEXT + separator + getFormatterForType(dataContext.dataItemFormat);

    // early out for normalized as we don't do the %
    if (config.value.isNormalized) {
      return labelText;
    }
    const percentFormat = getFormatterForType(ColumnFormat.FORMAT_PERCENT);
    labelText = `${labelText} (${percentFormat}}`;

    return labelText;
  }

  function getFormatterForType(type) {
    const field = config.value.isNormalized ? Constant.RAW_VALUE : Constant.VALUE;
    let format = '';

    if (type === ColumnFormat.FORMAT_PERCENT) {
      return LABEL_PLACEHOLDERS.PERCENT;
    }

    switch (type) {
      case ColumnFormat.FORMAT_CURRENCY:
        format = preFixCurrency(ValueFormatters[type].numberFormat);
        break;

      case ColumnFormat.FORMAT_DECIMAL:
      case ColumnFormat.FORMAT_INTEGER:
        format = ValueFormatters[type].numberFormat;
        break;

      default:
        format = ValueFormatters[ColumnFormat.FORMAT_INTEGER].numberFormat;
    }

    return `{${field}.formatNumber('${format}')}`;
  }

  /**
   * Applies the standard label format ready for amcharts5
   * @param label
   * @param value
   * @param type
   * @param row the data row we're dealing with
   * @returns {string}
   */
  function formatLabel(label, value, type, row = {}) {
    let formattedValue;
    if (type === ColumnFormat.FORMAT_TIME) {
      formattedValue = DurationFormatter.new(root.value, {}).format(value, TOOLTIP_DURATION_FORMAT);
    } else {
      const numberFormat = getNumberFormat(value, true);
      formattedValue = NumberFormatter.new(root.value, {}).format(value, numberFormat);
    }
    const labelHeading = label ? `${label}: ` : '';

    if (type === ColumnFormat.FORMAT_CURRENCY) {
      return `${labelHeading}${preFixCurrency(formattedValue, row)}`;
    }
    return `${labelHeading}${formattedValue}${valueSymbol(type)}`;
  }

  /**
   * Prefix the currency symbol to our {value}
   * @param formattedValue
   * @param row
   * @returns {*}
   */
  function preFixCurrency(formattedValue, row = {}) {
    const { currencyDiscrepancy, showCurrency } = config.value;
    let currencySymbol = '';
    if (!getters.session.getUserSettings().isModuleAvailable(AppModule.CLIENT_CURRENCY)) {
      currencySymbol = valueSymbol(ColumnFormat.FORMAT_CURRENCY);
    } else if (!currencyDiscrepancy || (currencyDiscrepancy && showCurrency)) {
      currencySymbol =
        row?.client_currency ||
        config.value.get(Constant.CLIENT_CURRENCY_SYMBOL) ||
        valueSymbol(ColumnFormat.FORMAT_CURRENCY) ||
        '';
    }

    // if already formatted then don't return
    if (currencySymbol === formattedValue.substring(0, currencySymbol.length)) {
      return formattedValue;
    }

    return currencySymbol + formattedValue;
  }

  /**
   * Remove any labels (and their corresponding ticks) for items that fall below the threshold
   * or if we're dealing with a LOT of labels we hide every other label
   */
  function hideLabels(isPieChart = false) {
    chart.value.series.each((series) => {
      series.labels.template.adapters.add(Constant.DISABLED, (disabled, target) => {
        const { dataItem } = target;

        if (dataItem) {
          const tick = dataItem.get(Constant.TICK);
          const hideLabel = shouldHideLabel(dataItem, isPieChart);

          if (hideLabel) {
            target.set(Constant.FORCE_HIDDEN, true);
            if (tick) {
              tick.set(Constant.FORCE_HIDDEN, true);
            }
          } else {
            target.set(Constant.FORCE_HIDDEN, false);
            if (tick && config.value.plotType !== SeriesType.PICTORIAL) {
              tick.set(Constant.FORCE_HIDDEN, false);
            }
          }
          return disabled;
        }
      });
    });
  }

  /**
   * Whether we hide the label or not
   * Covers the % cut-off as well as having too many labels
   * @param dataItem
   * @param isPieChart
   * @returns {boolean}
   */
  function shouldHideLabel(dataItem, isPieChart = false) {
    // short-circuit early out if we're hiding the labels entirely
    if (labelsAreHiddenInConfig() && !isPieChart) {
      return true;
    }

    const cutOffAmount = config.value.labelPct;
    const sliceAmount = isPieChart
      ? config.value.data.currentPeriodData.length
      : config.value.data.length;
    const { index } = dataItem.dataContext;
    let hideLabel = false;

    if (dataItem.dataContext.overrideLabelShow !== undefined) {
      return !dataItem.dataContext.overrideLabelShow;
    }

    if (cutOffAmount > 0) {
      hideLabel = dataItem.get(Constant.VALUE_PCT_TOTAL) < cutOffAmount;
    }

    Object.keys(LABEL_CUTOFFS).forEach((cutOff) => {
      if (sliceAmount > cutOff) {
        if (index % LABEL_CUTOFFS[cutOff] === 1) {
          hideLabel = true;
        }
      }
    });

    return hideLabel;
  }

  /**
   * There's a few permutations that mean "hide labels" in the config
   * @returns {boolean}
   */
  function labelsAreHiddenInConfig() {
    // easiest is the "show labels" selector
    if (!config.value.showLabels) {
      return true;
    }

    // you can also effectively hide the labels by hiding all the separate parts
    return !![
      config.value.showLabelValues,
      config.value.showLabelNames,
      config.value.showLabelPercent,
    ].every((configValue) => !configValue);
  }

  /**
   * Hide any labels that appear out of the bounds of the chart
   */
  function hideOffScreenLabels() {
    chart.value.series.each((series) => {
      series.labels.template.adapters.add(Constant.Y, (y, target) => {
        const { dataItem } = target;
        if (dataItem) {
          const tick = dataItem.get(Constant.TICK);
          if (tick) {
            if (y < 0) {
              tick.set(Constant.FORCE_HIDDEN, true);
            }
          }
          return y;
        }
      });
    });
  }

  /**
   * The built-in truncation function for labels and tooltips
   * also includes any values on the end.  So pre-truncate to make sure things fit
   * @param label
   * @param truncateAt
   */
  function truncateLabel(label, truncateAt = 25) {
    if (label && label.length > truncateAt) {
      label = label.substring(0, truncateAt - ELLIPSIS.length) + ELLIPSIS;
    }

    return label;
  }

  return {
    formatLabel,
    formatLabelValues,
    generateLabelText,
    hideLabels,
    handleLabels,
    preFixCurrency,
    getFormatterForType,
    truncateLabel,
    generateToolTipText,
  };
}
