export const getters = {
  /**
   * @param {SessionState} state
   * @returns {User}
   */
  getUser(state) {
    return state.userSettings ? state.userSettings.user : null;
  },
  /**
   * @param {SessionState} state
   * @returns {boolean}
   */
  isLoggedIn(state) {
    return state.userSettings ? state.userSettings.isLoggedIn : false;
  },
  /**
   * @param {SessionState} state
   * @returns {boolean}
   */
  isLoggingOut(state) {
    return state.status ? state.status.isLoggingOut : false;
  },
  /**
   * @param {SessionState} state
   * @returns {boolean}
   */
  isLoggingIn(state) {
    return !!state.status?.isLoggingIn;
  },
  /**
   * @param {SessionState} state
   * @returns {{}}
   */
  getOnboardingStep(state) {
    return state.userSettings ? state.userSettings.onboardingStep : 1;
  },
  /**
   * @param {SessionState} state
   * @returns {UserSettings}
   */
  getUserSettings(state) {
    return state.userSettings || null;
  },
  /**
   * @param {SessionState} state
   * @returns {?string}
   */
  getThemeColor(state) {
    return state.userSettings?.currentThemeColor;
  },
  /**
   * @param {SessionState} state
   * @returns {boolean}
   */
  getLoginRedirectPath(state) {
    return state.loginRedirectPath || null;
  },
  /**
   * @param {SessionState} state
   * @param {Module|string} feature
   * @returns {boolean}
   */
  isFeatureAvailable(state, feature) {
    return state.userSettings && state.userSettings.isFeatureAvailable(feature);
  },
  /**
   * @param {SessionState} state
   * @param {Module|string} module
   * @returns {boolean}
   */
  isModuleAvailable(state, module) {
    return state.userSettings && state.userSettings.isModuleAvailable(module);
  },
  /**
   * @param {SessionState} state
   * @returns {boolean}
   */
  isSuperAdmin(state) {
    return state.userSettings && state.userSettings.isSuperAdmin();
  },
  /**
   * @param {SessionState} state
   * @returns {boolean}
   */
  isClusterAdmin(state) {
    return state.userSettings && state.userSettings.isClusterAdmin();
  },
  /**
   * @param {SessionState} state
   * @returns {boolean}
   */
  isAdmin(state) {
    return state.userSettings && state.userSettings.isAdmin();
  },
  /**
   * @param {SessionState} state
   * @returns {boolean}
   */
  isAgent(state) {
    return state.userSettings && state.userSettings.isAgent();
  },
  /**
   * @param {SessionState} state
   * @returns {boolean}
   */
  isClient(state) {
    return state.userSettings && state.userSettings.isClient();
  },
  /**
   * @type {SessionState}
   * @returns {BrandMapping}
   */
  getMapping(state) {
    return state.brandMapping;
  },
  /**
   * @type {SessionState}
   * @returns {boolean}
   */
  hasDevTools(state) {
    return state.userSettings?.hasDevTools;
  },
  /**
   * @type {SessionState}
   * @returns {boolean}
   */
  isSuperOrClusterAdmin(state) {
    return state.userSettings?.isSuperAdmin() || state.userSettings?.isClusterAdmin();
  },
  /**
   * @type {SessionState}
   * @returns {boolean}
   */
  isInstanceSuspended(state) {
    return state.userSettings?.isInstanceSuspended();
  },
  /**
   * @type {SessionState}
   * @returns {boolean}
   */
  canCreateUsersBelowOnly(state) {
    return state.userSettings.canCreateUsersBelowOnly();
  },
  /**
   * @type {SessionState}
   * @returns {boolean}
   */
  canCreateUsersAtAndBelow(state) {
    return state.userSettings.canCreateUsersAtAndBelow();
  },

  /**
   * @param state
   * @returns {string|*}
   */
  getClientCurrency(state) {
    return state.clientCurrency;
  },

  /**
   * @param {SessionState} state
   * @returns {boolean}
   */
  useRecaptcha(state) {
    return state.userSettings ? state.userSettings.useRecaptcha : false;
  },

  /**
   * @param {SessionState} state
   * @returns {boolean}
   */
  needsHipaaAcknowledgement(state) {
    return state.userSettings ? state.userSettings.needsHipaaAcknowledgement : false;
  },

  /**
   * @param {SessionState} state
   * @returns {string|null}
   */
  getUserReportLanguage(state) {
    return state.userSettings && state.userSettings.user
      ? state.userSettings.user.report_language
      : null;
  },
};
