export const Locale = {
    EN: 'en',
    FR: 'fr',
    JA: 'ja',
    PLATFORM_DEFAULT: 'p_def'
};

export const LocaleMap = {
    en: 'en_US',
    fr: 'fr_CA',
    ja: 'ja_JP',
}
