'use strict';

// CSS Imports
import './plugins/bootstrap/bootstrap.css';
import './modules/shared/styles/main.less';
import '../../analytics/app/modules/shared/styles/app.less';

import './main';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
import 'angular-sanitize';
import 'restangular';
import 'angular-ui-router';
import 'angular-loading-bar';
import 'angular-gettext';
import 'angular-summernote/dist/angular-summernote';
import 'ng-img-crop/compile/unminified/ng-img-crop';
import 'angularjs-slider';
import 'angular-ui-sortable';
import { v4 as uuidv4 } from 'uuid';

// importing core modules
import './modules/shared/core.shared.module';
import './modules/shared/scripts/app.ctrls';
import './modules/connect/connect.module';
import './modules/connect/legacy/connectservices';
import './modules/billing/billing.module';
import './modules/cluster/cluster.module';
import './modules/preferences/preferences.module';
import './modules/role/role.module';
import './modules/role/legacy/role';
import './modules/feedbacks/feedbacks.module';
// importing analytics modules
import '../../analytics/app/modules/shared/app.shared.module';
import '../../analytics/app/modules/dashboard/dashboard.module';
import '../../analytics/app/modules/dashboard/legacy/leads';
import '../../analytics/app/modules/overview/overview.module';
import '../../analytics/app/modules/overview/legacy/overview';
import '../../analytics/app/modules/servicedetails/servicedetails.module';
import '../../analytics/app/modules/servicedetails/legacy/details';
import '../../analytics/app/modules/calculations/calculations.module';
import '../../analytics/app/modules/campaign/campaign.module';
import '../../analytics/app/modules/campaign/legacy/campaigns';
import '../../analytics/app/modules/importwizard/importwizard.module';
import '../../analytics/app/modules/reportbuilder/reportbuilder.module';
import '../../analytics/app/modules/reportbuilder/legacy/html';
import '../../analytics/app/modules/reportbuilder/legacy/pdf';
import '../../analytics/app/modules/reportbuilder/legacy/report-builder';
import '../../analytics/app/modules/scheduledreport/scheduledreport.module';
import '../../analytics/app/modules/scheduledreport/legacy/scheduled-reporting';
import '../../analytics/app/modules/marginrule/marginrule.module';
import '../../analytics/app/modules/reportingprofile/legacy/reporting-profiles';
import '../../analytics/app/modules/reportingprofile/legacy/reporting-profile';

import { LoginRedirect } from 'coreModules/login/login.constants';

import DevTools from './devtools';
import oAuth from './oauth';
import {InstanceStatus} from 'coreModules/shared/scripts/app.constants';
import UuidService from "../../grok/src/modules/core/app/services/UuidService";
import { LoginState } from "./modules/login/login.constants";

var Globals = $.globals;
var Main = $.core.main;

angular.module('app', [
    /* Angular modules */
    'ngSanitize',
    'restangular',
    'ui.router',
    'angular-loading-bar',
    'gettext',
    'summernote',
    'ngImgCrop',
    'rzModule',
    'ui.sortable',

    /* Core modules */
    'core.shared',
    // Needs to be kept seperate until IO & analytics merge
    'core.app.ctrls',

    'connect',
    'billing',
    'cluster',
    'preferences',
    'role',
    'feedbacks',

    /* Analytics modules */
    'analytics.shared',
    'legacydashboards',
    'overview',
    'servicedetails',
    'calculations',
    'campaign',
    'importwizard',
    'reportbuilder',
    'scheduledreport',
    'marginrule'
])

    // Making lodash accessible across the app as well as unit tests
    .constant('_', _)

    // Removes the adding of ng-scope classes used for debugging in Batarang, Protractor and ng-inspector
    .config(['$compileProvider', function ($compileProvider) {
        var isDevEnv = window.location.pathname.contains('/app/');
        $compileProvider.debugInfoEnabled(isDevEnv);
        $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|file|javascript):/);
    }])

    // Configure loading bar
    .config(['cfpLoadingBarProvider', function(cfpLoadingBarProvider) {
        cfpLoadingBarProvider.latencyThreshold = 500;
        cfpLoadingBarProvider.includeSpinner = false;
    }])

    // Configure restangular
    .config(['RestangularProvider', function(RestangularProvider) {
        RestangularProvider.setBaseUrl('/server/api');
        // add a response intereceptor
        RestangularProvider.addResponseInterceptor(function(data, operation, what, url, response, deferred) {
            var extractedData;
            if (data.metadata) {
                // .. and handle the data and meta data
                extractedData = data.metadata.columns;
            }
            // Since getList expects an array, then we need to wrap
            // result in an array and unwrap using _.first() wherever used
            else if(_.isObject(data) && operation === 'getList') {
                // If sEcho is set then data being returned is for server side datatable
                extractedData = !_.isUndefined(data.data.sEcho) ? [data.data] : _.values(data.data);

                // Add comparison data if any
                if (data.has_comparison_data) {
                    extractedData.has_comparison_data = data.has_comparison_data;
                }

                // Add total data if any
                if (data.total_data) {
                    // Total data always only contains one item
                    extractedData.total_data = _.first(data.total_data);
                }

                // Add extra data if any
                if (data.extra_data) {
                    extractedData.extra_data = data.extra_data;
                }
            }
            else {
                extractedData = data.data;
            }

            if ('currency_discrepancy' in data) {
                extractedData.$$currency_discrepancy = data.currency_discrepancy
            }

            if(data?.query_id) {
                UuidService.remove(data.query_id);
            }

            return extractedData;
        });
        RestangularProvider.addFullRequestInterceptor(function(element, operation, route, url, headers, params, httpConfig) {
            //This allows to convert the form data to support file uploads
            headers['Content-Type'] = undefined;
            headers['browser-location'] = window.location.href;
            if (!_.isEmpty(element)) {
                var formData = new FormData();
                formData.append('model', angular.toJson(element));
                if (element.files != null) {
                    for (var i = 0; i < element.files.length; i++) {
                        formData.append(element.files[i].key, element.files[i]);
                    }
                }
            }

            // DEV ONLY - Store all requests to be able to expose them later and replay them
            if (DevTools.isEnabled()) {
                // Always return profiler payload in dev mode
                if (operation === 'get' || operation === 'getList') {
                    params.sql = 1;
                    DevTools.logRequest(url, params);
                }
            }
            if (operation === 'get' || operation === 'getList') {
                const id = uuidv4();
                UuidService.add(id);
                params.query_id = id;
            }
            return {
                element: formData,
                params: params,
                headers: headers
            };
        });
    }])

    .config(['$provide', '$httpProvider', function ($provide, $httpProvider) {

        // Intercept http calls.
        $provide.factory('AppHttpInterceptor',
            [
                '$q',
                '$window',
                '$injector',
                '$rootScope',
                '$location',
                'UserLoginFactory',
                function (
                    $q,
                    $window,
                    $injector,
                    $rs,
                    $location,
                    UserLoginFactory
                ) {
                    return {
                        // On request success
                        request: function (config) {
                            // Return the config or wrap it in a promise if blank.
                            return config || $q.when(config);
                        },
                        // On request failure
                        requestError: function (rejection) {
                            // Return the promise rejection.
                            return $q.reject(rejection);
                        },
                        // On response success
                        response: function (response) {
                            var json = response.data;

                            // allows instance-specific custom redirection on request response.
                            if (typeof json?.data?.custom_redirect === 'string') {
                                window.location.href = json.data.custom_redirect;
                                window.location.reload();
                                return false;
                            }

                            if (json.status != null && json.status == 'redirect') {
                                Main.notify(json.message, json.type);
                                $injector.get('$state').go(json.state);
                            }
                            else if (DevTools.isSQLError(json)) {
                                Main.notify('There seems to be an issue on our end. Please contact support.', Globals.notify.warning);
                            }

                            var config = response.config;
                            if (DevTools.isEnabled()) {
                                if (json.error || DevTools.isErrorString(json)) {
                                    // Errors with success status codes
                                    DevTools.logError(config.url, config.params, json);
                                }
                                // Only log responses we are explicitely profiling
                                else {
                                    if (config.params && 'sql' in config.params) {
                                        DevTools.logResponse(config.url, config.params, json);
                                    }
                                }
                            }

                            // Append any warnings that could have been sent back
                            if (!_.isUndefined(json.warnings) && !_.isEmpty(json.warnings)) {
                                Main.notifyMultiple(json.warnings, Globals.notify.info);
                            }

                            // Return the response or promise.
                            return response || $q.when(response);
                        },
                        // On response failure
                        responseError: function (rejection) {
                            // Return the promise rejection.
                            var json = rejection.data;
                            const status = json.status || rejection.status;

                            if (!_.isNull(json)) {
                                // Unauthorized access
                                if (status == 401) {
                                    // Redirect user to login page
                                    $rs.query_params = $location.search();
                                    if (!_.includes(['forgotpassword', 'resetpassword', 'ez_login_redirect', 'ez_login', 'google_login'], $injector.get('$state').current.name)) {
                                        sessionStorage.setItem(LoginRedirect.REDIRECT_URL_KEY, $location.absUrl());
                                        $injector.get('$state').go('login');
                                    }
                                    Main.formErrorNotify(json);
                                }
                                else if (status == 403) {
                                    var route = UserLoginFactory.getDefaultHomeRoute($rs.user);
                                    $injector.get('$state').go(route.state, route.params, route.options);
                                    Main.formErrorNotify(json);
                                }
                                else if (status == 404) {
                                    if (rejection.method === 'GET') {
                                        var route = UserLoginFactory.getRedirectRoute($rs.user);
                                        $injector.get('$state').go(route.state, route.params, route.options);
                                    }
                                    Main.formErrorNotify(json);
                                }
                                else if (status == 500) {
                                    Main.notify('There seems to be an issue on our end. Please contact support.', Globals.notify.warning);
                                }
                                else { //json.status == 400 && magento + exceptions
                                    //This is to support PHP throw new Exception
                                    if (json.msg) {
                                        Main.formErrorNotify(json.msg);
                                    }
                                    else if (json.data) {
                                        //This handles form  errors
                                        Main.formErrorNotify(json);
                                    }
                                }

                                // DEV ONLY - store widget server api errors
                                if (DevTools.isEnabled()) {
                                    var config = rejection.config;
                                    var response = json.data || json.msg || json;
                                    DevTools.logError(config.url, config.params, response);
                                }
                            }

                            // Append any warnings that could have been sent back
                            if (!_.isUndefined(rejection.warnings) && !_.isEmpty(rejection.warnings)) {
                                Main.notifyMultiple(rejection.warnings, Globals.notify.warning, {autoHide: false});
                            }

                            return $q.reject(rejection);
                        }
                    };
                }]);

        // Add the interceptor to the $httpProvider.
        $httpProvider.interceptors.push('AppHttpInterceptor');

    }])

    .config([
        '$stateProvider',
        '$urlRouterProvider',
        'AppConfig',
        function (
            $stateProvider,
            $urlRouterProvider,
            AppConfig
        ) {

            var switchApp = ['$stateParams', function ($stateParams) {
                window.location = AppConfig.IO_URL + '#' + $stateParams.url + ($stateParams.id ? '/' + $stateParams.id : '') ;
            }];
            var switchIOClientApp = ['$stateParams', function ($stateParams) {
                window.location = AppConfig.IO_CLIENT_URL + '#' + $stateParams.url + ($stateParams.id ? '/' + $stateParams.id : '') ;
            }];

            $stateProvider
                .state('dashio', {params: {url: '/dashio', id: ':id'}, resolve: {isIOToolRoute: switchApp}})
                .state('io_orders', {params: {url: '/items/orders'}, resolve: {isIOToolRoute: switchApp}})
                .state('io_detailedOrders', {params: {url: '/items/detailedOrders'}, resolve: {isIOToolRoute: switchApp}})
                .state('io_addOrder', {params: {url: '/items/addOrder'}, resolve: {isIOToolRoute: switchApp}})
                .state('io_cancelReasons', {params: {url: '/admin/cancelReasons'}, resolve: {isIOToolRoute: switchApp}})

                .state('myTasks', {params: {url: '/items/myTasks'}, resolve: {isIOToolRoute: switchApp}})
                .state('myQueues', {params: {url: '/items/myQueues'}, resolve: {isIOToolRoute: switchApp}})
                .state('queueManager', {params: {url: '/items/queueManager'}, resolve: {isIOToolRoute: switchApp}})
                .state('creativeLibrary', {params: {url: '/items/creativeLibrary'}, resolve: {isIOToolRoute: switchApp}})
                .state('pixelManager', {params: {url: '/items/pixelManager'}, resolve: {isIOToolRoute: switchApp}})
                .state('viewWorkflowClient', {params: {url: '/items/workflowclient'}, resolve: {isIOToolRoute: switchApp}})

                .state('notificationsCurrent', {params: {url: '/notifications'}, resolve: {isIOToolRoute: switchApp}})
                .state('notificationsArchived', {params: {url: '/notifications'}, resolve: {isIOToolRoute: switchApp}})

                .state('inventoryCalendar', {params: {url: '/admin/inventoryCalendar'}, resolve: {isIOToolRoute: switchApp}})
                .state('crmConnections', {params: {url: '/admin/crmConnections'}, resolve: {isIOToolRoute: switchApp}})
                .state('products', {params: {url: '/admin/products'}, resolve: {isIOToolRoute: switchApp}})
                .state('customers', {params: {url: '/admin/customers'}, resolve: {isIOToolRoute: switchApp}})
                .state('adminQueues', {params: {url: '/admin/queues'}, resolve: {isIOToolRoute: switchApp}})
                .state('adminTasks', {params: {url: '/admin/tasks'}, resolve: {isIOToolRoute: switchApp}})
                .state('lookupTypes', {params: {url: '/admin/lookupTypes'}, resolve: {isIOToolRoute: switchApp}})
                .state('workflows', {params: {url: '/admin/workflows'}, resolve: {isIOToolRoute: switchApp}})
                .state('stepGroups', {params: {url: '/admin/stepGroups'}, resolve: {isIOToolRoute: switchApp}})
                .state('ruleSet', {params: {url: '/admin/tapIORuleSet'}, resolve: {isIOToolRoute: switchApp}})
                .state('formList', {params: {url: '/admin/formList'}, resolve: {isIOToolRoute: switchApp}})
                .state('ioclients', {params: {url: '/admin/ioclients'}, resolve: {isIOToolRoute: switchApp}})

                .state('reportOrders', {params: {url: '/reporting/orders'}, resolve: {isIOToolRoute: switchApp}})
                .state('lineItemReports', {params: {url: '/reporting/lineItems'}, resolve: {isIOToolRoute: switchApp}})
                .state('tasksReports', {params: {url: '/reporting/tasks'}, resolve: {isIOToolRoute: switchApp}})
                .state('masterReport', {params: {url: '/reporting/master'}, resolve: {isIOToolRoute: switchApp}})
                .state('orderHistoryReport', {params: {url: '/reporting/orderHistory'}, resolve: {isIOToolRoute: switchApp}})
                .state('lineItemHistoryReport', {params: {url: '/reporting/lineItemHistory'}, resolve: {isIOToolRoute: switchApp}})
                .state('slotBookingReport', {params: {url: '/reporting/slotBookings'}, resolve: {isIOToolRoute: switchApp}})
                .state('integrations', {params: {url: '/admin/integrations'}, resolve: {isIOToolRoute: switchApp}})
                .state('managementTools', {params: {url: '/admin/tools'}, resolve: {isIOToolRoute: switchApp}})
                .state('nightlyStatus', {params: {url: '/admin/nightlyStatus'}, resolve: {isIOToolRoute: switchApp}})
                .state('exceptionLogs', {params: {url: '/admin/exceptionLogs'}, resolve: {isIOToolRoute: switchApp}})
                .state('userAvailability', {params: {url: '/userAvailability'}, resolve: {isIOToolRoute: switchApp}})
                .state('calendarSlots', {params: {url: '/admin/calendar/slots'}, resolve: {isIOToolRoute: switchApp}})
                .state('scheduledJobs', {params: {url: '/admin/scheduled/jobGroups'}, resolve: {isIOToolRoute: switchApp}})
                .state('buySheetConfig', {params: {url: '/admin/buySheetConfig'}, resolve: {isIOToolRoute: switchApp}})
                .state('ioServiceConnections', {params: {url: '/admin/ioServiceConnections'}, resolve: {isIOToolRoute: switchApp}})

                .state('vendorRegistration', {params: {url: '/register'}, resolve: {isIOToolRoute: switchApp}})
                .state('io_clientDash', {params: {url: '/'}, resolve: {isIOToolRoute: switchIOClientApp}})
                .state('io_ioOrders', {params: {url: '/items/addOrder'}, resolve: {isIOToolRoute: switchIOClientApp}});

            //We need to ignore routing if we are exporting to pdf to avoid loading layout views
            var isExportRoute = function() {
                return AppConfig.ROOT_URL == '/app/dash/reporting/export_dashboard';
            };

            if (!isExportRoute()) {
                $urlRouterProvider.otherwise('/');
            }
        }])
    .run([
        '$rootScope',
        '$state',
        '$timeout',
        '$location',
        '$window',
        'PubSub',
        'RouteEvents',
        'AppConfig',
        'CoreFactory',
        'AppFactory',
        'LoginFactory',
        'ServiceFactory',
        'DesignFactory',
        'UIFactory',
        'Restangular',
        'UserLoginFactory',
        'LanguageService',
        'ExportFactory',
        'WindowUtilUIService',
        'AppModule',
        'UserFactory',
        function ($rootScope,
                  $state,
                  $timeout,
                  $location,
                  $window,
                  PubSub,
                  RouteEvents,
                  AppConfig,
                  CoreFactory,
                  AppFactory,
                  LoginFactory,
                  ServiceFactory,
                  DesignFactory,
                  UIFactory,
                  Restangular,
                  UserLoginFactory,
                  LanguageService,
                  ExportFactory,
                  WindowUtilUIService,
                  AppModule,
                  UserFactory
        ) {
            //Legacy root url support for $.fn.actionUrl for the magento api url 'app/dash'
            Globals.utils.rootUrl = AppConfig.MAGE_URL;
            Globals.utils.appUrl = AppConfig.ROOT_URL;
            Globals.utils.apiUrl = AppConfig.API_URL;
            Globals.utils.taUrl = AppConfig.TA_URL;
            Globals.utils.ioUrl = AppConfig.IO_URL;
            window.isNUI = false;

            var params = $location.search();
            if (!_.isUndefined(params.oauth_client_id) && !_.isUndefined(params.oauth_redirect_uri)) {
                // set OAuth information globally
                oAuth.clientId = params.oauth_client_id;
                oAuth.redirect = params.oauth_redirect_uri;
                oAuth.icon = !_.isUndefined(params.oauth_icon) ? params.oauth_icon : null;
                oAuth.state = !_.isUndefined(params.oauth_state) ? params.oauth_state : null;

                window.localStorage.setItem('oauth_request', JSON.stringify(params))
                // remove the query parameters in the url
                $location.url($location.path());
            }

            $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromParams, options, error) {
                Main.notify('Something went wrong. We are looking into it.', Globals.notify.error);
                console.log(error);
            });

            $rootScope.$on('$stateChangeStart', function (event, toState, toParams) {
                const TARoutesInTO = ['users', 'users.filter', 'users.add', 'users.addfromclient', 'users.detail',
                    'clients', 'clients.filter', 'clientDash', 'clients.add', 'clients.detail',
                    'roles', 'roles.add', 'roles.detail',
                    'clusters', 'clusters.filter', 'clusters.add', 'clusters.detail', 'clusters.services',
                    'preferences', 'overview'];
                UuidService.kill();
                //This clears non-persisted datatables from the oTables object array
                PubSub.emit(RouteEvents.CHANGEROUTE);
                $.core.datatable.resetTables();
                if (toState.name !== 'login'
                    && toState.name !== 'remote_login'
                    && toState.name !== 'google_login'
                    && toState.name !== 'ez_login'
                    && toState.name !== 'ez_login_redirect'
                    && toState.name !== 'forgotpassword'
                    && toState.name !== 'resetpassword') {

                    if (!_.isEmpty(AppFactory.getUser())) {
                      UserLoginFactory.setIntendedState(toState.name, toParams);
                    }

                    if (typeof toState.name !== 'undefined') {
                        document.cookie = "from_google_login_redirect_state="+toState.name+"; path=/";
                    }
                    if (typeof toParams.id !== 'undefined') {
                        document.cookie = "from_google_login_redirect_params="+toParams.id+"; path=/";
                    }

                }

                //Make sure if user is suspended for payment or inactive that they can only navigate to these pages
                if (($rootScope.user.instanceStatus == InstanceStatus.SUSPENDED_UNPAID || $rootScope.user.instanceStatus == InstanceStatus.SUSPENDED_INACTIVE) && $rootScope.util.isSuperAdmin()) {
                    if (toState.name != 'billing' && toState.name != 'billing.address' && toState.name != 'billing.creditcard' && ['login', 'logout', 'loginlayout'].indexOf(toState.name) === -1) {
                        $timeout(function() {
                            $state.go('billing');
                        });
                    }
                }
                /**
                 * This condition applies when you are in analytics app of IO_TOOL and you try to access any route
                 * that isn't part of the allowed routes in IO_TOOL. We should redirect him to the new app when
                 * DISABLE_OLD_UI switch is enabled.
                 */
                const allowedIoRedirect = document.referrer.contains(AppConfig.IO_URL) && _.includes(TARoutesInTO, toState.name) && $location.absUrl().contains(AppConfig.TA_PRE_URL);
                if (![LoginState.EZLOGIN_REDIRECT, LoginState.EZLOGIN].includes(toState.name) && !allowedIoRedirect && $rootScope.user && (AppFactory.mustGoToNewApp($rootScope.user) || _.includes($rootScope.user.availableModules, AppModule.DISABLE_OLD_UI))
                    && !(oAuth.clientId && oAuth.redirect)) {
                    WindowUtilUIService.navigateToNewApp();
                }
            });

            $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
                _trackRouterStateChanges({event: event, toState: toState, toParams: toParams, fromState: fromState, fromParams: fromParams});

                //Check if overview which is home state else use state name
                var viewName = toState.name;
                var viewId = toParams.id;
                var fromStateName = fromState.name;

                if (typeof dataLayer != 'undefined') {
                    dataLayer.push({
                        currentUrl: window.location.href,
                        destination: toState.url,
                        event: 'stateChange'
                    });
                }

                //Validate if feature is accessible
                // NOTE: (cannot be placed in $stateChangeStart since when reloading the page the user object cannot yet be verified)
                if (viewName === 'categories' || viewName === 'categories.detail' || viewName === 'categories.metric') {
                    if (!$rootScope.util.isSuperAdmin()) {
                        Main.notify('You do not have access to this feature. Please contact your account manager to discuss your options.', Globals.notify.error);
                        $timeout(function () {
                            $state.go('overview');
                        });
                    }
                }

                // Certain events need a timeout for the element to be on page before applying anything on it
                $timeout(function(){
                    var $body = $('body');
                    var $rightFrame = $('#right-frame');

                    Main.hideAjaxMainLoading();

                    Main.toggleDashFilters();

                    Main.highlightCurrentNavMenu(viewName, viewId);

                    if (viewName !== 'login') {
                        if (!_.isNull(oAuth.clientId)) {
                            LoginFactory.getOAuthAuthorizationCode();
                        }
                    }

                    //If we just generated a dashboard from service mapper
                    //We will need to bind nav menu since onboarding was being shown
                    if (viewName.contains('overview') && fromStateName.contains('service.manage') && $rootScope.user.justOnboarded) {
                        $timeout(function() {
                            Main.navMenu();
                        },0, false);
                    }

                    if (!viewName.contains('service.manage')) {
                        Main.hideFooter();
                    }

                    // Legacy support for tooltips
                    $rightFrame.focus(); //Allow user to scroll using arrow keys
                    $('.tooltip').tooltip('hide');
                    Main.tooltip($rightFrame);

                    // Ensure that when we load a new state/page, we are at the top of the screen
                    $rightFrame.scrollTop(0);

                    // In case was in dash page previsouly
                    $rightFrame.removeClass('page-dash');

                    // Apply proper offset if viewing as impersonated user
                    if ($rootScope.user.isImpersonationMode) {
                        $body.addClass('impersonation-mode');
                    }
                    else {
                        $body.removeClass('impersonation-mode');
                    }

                    if ($rootScope.redirector != null) {
                        $rootScope.redirector.show = false;
                    }
                });
            });

            //App util functions
            $rootScope.util = {
                rootUrl: function(path) {
                    path = angular.isUndefined(path) ? '' : path;
                    return AppConfig.ROOT_URL + path;
                },
                mageUrl: function(path) {
                    path = angular.isUndefined(path) ? '' : path;
                    return AppConfig.MAGE_URL + path;
                },
                apiUrl: function(path) {
                    path = angular.isUndefined(path) ? '' : path;
                    return AppConfig.API_URL + path;
                },
                isSuperAdmin: function() {
                    return $rootScope.user.type == Globals.user.type.superadmin;
                },
                isClusterAdmin: function() {
                    return $rootScope.user.type == Globals.user.type.clusteradmin;
                },
                isAgent: function() {
                    return $rootScope.user.type == Globals.user.type.agent;
                },
                isClient: function() {
                    return $rootScope.user.type == Globals.user.type.client;
                },
                isFeatureAvailable: function($featureName) {
                    return $rootScope.user.availableFeatures && $rootScope.user.availableFeatures.contains($featureName);
                },
                isModuleAvailable: function($moduleName) {
                    return $rootScope.user.availableModules && $rootScope.user.availableModules.contains($moduleName);
                }
            };

            //Initialize the user with some backend data (this occurs before login as well)
            $rootScope.user = {};

            if (window.useAdobePptConverter) {
                import(/* webpackChunkName: "adobe-export-fonts" */ 'coreModules/export/fonts/adobe.export.fonts.css');
            }

            // ToDo: remove this when OWA is no longer in production
            if (window.dashExport) {
                // see export_dashboard.phtml (OWA) for the script that will set this parameter
                // see dash_export.phtml (NWA)
                DesignFactory.setIsExportingPage(true);
                return;
                // we break here so that we do not make an extra call to initUser if we are exporting the page
            }

            $timeout(() => {
                LoginFactory.initUser().then(async (json) => {
                    const settings = json.data.settings;
                    const mustGoToNewApp = AppFactory.mustGoToNewApp(settings);

                    if (!ExportFactory.getIsExporting()) {
                        /**
                         * Do not redirect to new app if mustGoToNewApp(if IOAuthorized) returns false
                         * and the disable OLD UI switch is on and current TA route is shared between IO_TOOL and TA.
                         */
                        if (!(oAuth.clientId && oAuth.redirect)) {
                            const allowedIoRedirect = document.referrer.contains(AppConfig.IO_URL) && ['/users', '/clients', '/preference', '/clusters', '/roles'].some(path => $location.absUrl().contains(path)) && $location.absUrl().contains(AppConfig.TA_PRE_URL);
                            if (![LoginState.EZLOGIN_REDIRECT, LoginState.EZLOGIN].includes(this.toState.name) && !allowedIoRedirect && (mustGoToNewApp || _.includes(settings.availableModules, AppModule.DISABLE_OLD_UI))) {
                                WindowUtilUIService.navigateToNewApp();
                            }
                        }
                        if (!mustGoToNewApp) {
                            await UserFactory.updateAppPreference({use_nui: false});
                            // Notify user if status message from backend is being sent in the settings object
                            if (settings.statusMessage != null) {
                                var statusMessage = settings.statusMessage.isJSON() ? JSON.parse(settings.statusMessage) : null;
                                if (statusMessage != null) {
                                    Main.notify(statusMessage.message, $.globals.notify.warning, {autoHideDelay: 30000});
                                }
                            }
                        }
                    }

                    //Initialize i18n, load if required
                    LanguageService.setApplicationLanguage(settings);
                });
            }, 100);

            _detectBrowserBackButtonPressed();
            _trackRouterStateChanges();
            _checkAppLoadedInAnIframe();

            function _checkAppLoadedInAnIframe() {
                if(window.location !== window.top.location) {
                    let requestingURL = document.referrer;
                    if(requestingURL === "" && !_.isUndefined(window.location.ancestorOrigins)) {
                        requestingURL = window.location.ancestorOrigins[0];
                    }
                    Restangular.one('callerInfo?url='+requestingURL).get(); // Logging caller Info to detect which clients are using our app in an iframe.
                }
            }

            function _trackRouterStateChanges(event) {
                UserLoginFactory.setEventState(event);
            }

            function _detectBrowserBackButtonPressed() {
                $rootScope.$on('$locationChangeSuccess', function() {
                    $rootScope._actualLocation = $location.absUrl();
                });

                $rootScope.$watch(function () {return $location.absUrl()}, function (newLocation, oldLocation) {
                    if($rootScope._actualLocation === newLocation) {
                        UIFactory.hideModal();
                    }
                });
            }
        }]);
