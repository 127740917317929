'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
import tapDbVizBlockerHtmlUrl from './tapdbviz.blocker.html';

angular.module('core.tapdbviz.components', [])

    .controller('TapDbVizController', TapDbVizController)
    .component('tapDbVizBlocker', {
        templateUrl: tapDbVizBlockerHtmlUrl,
        controllerAs: 'vm',
        controller: TapDbVizBlockerController
    });

function TapDbVizController (
    tableNames,
    TapDbVizResource,
    DesignFactory,
    WidgetFactory,
    WidgetTypeGrouping
) {
    var vm = this;
    vm.dash = null;
    vm.state = {
        isLoading: false
    };
    vm.select = {};
    vm.select.options = {
        width: '400px',
        placeholder: 'Select a table...',
        allowClear: false,
    };
    vm.select.values = _.map(tableNames, function(tableName) {
        return {id: tableName, text: tableName};
    });
    vm.select.onChange = function(ui, event) {
        vm.dash = null;
        vm.state.isLoading = true;
        TapDbVizResource.getDashboard(event.val).then(function(json) {
            vm.state.isLoading = false;
            vm.dash = json.plain();
            var page = vm.dash.page;
            DesignFactory.setCurrentPage(page);
            $('#right-frame').addClass('page-dash');
            DesignFactory.setCurrentLayoutId(1);
        });
    }

    WidgetFactory.getColumnValues('type').then(function(data) {
        WidgetFactory.setWidgetTypes(data.plain());
    });
}

function TapDbVizBlockerController() {
    var vm = this;
    vm.password = null;
    vm.checkPassword = checkPassword;
    vm.$postLink = $postLink;

    function $postLink() {
        $('#tapdbviz-blocker').modal({
            show: true,
            keyboard: false,
            backdrop: 'static'
        });
    }

    function checkPassword(password) {
        // password is tapdev!
        if (hashCode(password) === -1540213553) {
            $('#tapdbviz-blocker').modal('hide');
        }
    }

    function hashCode(s) {
        return s.split("").reduce(function(a,b){a=((a<<5)-a)+b.charCodeAt(0);return a&a},0);
    }
}