'use strict';
import angular from 'angular';

angular.module('core.taptester.components', [])

    .controller('TapTesterController', TapTesterController);

function TapTesterController (
    AppConfig
) {
    var vm = this;
    vm.launchTestSuite = launchTestSuite;
    vm.launchTestGroup = launchTestGroup;

    function launchTestSuite(suite) {
        window.open(AppConfig.API_URL + 'taplabs/taptester/suite/' + suite, '_blank');
    }

    function launchTestGroup(group) {
        window.open(AppConfig.API_URL + 'taplabs/taptester/group/' + group, '_blank');
    }
}