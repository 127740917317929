import { useRadarHand } from '@/modules/core/charts/am5/gauge/composables/useRadarHand';
import { useGaugeAxes } from '@/modules/core/charts/am5/gauge/composables/useGaugeAxes';
import { useTitle } from '@/modules/core/charts/am5/bar/composables/useTitle';
import { useAxisLessTooltip } from '@/modules/core/charts/am5/gauge/composables/useAxisLessTooltip';
import { useGradient } from '@/modules/core/charts/am5/base/composables/fills/useGradient';
import { useLabels } from '@/modules/core/charts/am5/base/composables/series/useLabels';
import { AxisRendererCircular } from '@amcharts/amcharts5/radar';
import { ValueAxis } from '@amcharts/amcharts5/xy';
import { Legend, p50, percent } from '@amcharts/amcharts5';
import {
  Constant,
  GAUGE_TOO_MANY_HANDS_FOR_BIG_NUMBER,
  GaugeBigNumberPosition,
  LABEL_PLACEHOLDERS,
} from '@/modules/core/charts/am5/charts.constants';
import {
  GradientTargets,
  HandTypes,
  WidgetBackgroundGradientColorOptions,
} from '@/modules/ta/widget/widget.constants';
import { GaugeHand } from '@/modules/core/charts/am5/gauge/models/GaugeHand';
import { ColumnFormat } from '@/modules/core/app/constants/data.constants';
import { GaugeExtent } from '@/modules/core/charts/models/GaugeExtent';
import { useNumberFormatter } from '@/modules/core/charts/am5/gauge/composables/useNumberFormatter';
import { useAxisRange } from '@/modules/core/charts/am5/gauge/composables/useAxisRange';
import { useNoticeIcon } from '@/modules/core/charts/am5/base/composables/useNoticeIcon';

export function useMultiAxes(context) {
  const { root, config } = context();
  const { createRadarHand } = useRadarHand(context);
  const { getChartValue } = useGaugeAxes(context);
  const { addTitle } = useTitle(context);
  const { addCursor, createTooltip } = useAxisLessTooltip(context);
  const { createLinearGradient, shouldApplyGradient } = useGradient(context);
  const { preFixCurrency, truncateLabel } = useLabels(context);
  const { formatNumber, getAxisFormatter } = useNumberFormatter(context);
  const { addAxisRange } = useAxisRange(context);
  const { createNoticeIcon } = useNoticeIcon(context);
  let isSingleAxis = false;

  function addMultiAxes() {
    if (!config.value.axisRanges) {
      return;
    }

    createGaugeChart();
    if (config.value.comparisonEnabled) {
      createGaugeChart(true);
    }
  }

  function createGaugeChart(isComparison = false) {
    // if every axis range is type percent then we only show the one semicircle
    // otherwise we show two and point the hand to each
    const axisExtents = createExtents(
      isComparison ? config.value.comparisonAxisRanges : config.value.axisRanges
    );
    let legendData = [];

    // reverse the extents so we always end up with the outer rim on the top
    axisExtents.reverse();

    // loop over the axisExtents here and create axis / renderer for each
    axisExtents.forEach((extent) => {
      const renderer = createRenderer(extent, isComparison);
      legendData = [...legendData, ...createAxis(renderer, extent, isComparison)];
    });

    if (config.value.hasLegend) {
      let legendYPos = config.value.bigNumberIsBelowGauge() ? 100 : 90;
      if (config.value.comparisonEnabled) {
        legendYPos -= 10;
      }

      const legend = getChartValue(isComparison).children.push(
        Legend.new(root.value, {
          nameField: Constant.NAME,
          fillField: Constant.COLOR,
          strokeField: Constant.COLOR,
          centerX: p50,
          x: p50,
          y: percent(legendYPos),
          opacity: isComparison ? 0.6 : 1,
          layout: config.value.comparisonEnabled
            ? root.value.verticalLayout
            : root.value.horizontalLayout,
        })
      );

      legend.data.setAll(legendData.reverse());
    }

    if (isComparison) {
      addPeriodLabel(false);
      addPeriodLabel(true);
    }
  }

  function createExtents(axisRange) {
    // if every axis range is type percent then we only show the one semicircle
    const allPercent = axisRange.every((range) => range.format === ColumnFormat.FORMAT_PERCENT);
    if (allPercent) {
      return [
        new GaugeExtent({
          valueFrom: 0,
          valueTo: 100,
          inside: false,
          fill: config.value.backgroundColor,
          ranges: axisRange,
          isSingleAxis: true,
        }),
      ];
    }

    // also if every range has the same to value then we just return one
    const maxExtents = getMaximumExtents(axisRange);
    const gaugeExtents = [];
    const extentLength = Object.values(maxExtents).length;
    isSingleAxis = extentLength === 1;

    Object.values(maxExtents).forEach((extent, extentIndex) => {
      gaugeExtents.push(
        new GaugeExtent({
          valueFrom: 0,
          valueTo: extent,
          inside: extentIndex > 0,
          fill: extentLength === 0 ? config.value.backgroundColor : axisRange[extentIndex].fill,
          ranges: isSingleAxis ? axisRange : axisRange[extentIndex],
          isSingleAxis,
        })
      );
    });

    return gaugeExtents;
  }

  /**
   * For each axis range we round up the values to the nearest multiple as outlined in the PRD
   * if we end up with two of the same values then we just want to have a single axis
   * @returns {{}}
   */
  function getMaximumExtents(axisRanges) {
    const maxExtents = {};
    let format;

    axisRanges.forEach((axisRange, index) => {
      format = axisRange.format;
      let roundedUpValue = 100;
      if (axisRange.format !== ColumnFormat.FORMAT_PERCENT) {
        roundedUpValue = roundUpValue(axisRange.value);
        if (index > 0) {
          if (roundedUpValue === maxExtents[0] && axisRange.format === format) {
            return;
          }
        }
      }
      maxExtents[index] = roundedUpValue;
    });

    return maxExtents;
  }

  /**
   * Depending on the length of the value (physical string length of the number)
   * is how much we round by.  A single-figure number gets rounded to 10, 2 digits to 100, etc
   * @param value
   * @returns {number}
   */
  function roundUpValue(value) {
    let roundValue;

    if (value === 0) {
      return 10;
    }

    switch (String(Math.round(value)).length) {
      case 0:
      case 1:
        roundValue = 10;
        break;

      case 2:
        roundValue = 100;
        break;

      case 3:
        roundValue = 1000;
        break;

      case 4:
        roundValue = 10000;
        break;

      case 5:
        roundValue = 100000;
        break;

      case 6:
        roundValue = 1000000;
        break;

      default:
        roundValue = 1000000;
    }

    return Math.ceil(value / roundValue) * roundValue;
  }

  function createAxis(renderer, extent, isComparison = false) {
    const chart = getChartValue(isComparison);
    const axisRange = Array.isArray(extent.ranges) ? extent.ranges.reverse() : [extent.ranges];
    const legends = [];

    if (config.value.useTooltip) {
      addCursor(chart);
    }

    axisRange.forEach((range, rangeIndex) => {
      let tooltipText = '';
      let tooltipColor = '';

      if (config.value.useTooltip) {
        let tooltipValue = LABEL_PLACEHOLDERS.TOOLTIP_VALUE;
        if (range.format === ColumnFormat.FORMAT_CURRENCY) {
          tooltipValue = preFixCurrency(tooltipValue);
        }

        if (range.format === ColumnFormat.FORMAT_PERCENT) {
          tooltipValue += '%';
        }

        tooltipText = `${range.labelText}: ${formatNumber(
          range.value,
          range.format
        )} (${tooltipValue})`;
        tooltipColor = range.fill;
      }

      const axisSettings = {};
      if (config.value.useTooltip) {
        const tooltipData = {
          color: tooltipColor,
          tooltipText,
          isComparison,
        };

        if (extent.inside || (axisRange.length > 0 && rangeIndex === 1)) {
          tooltipData.dy = 35;
        }

        axisSettings.tooltip = createTooltip(tooltipData);
      }

      // the axis needs specific formatters to keep things short
      const numberFormat = getAxisFormatter(range.format);

      const axis = chart.xAxes.push(
        ValueAxis.new(root.value, {
          maxDeviation: 0,
          min: extent.valueFrom,
          max: extent.valueTo,
          strictMinMax: true,
          renderer,
          ...axisSettings,
          ...numberFormat,
        })
      );

      // no hand means we need to fill the band like we do in comparative mode
      if (config.value.gaugeHandType === HandTypes.NONE) {
        // modify the range as we're going 0 to <value> now
        const modifiedRange = {
          ...range,
          value: 0,
          endValue: range.value,
          isInside: extent.inside,
        };

        addAxisRange(axis, modifiedRange, range.value, isComparison, axisRange.length > 0);
      }

      // apply currency-based formatting to the axis labels which isn't possible without an adapter
      if (range.format === ColumnFormat.FORMAT_CURRENCY) {
        axis.get(Constant.RENDERER).labels.template.adapters.add(Constant.TEXT, (text) => {
          // eslint-disable-next-line tap/no-raw-text-js
          if (typeof text === 'undefined') {
            return '';
          }

          const number = Number(text);
          // number is minus or has already been prefixed
          if (number < 0 || Number.isNaN(number)) {
            return text;
          }
          return preFixCurrency(text);
        });
      }

      const handColor = config.value.gaugeHandCustomColor ? config.value.handColor : range.fill;
      const handConfig = {
        value: range.value,
        fill: handColor,
        isComparison,
        inside: extent.inside,
        isSingleAxis,
      };

      // multi axis will only show a number on the hand when there's a single value
      if (
        config.value.labels.length === 1 ||
        (range.bigNumber &&
          [GaugeBigNumberPosition.INSIDE, GaugeBigNumberPosition.BELOW].includes(
            range.bigNumber.position
          ))
      ) {
        handConfig.bigNumber = range.bigNumber;
      } else if (
        range.bigNumber &&
        [GaugeBigNumberPosition.HAND, GaugeBigNumberPosition.HAND_TRANSPARENT].includes(
          range.bigNumber.position
        )
      ) {
        createBigNumberHelpTooltip(isComparison);
      }

      createRadarHand(getChartValue(isComparison), axis, new GaugeHand(handConfig));

      legends.push(createLegendData(range, handColor));
    });

    return legends;
  }

  function createBigNumberHelpTooltip(isComparison = false) {
    const chartValue = getChartValue(isComparison);

    const userData = chartValue.get(Constant.USER_DATA, {});
    if (userData.BIGNUMBER_TOOLTIP_ADDED || config.value.isExporting) {
      return;
    }

    const label = createNoticeIcon(
      GAUGE_TOO_MANY_HANDS_FOR_BIG_NUMBER,
      config.value.canIncludeInteractivity()
    );
    chartValue.children.push(label);

    // only one tooltip as we're just pushing children
    userData.BIGNUMBER_TOOLTIP_ADDED = true;
    chartValue.set(Constant.USER_DATA, userData);
  }

  function createLegendData(axisRange, color) {
    let label = axisRange.labelText;
    if (!config.value.comparisonEnabled) {
      label = truncateLabel(label, 15);
    }

    return {
      name: `${label}: ${formatNumber(axisRange.value, axisRange.format)}`,
      color,
    };
  }

  function addPeriodLabel(priorPeriod) {
    const chartValue = getChartValue(priorPeriod);

    const titleSettings = {
      fontSize: Constant.ONE_EM,
      fontWeight: 600,
      marginTop: -20,
      x: p50,
    };

    const label = addTitle(
      priorPeriod ? Constant.PRIOR_PERIOD : Constant.CURRENT_PERIOD,
      titleSettings
    );

    chartValue.topAxesContainer.children.unshift(label);
  }

  function getBaseColor(extent) {
    return config.value.fillType === WidgetBackgroundGradientColorOptions.SOLID
      ? extent.fill
      : config.value.gradientColor;
  }

  function createRenderer(axisExtent, isComparison = false) {
    let baseColor = getBaseColor(axisExtent);
    if (config.value.gaugeHandType === HandTypes.NONE) {
      baseColor = config.value.backgroundColor;
    }

    const strokeWidth =
      config.value.gaugeHandType === HandTypes.NONE
        ? 0
        : Math.round(config.value.gaugeThickness / 4);

    const rendererSettings = {
      opacity: isComparison ? 0.6 : 1,
      strokeOpacity: isComparison ? 0.6 : 1,
      strokeWidth,
      inside: axisExtent.inside,
      ...config.value.getShadowSettings(),
    };

    if (axisExtent.inside) {
      rendererSettings.radius = -10 - config.value.getGaugeMultiAxisThickness();
    }

    if (config.value.fillType === WidgetBackgroundGradientColorOptions.SOLID) {
      rendererSettings.stroke = baseColor;
    } else if (shouldApplyGradient(GradientTargets.BAND, config.value.gaugeGradientTarget)) {
      rendererSettings.strokeGradient = createLinearGradient(
        [baseColor, config.value.gaugeGradientTo],
        config.value.fillType === WidgetBackgroundGradientColorOptions.LINEAR_Y ? 90 : 0
      );
    } else {
      rendererSettings.stroke = baseColor;
    }

    const renderer = AxisRendererCircular.new(root.value, rendererSettings);

    renderer.grid.template.setAll({
      forceHidden: true,
    });

    if (config.value.showTicks) {
      renderer.ticks.template.setAll({
        stroke: '#333333',
        visible: true,
        length: 10,
        strokeOpacity: isComparison ? 0.6 : 1,
        inside: axisExtent.inside,
      });
    }

    renderer.labels.template.setAll({
      radius: config.value.gaugeHandType === HandTypes.NONE && axisExtent.inside ? 25 : 15,
      inside: axisExtent.inside,
      visible: config.value.showLabel,
    });

    return renderer;
  }

  return {
    addMultiAxes,
  };
}
