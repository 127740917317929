import angular from 'angular';
import _ from 'lodash';
import {WidgetType} from 'coreModules/design/widget/design.widget.constants';

angular.module('exportbuilder.dashboard.models')
    .factory('ExportBuilderDashboardUIModelFactory', ExportBuilderDashboardUIModelFactory);

/**
 * @ngInject
 */
function ExportBuilderDashboardUIModelFactory(
    $timeout,
    AppFactory,
    AppModule,
    WidgetFactory,
    PageOrientation,
    AppModelFactory,
    ClusterService,
    ClientGroupFactory,
    ClientFactory,
    UserFactory,
    ReportElementTypes,
    ReportElementMacroTypes,
    ReportElementShapeTypes,
    UserType,
    DataSourceType,
    SmartCampaignConstants,
    gettextCatalog,
    SmartCampaignResource,
    PageFormatConstants,
    PageFormatDropdownConstants
) {
    return {
        getImageFileInputOptions: getImageFileInputOptions,
        getPanelClientSelectOption: getPanelClientSelectOption,
        getPanelClientGroupSelectOption: getPanelClientGroupSelectOption,
        getPanelSmartCampaignSelectOption: getPanelSmartCampaignSelectOption,
        getPanelUsersSelectionOption: getPanelUsersSelectionOption,
        getPanelClusterOption: getPanelClusterOption,
        getPanelAspectRatioOptions: getPanelAspectRatioOptions,
        getOverlayClusterOption: getOverlayClusterOption,
        getOverlayClientOption: getOverlayClientOption,
        getOverlayClientGroupOption: getOverlayClientGroupOption,
        getDesignPanelSelectOption: getDesignPanelSelectOption,
        getFontSizeSelectOption: getFontSizeSelectOption,
        getDesignPanelSliderOption: getDesignPanelSliderOption,
        getFacadeUIState: getFacadeUIState,
        getFullElementViewModel: getFullElementViewModel,
        getLayersPageSortOptions: getLayersPageSortOptions,
        getLayersElementSortOptions: getLayersElementSortOptions,
    };

    /**
     * @constructor
     */
    function ImageFileInputOptions() {
        var self = this;

        self.uploadUrl = "/server/api/export/builder/image";
        self.uploadAsync = false;
        self.minFileCount = 1;
        self.maxFileCount = 1;
        self.showUpload = false;
        self.showRemove = false;
        self.showBrowse = true;
        self.showClose = false;
        self.showCaption = false;
        self.dropZoneEnabled = false;
        self.showUploadedThumbs = false;
        self.showPreview = false;
        self.browseClass = 'fileupload-browse';
        self.browseLabel = '';
        self.browseIcon = '';
    }

    /**
     * @returns {PanelClientSelectOption}
     * @constructor
     */
    function PanelClientSelectOption() {
        var selectorType = 'client';
        var brandMappings = AppFactory.getBrandMappings();
        return AppModelFactory.getSelectOptions({
            selectorType: selectorType,
            width: '100%',
            multiple: false,
            allowClear: true,
            placeholder:  gettextCatalog.getString('No {{ client }} assigned: report will load data for all {{ clients }}', { client: brandMappings.client, clients: brandMappings.client.pluralize()}),
            loaded: false,
            formatSelection: function(item) {
                return item.text || item.name;
            },
            onClear: function() {

            },
            formatData: function(json) {
                var data = json.values;
                this.values = _.map(data, function(serviceValue) {
                    return {
                        type: selectorType,
                        id: serviceValue.key,
                        text: serviceValue.value
                    }
                });
                this.loaded = true;
            },
            getDataCall: function(query) {
                var params = {};
                if (query) {
                    params.q = query + '|company_name';
                }

                return ClientFactory.getFieldValues('id', params);
            }
        });
    }

    /**
     * @returns {PanelClientGroupSelectOption}
     * @constructor
     */
    function PanelClientGroupSelectOption() {
        var selectorType = 'client_group';
        var brandMappings = AppFactory.getBrandMappings();
        return AppModelFactory.getSelectOptions({
            selectorType: selectorType,
            width: '100%',
            multiple: false,
            allowClear: true,
            placeholder: gettextCatalog.getString('No {{ clientgroup }} assigned: report will load data for all {{ clientgroups }}', { clientgroup: brandMappings.client_group, clientsgroups: brandMappings.client_group.pluralize()}),
            loaded: false,
            formatSelection: function(item) {
                return item.text || item.name;
            },
            onClear: function() {

            },
            formatData: function(json) {
                var data = json.values;
                this.values = _.map(data, function(serviceValue) {
                    return {
                        type: selectorType,
                        id: serviceValue.key,
                        text: serviceValue.value
                    }
                });
                this.loaded = true;
            },
            getDataCall: function(query) {
                var params = {};
                if (query) {
                    params.q = query + '|name';
                }

                return ClientGroupFactory.getFieldValues('id', params);
            }
        })
    }

    function PanelSmartCampaignSelectOption() {
        var selectorType = DataSourceType.SMART_CAMPAIGN;
        var brandMappings = AppFactory.getBrandMappings();
        return AppModelFactory.getSelectOptions({
            selectorType: selectorType,
            width: '100%',
            multiple: false,
            allowClear: true,
            placeholder: 'No smart campaigns are assigned', // fix this internalisation check
            loaded: false,
            formatSelection: function(item) {
                return item.text || item.name;
            },
            onClear: function() {

            },
            formatData: function(data) {
                this.values = _.map(data, function(smartCampaign) {
                    smartCampaign.text = smartCampaign.name;
                    return smartCampaign;
                });
                this.loaded = true;
            },
            getDataCall: function(query) {
                var params = {[SmartCampaignConstants.CAMPAIGN_TYPE] : SmartCampaignConstants.TYPE.FILTERING};
                if (query) {
                    params.q = query + '|name';
                }

                return SmartCampaignResource.getData(params);
            }
        })
    }

    function PanelUsersSelectionOption() {
        var selectorType = 'users';
        return AppModelFactory.getSelectOptions({
            selectorType: selectorType,
            width: '100%',
            multiple: true,
            allowClear: true,
            placeholder: 'Select users',
            loaded: false,
            formatSelection: function(item) {
                return item.text || item.name;
            },
            onClear: function() {

            },
            formatData: function(data) {
                this.values = _.map(data, function(user) {
                    const userFullName = [user.first_name, user.last_name]
                        .filter(function(part) {return part;}).join(' ');
                    return {
                        type: selectorType,
                        id: user.id,
                        text: user.email + (userFullName ? ' (' + userFullName + ')' : '')
                    }
                });
                this.loaded = true;
            },
            getDataCall: function(query) {
                var params = {
                    status: 'active'
                };
                if (query) {
                    params.q = query + '|name';
                }
                return UserFactory.getData(params);
            }
        });
    }

    /**
     * @returns {OverlayClusterOption}
     * @constructor
     */
    function OverlayClusterOption() {
        const options = PanelClusterOption();
        options.allowClear = AppFactory.getUser().isSuperAdmin();
        return options;
    }

    /**
     * @returns {OverlayClientOption}
     * @constructor
     */
    function OverlayClientOption() {
        const options = PanelClientSelectOption();
        options.allowClear = true;
        return options;
    }

    /**
     * s
     * @returns {PanelClientGroupSelectOption}
     * @constructor
     */
    function OverlayClientGroupOption() {
        const options = PanelClientGroupSelectOption();
        options.allowClear = true;
        return options;
    }

    /**
     * @returns {PanelClusterOption}
     * @constructor
     */
    function PanelClusterOption() {
        var selectorType = 'cluster';

        return AppModelFactory.getSelectOptions({
            selectorType: selectorType,
            multiple: false,
            allowClear: true,
            width: '100%',
            placeholder: gettextCatalog.getString('No Business Unit assigned'),
            loaded: false,
            formatData: function(json) {
                var data = json.values;
                this.values = _.map(data, function(serviceValue) {
                    return {
                        type: selectorType,
                        id: serviceValue.key,
                        text: serviceValue.value
                    }
                });
                this.loaded = true;
            },
            getDataCall: function(query) {
                var params = {status: 'active'};
                if (query) {
                    params.q = query + '|name';
                }

                if (AppFactory.getUser().type === UserType.AGENT) {
                    return Promise.resolve([]);
                }

                return ClusterService.getFieldValues('id', params);
            }
        });
    }

    /**
     * @constructor
     */
    function DesignPanelSelectOption() {
        var self = this;

        self.width = '145px';
        self.allowClear = false;
        self.familyfonts = [
            {id: 'Arial', text: 'Arial'},
            {id: 'Arial Black', text: 'Arial Black'},
            {id: 'Century Gothic', text: 'Century Gothic'},
            {id: 'Comic Sans MS', text: 'Comic Sans MS'},
            {id: 'Courier New', text: 'Courier New'},
            {id: 'Helvetica', text: 'Helvetica'},
            {id: 'Impact', text: 'Impact'},
            {id: 'Lato', text: 'Lato'},
            {id: 'Lucida Grande', text: 'Lucida Grande'},
            {id: 'Tahoma', text: 'Tahoma'},
            {id: 'Times New Roman', text: 'Times New Roman'},
            {id: 'Verdana', text: 'Verdana'},
            {id: 'Roboto Light', text: 'Roboto Light'},
            {id: 'Roboto Regular', text: 'Roboto Regular'},
            {id: 'Roboto Black', text: 'Roboto Black'},
            {id: 'Playfair Display Black', text: 'Playfair Display Black'},
        ];
        if (AppFactory.getUser()?.isModuleAvailable(AppModule.CUSTOM_FONTS)) {
            self.familyfonts = [
                ...self.familyfonts,
                {id: 'IBM Plex Sans', text: 'IBM Plex Sans'},
                {id: 'IBM Plex Serif', text: 'IBM Plex Serif'}
            ]
        }
        self.familyfonts = _.sortBy(self.familyfonts, ['id']);
        self.selectedValue = null;
    }


    function DesignPanelAspectRatio() {
        var self = this;

        self.width = '75px';
        self.dropdownCssClass = 'format-dropdown';
        self.allowClear = false;
        self.minimumResultsForSearch = -1;
        self.aspectRatioOptions = [
            {id: PageFormatConstants.STANDARD, value: PageFormatDropdownConstants.STANDARD},
            {id: PageFormatConstants.WIDESCREEN, value: PageFormatDropdownConstants.WIDESCREEN}
        ];
        self.selectedValue = null;
    }

    function DesignPanelFontSizeSelectOption() {
        const self = this;

        self.width = '145px';
        self.allowClear = false;
        let fontSizes = [];
        for (let i = 8; i <= 150; i++) {
            fontSizes.push({
                id: i,
                text: `${i} px`
            })
        }
        self.fontSizes = fontSizes;
        self.selectedValue = 8;
    }

    /**
     *
     * @param model
     * @constructor
     */
    function DesignPanelSliderOption(model) {
        var self = this;

        self.id = _.isNil(model.id) ? Math.random() + ' -slider' : model.id + '-slider';
        self.ready = false;
        self.showSelectionBar = true;
        self.hideLimitLabels = true;
        self.hidePointerLabels = true;
        self.value = _.isNil(model.value) ? null : 0;
        self.ceil = model.ceil || 100;
        self.floor = model.floor || 0;
        self.step = model.step || 5;
        self.onEnd = model.onEnd || null;
    }

    /**
     * @constructor
     */
    function FacadeUIState() {
        var self = this;

        self.isDownloadingReport = false;
        self.gridMode = false;
        self.isShowingModal = false;
        self.isResizing = false;
        self.imageIsUploading = false;
        self.showElementSizes = false;
        self.isPressingShift = false;
        self.isPressingCMD = false;
        self.isSavingReport = false;
        self.isPublishingReport = false;
        self.isOverflow = false;
    }

        /**
         * Creates data structure for view based on export element item
         * @param item
         * @constructor
         * @returns {*}
         */
        function FullElementViewModel(item) {
            var element = {
                title: '',
                name: '',
                styles: {}
            };
            switch (item.type) {
                case ReportElementTypes.WIDGET:
                    var widgetTypes = AppFactory.arrayToMemoizedObj(WidgetFactory.getAllWidgetTypes(), 'id');
                    const icon = [WidgetType.MEDIA, WidgetType.EXECUTIVESUMMARY].includes(item.widget.type)
                        ? 'image-bold'
                        : widgetTypes[item.widget.type].icon + '-bold';
                    element = {
                        icon: 'icon icomoon-' + icon,
                        title: item.widget.title || 'Widget',
                        name: widgetTypes[item.widget.type].name
                    };
                    break;

            case ReportElementTypes.TEXT:
                element = {
                    icon: 'icon icomoon-text-style',
                    title: gettextCatalog.getString('Text'),
                    name: gettextCatalog.getString('Text')
                };

                if (item.metadata.macro_type) {
                    element.icon += ' item-type-macro';
                    element.name = 'Variable';

                    switch (item.metadata.macro_type) {
                        case ReportElementMacroTypes.DATE_RANGE:
                            element.title = gettextCatalog.getString('Date Range (Variable)');
                            break;

                        case ReportElementMacroTypes.RECIPIENT_NAME:
                            element.title = gettextCatalog.getString('Recipient Name (Variable)');
                            break;

                        case ReportElementMacroTypes.PAGE_NUMBER:
                            element.title = gettextCatalog.getString('Page Number (Variable)');
                            break;

                        case ReportElementMacroTypes.PAGE_TITLE:
                            element.title = gettextCatalog.getString('Page Title (Variable)');
                            break;

                        case ReportElementMacroTypes.CREATED_ON:
                            element.title = gettextCatalog.getString('Created On (Variable)');
                            break;
                    }
                }
                break;

            case ReportElementTypes.IMAGE:
                element = {
                    icon: 'icon icomoon-image-bold',
                    title: 'Image',
                    name: 'Image'
                };

                if (item.metadata.macro_type) {
                    element.icon += ' item-type-macro';
                    element.name = 'Variable';
                    switch (item.metadata.macro_type) {
                        case ReportElementMacroTypes.COMPANY_LOGO:
                            element.title = gettextCatalog.getString('Company Logo (Variable)');
                            break;
                    }
                }
                break;

            case ReportElementTypes.SHAPE:
                switch (item.metadata.shape_type) {
                    case ReportElementShapeTypes.SQUARE:
                        element = {
                            icon: 'icon icon-square item-type-shape',
                            name: gettextCatalog.getString('Square'),
                            title: gettextCatalog.getString('Square (Shape)')
                        };
                        break;

                    case ReportElementShapeTypes.CIRCLE:
                        element = {
                            icon: 'icon icon-circle item-type-shape',
                            name: gettextCatalog.getString('Circle'),
                            title: gettextCatalog.getString('Circle (Shape)')
                        };
                        break;
                }
                break;

            case ReportElementTypes.ICON:
                element = {
                    icon: 'icon icomoon-share',
                    title: gettextCatalog.getString('Icon'),
                    name: gettextCatalog.getString('Icon')
                };
                break;
            case ReportElementTypes.TABLE:
                element = {
                    icon: 'icon icomoon-datagrid-bold',
                    title: gettextCatalog.getString('Pricing Table'),
                    name: gettextCatalog.getString('Pricing Table')
                };
                break;

        }
        return element;
    }

    /**
     * @constructor
     */
    function LayersPageSortOption(options) {
        options = options || {};

        var self = this;

        self.isSorting = false;
        self.containment = '.pages';
        self.axis = 'y';
        self.items = '.page-sortable';
        self.placeholder = 'sortable-placeholder';

        /**
         * Callback from jQuery UI
         */
        self.stop = function () {
            $timeout(function () {
                self.isSorting = false;
            }, 100, false);

            options.stop && options.stop();
        };

        /**
         * Callback from jQuery UI
         */
        self.start = function () {
            self.isSorting = true;
            options.start && options.start();
        };
    }

    /**
     * @constructor
     */
    function LayersElementSortOption(options) {
        var self = this;

        self.axis = 'y';

        self.stop = function () {
            options.stop && options.stop.apply(undefined, arguments);
        };

        self.start = function () {
            options.start && options.start.apply(undefined, arguments);
        };
    }

    function getPanelAspectRatioOptions() {
        return new DesignPanelAspectRatio();
    }

    function getPanelClientSelectOption() {
        return new PanelClientSelectOption()
    }

    function getPanelClientGroupSelectOption() {
        return new PanelClientGroupSelectOption();
    }

    function getPanelSmartCampaignSelectOption() {
        return new PanelSmartCampaignSelectOption();
    }

    function getPanelUsersSelectionOption() {
        return new PanelUsersSelectionOption();
    }

    function getPanelClusterOption() {
        return new PanelClusterOption();
    }

    function getOverlayClusterOption() {
        return new OverlayClusterOption();
    }

    function getOverlayClientOption() {
        return new OverlayClientOption();
    }

    function getOverlayClientGroupOption() {
        return new OverlayClientGroupOption();
    }

    function getImageFileInputOptions() {
        return new ImageFileInputOptions();
    }

    function getDesignPanelSelectOption() {
        return new DesignPanelSelectOption();
    }

    function getFontSizeSelectOption() {
        return new DesignPanelFontSizeSelectOption();
    }

    function getDesignPanelSliderOption(model) {
        return new DesignPanelSliderOption(model);
    }

    function getFacadeUIState() {
        return new FacadeUIState();
    }

    function getFullElementViewModel(item) {
        return new FullElementViewModel(item);
    }

    function getLayersPageSortOptions(options) {
        return new LayersPageSortOption(options)
    }

    function getLayersElementSortOptions(options) {
        return new LayersElementSortOption(options)
    }
}
