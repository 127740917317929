'use strict';

import angular from 'angular';
import _ from 'lodash';
import widgetBuilderDataTab from './widget.builder.tab.data.html';
import widgetBuilderStylesTab from './widget.builder.tab.styles.html';
import widgetBuilderLibraryTab from './widget.builder.tab.library.html';
import widgetBuilderLibraryItem from './widget.builder.tab.library.item.html';
import timeGroupingDropdown from './widget.builder.tab.timegroupings.html';
import widgetBuilderColumnsConfig from './widget.builder.tab.columnsconfig.html';
import geoOptionsDropdown from './widget.builder.tab.geooptions.html';
import widgetLiveCallFilter from './widget.builder.tabs.livefilters.html';
import filterItem from './widget.builder.tabs.livefilters.items.html';
import widgetLiveCallCustomAggregation from './widget.builder.tabs.livecustomaggregation.html';
import {
    $WidgetBuilderEvents
} from 'coreModules/design/widget/builder/widget.builder.constants';

import {
    DrawOption
} from 'coreModules/design/layout/drawoptionpanel/drawoptionpanel.constants';


import {
    WidgetType,
    DisabledColumnState,
    ChartPlotType,
    TimeGrouping,
    ConditionalType,
    ConditionalTypeRuleValues,
} from 'coreModules/design/widget/design.widget.constants';

import {
    LibrarySearchGroup
} from 'coreModules/library/library.constants';
import {
    LAYERS_PRIORITY,
    $MapBoxEvents
} from "../../../../../../../grok/src/modules/ta/widget/mapbox.constants";
import {BenchmarkDrawOptions, BenchmarkSupportedServices} from "../widget.builder.constants";

const LIVE_DATA_CATEGORY_MESSAGE_TEXT = 'Please select an assignment to see all the Dimensions, Metrics and Filters';

const ADOBE_SERVICE_ID = '200';
const THE_TRADE_DESK_ID = '61';
const FACEBOOK_ADS_ID = '39';

angular.module('widget.builder.components')
    .component('widgetBuilderDataTab', {
        templateUrl: widgetBuilderDataTab,
        controllerAs: 'vm',
        bindings: {
            setLoading: '&'
        },
        replace: true,
        controller: WidgetBuilderDataTabController
    })
    .component('widgetLiveCallFilter', {
        templateUrl: widgetLiveCallFilter,
        controllerAs: 'vm',
        bindings: {
            widget: '<',
            data: '<'
        },
        controller: WidgetLiveCallFilterController
    })
    .component('widgetLiveCallCustomAggregation', {
        templateUrl: widgetLiveCallCustomAggregation,
        controllerAs: 'vm',
        bindings: {
            data: '<'
        },
        controller: WidgetLiveCallFormulaController
    })
    .component('timeGroupingDropdown', {
        templateUrl: timeGroupingDropdown,
        controllerAs: 'vm',
        bindings: {
            timeGrouping: '<',
            weeklyStartDay: '<'
        },
        controller: TimeGroupingDropdownController
    })
    .component('widgetBuilderColumnsConfig', {
        templateUrl: widgetBuilderColumnsConfig,
        controllerAs: 'vm',
        bindings: {
            widget: '<',
            selectedColumns: '=',
            onColumnChange: '<',
            isConfiguringColumns: '='
        },
        controller: WidgetBuilderColumnsConfigController
    })
    .component('geoOptionsDropdown', {
        templateUrl: geoOptionsDropdown,
        controllerAs: 'vm',
        bindings: {
            selectedGeoCode: '<'
        },
        controller: GeoOptionsDropdownController
    })
    .component('widgetBuilderStylesTab', {
        templateUrl: widgetBuilderStylesTab,
        controllerAs: 'vm',
        bindings: false,
        replace: true,
        controller: WidgetBuilderStylesTabController
    })
    .component('widgetBuilderLibraryTab', {
        templateUrl: widgetBuilderLibraryTab,
        controllerAs: 'vm',
        bindings: false,
        replace: true,
        controller: WidgetBuilderLibraryTabController
    })
    .component('widgetLibraryItem', {
        templateUrl: widgetBuilderLibraryItem,
        controllerAs: 'vm',
        bindings: {
            item: '<',
            isSwapping: '='
        },
        replace: true,
        controller: WidgetLibraryItemController
    })
    .component('liveFilterItem', {
        templateUrl: filterItem,
        controllerAs: 'vm',
        bindings: {
            datasource: '<',
            orDisabled: '<',
            filter: '<',
            widgetId: '<',
            isSingleFilter: '<',
            isFirst: '<'
        },
        controller: LiveFilterItemController
    });

/**
 * @ngInject
 */
function WidgetBuilderDataTabController(
    $timeout,
    $scope,
    $rootScope,
    $interval,
    PubSub,
    DataSourceType,
    CustomDataViewMessage,
    WidgetFactory,
    $WidgetHeaderEvents,
    WidgetBuilderDataModelFactory,
    ExportBuilderDashboardService,
    WidgetBuilderService,
    WidgetBuilderUIService,
    WidgetUtilService,
    DashboardContextService,
    DashboardFilterPanelFactory,
    AppFactory,
    AppModule,
    ServiceFactory,
    UIFactory,
    WidgetFilterFactory,
    WidgetHeaderModalFactory,
    WidgetBuilderConstants,
    BenchmarkEnabledPlotTypes,
    DesignFactory,
    ReportStudioTemplateDataService,
    ColumnFormat,
) {
    const vm = this;

    /**
     * @type {WidgetBuilderDataTabData}
     */
    vm.data = null; // Widget types are lazy loaded so vm.data is set inside _init

    /**
     * @type {WidgetBuilderDataTabState}
     */
    vm.state = WidgetBuilderDataModelFactory.getTabState();

    /**
     * @type {boolean}
     */
    vm.isDataWidget = false;

    /**
     * @type {boolean}
     */
    vm.showSampleData = true;

    /**
     * @type {boolean}
     */
    vm.useDataCloud = true;

    /**
     * @type {boolean}
     */
    vm.isDrillingDown = true;

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.getWidgetModel = getWidgetModel;
    vm.onWidgetTitleBlur = onWidgetTitleBlur;
    vm.showSampleDataToggle = showSampleDataToggle;
    vm.toggleSampleData = toggleSampleData;
    vm.disableDataCloud = disableDataCloud;
    vm.disableSampleData = disableSampleData;
    vm.toggleLiveData = toggleLiveData;
    vm.widgetTypeCanGroup = widgetTypeCanGroup;
    vm.widgetTypeCanDrilldown = widgetTypeCanDrilldown;
    vm.toggleDrilldown = toggleDrilldown;
    vm.pageHasClientFilter = pageHasClientFilter;
    vm.toggleShowLiveFilter = toggleShowLiveFilter;
    vm.toggleShowLiveCallFormulas = toggleShowLiveCallFormulas;
    vm.canShowLiveFilters = canShowLiveFilters;
    vm.canShowLiveCallFormulas = canShowLiveCallFormulas;
    vm.canTimeGroup = canTimeGroup;
    vm.getTimeGrouping = getTimeGrouping;
    vm.getWeeklyStartDay = getWeeklyStartDay;
    vm.goToSelectColumnConfig = goToSelectColumnConfig;
    vm.showCustomDataViewMessage = showCustomDataViewMessage;
    vm.getCustomDataViewMessageText = getCustomDataViewMessageText;
    vm.widgetColumnsCanMultiGroup = widgetColumnsCanMultiGroup;
    vm.getFilterIconLabel = getFilterIconLabel;
    vm.getSectionOpenCloseIcon = getSectionOpenCloseIcon;
    vm.getLiveFormulaIconLabel = getLiveFormulasIconLabel;
    vm.hasSelectedAssignment = hasSelectedAssignment;
    vm.showDimensionTooltip = showDimensionTooltip;
    vm.onComparisonOptionChange = onComparisonOptionChange;
    vm.canShowComparisonOptions = canShowComparisonOptions;
    vm.canShowSparklineOptions = canShowSparklineOptions;
    vm.onSparklineOptionChange = onSparklineOptionChange;
    vm.openWidgetHeaderModal = openWidgetHeaderModal;
    vm.getLiveAssignmentsDropDownClass = getLiveAssignmentsDropDownClass;
    vm.disableGroupBySelect = disableGroupBySelect;
    vm.getWidgetPlainTitle = WidgetFactory.getWidgetPlainTitle;
    vm.closeWarningCard = closeWarningCard;
    vm.toggleAllWarningCards = toggleAllWarningCards;
    vm.togglePosition = togglePosition;
    vm.calculateCardStyle = calculateCardStyle;
    vm.getDiscrepancyWarningFilteredObj = getDiscrepancyWarningFilteredObj;
    vm.showEmbedSparklineDateAlertMessage = showEmbedSparklineDateAlertMessage;

    /**
     * to get the message text for Assignments
     * @type {function(): string}
     */
    vm.getCustomAssignmentsMessageText = getCustomAssignmentsMessageText;


    /**
     * to get the tooltip message for On Demand Calls Custom Aggregation
     * @type {function(): string}
     */
    vm.getCustomAggregationTooltipMessage = getCustomAggregationTooltipMessage;

    /**
     * to get the message text for data category
     * @type {function(): string}
     */
    vm.getDataCategoryMessageTextForLive = getDataCategoryMessageTextForLive;

    /**
     * Show tool tip for data category if it is adobe analytics only
     * @type {function(): string}
     */
    vm.showDataCategoryMessageForLive = showDataCategoryMessageForLive;

    /**
     * to decide whether to show Assignments drop down or not
     * @type {boolean}
     */
    vm.canShowAssignments = false;


    /**
     * to get the message icon for on demand Filters
     * @type {function(): string}
     */
    vm.getIconFilterText = getIconFilterText;

    /**
     * to decide whether to show on demand filters select
     * @type {boolean}
     */
    vm.showLiveFilters = false;


    /**
     * to decide whether to show on demand filters select
     * @type {boolean}
     */
    vm.showLiveFormulas = false;

    vm.showMetricTips = false;

    vm.applyBottomMargin = null;

    vm.toggleAllWarningCardsTextDisplay = DrawOption.CARD_TEXT_DISPLAY_HIDE;

    vm.iconEyeStatus = "icon-eye-slash";

    vm.embedSparklineAddDateAlertMessage = '';

    vm.canShowBenchmarks = canShowBenchmarks;

    function $onInit() {
        _registerEvents();
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    async function _init() {
        if (vm.state.isActive) {
            return;
        }
        vm.state.isActive = true;
        vm.state.isLoading = true;
        vm.setLoading({value: true});
        vm.showMetricTips = false;
        vm.applyBottomMargin = null;
        let widgetModel = getWidgetModel();
        let isEditing = WidgetBuilderService.getPanelState().isEditing;
        vm.isDataWidget = WidgetBuilderService.getPanelState().isDataWidget;

        if (vm.isDataWidget) {
            vm.data = await WidgetBuilderDataModelFactory.getTabData(widgetModel, DashboardContextService.resolvePageEntityQueryParam());
            if (widgetModel) {
                vm.data.selectedColumnsSelect.options['isSortable'] = true;
                vm.data.groupedColumnsSelect.options['isSortable'] = true;
            }
            _setAllSelectEvents();

            // Cannot edit data source or data view if widget is being edited
            vm.data.dataSourceSelect.options.disabled = isEditing;
            if (vm.data.dataViewSelect) {
                vm.data.dataViewSelect.options.disabled = isEditing;
            }
            vm.state.isLoading = true;
            vm.setLoading({ value: true });
            setAsPreview(widgetModel);

            if(isEditing && !_.isEmpty(widgetModel.metadata.dynamic.filters)){
                vm.showLiveFilters = true;
            }
            if(isEditing && widgetModel.has_live_integration && !_.isEmpty(widgetModel.metadata.live_formula_fields)){
                vm.showLiveFormulas = true;
            }
        } else {
            vm.data = WidgetBuilderDataModelFactory.getTabAdminData(widgetModel);
            _setSelectedColumnsSelectEvents();
            _setSelectedBenchmarkColumnsSelectEvents();
            setAsPreview(widgetModel);
        }
    }

    function canShowBenchmarks() {
        if (!$rootScope.util.isModuleAvailable(AppModule.BENCHMARKS)) {
            return false;
        }

        const widgetModel = getWidgetModel();
        if (!widgetModel) {
            return false;
        }
        const plotType = widgetModel.metadata?.draw_options?.plot_type;
        return typeof BenchmarkEnabledPlotTypes[plotType] !== "undefined" && BenchmarkEnabledPlotTypes[plotType];
    }

    function setAsPreview(widgetModel) {
        WidgetBuilderService.setWidgetAsPreview(vm.data, widgetModel).then(function () {
            $timeout(() => {
                vm.state.isLoading = false;
                vm.setLoading({value: false});
                vm.useDataCloud = !getWidgetModel().has_live_integration;
                vm.isDrillingDown = !getWidgetModel().metadata.is_multi_grouped;
                vm.showSampleData = getWidgetModel().metadata.draw_options.show_sample_data;
                setCanShowAssignments(getWidgetModel().metadata.data_source, !getWidgetModel().has_live_integration);

                if (vm.isDataWidget) {
                    if (!vm.useDataCloud) {
                        // update Assignments in case of On Demand Widgets only
                        _updateWidgetAssignmentSelectValue();
                    }
                    _updateSelectedColumnValueStates();
                    _updateColumnSelectedValues();

                    let dataSource = getWidgetModel()
                        ? getWidgetModel().metadata.data_source
                        : vm.data.dataSourceSelect.selectedValue;
                    dataSource.data_view_is_geo = false
                    if (vm.data.dataViewSelect) {
                        dataSource.data_view_is_geo = vm.data.dataViewSelect.selectedValue.is_geo;
                    }

                    WidgetBuilderService.updateWidgetTypeStates(dataSource);
                }
            });
        });
    }

    function onComparisonOptionChange() {
        const widget = getWidgetModel();
        widget.metadata.comparison_option = vm.data.comparisonOption.value;
        WidgetBuilderService.setWidgetModel(widget);
        _updateWidgetDisplay()
    }

    function onSparklineOptionChange() {
        const widget = getWidgetModel();
        widget.metadata.sparkline_option = vm.data.sparklineOption.value;
        WidgetBuilderService.setWidgetModel(widget);
        _updateWidgetDisplay()
    }

    function canShowComparisonOptions() {
        const widget = getWidgetModel();
        return widget ? WidgetUtilService.isBigNumber(widget.metadata.type) && widget.metadata.draw_options[DrawOption.PLOT_TYPE] === ChartPlotType.COMPARISON : false;
    }

    function canShowSparklineOptions() {
        const widget = getWidgetModel();
        return widget ? WidgetUtilService.isBigNumber(widget.metadata.type) && widget.metadata.draw_options[DrawOption.PLOT_TYPE] === ChartPlotType.SPARKLINE : false;
    }
    /**
     * Set the selected Assignments in the WidgetModel
     * @private
     */
    function _updateWidgetAssignmentSelectValue() {
        $timeout(function() {
            const assignments = WidgetBuilderDataModelFactory.getSelectedAssignmentsFromWidget(vm.data.widgetAssignmentSelect);
            WidgetBuilderService.setAssignments(assignments);
        }, 0, false);
    }

    function onWidgetTitleBlur() {
        WidgetBuilderService.setTitle(vm.data.title);
        PubSub.$emit($WidgetHeaderEvents.REFRESH_TITLE + getWidgetModel().id);
    }

    function showSampleDataToggle() {
        if (DashboardContextService.isDemoModeEnabled()) {
            return false;
        }

        return vm.isDataWidget;
    }

    function toggleSampleData() {
        WidgetBuilderService.setShowSampleData(vm.showSampleData);
        WidgetBuilderService.updateWidgetDisplay();
    }

    /**
     * Disable Sample data toggle when assignment
     * not selected in On Demand widget
     */
    function disableSampleData() {
        return !vm.useDataCloud && vm.data &&
            vm.data.widgetAssignmentSelect &&
            _.isEmpty(vm.data.widgetAssignmentSelect.selectedValues);
    }

    function disableDataCloud() {
        let hasStorageIntegrationsOnly = ServiceFactory.getEntityServices().every(function (service) {
            return !parseInt(service.has_live_integration);
        });
        return (hasStorageIntegrationsOnly ? hasStorageIntegrationsOnly : !WidgetBuilderService.getIsNewWidget());
    }

    function setCanShowAssignments(dataSource, isUsingDataCloud) {
        // If Data Warehouse is off then
        // we can show accounts which can be selected to fetch On Demand Data.
        if (!isUsingDataCloud) {
            vm.canShowAssignments = true;
        } else {
            vm.canShowAssignments = false;
        }
    }

    function canShowLiveFilters() {
        // Do not show filter tab if filtersColumns are not present
        const filtersColumns = vm.data && vm.data.filtersColumnsSelect && vm.data.filtersColumnsSelect.values;
        if (!filtersColumns || !filtersColumns.length) {
            return false;
        }

        const widgetModel = getWidgetModel();
        if (widgetModel && vm.data) {
            return WidgetFilterFactory.isWidgetFilterable(widgetModel) && vm.isDataWidget && widgetModel.has_live_integration && !vm.useDataCloud;
        }

        return false;
    }

    function canShowLiveCallFormulas() {
        const widgetModel = getWidgetModel();
        let canShow = false;
        if (widgetModel && vm.data) {
            canShow = vm.isDataWidget && widgetModel.has_live_integration && !vm.useDataCloud && vm.data.liveFormulaSelect?.values?.length;
        }
        return canShow;
    }

    function getSectionOpenCloseIcon(section) {
        return ((section === 'liveFilters' && vm.showLiveFilters) || (section === 'customAggregation' && vm.showLiveFormulas))
            ? 'icon icomoon-cross'
            : 'icon icomoon-add';
    }

    function getLiveAssignmentsDropDownClass() {
        if (vm.data.widgetAssignmentSelect?.options?.multiple) return 'assignments-drop-down-multi-select';
        return 'assignments-drop-down-single-select';
    }

    function getFilterIconLabel() {
        return vm.showLiveFilters ? 'Close On Demand Calls Section (unsaved filters will be removed)' : 'Add On Demand Calls Filters';
    }

    function getLiveFormulasIconLabel() {
        return vm.showLiveFormulas ? 'Close On Demand Calls Formulas' : 'Add On Demand Calls Formulas';
    }

    function resetSortMetadata() {
        const widget = getWidgetModel();
        widget.metadata.sort_order = false;
        widget.metadata.sort_by = false;
        WidgetBuilderService.setWidgetModel(widget);
    }

    async function toggleLiveData() {
        WidgetBuilderService.copyLiveIntegrationValue(getWidgetModel().has_live_integration)
        WidgetBuilderService.setLiveIntegration(!getWidgetModel().has_live_integration);
        resetSortMetadata();
        await _updateDataSourceSelectValues(true);
    }

    function toggleDrilldown() {
        WidgetBuilderService.setIsMultiGrouped(!vm.isDrillingDown && widgetColumnsCanMultiGroup());
        WidgetBuilderService.updateWidgetDisplay();
    }

    function pageHasClientFilter() {
        return  !_.isNull(DashboardContextService.resolvePageClient());
    }

    /**
     * @private
     */
    function _updateColumnSelectedValues() {
        _updateGroupedColumnSelectedValues();
        _updateSelectedColumnSelectedValues();
    }


    /**
     * @private
     */
    function hasSelectedAssignment() {
        if (vm.data && vm.data.widgetAssignmentSelect) {
            return !!vm.data.widgetAssignmentSelect.selectedValues;
        }
    }

    /**
     * @private
     */
    function _updateSelectedColumnValueStates() {
        $timeout(function() {
            WidgetBuilderService.updateSelectedColumnValueStates(vm.data.selectedColumnsSelect);
        }, 0, false);
    }

    /**
     * @private
     */
    function _updateSelectedColumnSelectedValues() {
        const widget = getWidgetModel();
        $timeout(function() {
            if (widget.id === WidgetBuilderConstants.NEW_WIDGET_ID) {
                vm.showMetricTips = true;
            }

            if (WidgetUtilService.isGroupedColumnPlotType(widget.metadata.draw_options)) {
                sortSelectedGroupedColumn();
            }
            WidgetBuilderService.updateSelectedColumnSelectedValues(
                vm.data.selectedColumnsSelect,
                vm.data.groupedColumnsSelect
            );

            // update the colour of the selected benchmarks
            _updateSelectedBenchmarkColumnSelectedValues();
        }, 0, false);
        $timeout(function() {
            removeSelectedGroupedColumns();
            removeSelectedConditionalColumns();
            removeSelectedEmbedSparklineColumn();
            if (!vm.useDataCloud) {
                _updateLiveFormulaColumnSelect();
            }
        }, 0, false);
    }

    /**
     * @private
     */
    function _updateSelectedBenchmarkColumnSelectedValues() {
        $timeout(function() {
            WidgetBuilderService.updateSelectedBenchmarkColumnSelectedValues(vm.data.selectedColumnsSelect, vm.data.selectedBenchmarkColumnsSelect);
        }, 0, false);
    }

    function sortSelectedGroupedColumn() {
        const widget = getWidgetModel();
        WidgetBuilderService.sortGroupedColumns(widget);
        WidgetBuilderService.updateWidgetDisplay();
    }

    /**
     * @private
     */
    function _updateGroupedColumnSelectedValues() {
        $timeout(function() {
            WidgetBuilderService.updateGroupedColumnSelectedValues(vm.data.selectedColumnsSelect, vm.data.groupedColumnsSelect, !vm.useDataCloud);
        }, 0, false);
    }


    /**
     * @param {bool} forceUpdate
     * @private
     */
    async function _updateDataSourceSelectValues(forceUpdate) {
        let widgetModel = getWidgetModel();
        let selectedEntity = DashboardContextService.resolveSelectedEntity();

        let params = widgetModel.has_live_integration ? {has_live_integration: 1} : {};
        if( selectedEntity ){
            if (selectedEntity.type === DataSourceType.CLIENT_GROUP) {
                params.client_group_id = selectedEntity.id;
            } else {
                params.client_id = selectedEntity.id;
            }
        }

        await vm.data.dataSourceSelect.getSelectValues(widgetModel.metadata.data_source, params);
        $timeout(function() {
            let newVals = vm.data.dataSourceSelect.values;
            let selectedVal = vm.data.dataSourceSelect.selectedValue;
            if (forceUpdate || !_.filter(newVals, {children: [{id: selectedVal.id, type: selectedVal.type}]}).length) {
                WidgetBuilderService.updateDataSourceSelectedValues(vm.data.dataSourceSelect);
                vm.data.datasource = vm.data.dataSourceSelect.selectedValue;
                _onDataSourceChanged(null, vm.data.dataSourceSelect.selectedValue);
            }
        }, 0);
    }

    /**
     * Will set appropriate column selections based on widget type and temp store unused columns
     * @param widgetType
     * @private
     */
    function _updateColumnSelections(widgetType) {
        if (WidgetBuilderService.getTempGroupedColumns().length) {
            WidgetBuilderService.setGroupedColumns(WidgetBuilderService.getTempGroupedColumns());
            WidgetBuilderService.setTempGroupedColumns([]);
        }
        if (!widgetType.can_group_by) {
            WidgetBuilderService.setTempGroupedColumns(vm.data.groupedColumnsSelect.selectedValues);
            WidgetBuilderService.setGroupedColumns([]);
        }
    }

    /**
     * @returns {WidgetBuilderModel}
     */
    function getWidgetModel() {
        return WidgetBuilderService.getWidgetModel();
    }

    function _reset() {
        vm.data = null;
        vm.state = WidgetBuilderDataModelFactory.getTabState();
    }

    function _closeLiveFilterSection() {
        vm.showLiveFilters = false;
    }

    function _closeLiveFormulasSection() {
        vm.showLiveFormulas = false;
    }

    function _updateWidgetTitle(newTitle) {
        if (!_.isNull(vm.data)) {
            vm.data.title = newTitle;
            _init();
        }
    }

    function _updateGroupedColumn(layer) {
        $timeout(function() {
            const widgetModel = getWidgetModel();
            const selectedLayers =  widgetModel.metadata.draw_options.layers;
            const layers = widgetModel.metadata.data_source.mapbox_config?.layers;
            layer = _.maxBy(selectedLayers, (layer) => LAYERS_PRIORITY[layer]);
            const dataView = vm.data?.dataViewSelect?.selectedValue?.id || widgetModel.data_view_id || widgetModel.metadata.data_source.data_view;
            const fieldName = layers[dataView][layer];
            let values = vm.data.groupedColumnsSelect.selectedValues;
            vm.data.groupedColumnsSelect.values.forEach(function(value) {
                value.children.forEach(node => {
                    if ([THE_TRADE_DESK_ID, FACEBOOK_ADS_ID].includes(widgetModel.metadata.data_source.id)) {
                        if(node.groupby_name_field === fieldName) {
                            values = [node];
                        }
                    } else if(node.field === fieldName) {
                        values = [node];
                    }
                })
            });
            vm.data.groupedColumnsSelect.selectedValues = values;
            vm.data.groupedColumnsSelect.options.updateSelectValues(values);
            _onGroupColumnChanged();
        }, 0);
    }

    function _setAllSelectEvents() {
        _setDataSourceSelectEvents();
        _setDataViewSelectEvents();
        _setSelectedColumnsSelectEvents();
        _setSelectedBenchmarkColumnsSelectEvents();
        _setGroupedColumnsSelectEvents();
        _setWidgetAssignmentSelectEvents();
    }

    function _setDataSourceSelectEvents() {
        if (vm.data.dataSourceSelect) {
            vm.data.dataSourceSelect.onChange = _onDataSourceChanged;
        }
    }

    function _setDataViewSelectEvents() {
        if (vm.data.dataViewSelect) {
            vm.data.dataViewSelect.onChange = _onDataViewChanged;
        }
    }

    function _setWidgetAssignmentSelectEvents() {
        if (vm.data.widgetAssignmentSelect) {
            vm.data.widgetAssignmentSelect.onChange = _onWidgetAssignmentChanged;
        }
    }

    /**
     * Force the Sample Data if no assignment is selected
     * and notify a message saying select an assignment
     */
    function forceSampleDataAndNotify() {
        // force sample data if assignment is not selected
        vm.showSampleData = true;
        WidgetBuilderService.setShowSampleData(vm.showSampleData);
        // If assignment is empty the sample data is automatically disabled because actual data call cannot be made without assignment.
        // So notify this message to alert the user
        if (DashboardContextService.resolvePageClient() || !DashboardContextService.pageHasActiveFilter()) {
            UIFactory.notify.showWarning('To turn off sample data, please select an Assignment');
        }
    }

    /**
     * Notify if selected columns contain dynamic metrics or dynamic groubys
     * as changing the assignment leads to change in selected columns.
     * @param selectedColumns
     */
    function checkForDynamicSelectedColumns(selectedColumns) {
        let dynamicSelectedColumns = null;
        if (selectedColumns) {
            dynamicSelectedColumns = _.filter(selectedColumns, (selectedColumn) => selectedColumn.is_dynamic_metric || selectedColumn.is_dynamic_groupby);
            if (dynamicSelectedColumns.length) {
                UIFactory.notify.showWarning('Changing the selected assignment will impact on demand widget configuration <br/> by removing fields that this assignment does not have access to');
            }
        }
    }

    /**
     * On Assignment changes update the selected columns and grouped columns
     * to get the respective columns based on the assignments.
     * @param ui
     * @param event
     * @private
     */
    function _onWidgetAssignmentChanged(ui = null, event = {}) {
        // need to show a message changing assignments may lead to change in rebuilding on demand widgets
        if (vm.data.widgetAssignmentSelect.options.multiple) {
            const threshold = vm.data.dataSourceSelect.selectedValue.live_assignments_threshold;
            if (event.val.length > threshold) {
                $timeout(function() {
                    const selectedValues = vm.data.widgetAssignmentSelect.selectedValues.slice(0, threshold);
                    vm.data.widgetAssignmentSelect.selectedValues = selectedValues;
                    vm.data.widgetAssignmentSelect.options.updateSelectValues(selectedValues);
                }, 0, false);
                UIFactory.notify.showWarning(`This widget has a limit of ${threshold} assignments total for the client, client group, or business unit`, {autoHideDelay: 4000});
                return;
            }
        }
        let selectedColumns = vm.data.selectedColumnsSelect.selectedValues;
        let groupedColumns = vm.data.groupedColumnsSelect.selectedValues;
        let selectedFilters = vm.data.filtersColumnsSelect.selectedValues;
        vm.data.selectedColumnsSelect = null;
        vm.data.groupedColumnsSelect = null;
        _updateWidgetAssignmentSelectValue();
        $timeout(function() {
            let dataSource = WidgetBuilderDataModelFactory.getDataSourceModel(
                vm.data.dataSourceSelect.selectedValue,
                vm.data.dataViewSelect.selectedValue
            );
            checkForDynamicSelectedColumns(selectedColumns);
            if (!vm.data.widgetAssignmentSelect?.selectedValues) {
                // The dynamic metrics are specific to a assignment. Since, assignment is removed, we must remove the dynamic metrics from selected columns
                selectedColumns = selectedColumns.filter((column) => !column.is_dynamic_metric && !column.is_dynamic_groupby);
                groupedColumns = groupedColumns.filter((column) => !column.is_dynamic_groupby);
                forceSampleDataAndNotify();
            }
            _updateSelectedColumnSelect(dataSource, selectedColumns);
            _updateGroupedColumnSelect(dataSource, groupedColumns);
            if (!vm.useDataCloud) {
                _updateFilterColumnSelect(dataSource, selectedFilters);
                _updateLiveFormulaColumnSelect(selectedColumns);
            }
            WidgetBuilderService.setWidgetAsPreview(vm.data, null).then(() => {
                _updateSelectedColumnValueStates();
                _updateColumnSelectedValues();
            });
        }, 0);
    }

    function _setSelectedColumnsSelectEvents() {
        if (vm.data.selectedColumnsSelect) {
            vm.data.selectedColumnsSelect.onSelected = _onSelectColumnSelected;
            vm.data.selectedColumnsSelect.onChange = _onSelectColumnChanged;
        }
    }

    function _setSelectedBenchmarkColumnsSelectEvents() {
        if (vm.data.selectedBenchmarkColumnsSelect) {
            vm.data.selectedBenchmarkColumnsSelect.onSelected = _onSelectBenchmarkColumnSelected;
            vm.data.selectedBenchmarkColumnsSelect.onChange = _onSelectBenchmarkColumnChanged;
        }
    }

    function _setGroupedColumnsSelectEvents() {
        if (vm.data.groupedColumnsSelect) {
            vm.data.groupedColumnsSelect.onChange = _onGroupColumnChanged;
        }
    }

    /**
     * @returns {boolean}
     */
    function widgetTypeCanGroup() {
        if (!getWidgetModel()) {
            return false;
        }
        return (WidgetFactory.getWidgetType(getWidgetModel().type).can_group_by)
    }

    /**
     * @returns {boolean}
     */
    function widgetTypeCanDrilldown() {
        const model = getWidgetModel();
        if (!model) {
            return false;
        }
        return model.type !== WidgetType.GEOCHART && model.type !== WidgetType.BUBBLECHART && (WidgetFactory.getWidgetType(getWidgetModel().type).can_drilldown)
    }

    /**
     * @returns {boolean}
     */
    function widgetColumnsCanMultiGroup() {
        return vm.data.groupedColumnsSelect
            && vm.data.groupedColumnsSelect.selectedValues.length > 1;
    }

    /**
     * @returns {boolean}
     */
    function canTimeGroup() {
        let groupedSelect = vm.data.groupedColumnsSelect;
        if (_.isNil(groupedSelect)) {
            return false;
        }
        let selectedValues = groupedSelect.selectedValues;
        if (_.isEmpty(selectedValues)) {
            return false;
        }
        return !_.isNull(getTimeGrouping()) && _.filter(selectedValues, {is_primary_date_field: true}).length;
    }

    /**
     * @returns {*}
     */
    function getTimeGrouping() {
        let model = getWidgetModel();
        return model ? model.metadata.time_grouping : null;
    }

    function getWeeklyStartDay() {
        let model = getWidgetModel();
        return model ? model.metadata.weekly_start_day : null;
    }

    function goToSelectColumnConfig() {
        vm.state.isConfiguringColumns = true;
    }

    /**
     * @returns {string}
     */
    function showCustomDataViewMessage() {
        let dataSource = vm.data.dataSourceSelect.selectedValue;
        return dataSource.type === DataSourceType.SERVICE_DATA
            && dataSource.id !== null
            && !_.isEmpty(getCustomDataViewMessageText());
    }


    /**
     *
     * @returns {boolean}
     */
    function showDimensionTooltip() {
        let dataSource = vm.data.dataSourceSelect.selectedValue;
        return dataSource.type === DataSourceType.GOAL_DATA;
    }

    /**
     * @returns {boolean}
     */
    function showDataCategoryMessageForLive() {
        let dataSource = vm.data && vm.data.dataSourceSelect && vm.data.dataSourceSelect.selectedValue;
        if(dataSource && dataSource.type === DataSourceType.SERVICE_DATA && dataSource.id === ADOBE_SERVICE_ID) {
            return true;
        }
        return false;
    }

    /**
     * @returns {string}
     */
    function getCustomDataViewMessageText() {
        let dataSource = vm.data.dataSourceSelect.selectedValue;
        return _.includes(CustomDataViewMessage.IDS, dataSource.id)
            ? 'Can\'t find the data set you need? Contact us so we can build a custom ' + dataSource.name + ' data view for you.'
            : '';
    }

    /**
     * Toggles show on demand filters
     */
    function toggleShowLiveFilter() {
        vm.showLiveFilters = !vm.showLiveFilters;
    }

    /**
     * Toggles show on demand formulas
     */
    function toggleShowLiveCallFormulas() {
        vm.showLiveFormulas = !vm.showLiveFormulas;
    }

    /**
     * @returns {string}
     */
    function getCustomAssignmentsMessageText() {
        return `Select up to ${vm.data.dataSourceSelect.selectedValue.live_assignments_threshold} assignments that you want to display`;
    }

    /**
     * @returns {string}
     */
    function getCustomAggregationTooltipMessage() {
        return `Certain metrics offer custom aggregation options depending on how you want to present your data. In many cases, the default of "Do not aggregate" will show dashes because aggregating this field is not applicable from the source.  You can choose to change this to other options in the list as you see fit`;
    }

    function getDataCategoryMessageTextForLive() {
        return LIVE_DATA_CATEGORY_MESSAGE_TEXT;
    }

    /**
     * @returns {string}
     */
    function getIconFilterText() {
        if (vm.showLiveFilters) {
            return 'Close On Demand Calls Section (unsaved filters will be removed)';
        } else {
            return 'Add On Demand Calls Filters';
        }
    }

    /**
     * @param ui
     * @param event
     * @private
     */
    function _onDataSourceChanged(ui = null, event = {}) {
        WidgetBuilderService.resetMapboxLayers();
        vm.data.dataViewSelect = null;
        vm.data.selectedColumnsSelect = null;
        vm.data.groupedColumnsSelect = null;
        vm.data.widgetAssignmentSelect = null;
        vm.data.filtersColumnsSelect = null;
        vm.data.liveFormulaSelect = null;
        let dataSource = ui ? WidgetBuilderDataModelFactory.getDataSourceModel(event.added) : WidgetBuilderDataModelFactory.getDataSourceModel(event);
        setCanShowAssignments(dataSource, vm.useDataCloud);
        resetSortMetadata();
        _updateDataSourceSelects(dataSource);
    }

    /**
     *
     * @param {*} dataSource
     */
    function _updateDataSourceSelects(dataSource) {
        vm.state.isLoading = true;
        vm.setLoading({value: true});
        $timeout(async function() {
            if (WidgetBuilderService.canSelectDataView(dataSource.type)) {
                _updateDataViewSelect(dataSource);
                if (!vm.useDataCloud && vm.canShowAssignments) {
                    await _updatewidgetAssignmentSelect(dataSource);
                    if (!vm.data.widgetAssignmentSelect?.selectedValues || vm.data.widgetAssignmentSelect.selectedValues.length === 0) {
                        // if assignment is empty
                        forceSampleDataAndNotify();
                    }
                }
            }
            _updateSelectedColumnSelect(dataSource);
            _updateGroupedColumnSelect(dataSource);
            if (!vm.useDataCloud) {
                _updateFilterColumnSelect(dataSource);
                _updateLiveFormulaColumnSelect();
            }
            WidgetBuilderService.setWidgetAsPreview(vm.data, null).then(() => {
                _updateSelectedColumnValueStates();
                _updateColumnSelectedValues();
                vm.state.isLoading = false;
                vm.setLoading({value: false});
            });
        }, 0);
    }

    /**
     * @param ui
     * @param event
     * @private
     */
    function _onDataViewChanged(ui, event) {
        WidgetBuilderService.resetMapboxLayers();
        vm.data.selectedColumnsSelect = null;
        vm.data.groupedColumnsSelect = null;
        vm.data.filtersColumnsSelect = null;
        vm.data.liveFormulaSelect = null;


        $timeout(function() {
            let dataSource = WidgetBuilderDataModelFactory.getDataSourceModel(
                vm.data.dataSourceSelect.selectedValue,
                event.added
            );
            _updateSelectedColumnSelect(dataSource);
            _updateGroupedColumnSelect(dataSource);

            if (getWidgetModel().has_live_integration) {
                _updateFilterColumnSelect(dataSource);
                _updateLiveFormulaColumnSelect();
            }

            WidgetBuilderService.setWidgetAsPreview(vm.data, null).then(() => {
                _updateSelectedColumnValueStates();
                _updateColumnSelectedValues();
            });
            WidgetBuilderService.setDataViewId(vm.data.dataViewSelect.selectedValue.id);
            WidgetBuilderService.updateWidgetTypeStates(dataSource);

            _checkWidgetTypeDataViewCompatibility(dataSource);
        }, 0);
    }

    function _checkWidgetTypeDataViewCompatibility(dataSource) {
        const { type } = WidgetBuilderService.getWidgetModel();

        if (WidgetUtilService.isGeoChart(type) &&
          !dataSource.data_view_is_geo) {
            const { widgetTypes } = WidgetBuilderService.getPanelData();
            PubSub.emit($WidgetBuilderEvents.UPDATE_WIDGET_TYPE, widgetTypes[1]);
        }
    }

    /**
     * @param event
     * @private
     */
    function _onSelectColumnSelected(event) {
        let _selectedColumns = _.concat(vm.data.selectedColumnsSelect.selectedValues, [event.choice]);

        const lastObject = _.last(_selectedColumns);
        const isLastDiscrepancyWarningNull = _.isNull(lastObject.possible_discrepancy_warning);
        if (!isLastDiscrepancyWarningNull) {
            vm.showMetricTips = true;
            if (vm.toggleAllWarningCardsTextDisplay === DrawOption.CARD_TEXT_DISPLAY_SHOW) {
                toggleAllWarningCards(vm.toggleAllWarningCardsTextDisplay);
            }
        }

        event.choice.color = WidgetBuilderService.getColumnColor(event.choice, _selectedColumns);
    }

    /**
     * @param event
     * @private
     */
    function _onSelectBenchmarkColumnSelected(event) {
        let _selectedBenchmarkColumns = _.concat(vm.data.selectedColumnsSelect.benchmarkSelector.selectedValues, [event.choice]);

        event.choice.color = WidgetBuilderService.getColumnColor(event.choice, _selectedBenchmarkColumns);
    }

    function _onSelectBenchmarkColumnChanged() {
        _updateWidgetDisplay();
        _updateSelectedBenchmarkColumnSelectedValues();
    }

    function _onSelectColumnChanged() {
        _updateWidgetDisplay();
        _updateSelectedColumnSelectedValues();
    }

    function _onGroupColumnChanged(ui, event) {
        let widgetModel = getWidgetModel();
        if (WidgetUtilService.isDataGrid(widgetModel.type)
            || WidgetUtilService.isSerialChart(widgetModel.type)) {
            if (event.added) {
                vm.data.selectedColumnsSelect.selectedValues.unshift(
                    WidgetBuilderService.getMatchingSelectColumn(vm.data.selectedColumnsSelect, event.added)
                );
            } else if (event.removed) {
                let removedCol = WidgetBuilderService.getMatchingSelectColumn(vm.data.selectedColumnsSelect, event.removed)
                vm.data.selectedColumnsSelect.selectedValues = vm.data.selectedColumnsSelect.selectedValues.filter(column => column.field !== removedCol.field);
            }
            $timeout(() => {
                _updateSelectedColumnValueStates();
                _updateSelectedColumnSelectedValues();
            }, 0);
        }
        _updateGroupedColumnSelectedValues();
        _updateWidgetDisplay();
        if (WidgetUtilService.isGroupedColumnPlotType(widgetModel.metadata.draw_options)) {
            if (event.added) {
                let newUserGroupedColumnObject = {name:'', color:'',values:[event.added.id]};
                let index = _.findIndex(widgetModel.metadata.user_grouped_columns, userGroupedColumn => _.isEmpty(userGroupedColumn.name) && _.isEmpty(userGroupedColumn.color));
                if (index > -1) {
                    widgetModel.metadata.user_grouped_columns[index].values.push(event.added.id);
                } else {
                    widgetModel.metadata.user_grouped_columns.splice(0,0,newUserGroupedColumnObject);
                }
            }
        }
    }

    /**
     * @param {DataSourceModel} dataSource
     * @private
     */
    function _updateDataViewSelect(dataSource) {
        let widgetModel = getWidgetModel();
        vm.data.dataViewSelect = WidgetBuilderDataModelFactory.getDataViewSelect(dataSource, widgetModel.has_live_integration, WidgetUtilService.isGeoChart(widgetModel.type));
        _setDataViewSelectEvents();
    }

    /**
     * Update the Widget Assignments Drop Down based on
     * datasource of the widget and
     * selected entity associated to the dashboard
     * @param dataSource
     * @private
     */
    async function _updatewidgetAssignmentSelect(dataSource) {
        vm.data.widgetAssignmentSelect = await WidgetBuilderDataModelFactory.getWidgetAssignmentSelect(dataSource, null);
        _setWidgetAssignmentSelectEvents();
    }


    /**
     * Update the Widget Assignments Drop Down based on
     * datasource of the widget and
     * selected entity associated to the dashboard
     * @private
     */
    async function _updateWidgetAssignmentSelectState() {
        const dataSource = getWidgetModel().metadata.data_source;
        vm.data.widgetAssignmentSelect = await WidgetBuilderDataModelFactory.getWidgetAssignmentSelect(dataSource, null);
        _setWidgetAssignmentSelectEvents();
    }

    /**
     * @param {DataSourceModel} dataSource
     * @param selectedColumns
     * @param selectedBenchmarks
     * @private
     */
    function _updateSelectedColumnSelect(dataSource, selectedColumns = null, selectedBenchmarks = null) {
        let widgetModel = getWidgetModel();
        if (vm.data.dataViewSelect) {
            dataSource.data_view = vm.data.dataViewSelect.selectedValue.id;
        }
        const assignments = WidgetBuilderDataModelFactory.getSelectedAssignmentsFromWidget(vm.data.widgetAssignmentSelect);
        vm.data.selectedColumnsSelect = WidgetBuilderDataModelFactory.getSelectedColumnsSelect(
            dataSource,
            selectedColumns,
            widgetModel.type,
            widgetModel.has_live_integration,
            assignments,
            selectedBenchmarks
        );
        vm.data.selectedBenchmarkColumnsSelect = vm.data.selectedColumnsSelect.benchmarkSelector;
        _setSelectedColumnsSelectEvents();
        _setSelectedBenchmarkColumnsSelectEvents();
    }

    /**
     * @param {DataSourceModel} dataSource
     * @param groupedColumns
     * @private
     */
    function _updateGroupedColumnSelect(dataSource, groupedColumns = null) {
        let widgetModel = getWidgetModel();
        const assignments = WidgetBuilderDataModelFactory.getSelectedAssignmentsFromWidget(vm.data.widgetAssignmentSelect);
        vm.data.groupedColumnsSelect = WidgetBuilderDataModelFactory.getGroupedColumnsSelect(dataSource, groupedColumns, widgetModel.has_live_integration, assignments);
        _setGroupedColumnsSelectEvents();
    }

    /**
     * @param {DataSourceModel} dataSource
     * @param groupedColumns
     * @private
     */
    function _updateFilterColumnSelect(dataSource, fitlerColumns = null) {
        let widgetModel = getWidgetModel();

        if(_.isEmpty(widgetModel.metadata.dynamic.filters)) {
            vm.showLiveFilters = false;
            PubSub.emit($WidgetBuilderEvents.CLEAR_FILTERS);
        }

        const assignments = WidgetBuilderDataModelFactory.getSelectedAssignmentsFromWidget(vm.data.widgetAssignmentSelect);
        vm.data.filtersColumnsSelect = WidgetBuilderDataModelFactory.getFilterColumnsSelect(dataSource, fitlerColumns, widgetModel.type, widgetModel.has_live_integration, assignments);
    }

    function _updateLiveFormulaColumnSelect(selectedColumns = null) {
        let widgetModel = getWidgetModel();
        vm.data.liveFormulaSelect = WidgetBuilderDataModelFactory.getLiveFormulaSelect(selectedColumns || vm.data.selectedColumnsSelect?.selectedValues, widgetModel);
        PubSub.emit($WidgetBuilderEvents.UPDATE_LIVE_FORMULAS);
    }

    function _updateWidgetDisplay() {
        $timeout(function() {
            WidgetBuilderService.updatePreviewWidgetFromData(vm.data);
            _setIsSortable();
        }, 0);
    }

    function _setIsSortable() {
        vm.data.selectedColumnsSelect.options['isSortable'] = true;
        vm.data.groupedColumnsSelect.options['isSortable'] = true;
    }

    /**
     * @private
     */
    function _registerEvents() {
        PubSub.on($WidgetBuilderEvents.INIT_DATA_TAB, _init);
        PubSub.on($WidgetBuilderEvents.UPDATE_COLUMN_VALUE_STATES, _updateSelectedColumnValueStates);
        PubSub.on($WidgetBuilderEvents.UPDATE_COLUMN_SELECTED_VALUES, _updateColumnSelectedValues);
        PubSub.on($WidgetBuilderEvents.UPDATE_COLUMN_SELECTIONS, _updateColumnSelections);
        PubSub.on($WidgetBuilderEvents.RESET_DATA_TAB, _reset);
        PubSub.on($WidgetBuilderEvents.CLOSE_LIVE_FILTERS_SECTION, _closeLiveFilterSection);
        PubSub.on($WidgetBuilderEvents.CLOSE_LIVE_FORMULAS_SECTION, _closeLiveFormulasSection);
        PubSub.on($WidgetHeaderEvents.REFRESH_TITLE, _updateWidgetTitle);
        PubSub.on($MapBoxEvents.LAYER_UPDATE, _updateGroupedColumn);
    }

    /**
     * @private
     */
    function _unregisterEvents() {
        PubSub.off($WidgetBuilderEvents.INIT_DATA_TAB, _init);
        PubSub.off($WidgetBuilderEvents.UPDATE_COLUMN_VALUE_STATES, _updateSelectedColumnValueStates);
        PubSub.off($WidgetBuilderEvents.UPDATE_COLUMN_SELECTED_VALUES, _updateColumnSelectedValues);
        PubSub.off($WidgetBuilderEvents.UPDATE_COLUMN_SELECTIONS, _updateColumnSelections);
        PubSub.off($WidgetBuilderEvents.RESET_DATA_TAB, _reset);
        PubSub.off($WidgetBuilderEvents.CLOSE_LIVE_FILTERS_SECTION, _closeLiveFilterSection);
        PubSub.off($WidgetBuilderEvents.CLOSE_LIVE_FORMULAS_SECTION, _closeLiveFormulasSection);
        PubSub.off($WidgetHeaderEvents.REFRESH_TITLE, _updateWidgetTitle);
        PubSub.off($MapBoxEvents.LAYER_UPDATE, _updateGroupedColumn);
    }

    function openWidgetHeaderModal() {
        const widget = WidgetBuilderService.getWidgetModel();
        vm.data.id = widget.id;
        WidgetHeaderModalFactory.initModal(vm.data, widget);
    }

    function disableGroupBySelect() {
        const widget = WidgetBuilderService.getWidgetModel();
        const plot_type = widget?.metadata?.draw_options?.plot_type;
        if (!plot_type || plot_type !== 'mapbox') {
            return false;
        }
        const {layers} = widget?.metadata?.draw_options;
        if (layers && !_.isEmpty(layers)) {
            return !!layers.filter(layer => layer !== 'latlong').length;
        }
        return false;
    }

    function closeWarningCard (card) {
        WidgetBuilderUIService.closeProblematicMetricCard(card);
        updateCardsDisplayTextStatus();
    }

    function toggleAllWarningCards (toggleState){
        WidgetBuilderUIService.toggleAllProblematicMetricCards(toggleState);
        updateCardsDisplayTextStatus();
    }

    function togglePosition () {
        WidgetBuilderUIService.togglePositionRelativeAbsolute();
        updateCardsDisplayTextStatus();
    }

    function updateCardsDisplayTextStatus() {
        // Check if all cards are hidden
        const allHidden = WidgetBuilderUIService.checkAllCardsStatus()
        vm.toggleAllWarningCardsTextDisplay = allHidden ? DrawOption.CARD_TEXT_DISPLAY_SHOW : DrawOption.CARD_TEXT_DISPLAY_HIDE;
        vm.iconEyeStatus = allHidden ? 'icon-eye' : 'icon-eye-slash';
    }

    function calculateCardStyle (index) {
        const positionStatus = WidgetBuilderUIService.getPositionStatus();
        vm.applyBottomMargin = { marginBottom: positionStatus === DrawOption.POSITION_RELATIVE ? '0px' : '185px' };

        //when the position is absolute below logic is to handle the margin-bottom
        if (positionStatus !== DrawOption.POSITION_RELATIVE) {
            vm.applyBottomMargin = { marginBottom: vm.toggleAllWarningCardsTextDisplay === DrawOption.CARD_TEXT_DISPLAY_SHOW ? '0px' : '185px' };
        }

        return {
            bottom: index < 3 ? `${index * 3}px` : '9px',
            position: positionStatus,
        };
    }

    function getDiscrepancyWarningFilteredObj() {
        const getFilteredData = WidgetBuilderUIService.getProblematicMetricData(vm.data.selectedColumnsSelect.selectedValues, 'possible_discrepancy_warning');
        if (getFilteredData.length === 0) {
            vm.showMetricTips = false;
            return ;
        }

        return getFilteredData;
    }

    /**
     * Helper function show Date group by alert message for embed sparkline
     * @returns {boolean}
     */
    function showEmbedSparklineDateAlertMessage () {
        const widget = getWidgetModel();
        if (!widget) {
            return false;
        }
        const hasGroupedFieldLogDate = _.some(widget.metadata.data_columns.grouped, (column) => {
                                                    return column.format === ColumnFormat.FORMAT_DATETIME || column.format === ColumnFormat.FORMAT_DATE;
                                                });

        if (hasGroupedFieldLogDate && WidgetUtilService.isEmbeddedSparklinesPlotType(widget.metadata.draw_options)) {
            vm.embedSparklineAddDateAlertMessage = "Sparklines are not visible because data has been grouped by Date. Change the Dimension to see sparklines.";
            return true;
        }
        return false;
    }

    /**
     * Helper function to remove the conditional columns on removing selectedcolumn
     */
    function removeSelectedConditionalColumns() {
        const widget = WidgetBuilderService.getWidgetModel();
        let selectedColumnValues= [];
        let selectedColumnGroupByValues= [];
        _.forEach(vm.data.selectedColumnsSelect.selectedValues, (selectedValue) => {
            selectedColumnValues.push(selectedValue.id);
            selectedColumnGroupByValues.push(selectedValue.groupby_id_field);
        });

        let selectedConditionalColumns = [];
        _.forEach(widget.metadata.user_conditional_columns, (userConditionalColumn, conditionalColumnIndex) => {
            _.forEach(userConditionalColumn.values, (value) => {
                selectedConditionalColumns.push(value);
            });
        });
        let removedSelectedColumns = _.filter(selectedConditionalColumns, selectedColumn => {
            return !selectedColumnValues.includes(selectedColumn) && !selectedColumnGroupByValues.includes(selectedColumn);
        })
        if (!_.isEmpty(removedSelectedColumns)) {
            _.forEach(widget.metadata.user_conditional_columns, (userConditionalColumn, conditionalColumnIndex) => {
                _.forEach(removedSelectedColumns, (removedColumn, removedColumnIndex) => {
                    if (userConditionalColumn?.values?.includes(removedColumn)) {
                        userConditionalColumn.values.splice(userConditionalColumn.values.indexOf(removedColumn), 1);
                    }
                });
                if (_.isEmpty(userConditionalColumn['values'])) {
                    widget.metadata.user_conditional_columns.splice(conditionalColumnIndex,1);
                }
            });
        }
    }

    /**
     * Helper function to remove the embed sparkline metrics on removing selectedcolumn
     */
    function removeSelectedEmbedSparklineColumn() {
        const widget = WidgetBuilderService.getWidgetModel();
        let selectedColumnValues = [];

        // Collect selected column IDs
        selectedColumnValues = _.map(vm.data.selectedColumnsSelect.selectedValues, (selectedValue) => {
            return selectedValue.id;
        });

        if (
            !_.isEmpty(widget.metadata.fields_to_embed_sparklines) &&
            widget.metadata.fields_to_embed_sparklines[0] &&
            _.isArray(widget.metadata.fields_to_embed_sparklines[0].values)
        ) {
            let userEmbedSparklineColumn = widget.metadata.fields_to_embed_sparklines[0];

            // Proceed only if values array length is greater than 0
            if (userEmbedSparklineColumn.values.length > 0) {
                for (let i = userEmbedSparklineColumn.values.length - 1; i >= 0; i--) {
                    let value = userEmbedSparklineColumn.values[i];

                    if (!selectedColumnValues.includes(value)) {
                        // Remove the value if it's not in selectedColumnValues
                        userEmbedSparklineColumn.values.splice(i, 1);
                    }
                }

                // Remove the entire embed sparkline column object if values are empty
                if (_.isEmpty(userEmbedSparklineColumn.values)) {
                    widget.metadata.fields_to_embed_sparklines = [];
                }
            }

        }
    }


    /**
     * Helper function to remove grouped columns on removing selectedcolumn
     */
    function removeSelectedGroupedColumns() {
        const widget = WidgetBuilderService.getWidgetModel();
        let selectedColumnValues = _.map(vm.data.selectedColumnsSelect.selectedValues, selectedValue => {
           return selectedValue.id;
        });
        let selectedColumnGroupByValues = _.map(vm.data.selectedColumnsSelect.selectedValues, selectedValue => {
            return selectedValue.groupby_id_field;
        });
        let selectedGroupedColumns = [];
        _.forEach(widget.metadata.user_grouped_columns, (userGroupedColumn, groupedColumnIndex) => {
            _.forEach(userGroupedColumn.values, (value) => {
                selectedGroupedColumns.push(value);
            });
        });
        let removedSelectedColumns = _.filter(selectedGroupedColumns, selectedColumn => {
            return !selectedColumnValues.includes(selectedColumn) && !selectedColumnGroupByValues.includes(selectedColumn);
        })
        if (!_.isEmpty(removedSelectedColumns)) {
            _.forEach(widget.metadata.user_grouped_columns, (userGroupedColumn, groupedColumnIndex) => {
                _.forEach(removedSelectedColumns, (removedColumn, removedColumnIndex) => {
                    if (userGroupedColumn?.values?.includes(removedColumn)) {
                        userGroupedColumn.values.splice(userGroupedColumn.values.indexOf(removedColumn), 1);
                    }
                });
                if (userGroupedColumn) {
                    if (_.isEmpty(userGroupedColumn['name']) && _.isEmpty(userGroupedColumn['color']) && _.isEmpty(userGroupedColumn['values'])) {
                        widget.metadata.user_grouped_columns.splice(groupedColumnIndex,1);
                    }
                }
            });
        }
    }
}

/**
 * @ngInject
 */
function TimeGroupingDropdownController(
    WidgetBuilderService,
    WidgetBuilderConstants,
    DateFactory,
    DataSourceFactory,
    $injector
) {
    const vm = this;
    vm.timeGroupingList = [];
    vm.weeklyStartDayList = [];

    vm.onChangeTimeGrouping = onChangeTimeGrouping;
    vm.onChangeStartDay = onChangeStartDay;

    const dataSource = WidgetBuilderService.getWidgetModel().metadata.data_source;

    vm.canShowTimeGrouping = !(dataSource.data_view && dataSource.data_view.includes(WidgetBuilderConstants.SITE_AUDITOR));

    if (DataSourceFactory.isSeoWebsiteDataSourceType(dataSource.type)) {
        $injector.get('SeoWebsitesFactory').timegroupings({data_view: dataSource.data_view}).then(setTimeGroupingList);
    } else {
        Promise.all([DateFactory.timeGroupings.get(WidgetBuilderService.getTimeGroupingQueryParams()).then(setTimeGroupingList),
            DateFactory.weeklyStartDays.get().then(setWeeklyStartDayList)]);
    }

    function setTimeGroupingList(data) {
        vm.timeGroupingList = data.plain();
    }

    function setWeeklyStartDayList(data) {
        vm.weeklyStartDayList = data.plain();
    }

    vm.canSelectWeeklyStartDay = () => {
        return vm.timeGrouping === TimeGrouping.GROUPING_WEEKLY;
    };

    function onChangeTimeGrouping() {
        WidgetBuilderService.setTimeGrouping(vm.timeGrouping);
        WidgetBuilderService.updateWidgetDisplay();
    }

    function onChangeStartDay() {
        WidgetBuilderService.setWeeklyStartDay(vm.weeklyStartDay);
        WidgetBuilderService.updateWidgetDisplay();
    }
}


/**
 * @ngInject
 */
function WidgetLiveCallFilterController(
    $scope,
    WidgetBuilderService,
    WidgetBuilderConstants,
    WidgetBuilderDataModelFactory,
    WidgetFilterFactory,
    AppFactory,
    AppModelFactory,
    DataSourceFactory,
    DashboardContextService,
    PubSub,
    RelativeDateRange,
    $WidgetFilterEvents,
    UIFactory,
    gettextCatalog,
    WidgetType,
    FilterParam

) {
    const vm = this;

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.columnUtil = AppFactory.util.column;
    vm.dataTypes = AppFactory.getDataTypes();
    vm.orDisabled = false;
    vm.allFilters = [];
    vm.originalFilters = [];
    vm.defaultAssociation = FilterParam.ASSOCIATION_AND;
    vm.isLoading = false;

    vm.selectOnChange = selectOnChange;
    vm.getSelectedMetricColumns = getSelectedMetricColumns;
    vm.getSelectedAttributeColumns = getSelectedAttributeColumns;
    vm.hasActiveFilters = hasActiveFilters;
    vm.removeFilterColumn = removeFilterColumn;
    vm.saveFilterFn = saveFilterFn;
    vm.isFirst = isFirst;
    vm.isLast = isLast;
    vm.hasModifiedFilters = hasModifiedFilters;
    vm.deleteFilterSet = deleteFilterSet;
    vm.canDeleteFilterSet = canDeleteFilterSet;
    vm.isFirstMetric = isFirstMetric;
    vm.isSingleFilter = isSingleFilter;
    vm.isDisabled = isDisabled;

    function isDisabled(filterColumn) {
        const group = _.find(vm.data.filtersColumnsSelect.values, {key: filterColumn.format});
        if (!group) return false;
        const column = _.find(group.children, {id: filterColumn.field});
        return column?.is_invalid;
    }


    function $onInit() {
        _getPreExistingFilters();
        _resetFilterSelectColumns();
        _setOrIsDisabled();
        _registerEvents();
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    /**
     * @returns {Boolean}
     */
    function isSingleFilter() {
        return vm.allFilters.length === 1;
    }

    /**
     * @returns {Boolean}
     * @private
     */
    function _setOrIsDisabled() {
        // once endpoint is created on microservice side to check if OR is disabled/enabled for a specific service
        //  make call here
        vm.orDisabled = false;
    }

    /**
     * @param {ItemFilterModel} filter
     * @returns {Boolean}
     */
    function isFirst(filter) {
        return filter.order == 0;
    }

    /**
     * @param {ItemFilterModel} filter
     * @returns {Boolean}
     */
    function isFirstMetric(filter) {
        const attributes = vm.getSelectedAttributeColumns();
        return filter.order == attributes.length;
    }

    /**
     * @param {ItemFilterModel} filter
     * @returns {Boolean}
     */
    function isLast(filter) {
        return filter.order == (vm.allFilters.length - 1);
    }

    /**
     * Compares ogirinal filters and current filters to see if there is a change
     * @returns {Boolean}
     */
    function hasModifiedFilters() {
        let currentFilters = angular.copy(vm.allFilters);
        _.forEach(currentFilters, function(filter) {
            if (_isAttribute(filter)) {
                _.forEach(filter.values, function (value) {
                    // order on the value level of an attribute filter should not be compared
                    delete value.order;
                });
            }
        });

        return JSON.stringify(_.pickBy(vm.originalFilters, _.identity)).trim() !==
            JSON.stringify(_.pickBy(currentFilters, _.identity)).trim() && vm.allFilters.length;
    }

    /**
     *
     * @returns {Boolean}
     */
    function canDeleteFilterSet() {
        return vm.widget.metadata.filter_set_id && vm.widget.metadata.dynamic.is_live_filter;
    }


    /**
     * Delete FilterSet of current widget from DB and widget metadata
     */
    function deleteFilterSet() {
        vm.isLoading = true;
        const widgetModel = angular.copy(vm.widget);
        if (widgetModel.id) {
            widgetModel.widget_id = widgetModel.id;
            WidgetFilterFactory.removeFilterSet(widgetModel).then(function () {
                // enable all select columns

                vm.widget.metadata.dynamic.filters = [];
                vm.widget.metadata.dynamic.save_filter = false;
                vm.widget.metadata.dynamic.filter_set_name = null;
                vm.widget.metadata.dynamic.is_live_filter = null;
                vm.widget.metadata.filter_set_id = null;
                vm.allFilters = [];
                vm.originalFilters = [];
                _enableAllFitlerSelectColumns();

                vm.isLoading = false;
                UIFactory.notify.showSuccess(gettextCatalog.getString('Filter set successfully removed from widget'));
                PubSub.emit($WidgetBuilderEvents.CLOSE_LIVE_FILTERS_SECTION);
            });
        }
    }

    /**
     * Save all active filters
     */
    function saveFilterFn() {
        let selectedValues = null;
        vm.allFilters = _.sortBy(angular.copy(vm.allFilters), 'order');
        let hasWarning = false;
        // Need to massage data and validate values for non-empty filter values
        _.each(vm.allFilters, function(filter) {
            if (WidgetFilterFactory.isTextFilter(filter)) {
                let textValues = [];
                // //the first filter should have 'and' association (TA-34136)
                // if (changeAssociation && filter.association === "or") {
                //     filter.association = "and";
                // }
                // changeAssociation = false;
                _.forEach(filter.values, function (value) {
                    let textValueFilter = null;
                    if (_.isEmpty(value.text)) {
                        if (filter.expose_as_dash_filter) {
                            return;
                        }
                        hasWarning = true;
                        textValueFilter = WidgetBuilderDataModelFactory.getTextFilterValuesModel('');
                        // order needs to be kept
                        textValueFilter.order = value.order;
                        textValues.push(textValueFilter);
                        return UIFactory.notify.showWarning('Attribute Filters cannot have an empty value.');
                    }
                    // order needs to be kept
                    textValueFilter = WidgetBuilderDataModelFactory.getTextFilterValuesModel(value.text);
                    // order needs to be kept
                    textValueFilter.order = value.order;
                    textValues.push(textValueFilter);
                });
                selectedValues = textValues;
            } else if (WidgetFilterFactory.isNumericFilter(filter)) {
                const warningMessage = 'Metric Filters should have either a min, a max or both.';
                if (_.isEmpty(filter.values)) {
                    hasWarning = true;
                    return UIFactory.notify.showWarning(warningMessage);
                }
                filter.values.min = _.isUndefined(filter.values.min) ? null : filter.values.min;
                filter.values.max = _.isUndefined(filter.values.max) ? null : filter.values.max;
                let isEmpty = AppFactory.util.isAbsolutelyEmpty;
                if (isEmpty(filter.values.min) && isEmpty(filter.values.max)) {
                    hasWarning = true;
                    return UIFactory.notify.showWarning(warningMessage);
                }
                selectedValues = filter.values;
            }

            filter.values = selectedValues;
        });

        if (hasWarning) {
            return;
        }

        // if Single metric always needs to be AND association
        if (isSingleFilter()) {
            vm.allFilters[0].association = FilterParam.ASSOCIATION_AND;
        }

        if (_.isEmpty(vm.allFilters)) {
            return UIFactory.notify.showWarning('Minimum of one filter needed to apply filters');
        }

        vm.widget.metadata.dynamic.filters = vm.allFilters;
        vm.widget.metadata.dynamic.save_filter = true;
        vm.originalFilters = angular.copy(vm.allFilters);

        // Original Filters should not have order text properties
        _.forEach(vm.originalFilters, function(filter) {
            if (_isAttribute(filter)) {
                _.forEach(filter.values, function (value) {
                    // order on the value level of an attribute filter should not be compared
                    delete value.order;
                });
            }
        });

        UIFactory.notify.showSuccess(gettextCatalog.getString('Filter set successfully saved. Save the widget for the filterset to be applied.'))
    }


    /**
     * Remove filter
     * @param {ItemFilterModel} item
     */
    function removeFilterColumn(item) {
        _changeSelectColumnStatus(item, false);
        vm.allFilters = _.filter(vm.allFilters, function(filter) {
           return filter.order !== item.order;
        });
        vm.allFilters = _setOrdersAfterRemoval(item.order);
        vm.allFilters = _.sortBy(vm.allFilters, 'order');
    }

    /**
     * Check if there are any current selected filters
     * @returns {number}
     */
    function hasActiveFilters() {
      return vm.allFilters.length
    }

    /**
     * Flow to occur when column is selected on the drop down select for on demand filters
     */
    function selectOnChange($selectEl) {
        let filterItem = $selectEl.select2('data');
        // Remove the selected in the select2 display
        $selectEl.select2('data', null);

        if (_drawOptionIncompatibleSelection(filterItem)) {
            return UIFactory.notify.showWarning(gettextCatalog.getString('A metric filter cannot be applied when the Total Row is enabled.  Please remove the Total Row Draw Option before applying a Metric filter.'));
        } else {
            _changeSelectColumnStatus(filterItem, true);
        }

        filterItem = _convertToFilterItem(filterItem);
        filterItem = _setOrder(filterItem);
        vm.allFilters.push(filterItem);
        vm.allFilters = _.sortBy(vm.allFilters, 'order');
    }

    /**
     * Returns all Attribute columns (filters)
     * @returns {Array} ItemFilterModel
     */
    function getSelectedAttributeColumns() {
        return  _getAttributeColumns(vm.allFilters || []);
    }

    /**
     * Returns all metics columns (filters)
     * @returns {Array} ItemFilterModel
     */
    function getSelectedMetricColumns() {
        return  _getMetricColumns(vm.allFilters || []);
    }

    /**
     * Validates that metrics were not selected when widget is of type datagrid and total row draw option is enabled
     * @param {FilterItemModel} item
     * @returns {Boolean}
     * @private
     */
    function _drawOptionIncompatibleSelection(item) {
        let includeTotal = vm.widget.metadata.draw_options[DrawOption.SHOW_TOTAL_ROW] || vm.widget.metadata.draw_options[DrawOption.PERCENT_OF_TOTAL];

        return !!(includeTotal && item.is_metric && vm.widget.type === WidgetType.DATAGRID);
    }

    /**
     * On component init, set allFilters to be the pre-existing on demand filters from widget metadata
     * @private
     */
    function _getPreExistingFilters() {
        let filters = [];
        if (vm.widget.metadata.dynamic.is_live_filter) {
            let preExistingFilters = angular.copy(_.toArray(vm.widget.metadata.dynamic.filters));
            _.each(preExistingFilters, function (filter) {
                let filterItem = WidgetBuilderDataModelFactory.getItemFilterModel(filter);
                _changeSelectColumnStatus(filterItem, true);
                if (filterItem.expose_as_dash_filter && filterItem.values.length === 0) {
                    filterItem.values = [WidgetBuilderDataModelFactory.getTextFilterValuesModel(null)];
                    filter.values = filterItem.values;
                }
                filters.push(filterItem);
            });

            filters = _.sortBy(filters, 'order');
        }
        vm.originalFilters = angular.copy(filters);
        vm.allFilters = angular.copy(filters);

        // set default association
        if (vm.allFilters.length) {
            vm.defaultAssociation =  vm.allFilters[0].association;
        }
    }

    /**
     * Sets orders of all other active filters after removal on a filter
     * @param {number} index
     * @returns {array} allFilters
     */
    function _setOrdersAfterRemoval(index) {
        _.forEach(vm.allFilters, function(filter) {
            if ((filter.order || filter.order === 0) && filter.order > index) {
                filter.order -= 1;
            }
        });
        return vm.allFilters
    }

    /**
     * Converts column model to ItemFilterModel
     * @param model
     * @returns {ItemFilterModel} itemModel
     */
    function _convertToFilterItem(model) {
        // inherited from filter select
        delete model.values;
        model.association = vm.defaultAssociation;
        const itemModel = WidgetBuilderDataModelFactory.getItemFilterModel(model);
        if (!model.is_metric) {
            itemModel.values = [];
            itemModel.values.push(WidgetBuilderDataModelFactory.getTextFilterValuesModel(null));
        }
        return itemModel;
    }

    /**
     * Sets correct order to newly created item
     * @returns {ItemFilterModel} item
     * @private
     */
    function _setOrder(item) {
        if (_isAttribute(item)) {
            item.order = _getAttributeColumns(vm.allFilters).length;
            _updateMetricsOrder();
        } else if (_isMetric(item)) {
            item.order = vm.allFilters.length;
        }
        return item;
    }

    /**
     * Increments order of metrics if an attribute was added
     * @private
     */
    function _updateMetricsOrder() {
         _.forEach(vm.allFilters, function(filterItem) {
            if ((filterItem.order || filterItem.order === 0) && _isMetric(filterItem)) {
                filterItem.order += 1;
            }
          });
    }

    /**
     * Modify the select2 data and disable all options or enable all options
     * @private
     */
    function _enableAllFitlerSelectColumns(disableAll = false) {
        _.each(vm.data.filtersColumnsSelect.values, function (group) {
            if (group) {
                _.each(group.children, function (item) {
                    item.disabled = disableAll;
                });
            }
        });
    }

    /**
     * Modify the select2 data to disable or enable the selected item as option
     * @param filterColumn
     * @param {Boolean} disable
     * @private
     */
    function _changeSelectColumnStatus(filterColumn, disable) {
        let group = _.find(vm.data.filtersColumnsSelect.values, {key: filterColumn.format});
        if (group) {
            _.each(group.children, function (item) {
                if (item.id === filterColumn.field) {
                    item.disabled = disable;
                }
            });
        }
    }

    /**
     * Remove filters
     * @private
     */
    function _clearFilters() {
        vm.allFilters = [];
        vm.widget.metadata.dynamic.filters = [];
        vm.widget.metadata.dynamic.save_filter = false;
        vm.originalFilters = [];
    }


    /**
     * @Param {ItemFilterModel} item
     * @returns {Boolean}
     * @private
     */
    function _isAttribute(item) {
        return !item.is_metric;
    }

    /**
     * @Param {ItemFilterModel} item
     * @returns {Boolean}
     * @private
     */
    function _isMetric(item) {
        return item.is_metric;
    }

    /**
     * Filters all columns to return only attribute filters
     * @private
     */
    function _getAttributeColumns(columns) {
            return _.filter(columns, function(column) {
                return !column.is_metric;
            });
    }

    /**
     * Filters all columns to return only metric filters
     * @private
     */
    function _getMetricColumns(columns) {
            return _.filter(columns, function(column) {
                return column.is_metric;
            });
    }

    /**
     * Sets the association of all filters to the same association
     * @param {String} association
     * @private
     */
    function _setAllAssociations(association) {
        let filters = [];
        _.forEach(vm.allFilters, function(filter) {
            filter.association = association;
            filters.push(filter)
        });

        vm.defaultAssociation = association;
        vm.allFilters = filters;
    }

    /**
     * Resets all select filters options to be enabled
     * @private
     */
    function _resetFilterSelectColumns() {
        if (_.isEmpty(vm.allFilters)) {
            _enableAllFitlerSelectColumns();
        }
    }

    /**
     * @private
     */
    function _registerEvents() {
        PubSub.on($WidgetBuilderEvents.CLEAR_FILTERS, _clearFilters);
        PubSub.on($WidgetBuilderEvents.CHANGE_ALL_FILTER_ASSOCIATION, _setAllAssociations);
    }

    /**
     * @private
     */
    function _unregisterEvents() {
        PubSub.on($WidgetBuilderEvents.CLEAR_FILTERS, _clearFilters);
        PubSub.off($WidgetBuilderEvents.CHANGE_ALL_FILTER_ASSOCIATION, _setAllAssociations);

    }
}

/**
 * @ngInject
 */
function LiveFilterItemController(AppFactory,
                                  FilterParam,
                                  WidgetBuilderDataModelFactory,
                                  WidgetFilterFactory,
                                  ReportStudioTemplateDataService,
                                  DesignFactory,
                                  DashboardContextService,
                                  gettextCatalog,
                                  PubSub) {

    const vm = this;

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;

    vm.FilterParam = FilterParam;
    vm.isNumericFilter = isNumericFilter;
    vm.isDateFilter = isDateFilter;
    vm.isTextFilter = isTextFilter;
    vm.isBooleanFilter = isBooleanFilter;
    vm.onClickSetToOR = onClickSetToOR;
    vm.onClickSetToAND = onClickSetToAND;
    vm.filterHasAndAssociation = filterHasAndAssociation;
    vm.filterHasOrAssociation = filterHasOrAssociation;
    vm.addValueInput = addValueInput;
    vm.isLastTextValue = isLastTextValue;
    vm.isFirstValue = isFirstValue;
    vm.removeTextValue = removeTextValue;
    vm.canExposeAsDashFilter = canExposeAsDashFilter;
    vm.dashFilterAlreadyExists = dashFilterAlreadyExists;

    vm.exposeDashFilterTooltip = function () {
        return gettextCatalog.getString(
            'If enabled, {{label}} filter will be interactive at the dashboard level.',
            {
                label: vm.filter.label
            });
    }
    vm.dashFilterAlreadyExistsTooltip = function () {
        return gettextCatalog.getString(
            'Option is currently disabled since the {{label}} filter was already added through another widget.',
            {
                label: vm.filter.label
            });
    }
    function canExposeAsDashFilter() {
        if (ReportStudioTemplateDataService.getIsActive() || DesignFactory.getCurrentPage().is_predefined) {
            return false;
        }
        if (vm.filter.field?.startsWith('dynamic:')) {
            // Cannot expose account specific dynamic filters as dashboard filters
            return false;
        }
        return true;
    }

    /**
     * Check if filter can be exposed as dashboard filter based on if it is already used in another widget
     * @returns {boolean}
     */
    function dashFilterAlreadyExists() {
        return DashboardContextService.dashFilterAlreadyExists(vm.widgetId, vm.filter);
    }

    function $onInit() {
        _setOrders();
    }

    function $onDestroy() {

    }

    function removeTextValue(textItem) {
        vm.filter.values = _.filter(vm.filter.values, function(value) {
            return textItem.order !== value.order;
        });

        vm.filter.values = _setOrdersAfterRemoval(textItem.order);
    }


    function isFirstValue(textItem) {
        return textItem.order === 0;
    }

    function isLastTextValue(textItem) {
        return textItem.order === (vm.filter.values.length - 1);
    }

    function addValueInput() {
        let defaultValueModel = WidgetBuilderDataModelFactory.getTextFilterValuesModel(null);
        defaultValueModel.order = vm.filter.values.length;
        vm.filter.values.push(defaultValueModel);
    }

    function filterHasAndAssociation() {
        return vm.filter.association === FilterParam.ASSOCIATION_AND;
    }

    function filterHasOrAssociation() {
        return vm.filter.association === FilterParam.ASSOCIATION_OR;
    }

    function onClickSetToOR() {
        if (vm.filter.association !== vm.FilterParam.ASSOCIATION_OR) {
            PubSub.emit($WidgetBuilderEvents.CHANGE_ALL_FILTER_ASSOCIATION, vm.FilterParam.ASSOCIATION_OR);
        }
    }

    function onClickSetToAND() {
        if (vm.filter.association !== vm.FilterParam.ASSOCIATION_AND) {
            PubSub.emit($WidgetBuilderEvents.CHANGE_ALL_FILTER_ASSOCIATION, vm.FilterParam.ASSOCIATION_AND);
        }
    }

    function isNumericFilter() {
        return WidgetFilterFactory.isNumericFilter(vm.filter);
    }

    function isDateFilter() {
        return WidgetFilterFactory.isDateFilter(vm.filter);
    }

    function isTextFilter() {
        return  WidgetFilterFactory.isTextFilter(vm.filter);
    }

    function isBooleanFilter() {
        return  WidgetFilterFactory.isBooleanFilter(vm.filter)
    }

    function _setOrdersAfterRemoval(index) {
        _.forEach(vm.filter.values, function(value) {
            if ((value.order || value.order === 0) && value.order > index) {
                value.order -= 1;
            }
        });
        return vm.filter.values
    }

    function _setOrders() {
        if (!vm.filter.is_metric && vm.filter.values.length) {
            _.forEach(vm.filter.values, function (value, i) {
                value.order = i;
            });
        }
    }
}


/**
 * @ngInject
 */
function WidgetLiveCallFormulaController(
    $timeout,
    WidgetBuilderService,
    WidgetBuilderDataModelFactory,
    PubSub,
    UIFactory,
    gettextCatalog
) {
    const vm = this;
    vm.hasActiveFormulas = hasActiveFormulas;
    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.allFormulas = [];
    vm.isLoading = false;
    vm.selectOnChange = selectOnChange;
    vm.formulaOnChange = formulaOnChange;
    vm.removeFormulaColumn = removeFormulaColumn;
    vm.deleteFormulaSet = deleteFormulaSet;
    vm.canDeleteFormulaSet = canDeleteFormulaSet;

    function $onInit() {
        vm.liveFormulaItemSelect = WidgetBuilderDataModelFactory.getLiveFormulaItemSelect();
        _getPreExistingFormulas();
        _registerEvents();
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    /**
     *
     * @returns {Boolean}
     */
    function canDeleteFormulaSet() {
        return vm.allFormulas.length > 0;
    }

    /**
     * Delete FormulaSet of current widget from widget metadata
     */
    function deleteFormulaSet() {
        vm.isLoading = true;
        vm.allFormulas = [];
        _enableAllFormulaSelectColumns();
        vm.isLoading = false;
        updateWidgetMetadataWithFormulas();
        vm.data.liveFormulaSelect.selectedValues = [];
        UIFactory.notify.showSuccess(gettextCatalog.getString('Formulas are removed from widget'));
        PubSub.emit($WidgetBuilderEvents.CLOSE_LIVE_FORMULAS_SECTION);
    }

    /**
     * Save all active formulas
     */
    function updateWidgetMetadataWithFormulas() {
        $timeout(function() {
            const formulaFields = [];
            const formulas = [];
            vm.allFormulas.forEach((formulaColumn) => {
                formulaFields.push(formulaColumn.field);
                formulas.push(formulaColumn.formula.id);
            });
            WidgetBuilderService.setLiveFormulas(formulaFields, formulas);
        }, 0);
    }

    /**
     * Remove formula
     * @param item
     */
    function removeFormulaColumn(item) {
        _changeSelectColumnStatus(item, false);
        vm.data.liveFormulaSelect.selectedValues = vm.data.liveFormulaSelect.selectedValues.filter((value) => value.field !== item.field);
        vm.allFormulas = _.filter(vm.allFormulas, (formulaColumn) => formulaColumn.field !== item.field);
        vm.data.liveFormulaSelect.values.forEach((formulaColumn) => {
            if (formulaColumn.field === item.field) {
                formulaColumn.disabled = false;
            }
        });
        updateWidgetMetadataWithFormulas();
    }

    /**
     * Check if there are any current selected formulas
     * @returns {number}
     */
    function hasActiveFormulas() {
        return vm.allFormulas.length;
    }

    function formulaOnChange($selectEl) {
        updateWidgetMetadataWithFormulas();
    }

    /**
     * Flow to occur when column is selected on the drop down select for on demand formulas
     */
    function selectOnChange($selectEl) {
        let formulaItem = $selectEl.select2('data');
        // Remove the selected in the select2 display
        $selectEl.select2('data', null);
        _changeSelectColumnStatus(formulaItem, true);
        formulaItem.formula = vm.liveFormulaItemSelect.values[0]; // Default formula -> Do not aggregate
        vm.allFormulas.push(formulaItem);
        vm.data.liveFormulaSelect.selectedValues.push(formulaItem);
        updateWidgetMetadataWithFormulas();
    }

    /**
     * On component init, set allFormulas to be the pre-existing on demand formulas
     * @private
     */
    function _getPreExistingFormulas() {
        vm.allFormulas = angular.copy(vm.data.liveFormulaSelect.selectedValues);
    }

    /**
     * Modify the select2 data and disable all options or enable all options
     * @private
     */
    function _enableAllFormulaSelectColumns(disableAll = false) {
        _.each(vm.data.liveFormulaSelect.values, function (item) {
            item.disabled = disableAll;
        });
    }

    /**
     * Modify the select2 data to disable or enable the selected item as option
     * @param formulaColumn
     * @param {Boolean} disable
     * @private
     */
    function _changeSelectColumnStatus(formulaColumn, disable) {
        formulaColumn.disabled = disable;
    }

    /**
     * Remove formulas
     * @private
     */
    function _clearFormulas() {
        vm.allFormulas = [];
        updateWidgetMetadataWithFormulas();
    }

    function _updateFormulas() {
        _getPreExistingFormulas();
        _resetFormulaSelectColumns();
        updateWidgetMetadataWithFormulas();
    }

    /**
     * Resets all select formulas options to be enabled
     * @private
     */
    function _resetFormulaSelectColumns() {
        if (_.isEmpty(vm.allFormulas)) {
            _enableAllFormulaSelectColumns();
        }
    }

    /**
     * @private
     */
    function _registerEvents() {
        PubSub.on($WidgetBuilderEvents.CLEAR_LIVE_FORMULAS, _clearFormulas);
        PubSub.on($WidgetBuilderEvents.UPDATE_LIVE_FORMULAS, _updateFormulas);
    }

    /**
     * @private
     */
    function _unregisterEvents() {
        PubSub.off($WidgetBuilderEvents.CLEAR_LIVE_FORMULAS, _clearFormulas);
        PubSub.off($WidgetBuilderEvents.UPDATE_LIVE_FORMULAS, _updateFormulas);
    }
}

/**
 * @ngInject
 */
function GeoOptionsDropdownController(
    WidgetBuilderService,
    GeoChartFactory
) {
    const vm = this;

    vm.onChange = onChange;

    vm.geoOptions = GeoChartFactory.getGeoList();

    vm.selectedGeoCode = vm.selectedGeoCode || vm.geoOptions[0].map;

    function onChange() {
        const selectedGeoOption = vm.geoOptions.find(option => option.map === vm.selectedGeoCode);
        WidgetBuilderService.setSelectedGeoCode(selectedGeoOption);
        WidgetBuilderService.updateWidgetDisplay();
    }
}

/**
 * @ngInject
 */
function WidgetBuilderColumnsConfigController(
    PubSub,
    AppFactory,
    $timeout,
    WidgetFactory,
    ChartFactory,
    WidgetUtilService,
    ChartAxisService,
    WidgetSortUtilService,
    WidgetBuilderService,
    WidgetBuilderConditionalPlotService,
    DashboardContextService,
    ColumnFormat,
    BulletShapes,
    BulletShapeIcons,
    AppModule,
    $rootScope,
    DataSourceFactory,
    UIColor,
    $scope,
    DesignFactory
) {
    const vm = this;
    vm.DisabledColumnState = DisabledColumnState;
    vm.ConditionalType = ConditionalType;
    vm.ColumnFormat = ColumnFormat;
    vm.WidgetType = WidgetType;

    /**
     * @type {Array}
     * @private
     */
    let _groupedColumns = vm.widget.metadata.data_columns.grouped;
    let tempSelectedLength = 0;
    vm.selectedBenchmarks = vm.widget.metadata.data_columns.benchmarks;
    vm.canAddUserGroupedColumns = true;
    vm.originalSelectedColumns = angular.copy(vm.selectedColumns);
    vm.originalSelectedColumns.values = _.map(vm.originalSelectedColumns, selectedColumn => {
        return {
            id: selectedColumn.id,
            text: selectedColumn.text,
            groupby_id_field: selectedColumn.groupby_id_field,
            field: selectedColumn.field
        }
    });
    vm.userGroupedColumnsSelect = angular.copy(vm.widget.metadata.user_grouped_columns) || [];
    vm.userGroupedColumns = angular.copy(vm.userGroupedColumnsSelect);
    vm.widget.metadata.user_grouped_columns = vm.userGroupedColumns;

    vm.userConditionalColumnsSelect = angular.copy(vm.widget.metadata?.user_conditional_columns) || [];
    vm.userConditionalColumns = angular.copy(vm.userConditionalColumnsSelect);
    vm.widget.metadata.user_conditional_columns = vm.userConditionalColumns;

    vm.userEmbeddedSparklineColumnsSelect = angular.copy(vm.widget.metadata?.fields_to_embed_sparklines) || [];
    vm.userEmbeddedSparklineColumns = angular.copy(vm.userEmbeddedSparklineColumnsSelect);
    vm.widget.metadata.fields_to_embed_sparklines = vm.userEmbeddedSparklineColumns;

    vm.conditionalDropdownValues = [
        { id: ConditionalType.GREATER_THAN, name: 'Greater than' },
        { id: ConditionalType.LESS_THAN, name: 'Less than' },
        { id: ConditionalType.BETWEEN, name: 'Between' }
    ];
    vm.conditionalAddAlertMessage = '';
    vm.embedSparklineAddAlertMessage = '';

    vm.$onInit = $onInit;
    vm.goBackDataConfig = goBackDataConfig;
    vm.getDisabledState = getDisabledState;
    vm.onColorChange = onColorChange;
    vm.onBenchmarkColorChange = onBenchmarkColorChange;
    vm.canShowTapcolors = canShowTapcolors;
    vm.getBgColor = getBgColor;
    vm.getBenchmarkBgColor = getBenchmarkBgColor;
    vm.getSortNum = getSortNum;
    vm.toggleLine = toggleLine;
    vm.onSortCallback = onSortCallback;
    vm.sortableOptions = {update: onSortCallback};
    vm.applySortOrder = applySortOrder;
    vm.canColumnSortable = canColumnSortable;
    vm.applyRounding = applyRounding;
    vm.applyYAxis = applyYAxis;
    vm.canDisplaySortNum = canDisplaySortNum;
    vm.canDisplayColumnColor = canDisplayColumnColor;
    vm.canDisplayComboChartButtons = canDisplayComboChartButtons;
    vm.canDisplayYAxisButton = canDisplayYAxisButton;
    vm.getYAxisButtonIcon = getYAxisButtonIcon;
    vm.isSortableDatagrid = isSortableDatagrid;
    vm.updateComparison = updateComparison;
    vm.getComparisonButtonCaretClass = getComparisonButtonCaretClass;
    vm.canShowUpdateComparison = canShowUpdateComparison;
    vm.canShowMetricGroupsOption = canShowMetricGroupsOption;
    vm.addNewUserGroupedColumn = addNewUserGroupedColumn;
    vm.removeUserGroupedColumn = removeUserGroupedColumn;
    vm.onUserGroupedColumnColorChange = onUserGroupedColumnColorChange;
    vm.onTextChangeCallback = onTextChangeCallback;
    vm.canShowAddUserGroupedColumnButton = canShowAddUserGroupedColumnButton;
    vm.canShowHeatMap = canShowHeatMap;
    vm.canShowHeatMapGradient = canShowHeatMapGradient;
    vm.applyHeatMapGradient = applyHeatMapGradient;
    vm.isGradientActive = isGradientActive;
    vm.canShowConditionalGroupsOption = canShowConditionalGroupsOption;
    vm.canShowEmbeddedSparklineOption = canShowEmbeddedSparklineOption;
    vm.addNewUserConditionalColumn = addNewUserConditionalColumn;
    vm.removeUserConditionalColumn = removeUserConditionalColumn;
    vm.canShowAddUserConditionalColumnButton = canShowAddUserConditionalColumnButton;
    vm.showEmbedSparklineAlertMessage = showEmbedSparklineAlertMessage;
    vm.showEmbedSparklineDateAlertMessage = showEmbedSparklineDateAlertMessage;
    vm.onRuleChange = onRuleChange;
    vm.onMinValueChangeCallback = _.debounce((newValue,index,ruleIndex) => {
        onRuleChange(ConditionalTypeRuleValues.MIN, newValue, index, ruleIndex);
    }, 1000);
    vm.onMaxValueChangeCallback =  _.debounce((newValue,index, ruleIndex) => {
        onRuleChange(ConditionalTypeRuleValues.MAX, newValue, index, ruleIndex);
    }, 1000);
    vm.addNewUserConditionalColumnRule = addNewUserConditionalColumnRule;
    vm.removeUserConditionalColumnRule = removeUserConditionalColumnRule;
    vm.canShowAddRuleButton = canShowAddRuleButton;
    vm.canShowRemoveRuleButton = canShowRemoveRuleButton;
    vm.ruleConditionalDropdownValues = ruleConditionalDropdownValues;
    vm.defaultChartPalette  = defaultChartPalette;
    vm.resetCurrentWidgetColor = resetCurrentWidgetColor;
    vm.canShowBulletShapeToggle = canShowBulletShapeToggle;
    vm.toggleBulletShape = toggleBulletShape;
    vm.getBulletShapeIcon = getBulletShapeIcon;
    vm.canShowBenchmarks = canShowBenchmarks;
    _setUserGroupedColumnsSelectEvents();
    _setUserConditionalColumnsSelectEvents();
    _setEmbeddedSparklineColumnsSelectEvents();
    vm.embedSparklineAddDateAlertMessage = "Sparklines are not visible because data has been grouped by Date. Change the Dimension to see sparklines.";

    function $onInit() {
        // _.each(vm.data.selectedColumnsSelect.selectedValues, function(column) {
        //     column.colorIndex = WidgetBuilderService.getColumnColorIndex(column);
        // });
        defaultChartPalette();
    }

    function showEmbedSparklineDateAlertMessage () {
        const hasGroupedFieldLogDate = _.some(vm.widget.metadata.data_columns.grouped, (column) => {
                                                    return column.format === ColumnFormat.FORMAT_DATETIME || column.format === ColumnFormat.FORMAT_DATE;
                                                });

        return hasGroupedFieldLogDate && WidgetUtilService.isEmbeddedSparklinesPlotType(vm.widget.metadata.draw_options);
    }

    // creating a default chart palette
    function defaultChartPalette(){
        let defaultColorChartPalette = [];
        const chartPalette = DashboardContextService.resolveChartPalette();
        const isMetricOnly = vm.selectedColumns.filter(function (el){ return !el.is_metric});
        const selectedColumnLength = vm.selectedColumns.length;
        let j = isMetricOnly.length;
        for(let i= 0; i < selectedColumnLength; i++){
            defaultColorChartPalette[j++] = ChartFactory.getPaletteColor(i, chartPalette);
        }
        vm.defaultColorChartPalette = defaultColorChartPalette;
    }

    function goBackDataConfig() {
        vm.isConfiguringColumns = false;
        PubSub.emit($WidgetBuilderEvents.UPDATE_COLUMN_SELECTED_VALUES);
    }

    /**
     * @param column
     * @returns {string|*}
     */
    function getDisabledState(column, $index) {
        if (WidgetUtilService.isGaugeChart(vm.widget.type)) {
            if ($index > 1) {
                return DisabledColumnState.UNUSED_METRIC;
            } else if ($index === 0) {
                if (vm.widget.metadata.draw_options[DrawOption.PLOT_TYPE] === ChartPlotType.CLASSIC) {
                    return DisabledColumnState.FIRST_SELECTED_METRIC;
                }
            }
        } else if (WidgetUtilService.isGeoChart(vm.widget.type)) {
            // If plot type is heat map, adjust
            if ($index > 0 && vm.widget.metadata.draw_options[DrawOption.PLOT_TYPE] === ChartPlotType.HEAT_MAP) {
                return DisabledColumnState.UNUSED_METRIC;
            }
        } else if (WidgetUtilService.isBigNumber(vm.widget.type)
            || WidgetUtilService.isSliceChart(vm.widget.type) ) {
            // Non numeric metrics can only be displayed in data grids
            if (!AppFactory.util.column.isNumeric(column.format)) {
                return DisabledColumnState.UNUSED_METRIC;
            }
        }

        // Disabled if selected column is currently being grouped by
        let groupbyNameColumn = AppFactory.arrayToMemoizedObj(_groupedColumns, 'field')[column.groupby_id_field];

        return _groupedColumns.length && !_.isUndefined(groupbyNameColumn)
            ? DisabledColumnState.GROUP_BY
            : false;
    }

    function canShowTapcolors(index) {
        return !(vm.widget.type === vm.WidgetType.BUBBLECHART && index < getUnusedMetricsIndex());
    }

    function canShowBenchmarks() {
        return $rootScope.util.isModuleAvailable(AppModule.BENCHMARKS);
    }

    function getUnusedMetricsIndex() {
        const totalLength = vm.selectedColumns?.length ?? 0;
        if (totalLength) {
            let groupByCount = 0;
            vm.selectedColumns.forEach((column, i) => {
                if (getDisabledState(column, i) === DisabledColumnState.GROUP_BY) {
                    groupByCount += 1;
                }
            });
            return groupByCount + 2;
        }
        return -1;
    }

    /**
     * @param column
     * @returns {*}
     */
    function getBgColor(column) {
        return WidgetBuilderService.getColumnColor(column, vm.selectedColumns);
    }

    /**
     * @param column
     * @returns {*}
     */
    function getBenchmarkBgColor(column) {
        return WidgetBuilderService.getBenchmarkColumnColor(column, vm.selectedBenchmarks, vm.selectedColumns);
    }

    /**
     * @param newColor
     * @param column
     * @param index
     * @returns {*}
     */
    function onColorChange(newColor, column, index = 0) {
        let paletteIndexOffset = 0;
        if (vm.widget.type === WidgetType.BUBBLECHART) {
            paletteIndexOffset = index >= 2 ? -2 : 0;
        }

        changePalette(newColor, column, index, paletteIndexOffset);
    }

    /**
     * @param newColor
     * @param column
     * @param index
     * @returns {*}
     */
    function onBenchmarkColorChange(newColor, column, index = 0) {
        const selectedMetrics = vm.selectedColumns.filter((selectedColumn) => selectedColumn.is_metric);
        changePalette(newColor, column, index, selectedMetrics.length);
    }

    /**
     * Update the actual palette with the provided offset
     */
    function changePalette(newColor, column, index, offset) {
        const currentChartPalette = _getCurrentPalette();
        const paletteIndex = (column.index ?? index) + offset;

        column.color = newColor;
        // Update the current palette
        WidgetBuilderService.setChartPalette(currentChartPalette, newColor, paletteIndex);
        WidgetBuilderService.updateWidgetDisplay();
    }

    /**
     * @returns {*}
     * @private
     */
    function _getCurrentPalette() {
        return DashboardContextService.resolveChartPalette(vm.widget.metadata.chart_palette);
    }

    /**
     * @param column
     * @param isLine
     */
    function toggleLine(column, isLine) {
        let lineColumns = _.isEmpty(vm.widget.metadata.line_columns) ? {} : vm.widget.metadata.line_columns;
        if (isLine != lineColumns[column.field]) {
            if (isLine && !lineColumns[column.field]) {
                lineColumns[column.field] = 1;
            } else if (lineColumns[column.field]) {
                delete lineColumns[column.field];
            }
            vm.widget.metadata.line_columns = lineColumns;
            WidgetBuilderService.updateWidgetDisplay();
        }
    }

    function onSortCallback() {
        vm.onColumnChange();
    }

    /**
     * Helper function to apply sort order
     * @param column
     * @param event
     */
    function applySortOrder(column, event) {
        // Assign the opposite
        column.sorting = column.sorting === 'asc' ? 'desc' : 'asc';
        // Multi sorting
        if (event.shiftKey) {
            WidgetBuilderService.$applyMultiSortOrder(column);
        } else {
            WidgetBuilderService.$applySortOrder(column);
        }
        $timeout(() => {
            vm.onColumnChange();
        }, 0);
    }

    /**
     *
     * @param column
     * @returns {*}
     */
    function canColumnSortable(column) {
        return column.is_sortable;
    }

    function applyRounding(column) {
        WidgetBuilderService.$applyRoundedMetric(column);
    }

    /**
     * Helper function to get sort number
     * @param column
     * @returns {number}
     */
    function getSortNum(column) {
        let sortBy = vm.widget.metadata.sort_by;
        return _.indexOf(sortBy, column.field) + 1;
    }

    /**
     * Helper function to determine if we should display sort number
     * @param column
     * @returns {boolean}
     */
    function canDisplaySortNum(column) {
        let sortBy = vm.widget.metadata.sort_by;
        if (_.isArray(sortBy) && sortBy.length > 1) {
            return _.indexOf(sortBy, column.field) > -1;
        }
        return false;
    }

    /**
     * @returns {boolean}
     */
    function canDisplayColumnColor() {
        // if (WidgetUtilService.isChartWidget(vm.widget.type)) {
        //     return _.isEmpty(_groupedColumns[1]) || vm.widget.metadata.is_multi_grouped;
        // }
        // return false;
        //
        return WidgetUtilService.isChartWidget(vm.widget.type) || canShowHeatMap();
    }

    /**
     * @returns {boolean}
     */
    function canShowHeatMap() {
        return WidgetBuilderService.canApplyHeapMapGradient(vm.widget);
    }

    /**
     * @param column
     * @returns {boolean}
     */
    function canShowHeatMapGradient(column) {
        return AppFactory.util.column.isNumeric(column.format) && canShowHeatMap();
    }

    /**
     * @param column
     */
    function applyHeatMapGradient(column) {
       let gradientValues = vm.widget.metadata.heatmap_gradient || [];
       if(gradientValues.includes(column.field)) {
           gradientValues = gradientValues.filter(function(e) { return e !== column.field })
       } else {
           gradientValues.push(column.field);
       }
        vm.widget.metadata.heatmap_gradient = gradientValues;
        WidgetBuilderService.updateWidgetDisplay();
    }

    /**
     * @param column
     * @returns {boolean}
     */
    function isGradientActive(column) {
        return !!vm.widget.metadata?.heatmap_gradient.includes(column.field);
    }

    /**
     * @param column
     * @returns {boolean}
     */
    function canDisplayComboChartButtons(column) {
        return vm.widget.type === WidgetType.COMBINATIONCHART
            && !_.filter(_groupedColumns, {label: column.label}).length;
    }

    /**
     * Helper function to determine if we should display y-axis button
     * @param column
     * @returns {boolean}
     */
    function canDisplayYAxisButton(column) {
        return ChartAxisService.displayYAxisButton(
            column,
            _groupedColumns,
            vm.widget.type,
            vm.widget.metadata.draw_options
        );
    }

    /**
     * Helper function for getting correct icon for YAxisButtons
     * @param column
     * @returns {boolean}
     */
    function getYAxisButtonIcon(column) {
        const isRotated = vm.widget.metadata.draw_options.is_rotated;
        return column.moving_y_axis?
            (isRotated ? 'icon-arrow-up' : 'icon-arrow-right'):
            (isRotated ? 'icon-arrow-down' : 'icon-arrow-left');
    }

    function isSortableDatagrid() {
        return vm.widget.type === WidgetType.DATAGRID && !vm.widget.metadata.draw_options[DrawOption.PIVOT_GRID];
    }

    /**
     * @param column
     */
    function applyYAxis(column) {
        column.moving_y_axis = !column.moving_y_axis;
        WidgetBuilderService.$applyYAxisPosition(column);
    }

    /**
     * Helper function to update the comparison
     * @param column
     */
    function updateComparison(column) {
        const widget = WidgetBuilderService.getWidgetModel();
        let comparisonOverwrites = widget.metadata.comparison_overwrite;
        if (comparisonOverwrites.includes(column.field)) {
            comparisonOverwrites.splice(comparisonOverwrites.indexOf(column.field), 1);
        } else {
            comparisonOverwrites.push(column.field);
        }
        widget.metadata.comparison_overwrite = comparisonOverwrites;
        WidgetBuilderService.setWidgetModel(widget);
        $timeout(() => {
            vm.onColumnChange();
        }, 0);
    }

    /**
     * Helper function to return the caret class
     * @param column
     * @return {string}
     */
    function getComparisonButtonCaretClass(column) {
        let caretClass = vm.widget.metadata.comparison_overwrite.includes(column.field) ? 'icon-caret-down' : 'icon-caret-up';
        if(column.compare_inverted) {
            caretClass = caretClass === 'icon-caret-down' ? 'icon-caret-up' : 'icon-caret-down';
        }

        return caretClass;
    }

    /**
     * Does the column support a separate bullet shape
     * @param column
     * @param isBenchmark
     * @return {boolean}
     */
    function canShowBulletShapeToggle(column, isBenchmark = false) {
        // benchmarks will always have shapes available if the bullets are enabled
        if (isBenchmark) {
            return vm.widget.metadata.draw_options[DrawOption.BENCHMARK_SHOW_BULLETS];
        }

        if (!WidgetUtilService.isAm5Chart(vm.widget.type, vm.widget.metadata.draw_options[DrawOption.PLOT_TYPE])) {
            return false;
        }

        if (!WidgetUtilService.isBulletedChart(vm.widget.type)) {
            return false;
        }

        if (WidgetUtilService.isComboChart(vm.widget.type)) {
            if (!vm.widget.metadata.line_columns[column.field]) {
                return false;
            }
        }

        if (!vm.widget.metadata.draw_options[DrawOption.HAS_BULLETS]) {
            return false;
        }

        return column.is_metric;
    }

    /**
     * Toggle the bullet shape (click to cycle through the shapes)
     * @param column
     * @param isBenchmark
     */
    function toggleBulletShape(column, isBenchmark = false) {
        const bulletShapes = Object.keys(BulletShapes);

        let currentShape = getBulletShape(column, isBenchmark);
        let currentIndex = 0;
        let nextShape;

        if (!currentShape) {
            currentShape = getDefaultBulletShape();
        }

        bulletShapes.forEach((type, index) => {
            if (type === currentShape) {
                currentIndex = index;
            }
        });

        currentIndex += 1;
        if (currentIndex >= bulletShapes.length) {
            currentIndex = 0;
        }
        nextShape = bulletShapes[currentIndex];

        const container = getBulletMetadataContainer(isBenchmark);
        container[column.field] = nextShape;

        vm.widget.metadata.draw_options[getBulletParam(isBenchmark)] = container;

        WidgetBuilderService.$applyBulletShape();
    }

    /**
     * Get the current bullet type for the column
     * @param column
     * @param isBenchmark
     * @returns {string}
     */
    function getBulletShape(column, isBenchmark = false) {
        const container = getBulletMetadataContainer(isBenchmark);
        return container[column.field];
    }

    /**
     * Gets the container that contains the bullet shape (either for a metric or a benchmark)
     * @param isBenchmark
     * @returns {*|Object}
     */
    function getBulletMetadataContainer(isBenchmark) {
        let container = vm.widget.metadata.draw_options[getBulletParam(isBenchmark)];
        return container || {};
    }

    /**
     * Grab the param name so we can pull it from the metadata -> draw options
     * depending on whether it's a benchmark or a regular metric
     * @param isBenchmark
     * @returns {string}
     */
    function getBulletParam(isBenchmark) {
        return isBenchmark ? 'bullets_per_benchmark' : 'bullets_per_column';
    }

    /**
     * Default bullet shape needs converting to uppercase to match the selections
     * @returns {string}
     */
    function getDefaultBulletShape() {
        if (typeof vm.widget.metadata.draw_options.bullets_shape !== 'undefined') {
            return vm.widget.metadata.draw_options.bullets_shape.toUpperCase();
        }

        return BulletShapes.CIRCLE;
    }

    /**
     * Helper function to return the bullet class
     * @param column
     * @param isBenchmark
     * @return {string}
     */
    function getBulletShapeIcon(column, isBenchmark = false) {
        const defaultBulletShape = vm.widget.metadata?.draw_options?.bullets_shape;

        let currentBulletShape = getBulletShape(column, isBenchmark);
        if (!currentBulletShape) {
            // default to a bullet if it's not selected / available
            return defaultBulletShape ? BulletShapeIcons[defaultBulletShape.toUpperCase()] : BulletShapeIcons.CIRCLE;
        }

        return BulletShapeIcons[currentBulletShape];
    }

    /**
     * Helper function to return whether button should be shown or not
     * @param column
     * @returns {*|boolean}
     */
    function canShowUpdateComparison(column) {
        const isBigNumberWidget = WidgetUtilService.isBigNumber(vm.widget.type);
        const isDataGridWidget = WidgetUtilService.isDataGrid(vm.widget.type);
        const allowedFormats = [
            vm.ColumnFormat.FORMAT_INTEGER,
            vm.ColumnFormat.FORMAT_CURRENCY,
            vm.ColumnFormat.FORMAT_DECIMAL,
            vm.ColumnFormat.FORMAT_PERCENT,
            vm.ColumnFormat.FORMAT_TIME
        ];

        if (isBigNumberWidget || isDataGridWidget) {
            return allowedFormats.includes(column.format);
        } else {
            return false;
        }
    }

    /**
     * Helper function to return whether metrics group feature should be shown or not
     */
    function canShowMetricGroupsOption() {
        return WidgetUtilService.isGroupedColumnPlotType(vm.widget.metadata.draw_options);
    }

    /**
     * Helper function to set method references, attach existing data for already existing fields
     * attaches remaining values when the values array is empty
     * @private
     */
    function _setUserGroupedColumnsSelectEvents() {
        if (_.isEmpty(vm.userGroupedColumnsSelect)) {
            setGroupedColumnDefaultEvent({}, 0, true);
        } else {
            vm.userGroupedColumnsSelect.forEach((userGroupedColumn, index) => {
                setGroupedColumnDefaultEvent(userGroupedColumn, index);
            });
        }
    }

    /**
     * Helper function to set the default events for user grouped column
     * @param userGroupedColumn
     * @param index
     * @param isFirst
     */
    function setGroupedColumnDefaultEvent(userGroupedColumn, index, isFirst = false){
        setMethodReferences(userGroupedColumn);
        if (!vm.userGroupedColumns[index]) {
            vm.userGroupedColumns.push(setMethodReferences({}, false));
        }
        if (!_.isEmpty(userGroupedColumn.values)) {
            userGroupedColumn.selectedValues = [];
            userGroupedColumn.values.forEach(valueId => {
                userGroupedColumn.selectedValues.push({
                    id: valueId,
                    text: getTextLabel(valueId),
                    groupby_id_field: valueId,
                });
            });
            userGroupedColumn.values = [...angular.copy(userGroupedColumn.selectedValues), ...getNewValuesSet(true)];
        } else {
            userGroupedColumn.values = getNewValuesSet();
        }

        if (isFirst) {
            vm.userGroupedColumnsSelect.push(userGroupedColumn);
        }
    }

    /**
     *
     * @param index
     * @private
     */
    function _onUserGroupedColumnChanged(index) {
        vm.userGroupedColumns[index].values = [];
        const selectedValues = vm.userGroupedColumnsSelect[index]?.selectedValues;
        if (selectedValues) {
            vm.userGroupedColumns[index].values = _.map(selectedValues, column => column.id);
            $timeout(function() {
                sortSelectedGroupedColumn();
            }, 0, false);
            $timeout(function () {
                let userGroupedColumnsBeforeUpdate = angular.copy(vm.userGroupedColumnsSelect);
                _.forEach(vm.userGroupedColumnsSelect, (userGroupedColumn) => {
                    let groupedColumnSelectedValues = userGroupedColumn.selectedValues ? angular.copy(userGroupedColumn.selectedValues) : [];
                    userGroupedColumn.values = [...groupedColumnSelectedValues, ...getNewValuesSet(false)];
                });

                if (JSON.stringify(userGroupedColumnsBeforeUpdate) !== JSON.stringify(vm.userGroupedColumnsSelect)) {
                    WidgetBuilderService.updateWidgetDisplay();
                }
            }, 0, false);
        }
    }

    /**
     * Helper function to add a new set of fields
     */
    function addNewUserGroupedColumn() {
        const allowNewGroupedColumn = (getSelectedValues().length < vm.originalSelectedColumns.length) && !_.isEmpty(_.last(vm.userGroupedColumnsSelect).selectedValues);

        if (allowNewGroupedColumn) {
            let newUserGroupedColumn = {};
            setMethodReferences(newUserGroupedColumn);
            newUserGroupedColumn.values = getNewValuesSet();
            vm.userGroupedColumnsSelect.push(newUserGroupedColumn);
            /**
             * pushes name & color without any method references
             * into the userGroupedColumns array to save in the DB
             */
            vm.userGroupedColumns.push(setMethodReferences({}, false));
        }
    }

    /**
     * Helper function to remove an existing grouped column
     * @param index
     */
    function removeUserGroupedColumn(index) {
        let tempUserGroupedColumnsSelect;
        $timeout(function () {
            vm.userGroupedColumnsSelect[index].selectedValues = [];

            let userGroupedColumnsBeforeUpdate = angular.copy(vm.userGroupedColumnsSelect);
            _.forEach(vm.userGroupedColumnsSelect, (userGroupedColumn) => {
                let groupedColumnSelectedValues = userGroupedColumn.selectedValues ? angular.copy(userGroupedColumn.selectedValues) : [];
                userGroupedColumn.values = [...groupedColumnSelectedValues, ...getNewValuesSet(false)];
            });

            if (JSON.stringify(userGroupedColumnsBeforeUpdate) !== JSON.stringify(vm.userGroupedColumnsSelect)) {
                WidgetBuilderService.updateWidgetDisplay();
            }
        }, 0);
        $timeout(function () {
            tempUserGroupedColumnsSelect = angular.copy(vm.userGroupedColumnsSelect);
            vm.userGroupedColumnsSelect = [];
        }, 0);
        $timeout(function () {
            // deliberately using timeout here, so that select2 fields get updated in the UI
            tempUserGroupedColumnsSelect.splice(index, 1);
            vm.userGroupedColumns.splice(index, 1);
            vm.userGroupedColumnsSelect = angular.copy(tempUserGroupedColumnsSelect);
            _.forEach(vm.userGroupedColumnsSelect, (userGroupedColumn) => {
                let groupedColumnSelectedValues = userGroupedColumn.selectedValues ? angular.copy(userGroupedColumn.selectedValues) : [];
                userGroupedColumn.values = [...groupedColumnSelectedValues, ...getNewValuesSet()];
            });
            WidgetBuilderService.updateWidgetDisplay();
        }, 5);
    }


    /**
     * Helper function to set the method references as required
     * @param column
     * @param setAllReferences
     */
    function setMethodReferences(column, setAllReferences = true) {
        column.name = column.name || "";
        column.color = column.color || getBgColor(column);
        if (!setAllReferences) {
            // since we are pushing the column into userGroupedColumns, returning in this scenario
            return column;
        }
        // these are used only for the UI/UX
        column.onChange = _onUserGroupedColumnChanged;
        column.multiple = true;
        column.loaded = true;
        column.placeholder = "Click here to enter metric(s)";
    }

    /**
     * Helper function that removes already selected values & returns the remaining ones
     * @param isExisting
     * @returns {*|*[]}
     */
    function getNewValuesSet(isExisting = false) {
        let selectedValues = getSelectedValues(isExisting);
        let filteredValues = [];
        filteredValues = _.filter(vm.originalSelectedColumns.values, value => {
            return !_.find(selectedValues, selectedValue => {
                return [value.id, value.groupby_id_field].includes(selectedValue.id);
            });
        });
        return filteredValues;
    }

    /**
     * Helper function that returns the selected ones
     * @param isExisting
     */
    function getSelectedValues(isExisting = false) {
        let selectedValues = [];
        vm.userGroupedColumnsSelect.forEach((userGroupedColumn, index) => {
            if (userGroupedColumn.selectedValues) {
                userGroupedColumn.selectedValues.forEach((selectedValue) => {
                    selectedValues.push(selectedValue);
                });
            } else if (userGroupedColumn.values && isExisting) {
                userGroupedColumn.values.forEach((value) => {
                    selectedValues.push({id: value, text: getTextLabel(value), groupby_id_field: value});
                });
            }
        });

        return selectedValues;
    }

    /**
     * Helper function to update the color code in the dB related data
     * @param newColor
     * @param column
     */
    function onUserGroupedColumnColorChange(newColor, index) {
        vm.userGroupedColumns[index].color = newColor;
        WidgetBuilderService.updateWidgetDisplay();
    }

    /**
     *
     * @param index
     * @returns {boolean}
     */
    function canShowAddUserGroupedColumnButton(index) {
        return (vm.userGroupedColumnsSelect.length - 1 === index && getNewValuesSet().length > 0);
    }

    /**
     * Helper function to update the group name in the dB related data
     * @param newGroupName
     * @param index
     */
    function onTextChangeCallback(newGroupName, index) {
        vm.userGroupedColumns[index].name = newGroupName;
    }

    /**
     * Helper function to return the text label when showing the already selected values
     * @param id
     */
    function getTextLabel(id) {
        let foundValue = _.find(vm.originalSelectedColumns.values, value => {
            if ([value.id, value.groupby_id_field].includes(id)) {
                return value.text;
            }
           return '';
        });

        return foundValue?.text;
    }

    /**
     * Helper function to sort the selected columns according to the
     * added user grouped columns
     */
    function sortSelectedGroupedColumn() {
        let selectedValues = getSelectedValues().slice().reverse();
        let alreadySortedColumns = [];

        vm.widget.metadata.data_columns.selected.forEach((selectedColumn, index) => {
            const selectedColumnField = selectedColumn.id || selectedColumn.groupby_id_field;
            const userGroupedSelectedColumn = WidgetBuilderService.getUserGroupedSelectedColumn(vm.widget, selectedValues, selectedColumnField);

            if (userGroupedSelectedColumn) {
                const tempUserGroupedColumn = angular.copy(vm.widget.metadata.user_grouped_columns.find(column => column.values.includes(userGroupedSelectedColumn.id)));

                tempUserGroupedColumn.values.forEach((value, valueIndex) => {
                        if (value !== userGroupedSelectedColumn.id && !alreadySortedColumns.includes(value)) {
                            WidgetBuilderService.moveSelectedColumn(vm.widget, value, index + valueIndex);
                        }
                        alreadySortedColumns.push(value);
                });
            }
        });
    }


    /**
     * Helper function to return whether conditional metrics group feature should be shown or not
     * @returns {boolean}
     */
    function canShowConditionalGroupsOption() {
        return WidgetUtilService.isConditionalPlotType(vm.widget.metadata.draw_options);
    }

    /**
     * Helper function to return whether embedded sparkline metrics feature should be shown or not
     * @returns {boolean}
     */
    function canShowEmbeddedSparklineOption() {
        return WidgetUtilService.isEmbeddedSparklinesPlotType(vm.widget.metadata.draw_options);
    }

    /**
     * Helper function to set method references, attach existing data for already existing fields
     * attaches remaining values when the values array is empty
     * @private
     */
    function _setUserConditionalColumnsSelectEvents() {
        if (_.isEmpty(vm.userConditionalColumnsSelect)) {
            setConditionalColumnDefaultEvent({}, 0, true);
        } else {
            vm.userConditionalColumnsSelect.forEach((userConditionalColumn, index) => {
                setConditionalColumnDefaultEvent(userConditionalColumn, index);
                validateConditionalRules(userConditionalColumn.rules,index);
            });
        }
    }
    /**
     * Helper function to set method references, attach existing data for already existing fields
     * attaches remaining values when the values array is empty
     * @private
     */
    function _setEmbeddedSparklineColumnsSelectEvents() {
        if (_.isEmpty(vm.userEmbeddedSparklineColumnsSelect)) {
            setEmbeddedSparklineColumnDefaultEvent({}, 0, true);
        } else {
            vm.userEmbeddedSparklineColumnsSelect.forEach((userEmbeddedSparklineColumn, index) => {
                setEmbeddedSparklineColumnDefaultEvent(userEmbeddedSparklineColumn, index);
            });
        }
    }

    /**
     * Helper function add default values for conditional group
     * @param userConditionalColumn
     * @param index
     */
    function setConditionalColumnDefaultEvent(userConditionalColumn, index, isFirst = false){
        setMethodReferencesConditional(userConditionalColumn);
        if (!vm.userConditionalColumns[index]) {
            vm.userConditionalColumns.push(setMethodReferencesConditional({}, false));
        }
        if (!_.isEmpty(userConditionalColumn.values)) {
            userConditionalColumn.selectedValues = [];
            userConditionalColumn.values.forEach(valueId => {
                userConditionalColumn.selectedValues.push({
                    id: valueId,
                    text: getTextLabel(valueId),
                    groupby_id_field: valueId,
                });
            });
            userConditionalColumn.values = [...angular.copy(userConditionalColumn.selectedValues), ...getConditionalNewValuesSet(true)];
        } else {
            userConditionalColumn.values = getConditionalNewValuesSet();
        }

        if (isFirst) {
            vm.userConditionalColumnsSelect.push(userConditionalColumn);
        }
    }

    /**
     * Helper function add default values for embed sparkline
     * @param userConditionalColumn
     * @param index
     */
    function setEmbeddedSparklineColumnDefaultEvent(userEmbeddedSparklineColumn, index, isFirst = false){
        setMethodReferencesSparkline(userEmbeddedSparklineColumn);
        if (!vm.userEmbeddedSparklineColumns[index]) {
            vm.userEmbeddedSparklineColumns.push(setMethodReferencesSparkline({}, false));
        }
        if (!_.isEmpty(userEmbeddedSparklineColumn.values)) {
            userEmbeddedSparklineColumn.selectedValues = [];
            userEmbeddedSparklineColumn.values.forEach(valueId => {
                userEmbeddedSparklineColumn.selectedValues.push({
                    id: valueId,
                    text: getTextLabel(valueId),
                    groupby_id_field: valueId,
                });
            });
            userEmbeddedSparklineColumn.values = [...angular.copy(userEmbeddedSparklineColumn.selectedValues), ...getEmbeddedSparklineNewValuesSet(true)];
        } else {
            userEmbeddedSparklineColumn.values = getEmbeddedSparklineNewValuesSet();
        }

        if (isFirst) {
            vm.userEmbeddedSparklineColumnsSelect.push(userEmbeddedSparklineColumn);
        }
    }

    /**
     *
     * @param index
     * @private
     */
    function _onUserConditionalColumnChanged(index) {
        vm.userConditionalColumns[index].values = [];
        const selectedValues = vm.userConditionalColumnsSelect[index]?.selectedValues;
        if (selectedValues) {
            vm.userConditionalColumns[index].values = _.map(selectedValues, column => column.id);

            $timeout(function () {
                let userConditionalColumnsBeforeUpdate = angular.copy(vm.userConditionalColumnsSelect);
                _.forEach(vm.userConditionalColumnsSelect, (userConditionalColumn) => {
                    let conditionalColumnSelectedValues = angular.copy(userConditionalColumn.selectedValues) || [];
                    userConditionalColumn.values = [...conditionalColumnSelectedValues, ...getConditionalNewValuesSet(false)];
                });

                if (JSON.stringify(userConditionalColumnsBeforeUpdate) !== JSON.stringify(vm.userConditionalColumnsSelect)) {
                    WidgetBuilderService.updateWidgetDisplay();
                }
            }, 0, false);
        }
    }

    /**
     * Handles changes in user embedded sparkline columns
     * @param index
     * @private
     */
    function _onUserEmbeddedSparklineColumnChanged(index) {
        if (!vm.userEmbeddedSparklineColumns || !vm.userEmbeddedSparklineColumnsSelect) {
            // Return early if the required objects are not defined
            return;
        }

        vm.userEmbeddedSparklineColumns[index].values = [];
        const selectedValues = vm.userEmbeddedSparklineColumnsSelect[index]?.selectedValues;

        if (selectedValues) {
            vm.userEmbeddedSparklineColumns[index].values = _.map(selectedValues, column => column.id);

            $timeout(function () {
                _.forEach(vm.userEmbeddedSparklineColumnsSelect, (userEmbeddedSparklineColumn) => {
                    let embeddedSparklineColumnSelectedValues = angular.copy(userEmbeddedSparklineColumn.selectedValues) || [];
                    userEmbeddedSparklineColumn.values = [...embeddedSparklineColumnSelectedValues, ...getEmbeddedSparklineNewValuesSet(false)];
                });

                if (selectedValues.length !== tempSelectedLength) {
                    WidgetBuilderService.updateWidgetDisplay();
                }
                tempSelectedLength = selectedValues.length;
            }, 0, false);
        }
    }

    /**
     * Helper function to set the conditional method references as required
     * @param column
     * @param setAllReferences
     * @returns {*}
     */
    function setMethodReferencesConditional(column, setAllReferences = true) {
        if(!column.hasOwnProperty('rules')){
            column.rules = [];
            column.rules.push(conditionalRule(column));
        }

        if (!setAllReferences) {
            return column;
        }
        column.onChange = _onUserConditionalColumnChanged;
        column.multiple = true;
        column.loaded = true;
        column.placeholder = "Click here to enter metric(s)";
    }

    /**
     * Helper function to set the embed sparkline method references as required
     * @param column
     * @param setAllReferences
     * @returns {*}
     */
    function setMethodReferencesSparkline(column, setAllReferences = true) {
        if (!setAllReferences) {
            return column;
        }
        column.onChange = _onUserEmbeddedSparklineColumnChanged;
        column.multiple = true;
        column.loaded = true;
        column.placeholder = "Click here to enter metric(s)";
    }

    /**
     * Helper function that removes already selected values & returns the remaining ones
     * @param isExisting
     * @returns {*|*[]}
     */
    function getConditionalNewValuesSet(isExisting = false) {
        let selectedValues = getConditionalSelectedValues(isExisting);
        let filteredValues = [];
        filteredValues = _.filter(vm.originalSelectedColumns.values, (value,index) => {
            return !_.find(selectedValues, selectedValue => {
                return [value.id, value.groupby_id_field].includes(selectedValue.id);
            }) && AppFactory.util.column.isNumeric(vm.originalSelectedColumns[index].format);
        });

        return filteredValues;
    }

    /**
     * Helper function that returns the selected ones
     * @param isExisting
     */
    function getConditionalSelectedValues(isExisting = false) {
        let selectedValues = [];
        vm.userConditionalColumnsSelect.forEach((userConditionalColumn, index) => {
            if (userConditionalColumn.selectedValues) {
                userConditionalColumn.selectedValues.forEach((selectedValue) => {
                    selectedValues.push(selectedValue);
                });
            } else if (userConditionalColumn.values && isExisting) {
                userConditionalColumn.values.forEach((value) => {
                    selectedValues.push({id: value, text: getTextLabel(value), groupby_id_field: value});
                });
            }
        });

        return selectedValues;
    }

    /**
     * Helper function that removes already selected values & returns the remaining ones
     * @param isExisting
     * @returns {*|*[]}
     */
    function getEmbeddedSparklineNewValuesSet(isExisting = false) {
        let selectedValues = getEmbeddedSparklineSelectedValues(isExisting);
        if (!vm.originalSelectedColumns || !vm.originalSelectedColumns.values) {
            // Return an empty array if originalSelectedColumns or its values are not defined
            return ;
        }

        let filteredValues = [];
        filteredValues = _.filter(vm.originalSelectedColumns.values, (value,index) => {
            return !_.find(selectedValues, selectedValue => {
                return [value.id, value.groupby_id_field].includes(selectedValue.id);
            }) && AppFactory.util.column.isNumeric(vm.originalSelectedColumns[index].format);
        });
        filteredValues.forEach(item => {
            item.disabled = selectedValues.length >= 2;
        });
        return filteredValues;
    }

    /**
     * Helper function that returns the selected ones
     * @param isExisting
     */
    function getEmbeddedSparklineSelectedValues(isExisting = false) {
        let selectedValues = [];
        vm.userEmbeddedSparklineColumnsSelect.forEach((userEmbeddedSparklineColumn, index) => {
            if (userEmbeddedSparklineColumn.selectedValues) {
                userEmbeddedSparklineColumn.selectedValues.forEach((selectedValue) => {
                    selectedValues.push(selectedValue);
                });
            } else if (userEmbeddedSparklineColumn.values && isExisting) {
                userEmbeddedSparklineColumn.values.forEach((value) => {
                    selectedValues.push({id: value, text: getTextLabel(value), groupby_id_field: value});
                });
            }
        });

        return selectedValues;
    }

    /**
     * Helper function to add a new set of fields
     */
    function addNewUserConditionalColumn() {
        const allowNewConditionalColumn = (getConditionalSelectedValues().length < vm.originalSelectedColumns.length &&
            !_.isEmpty(_.last(vm.userConditionalColumnsSelect)?.selectedValues)) || vm.userConditionalColumnsSelect.length === 0;

        if (allowNewConditionalColumn) {
            let newUserConditionalColumn = {};
            setMethodReferencesConditional(newUserConditionalColumn);
            newUserConditionalColumn.values = getConditionalNewValuesSet();
            vm.userConditionalColumnsSelect.push(newUserConditionalColumn);
            /**
             * pushes name & color without any method references
             * into the userConditionalColumns array to save in the DB
             */
            vm.userConditionalColumns.push(setMethodReferencesConditional({}, false));
        }
    }

    /**
     * Helper function to remove an existing conditional group column
     * @param index
     */
    function removeUserConditionalColumn(index) {
        let tempUserConditionalColumnsSelect;
        $timeout(function () {
            vm.userConditionalColumnsSelect[index].selectedValues = [];

            let userConditionalColumnsBeforeUpdate = angular.copy(vm.userConditionalColumnsSelect);
            _.forEach(vm.userConditionalColumnsSelect, (userConditionalColumn) => {
                let conditionalColumnSelectedValues = userConditionalColumn.selectedValues ? angular.copy(userConditionalColumn.selectedValues) : [];
                userConditionalColumn.values = [...conditionalColumnSelectedValues, ...getConditionalNewValuesSet(false)];
            });

            if (JSON.stringify(userConditionalColumnsBeforeUpdate) !== JSON.stringify(vm.userConditionalColumnsSelect)) {
                WidgetBuilderService.updateWidgetDisplay();
            }
        }, 0);
        $timeout(function () {
            tempUserConditionalColumnsSelect = angular.copy(vm.userConditionalColumnsSelect);
            vm.userConditionalColumnsSelect = [];
        }, 0);
        $timeout(function () {
            // deliberately using timeout here, so that select2 fields get updated in the UI
            tempUserConditionalColumnsSelect.splice(index, 1);
            vm.userConditionalColumns.splice(index, 1);
            vm.userConditionalColumnsSelect = angular.copy(tempUserConditionalColumnsSelect);
            _.forEach(vm.userConditionalColumnsSelect, (userConditionalColumn, index) => {
                let conditionalColumnSelectedValues = userConditionalColumn.selectedValues ? angular.copy(userConditionalColumn.selectedValues) : [];
                userConditionalColumn.values = [...conditionalColumnSelectedValues, ...getConditionalNewValuesSet()];
                userConditionalColumn.rules = vm.userConditionalColumns[index].rules;
            });
        }, 5);

        WidgetBuilderService.updateWidgetDisplay();
    }

    /**
     * Helper function to show the plus button for conditional groups. Max groups is 3
     * @param index
     * @returns {boolean}
     */
    function canShowAddUserConditionalColumnButton() {
        if (getConditionalNewValuesSet().length === 0) {
          vm.conditionalAddAlertMessage = "All metrics has been assigned to conditional groups.";
          return false;
        }
        if (vm.userConditionalColumnsSelect.length > 2) {
          vm.conditionalAddAlertMessage = "Three conditional groups at most are permitted.";
          return false;
        }
        return true;
    }

    /**
     * Helper function show alert message if more than 3 metric selected
     * @param index
     * @returns {boolean}
     */
    function showEmbedSparklineAlertMessage() {
        const selectedValuesLength = vm.userEmbeddedSparklineColumnsSelect?.[0]?.selectedValues?.length;

        if (selectedValuesLength !== undefined && selectedValuesLength > 1) {
            vm.embedSparklineAddAlertMessage = "Sparklines can be embedded in a max of 2 fields in the data grid.";
            return false;
        }
        return true;
    }


    /**
     * Helper function to change value of condition, color, min value, max value
     * @param type
     * @param value
     * @param index
     * @param ruleIndex
     */
    function onRuleChange(type, value, index, ruleIndex) {
        vm.userConditionalColumns[index]['rules'][ruleIndex][type] = value;
        if (type !== 'color') {
            validateConditionalRules(vm.userConditionalColumnsSelect[index].rules, index);
        }
        WidgetBuilderService.updateWidgetDisplay();
    }

    /**
     * @param column
     * @param initialCondition
     * @returns {{}}
     */
    function conditionalRule(column, initialCondition = ConditionalType.GREATER_THAN) {
       return  WidgetBuilderConditionalPlotService.getConditionalRule(initialCondition, getBgColor(column));
    }

    /**
     * @param index
     * @param ruleIndex
     * @returns {[{name: string, id: *},{name: string, id: *},{name: string, id: *}]}
     */
    function ruleConditionalDropdownValues(index, ruleIndex) {
        let dropDownValues = vm.conditionalDropdownValues;
        if (vm.userConditionalColumnsSelect[index].rules.length > 1) {
            const appliedRules =  vm.userConditionalColumnsSelect[index].rules.filter((value, index)=> index !== ruleIndex).map(obj => obj.condition);
            dropDownValues = WidgetBuilderConditionalPlotService.removeSelectedDropdownValue(appliedRules, dropDownValues);
        }
        return dropDownValues;
    }

    /**
     * @param index
     * @param ruleIndex
     * @returns {boolean}
     */
    function canShowAddRuleButton(index, ruleIndex) {
        const conditionTotalRules = vm.userConditionalColumnsSelect[index].rules.length;
        return  conditionTotalRules < 3 && conditionTotalRules-1 === ruleIndex;
    }

    /**
     * Helper function show the minus button to remove conditional rule
     * @param index
     * @returns {boolean}
     */
    function canShowRemoveRuleButton(index) {
        return vm.userConditionalColumnsSelect[index].rules.length > 1;
    }

    /**
     * @param column
     * @param index
     */
    function addNewUserConditionalColumnRule(column, index) {
        let existedRules = angular.copy(vm.userConditionalColumnsSelect[index].rules);
        const dropdownValues = [ConditionalType.GREATER_THAN, ConditionalType.LESS_THAN, ConditionalType.BETWEEN];

        const appliedConditions = existedRules.map(function(obj){ return obj.condition});
        const [remainingDropdownValue] = dropdownValues.filter(x => !appliedConditions.includes(x));

        $timeout(function () {
            if (column.hasOwnProperty('rules') && column.rules.length < 3) {
                vm.userConditionalColumnsSelect[index].rules.push(conditionalRule(column, remainingDropdownValue));
                vm.userConditionalColumns[index].rules.push(conditionalRule(column, remainingDropdownValue));
            }
        }, 0);
    }

    /**
     * @param index
     * @param ruleIndex
     */
    function removeUserConditionalColumnRule(index, ruleIndex) {
        let tempConditionalRules;
        $timeout(function () {
            tempConditionalRules = angular.copy(vm.userConditionalColumns[index].rules);
        }, 0);

        $timeout(function () {
            tempConditionalRules.splice(ruleIndex, 1);
            vm.userConditionalColumns[index].rules.splice(ruleIndex, 1);
            vm.userConditionalColumnsSelect[index].rules = angular.copy(tempConditionalRules);
        }, 5, false);

       WidgetBuilderService.updateWidgetDisplay();
    }

    /**
     * function show the caution message for conditional plot type invalid rules
     * @param rules
     * @param index
     */
    function validateConditionalRules(rules, index) {
        let cautionMessage = '';
        delete vm.userConditionalColumnsSelect[index]?.errorMessage;

        for (let i = 0; i < rules.length ; i++ ) {
            cautionMessage = WidgetBuilderConditionalPlotService.validateConditionalRule(rules, rules[i], i);
            if (cautionMessage) {
                if (rules.length > 1) {
                  cautionMessage ="CAUTION: There is an overlap in the ranges specified for " + cautionMessage + ". The rules will be prioritized based on their creation order.";
                }
                vm.userConditionalColumnsSelect[index].errorMessage = cautionMessage;
                break;
            }
        }
    }
     function resetCurrentWidgetColor(){
         vm.widget.metadata.chart_palette = null;
         WidgetBuilderService.updateWidgetDisplay();
     }
}

/**
 * @ngInject
 */
function WidgetBuilderStylesTabController(
    PubSub,
    WidgetBuilderStylesModelFactory,
    WidgetFactory,
    WidgetBuilderService,
    DesignFactory,
    DrawOptionFactory,
    DrawOptionPanelFactory,
    ColumnFormat,
    WidgetUtilService,
    DrawOption,
    DataSourceType,
    WidgetBuilderConstants
) {
    const vm = this;

    /**
     * @type {WidgetBuilderStylesTabState}
     */
    vm.state = WidgetBuilderStylesModelFactory.getTabState();

    /**
     * @type {WidgetBuilderStylesTabData}
     */
    vm.data = WidgetBuilderStylesModelFactory.getTabData();
    vm.validateBenchmarkDrawOptions = validateBenchmarkDrawOptions;

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.isMapBoxSelected = isMapBoxSelected;

    function $onInit() {
        _registerEvents();
        _getMapBoxLayers();
    }

    function isMapBoxSelected() {
        const show = vm.data.drawOptions[DrawOption.PLOT_TYPE] === WidgetType.MAPBOX;
        if(!show) {
            WidgetBuilderService.resetMapboxLayers();
        }
        return show;
    }

    function _getMapBoxLayers() {
        WidgetFactory.getMapBoxLayers().then(function (data) {
            vm.mapBoxLayers = data.plain();
        });
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    function _handleShowTotalRowOption() {
        const widgetModel = WidgetBuilderService.getWidgetModel();
        let filters = widgetModel.metadata.dynamic && !_.isEmpty(widgetModel.metadata.dynamic.filters) ? widgetModel.metadata.dynamic.filters : null;
        const hasMetricFilter = _.some(filters, {is_metric: true});

        const isGroupedOnNonAggregableColumn = widgetModel.metadata.data_columns.grouped.some(column =>
            column.format === ColumnFormat.FORMAT_TIME // more may come in future id required
        );
        vm.data.typeDrawOptionItems.forEach(option => {
          if (option.key === DrawOption.SHOW_TOTAL_ROW) {
            option.disabled = isGroupedOnNonAggregableColumn || hasMetricFilter;
            option.is_disabled =
                widgetModel.metadata.data_source.type == DataSourceType.GOAL_DATA;
            option.disabled_tooltip =
                "Totals row is not supported on this type of data.";
          }
        });
    }

    function _init() {
        let widgetModel = WidgetBuilderService.getWidgetModel();
        let metricLength = widgetModel.metadata.data_columns.selected;

        vm.state.isActive = true;

        vm.data.drawOptions = widgetModel.metadata.draw_options;
        vm.data.selectedColumns = widgetModel.metadata.data_columns.selected;
        vm.data.widgetTypeId = widgetModel.type;
        vm.data.datasource = widgetModel.metadata.data_source;
        vm.data.report_id = widgetModel.report_id;

        DrawOptionPanelFactory.setProps(vm.data);
        if (!vm.data.styleDrawOptionItems || !vm.data.typeDrawOptionItems) {
            vm.state.isLoading = true;
            DrawOptionPanelFactory.retrievePanelDrawOptions(vm.data).then(function (data) {
                vm.state.isLoading = false;
                vm.data.styleDrawOptionItems = data.widgetDrawOptions;
                vm.data.typeDrawOptionItems = data.widgetTypeDrawOptions;
                vm.data.widgetTypeTabTitle = data.widgetTypeTabTitle;
                if (WidgetUtilService.isDataGrid(widgetModel.type)) {
                    _handleShowTotalRowOption();
                }
                if (
                  metricLength.length === 1 &&
                  widgetModel.can_be_edited === true
                ) {
                  _validateDonutSum(vm.data.typeDrawOptionItems);
                } else if (metricLength.length > 1) {
                  _reValidateDonutSum(vm.data.typeDrawOptionItems);
                }
            });
        } else if (WidgetUtilService.isDataGrid(widgetModel.type)) {
            _handleShowTotalRowOption();
        } 
        if (!_.isNil(vm.data.typeDrawOptionItems) && metricLength.length >= 1) {
          metricLength.length === 1
            ? _validateDonutSum(vm.data.typeDrawOptionItems)
            : _reValidateDonutSum(vm.data.typeDrawOptionItems);
        }
    }

    function validateBenchmarkDrawOptions(drawOption) {
        if (!vm.data || !vm.data.datasource) {
            return true;
        }
        const { id } = vm.data.datasource;
        if(BenchmarkSupportedServices.includes(id)) {
            return true;
        }
        return !BenchmarkDrawOptions.includes(drawOption.key);
    }

    function _validateDonutSum(typeDrawOptions) {
      typeDrawOptions.forEach((currentValue, index) => {
        if (
          currentValue.key &&
          currentValue.key === WidgetBuilderConstants.DONUT_SUM_KEY &&
          currentValue.format === ""
        ) {
          currentValue.format = WidgetBuilderConstants.FORMAT_BOOL;
        }
      });
      return typeDrawOptions;
    }

    function _reValidateDonutSum(typeDrawOptions) {
      typeDrawOptions.forEach((currentValue) => {
        if (
          currentValue.key === WidgetBuilderConstants.DONUT_SUM_KEY &&
          currentValue.format === WidgetBuilderConstants.FORMAT_BOOL
        ) {
          currentValue.format = "";
        }
      });
      return typeDrawOptions;
    }

    function _reset() {
        vm.state = WidgetBuilderStylesModelFactory.getTabState();
        vm.data = WidgetBuilderStylesModelFactory.getTabData();
    }

    function _updateDrawOptions(data) {
        WidgetBuilderService.setDrawOptions(data.drawOptions);
        let isAmChartWidget = false;
        if (WidgetUtilService.isAm5Chart(vm.data.widgetTypeId, vm.data.drawOptions.plot_type)) {
            isAmChartWidget = true;
        }
        if (DrawOptionFactory.drawOptionRequiresRedraw(data.item.key, isAmChartWidget)) {
            WidgetBuilderService.updateWidgetDisplay();
        }
    }


    /**
     * @private
     */
    function _registerEvents() {
        PubSub.on($WidgetBuilderEvents.INIT_STYLES_TAB, _init);
        PubSub.on($WidgetBuilderEvents.RESET_STYLES_TAB, _reset);
        PubSub.on($WidgetBuilderEvents.UPDATE_STYLES, _updateDrawOptions);
    }

    /**
     * @private
     */
    function _unregisterEvents() {
        PubSub.off($WidgetBuilderEvents.INIT_STYLES_TAB, _init);
        PubSub.off($WidgetBuilderEvents.RESET_STYLES_TAB, _reset);
        PubSub.off($WidgetBuilderEvents.UPDATE_STYLES, _updateDrawOptions);
    }
}

/**
 * @ngInject
 */
function WidgetBuilderLibraryTabController(
    $timeout,
    PubSub,
    LibraryFactory,
    WidgetLibraryFactory,
    WidgetBuilderLibraryModelFactory
) {
    const vm = this;

    /**
     * @type {WidgetBuilderLibraryTabState}
     */
    vm.state = WidgetBuilderLibraryModelFactory.getTabState();

    /**
     * @type {WidgetBuilderLibraryTabData}
     */
    vm.data = WidgetBuilderLibraryModelFactory.getTabData();

    /**
     * @type {WidgetBuilderLibraryFilterSelectConfig}
     */
    vm.filterSelect = WidgetBuilderLibraryModelFactory.getFilterSelectConfig();

    /**
     * @type {WidgetBuilderLibrarySearchBoxConfig}
     */
    vm.titleSearchBox = WidgetBuilderLibraryModelFactory.getSearchBoxConfig();

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.toggleSearchBox = toggleSearchBox;
    vm.triggerSearch = triggerSearch;
    vm.showSearchResults = showSearchResults;
    vm.canSearch = canSearch;
    vm.clearSearch = clearSearch;
    vm.canFilter = canFilter;
    vm.clearFilter = clearFilter;
    vm.clearSearchAction = clearSearchAction;

    function $onInit() {
        _registerEvents();
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    function _init() {
        if (WidgetLibraryFactory.getLibraryNeedsRefresh()) {
            vm.data = WidgetBuilderLibraryModelFactory.getTabData();

            WidgetLibraryFactory.setLibraryNeedsRefresh(false);
        }

        if (_.isEmpty(vm.data.templateItems)) {
            vm.state.isLoading = true;
            WidgetLibraryFactory.getLibraryWidgets().then(function (data) {
                vm.state.isLoading = false;
                vm.data.templateItems = data.templateItems;
                _setConnectedState(vm.data.templateItems);
                vm.data.templateItems = _.orderBy(vm.data.templateItems, 'isConnected', 'desc');

                WidgetLibraryFactory.getSearchableValues().then(function (values) {
                    vm.filterSelect.values = values;
                });
            });
        }
    }

    /**
     * Determines if widget is connected or not, individually
     * @param items
     * @private
     */
    function _setConnectedState(items) {
        _.each(items, function(item) {
            item.isConnected = WidgetLibraryFactory.getIsConnected(item);
        });
    }

    function toggleSearchBox() {
        vm.state.isSearchBoxActive = !vm.state.isSearchBoxActive;
    }

    function triggerSearch() {
        $timeout(function() {
            if (!canSearch() && !canFilter()) {
                vm.data.searchedItems = [];
                vm.filterSelect.showResults = false;
                return false;
            }
            vm.state.isSearching = true;
            vm.data.searchedItems = [];
            let queryParams = LibraryFactory.resolveSearchQueryFromSearchValues(vm.filterSelect.selectedValues);
            if (!_.isEmpty(vm.titleSearchBox.value)) {
                queryParams[LibrarySearchGroup.TITLE] = vm.titleSearchBox.value;
            }
            WidgetLibraryFactory.getSearchedWidgets(queryParams).then(function (items) {
                vm.state.isSearching = false;
                vm.data.searchedItems = items;
                _setConnectedState(items);
                vm.filterSelect.showResults = true;
            });
        }, 0);
    }

    /**
     * @returns {boolean}
     */
    function showSearchResults() {
        return vm.filterSelect.showResults || vm.state.isSearching;
    }

    /**
     * @returns {boolean}
     * @private
     */
    function canSearch() {
        return vm.titleSearchBox.value.length > 1;
    }

    function clearSearch() {
        $timeout(function() {
            vm.titleSearchBox.value = '';
            _evaluateClear();
        }, 0);
    }

    /**
     * Used for Empty placeholder clear button
     */
    function clearSearchAction() {
        clearSearch();
        clearFilter();
    }

    /**
     * @returns {boolean}
     * @private
     */
    function canFilter() {
        return vm.filterSelect.selectedValues.length > 0;
    }

    function clearFilter() {
        vm.filterSelect.options.resetSelectValues();
        $timeout(function() {
            vm.filterSelect.selectedValues = [];
            _evaluateClear();
        }, 0);
    }

    /**
     * Checks if we should show original results or re trigger a search
     * @private
     */
    function _evaluateClear() {
        if (canSearch() && canFilter()) {
            vm.data.searchedItems = [];
            vm.filterSelect.showResults = false;
        } else {
            triggerSearch();
        }
    }

    function _reset() {
        clearSearch();
        vm.state = WidgetBuilderLibraryModelFactory.getTabState();
        vm.data.searchedItems = [];
        vm.filterSelect.showResults = false;
    }

    /**
     * @private
     */
    function _registerEvents() {
        PubSub.on($WidgetBuilderEvents.INIT_LIBRARY_TAB, _init);
        PubSub.on($WidgetBuilderEvents.RESET_LIBRARY_TAB, _reset);
    }

    /**
     * @private
     */
    function _unregisterEvents() {
        PubSub.off($WidgetBuilderEvents.INIT_LIBRARY_TAB, _init);
        PubSub.off($WidgetBuilderEvents.RESET_LIBRARY_TAB, _reset);
    }
}

/**
 * @ngInject
 */
function WidgetLibraryItemController(
    $state,
    UIFactory,
    AppModelFactory,
    DataSourceFactory,
    WidgetFactory,
    WidgetBuilderService,
    WidgetUtilService,
    WidgetBuilderUIService,
    DashboardContextService
) {
    const vm = this;

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.selectLibraryWidget = selectLibraryWidget;
    vm.getDataSourceIcon = getDataSourceIcon;
    vm.getDataSourceColor = getDataSourceColor;
    vm.getWidgetTypeIcon = getWidgetTypeIcon;
    vm.getWidgetPlainTitle = WidgetFactory.getWidgetPlainTitle;

    function $onInit() {
        _registerEvents();
    }

    function $onDestroy() {
        _unregisterEvents();
    }

    function selectLibraryWidget() {
        if (vm.item.isConnected || DashboardContextService.isDemoModeEnabled()) {
            vm.isSwapping = true;
            WidgetFactory.getWidget(vm.item.id, {all: true}).then(function (widget) {
                vm.isSwapping = false;

                WidgetBuilderService.setLibraryWidgetAsPreview(widget);
                if (WidgetUtilService.isMediaWidget(widget.type)) {
                    WidgetBuilderUIService.hidePanel();
                    WidgetBuilderService.setMediaWidgetModel(widget);
                    WidgetBuilderService.saveWidget();
                } else if (WidgetUtilService.isAdminWidget(widget.type)) {
                    WidgetBuilderUIService.hidePanel();
                    WidgetBuilderService.setAdminWidgetModel(widget);
                    WidgetBuilderService.saveWidget();
                } else {
                    WidgetBuilderService.setWidgetModel(widget);
                    WidgetBuilderService.updateWidgetTypeWithoutPlotType(WidgetFactory.getWidgetType(widget.type));
                    WidgetBuilderService.refreshStylesTab();
                    WidgetBuilderService.refreshDataTab();
                }
                WidgetBuilderService.setLibraryWidgetUsed(true);
            });
        } else {
            let dataSource = vm.item.data_source;
            let swalOptions = AppModelFactory.getSwalOptions({
                title: '',
                text: dataSource.id_name + ' is not connected. Would you like to connect it?',
                confirmButtonText: 'Yes',
                closeOnConfirm: false,
                confirmFn: function() {
                    let url = DataSourceFactory.isServiceDataSourceType(dataSource.type)
                        ? 'service.manage.open'
                        : 'categories';


                    $state.go(url, {id: dataSource.id}).then(function() {
                        swal.close();
                    });
                }
            });

            UIFactory.confirm(swalOptions);

        }
    }

    /**
     * @returns {string}
     */
    function getDataSourceIcon() {
        return DataSourceFactory.getDataSourceIcon(vm.item.data_source);
    }

    /**
     * @returns {string}
     */
    function getDataSourceColor() {
        return DataSourceFactory.getDataSourceColor(vm.item.data_source);
    }

    /**
     * @returns {string}
     */
    function getWidgetTypeIcon() {
        return WidgetFactory.getWidgetType(vm.item.type).icon;
    }

    /**
     * @private
     */
    function _registerEvents() {
    }

    /**
     * @private
     */
    function _unregisterEvents() {
    }
}
