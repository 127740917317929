'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';
import moment from "moment";
import {AppConstants} from "coreModules/shared/scripts/app.constants";

angular.module('orders.ctrls', [])
    .controller('OrderController', OrderController)
    .controller('DetailedOrdersController', DetailedOrdersController)
    .controller('OrderFormController', OrderFormController)
    .controller('OrderViewController', OrderViewController)
    .controller('CancelReasonController', CancelReasonController);

OrderController.$inject = ['$rootScope', '$scope', '$filter', '$window', 'UserService', 'OrdersService', 'ClusterService', 'DateRangeService', '$state', '$ioAlert', '$stateParams', '$modal', 'ModalService', 'CurrentUser', '$confirm','FormService'];

function OrderController($rs, $scope, $filter, $window, UserService, OrdersService, ClusterService, DateRangeService, $state, $ioAlert, $stateParams, $modal, ModalService, CurrentUser, $confirm, FormService) {
    const STATUS_ORDER_CANCEL = 31;
    const STATUS_ORDER_COMPLETE = 6;
    const STATUS_ORDER_CLOSED = 7;

    $scope.orders = [];
    $scope.searchObj = {};
    $scope.searchObj.searchText = '';
    $scope.searchObj.searchField = 'client';
    $scope.showSearch = true;
    $scope.showArchivedOrders = false;
    $scope.isTableInitialized = false;
    $scope.isAdmin = false;
    $scope.isAdtaxi = false;
    $scope.showOrderComments = true;
    $scope.showOrdersServerSearch = true;
    $scope.budgetManagementEnabled = false;
    $scope.disableClone = false;
    $scope.pageObj = {
        perPageOptions: [10, 25, 50, 100],
        maxSize: 2,
        perPage: 50,
        currentPage: 1,
        totalCount: 0,
        orderBy: 'id',
        reverse: true,
        startIndex: 1,
        endIndex: 1,
        limitIndex: 0,
        search: ''
    };

    /*
     * Handle counts for indexes
     */
    var calculate = function() {
        $rs.loadingInProgress = false;
        if ($scope.orders && $scope.orders.length) {
            var ordering = "asc";
            if ($scope.pageObj.reverse) {
                ordering = "desc";
            }
            var filterOrders = $filter('filter')($scope.orders, $scope.pageObj.search);
            filterOrders = _.orderBy(filterOrders, $scope.pageObj.orderBy, ordering);
            $scope.pageObj.totalCount = filterOrders.length;
            $scope.pageObj.startIndex = Math.ceil((($scope.pageObj.currentPage - 1) * $scope.pageObj.perPage) + 1);
            $scope.pageObj.endIndex = Math.min($scope.pageObj.currentPage * $scope.pageObj.perPage);
            if ($scope.pageObj.totalCount != 0 && $scope.pageObj.endIndex > $scope.pageObj.totalCount) {
                $scope.pageObj.endIndex = $scope.pageObj.totalCount;
            }
            var begin = (($scope.pageObj.currentPage - 1) * $scope.pageObj.perPage),
                end = begin + $scope.pageObj.perPage;
            $scope.filteredOrders = filterOrders.slice(begin, end);

            _.each($scope.filteredOrders, function(order) {
                order.nonEditable = false;
                if (order.status_id &&
                    ((order.status_id == STATUS_ORDER_COMPLETE && !$scope.enableEditingForCompletedEntities) ||
                        order.status_id == STATUS_ORDER_CANCEL ||
                        order.status_id == STATUS_ORDER_CLOSED)) {
                    order.nonEditable = true;
                }
            });
        } else {
            $scope.filteredOrders = [];
            $scope.pageObj = {
                perPageOptions: [10, 25, 50, 100],
                maxSize: 2,
                perPage: 50,
                currentPage: 1,
                totalCount: 0,
                orderBy: 'id',
                reverse: true,
                startIndex: 1,
                endIndex: 1,
                limitIndex: 0,
                search: ''
            };
        }

    };

    $scope.sortByHeader = function (orderBy, reverse) {
        var ordering = reverse ? 'desc' : 'asc';
        var filterOrders = _.orderBy($scope.orders, orderBy, ordering);
        $scope.pageObj.totalCount = filterOrders.length;
        $scope.pageObj.startIndex = Math.ceil((($scope.pageObj.currentPage - 1) * $scope.pageObj.perPage) + 1);
        $scope.pageObj.endIndex = Math.min($scope.pageObj.currentPage * $scope.pageObj.perPage);
        if ($scope.pageObj.totalCount != 0 && $scope.pageObj.endIndex > $scope.pageObj.totalCount) {
            $scope.pageObj.endIndex = $scope.pageObj.totalCount;
        }
        var begin = (($scope.pageObj.currentPage - 1) * $scope.pageObj.perPage),
            end = begin + $scope.pageObj.perPage;
        $scope.filteredOrders = filterOrders.slice(begin, end);
        _.each($scope.filteredOrders, function (order) {
            order.nonEditable = false;
            if (order.status_id &&
                ((order.status_id == STATUS_ORDER_COMPLETE && !$scope.enableEditingForCompletedEntities) ||
                    order.status_id == STATUS_ORDER_CANCEL ||
                    order.status_id == STATUS_ORDER_CLOSED)) {
                order.nonEditable = true;
            }
        });
    }

    $scope.$watch('pageObj.currentPage', function() {
        calculate();
    });
    $scope.$watch('pageObj.perPage', function() {
        calculate();
    });

    $scope.$watch('pageObj.search', function() {
        calculate();
    });

    var convertAllScopeOrderIdsToInt = function() {
        angular.forEach($scope.orders, function(order) {
            order.id = parseInt(order.id);
        });
    };

    let destroyClusterSelector = $rs.$on('ClusterChanged', function() {
        $scope.showArchivedOrders = false;
        getOrdersList();
    });

    $scope.$on('$destroy', function() {
        destroyClusterSelector();
    });


    var getOrdersList = function() {
        $rs.loadingInProgress = true;
        $rs.loadertitle = "Fetching Open Orders";
        $scope.tableHide = true;
        if (($rs.userRole.permissions == 'sales' && $rs.userRole.predefined_role_key == 'sales') || $rs.userRole.permissions == 'sales_viewer') {
            OrdersService.getOpenOrdersForSalesperson($rs.userRole.id).then(function() {
                $scope.orders = OrdersService.ordersList;
                initializeOrdersList();
                convertAllScopeOrderIdsToInt();
                $scope.showSearch = false;
                calculate();
            }, function() {
                $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
            });
        } else if ($rs.userRole.permissions == 'client') {
            OrdersService.getOpenOrdersForClient($rs.userRole.client_id).then(function() {
                $scope.orders = OrdersService.ordersList;
                initializeOrdersList();
                convertAllScopeOrderIdsToInt();
                $scope.showSearch = false;
                calculate();
            }, function() {
                $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
            });
        } else if (ClusterService.clusterIds && ClusterService.clusterIds.length) {
            if ($scope.searchObj.searchField && $scope.searchObj.searchText) {
                OrdersService.searchOrders(ClusterService.clusterIds, $scope.searchObj.searchField, $scope.searchObj.searchText).then(function() {
                    $scope.orders = OrdersService.ordersList;
                    initializeOrdersList();
                    convertAllScopeOrderIdsToInt();
                    calculate();
                }, function(error) {
                    $ioAlert.show('Error!', 'Something went wrong here: ' + error, 'error');
                });
            } else {
                OrdersService.getOpenOrders(ClusterService.clusterIds).then(function() {
                    $scope.orders = OrdersService.ordersList;
                    initializeOrdersList();
                    convertAllScopeOrderIdsToInt();
                    calculate();
                }, function() {
                    $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');

                });
            }
        } else {
            $rs.loadingInProgress = false;
            $scope.tableHide = false;
        }
    };

    var getArchivedOrdersList = function() {
        $rs.loadingInProgress = true;
        $rs.loadertitle = "Fetching Archived Orders";
        if (($rs.userRole.permissions == 'sales' && $rs.userRole.predefined_role_key == 'sales') || $rs.userRole.permissions == 'sales_viewer') {
            OrdersService.getArchivedOrdersForSalesperson($rs.userRole.id).then(function() {
                $scope.orders = OrdersService.ordersList;
                initializeOrdersList();
                convertAllScopeOrderIdsToInt();
                $scope.showSearch = false;
                calculate();
            }, function() {
                $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
            });
        } else if ($rs.userRole.permissions == 'client') {
            OrdersService.getArchivedOrdersForClient($rs.userRole.client_id).then(function() {
                $scope.orders = OrdersService.ordersList;
                initializeOrdersList();
                convertAllScopeOrderIdsToInt();
                $scope.showSearch = false;
                calculate();
            }, function() {
                $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
            });
        } else {
            OrdersService.getArchivedOrders(ClusterService.clusterIds).then(function() {
                $scope.orders = OrdersService.ordersList;
                initializeOrdersList();
                convertAllScopeOrderIdsToInt();
                calculate();
            }, function() {
                $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
            });
        }
    };

    var initializeOrdersList = function() {
        $scope.pageObj.totalCount = $scope.orders.length;
        $rs.loadingInProgress = false;
        $scope.tableHide = false;
        if (($rs.userRole.permissions == 'sales' && $rs.userRole.predefined_role_key == 'sales') || $rs.userRole.permissions == 'sales_viewer') {
            OrdersService.getOpenOrdersWorkflowStepsForSalesperson($rs.userRole.id).then(function() {
                var OrdersWorkflowStepsForSalesperson = OrdersService.ordersWorkflowStepsForSalesperson;
                for (var i = 0, len = $scope.orders.length; i < len; i++) {
                    var orderStatusObj = _.find(OrdersWorkflowStepsForSalesperson, function(item) {
                        return item.order_id == $scope.orders[i].id
                    });
                    if (orderStatusObj) {
                        $scope.orders[i].workflow_step_color = orderStatusObj.color;
                        $scope.orders[i].workflow_step_name = orderStatusObj.name;
                    } else {
                        $scope.orders[i].workflow_step_color = "";
                        $scope.orders[i].workflow_step_name = "";
                    }
                }
            }, function() {
                $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');

            });
        } else {
            OrdersService.getOpenOrdersWorkflowSteps(ClusterService.clusterId).then(function() {
                var OrdersWorkflowSteps = OrdersService.ordersWorkflowSteps;

                for (var i = 0, len = $scope.orders.length; i < len; i++) {
                    var orderStatusObj = _.find(OrdersWorkflowSteps, function(item) {
                        return item.order_id == $scope.orders[i].id
                    });


                    if (orderStatusObj) {
                        $scope.orders[i].workflow_step_color = orderStatusObj.color;
                        $scope.orders[i].workflow_step_name = orderStatusObj.name;

                    } else {
                        $scope.orders[i].workflow_step_color = "";
                        $scope.orders[i].workflow_step_name = "";

                    }
                }
            }, function() {
                $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');

            });
        }
    };

    var searchOrders = function(clusterIds, searchField, searchText, pageName) {
        OrdersService.searchOrders(clusterIds, searchField, searchText, pageName).then(function() {
            $scope.orders = OrdersService.ordersList;
            convertAllScopeOrderIdsToInt();
            initializeOrdersList();
            calculate();
        }, function(error) {
            $ioAlert.show('Error!', 'Something went wrong here: ' + error, 'error');
        });
    };

    $scope.saveSubmitUser = function(order) {
        if (order) {
            UserService.getSubmitUsers(order.cluster_id).then(function() {
                var modalInstance = $modal.open(
                    ModalService.returnModalType('submitUser', {
                        data: {
                            items: UserService.users
                        }
                    })
                );
                modalInstance.result.then(function(selectedItem) {
                    updateSubmitUser(selectedItem, order);
                }, function() {});
            }, function() {
                console.log("Error getting agent users.");
            });
        }
    };

    $scope.updateStatus = function(order) {
        if (order) {
            $state.go('managementTools', {
                'orderId': order.id
            })
        }
    };

    $scope.open = function(index) {
        $state.go('viewOrder', {
            id: $scope.orders[index].id,
            order: $scope.orders[index],
            lineItems: $scope.orders[index].lineItems
        });
    };

    $scope.openBuySheet = function(order) {
        $state.go('buySheet', {
            orderId: order.id
        })
    };

    $scope.copyOrder = function(order) {
        OrdersService.orderSupportsFlights(order.id).then(function(data) {
            if (data.errors) {
                $ioAlert.show('Error!', data.errors, 'error');
            } else {
                // The options' indexes will not always be 0, 1, and 2, because they are not always shown together,
                // so keep track of them separately.
                let currentIndex = 0;
                let copyFlightIndex = -1;
                let clearTotalBudgetsIndex = -1;
                let options = [{
                    'value': true,
                    'text': 'Copy Line Items'
                }];
                if (data.supported) {
                    copyFlightIndex = ++currentIndex;
                    options.push({
                        'value': true,
                        'text': 'Copy Flights'
                    });
                }
                if ($scope.budgetManagementEnabled) {
                    clearTotalBudgetsIndex = ++currentIndex;
                    options.push({
                        'value': false,
                        'text': 'Clear total_budget values on copied entities'
                    });
                }
                $confirm.open({
                    size: 'sm',
                    text: 'Are you sure you want to copy this order?',
                    options: options,
                }).then(function (data) {
                    const isCopyingLineItems = data.options[0].value;
                    const isCopyingFlights = copyFlightIndex > 0 ? data.options[copyFlightIndex].value : true;
                    const clearTotalBudget = clearTotalBudgetsIndex > 0 ? data.options[clearTotalBudgetsIndex].value : false;
                    copyOrder(order, isCopyingLineItems, isCopyingFlights, clearTotalBudget);
                }, function () {
                });
            }
        }, function(error) {
            $ioAlert.show('Error!', error, 'error');
        })
    };

    let copyOrder = function(order, isCopyingLineItem, isCopyingFlight, clearTotalBudget) {
        OrdersService.copyOrder(order.id, isCopyingLineItem, isCopyingFlight, clearTotalBudget).then(function() {
            if (OrdersService.errors) {
                for (var x = 0; x < OrdersService.errors.length; x++) {
                    console.log(OrdersService.errors[x]);
                    $ioAlert.show("Error", OrdersService.errors[x], "error");
                }
                OrdersService.errors = null;
            } else {
                $scope.loadOrders();
                $ioAlert.show('', 'Order copied successfully', 'success');
            }
        }, function(error) {
            $ioAlert.show('Error!', error, 'error');
        });
    };

    function getInstanceSettings_comments() {
        FormService.getInstanceSettings("role_sales_hide_sidebar").then(function(data) {
            if (data && data.id) {
                $scope.showOrderComments = !(data.value === "1");
            } else {
                $scope.showOrderComments = true;
            }
        }, function(error) {
            $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
            $scope.showOrderComments = true;
        });
    }
    $scope.search = function() {
        if ($scope.searchObj.searchField && $scope.searchObj.searchText) {
            searchOrders(ClusterService.clusterIds, $scope.searchObj.searchField, $scope.searchObj.searchText, $state.current.name);
        }
    };

    $scope.loadOrders = function() {
        $scope.pageObj.currentPage = 1;
        $scope.showArchivedOrders = false;
        getOrdersList();
    };

    $scope.loadArchivedOrders = function() {
        $scope.showArchivedOrders = true;
        $scope.pageObj.currentPage = 1;
        getArchivedOrdersList();
    };

    $scope.managePlacements = function(order) {
        $state.go('placementManager',
            {
                fromPage: 'orders',
                orderId: order.id
            });
    }

        $scope.$watch('searchObj.searchText', function(nV, oV) {
        if (!nV && oV) {
            $scope.loadOrders();
        }
    });

    var updateSubmitUser = function(user, order) {
        if (user && order) {
            UserService.updateSubmitUser(order.id, user.id).then(function() {
                // todo: how to reload datatable without doing this
                $window.location.reload();
            }, function() {
                $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again.', 'error');
            });
        }
    };

    const initInstanceSettings = function() {
        let params = [
            'disable_clone',
            'budget_management',
            'enable_editing_for_completed_entities',
        ];
        FormService.getInstanceSettingArray(params).then(function(data) {
            if (data && data.instance_settings) {
                const disableClone = data.instance_settings.disable_clone;
                $scope.disableClone = (disableClone === "1");
                const budgetManagementEnabled = data.instance_settings.budget_management;
                $scope.budgetManagementEnabled = (budgetManagementEnabled === "1");
                const enableEditingForCompletedEntities = data.instance_settings.enable_editing_for_completed_entities;
                $scope.enableEditingForCompletedEntities = (enableEditingForCompletedEntities === "1");

            } else {
                $ioAlert.show('Error!', 'Cannot find any instance setting', 'error');
            }
        }, function(error) {
            console.log(error);
            $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
        });
    }

    var init = function(){
        var data = {
            loggedInUser: $rs.userRole
        }
        CurrentUser.set(data);
        //show only for user_type superadmin and sales, salesmanager roles
        if($rs.userRole.user_type === "superadmin" && $rs.userRole.permissions !== "sales" && $rs.userRole.permissions !== "sales,admin"){
            $scope.showOrderComments = true;
        } else {
            getInstanceSettings_comments();
        }
        initInstanceSettings();
        if($rs.userRole.permissions == "sales_viewer" || $rs.userRole.permissions == 'viewer'){
            $scope.showOrdersServerSearch = false;
        }
        if ($rs.userRole.permissions == 'adops' ||
            $rs.userRole.permissions == 'adops,admin' ||
            $rs.userRole.permissions == 'superadmin') {
            $scope.allowPlacementManager = true;  // Allowed for Adops, Adops Manager and SuperAdmin user roles
        }
    }
    init();

    // currentUser.service.js
    CurrentUser.get().then(function(user) {
        // todo: should show an unauthorized page
        if (!user.isAuthorizedToListOrders()) {
            $state.go('myTasks');
        }
        ClusterService.initCluster($rs.userRole, 'multiSelectWithAllClusters');
        $scope.user = user;
        DateRangeService.init();
        $scope.isAdtaxi = $.core.main.userSettings.isAdtaxiInstance;
    });
}

DetailedOrdersController.$inject = ['$rootScope', '$scope', 'UserService', 'OrdersService', 'ClusterService', 'DateRangeService', '$state', '$ioAlert', 'TaskService', 'ModalService', '$filter', 'FormService'];

function DetailedOrdersController($rs, $scope, UserService, OrdersService, ClusterService, DateRangeService, $state, $ioAlert, TaskService, ModalService, $filter, FormService) {
    $scope.orders = [];
    $scope.searchObj = {};
    $scope.searchObj.searchText = '';
    $scope.searchObj.searchField = 'client';
    $scope.showSearch = true;
    $scope.showArchivedOrders = false;
    $scope.associatedTasks = [];
    $scope.cachedAssociatedOrderTasks = [];
    $scope.cachedAssociatedLineItemTasks = [];
    $scope.cachedAssociatedFlightTasks = [];
    $scope.associatedLineItems = [];
    $scope.cachedAssociatedLineItems = [];
    $scope.associatedFlights = [];
    $scope.cachedAssociatedFlights = [];
    $scope.lineItemsShowingFlights = [];
    $scope.cachedPartialDataPresent = false;
    $scope.sortReverse = false;
    $scope.sortType = "id";
    $scope.alreadyRendered = [];
    $scope.showOrdersServerSearch = true;
    $scope.pageObj = {
        perPageOptions: [10, 25, 50, 100],
        maxSize: 2,
        perPage: 50,
        currentPage: 1,
        totalCount: 0,
        orderBy: 'id',
        reverse: true,
        startIndex: 1,
        endIndex: 1,
        limitIndex: 0,
        search: '',
    };
    $scope.showDetailedOrderComments = true;
    $scope.showDetailedOrderLineItems = true;

    /*
    * Handle counts for indexes
    */
    var calculate = function() {
        $rs.loadingInProgress = false;
        if ($scope.orders && $scope.orders.length) {
            var ordering = "asc";
            if ($scope.pageObj.reverse) {
                ordering = "desc";
            }
            var filterOrders = $filter('filter')($scope.orders, $scope.pageObj.search);
            filterOrders = _.orderBy(filterOrders, $scope.pageObj.orderBy, ordering);
            $scope.pageObj.totalCount = filterOrders.length;
            $scope.pageObj.startIndex = Math.ceil((($scope.pageObj.currentPage - 1) * $scope.pageObj.perPage) + 1);
            $scope.pageObj.endIndex = Math.min($scope.pageObj.currentPage * $scope.pageObj.perPage);
            if ($scope.pageObj.totalCount != 0 && $scope.pageObj.endIndex > $scope.pageObj.totalCount) {
                $scope.pageObj.endIndex = $scope.pageObj.totalCount;
            }
            var begin = (($scope.pageObj.currentPage - 1) * $scope.pageObj.perPage),
                end = begin + $scope.pageObj.perPage;
            $scope.filteredOrders = filterOrders.slice(begin, end);
        } else {
            $scope.filteredOrders = [];
            $scope.pageObj = {
                perPageOptions: [10, 25, 50, 100],
                maxSize: 2,
                perPage: 50,
                currentPage: 1,
                totalCount: 0,
                orderBy: 'id',
                reverse: true,
                startIndex: 1,
                endIndex: 1,
                limitIndex: 0,
                search: ''
            };
        }

    };

    $scope.sortByHeader = function (orderBy, reverse) {
        var ordering = reverse ? 'desc' : 'asc';
        var filterOrders = _.orderBy($scope.orders, orderBy, ordering);
        $scope.pageObj.totalCount = filterOrders.length;
        $scope.pageObj.startIndex = Math.ceil((($scope.pageObj.currentPage - 1) * $scope.pageObj.perPage) + 1);
        $scope.pageObj.endIndex = Math.min($scope.pageObj.currentPage * $scope.pageObj.perPage);
        if ($scope.pageObj.totalCount != 0 && $scope.pageObj.endIndex > $scope.pageObj.totalCount) {
            $scope.pageObj.endIndex = $scope.pageObj.totalCount;
        }
        var begin = (($scope.pageObj.currentPage - 1) * $scope.pageObj.perPage),
            end = begin + $scope.pageObj.perPage;
        $scope.filteredOrders = filterOrders.slice(begin, end);
    }

    $scope.$watch('pageObj.currentPage', function() {
        calculate();
    });

    $scope.$watch('pageObj.perPage', function() {
        calculate();
    });

    $scope.$watch('pageObj.search', function() {
        calculate();
    });

    // $scope.$watch('pageObj.orderBy', function() {
    //     $scope.pageObj.reverse = true;
    // });

    let destroyClusterSelector = $rs.$on('ClusterChanged', function() {
        $scope.ordersActive = false;
        $scope.loadOrders();
    });

    $scope.$on('$destroy', function() {
        destroyClusterSelector();
    });

    let getOrdersList = function(filter = null) {
        if (ClusterService.clusterIds && ClusterService.clusterIds.length) {
            $scope.orders = [];
            $scope.lineItemsShowingFlights = [];
            if ($scope.cachedPartialDataPresent) {
                // If we searched on data that returned partial line items or flights, then clear the cache.
                $scope.cachedAssociatedLineItems = [];
                $scope.cachedAssociatedFlights = [];
                $scope.associatedFlights = [];
                $scope.cachedPartialDataPresent = false;
            }
            $scope.ordersActive = false;
            $scope.flightCountsReceived = false;
            let salespersonId = null;
            if ($rs.userRole.permissions == "sales_viewer") {
                salespersonId = $rs.userRole.id;
            }
            OrdersService.getOrders(ClusterService.clusterIds, salespersonId, filter).then(function() {
                for (let i = 0, len = OrdersService.ordersList.length; i < len; i++) {
                    $scope.orders.push({
                        "id": parseInt(OrdersService.ordersList[i].id),
                        "status_name": OrdersService.ordersList[i].status_name,
                        "order_name": OrdersService.ordersList[i].order_name,
                        "salesRegion": OrdersService.ordersList[i].sales_region,
                        "clientName": OrdersService.ordersList[i].client,
                        "salesperson": OrdersService.ordersList[i].salesperson,
                        "start_date": OrdersService.ordersList[i].start_date,
                        "end_date": OrdersService.ordersList[i].end_date,
                        "submit_user_name": OrdersService.ordersList[i].submit_user_name,
                        "create_date": OrdersService.ordersList[i].created_at,
                        "has_due_tasks": OrdersService.ordersList[i].has_due_tasks,
                        "has_escalated_tasks": OrdersService.ordersList[i].has_escalated_tasks,
                        "use_flights_as_placements": OrdersService.ordersList[i].use_flights_as_placements,
                        'workflow_step_id': OrdersService.ordersList[i].workflow_step_id,
                    });
                    if (!$scope.associatedFlights.hasOwnProperty(OrdersService.ordersList[i].id)) {
                        $scope.associatedFlights[OrdersService.ordersList[i].id] = [];
                        $scope.cachedAssociatedFlightTasks[OrdersService.ordersList[i].id] = [];
                        $scope.cachedAssociatedFlights[OrdersService.ordersList[i].id] = [];
                    }
                }

                $scope.ordersActive = true;
                if ($scope.flightCountsReceived) {
                    updateOrdersWithFlightCounts();
                }
                $scope.pageObj.totalCount = $scope.orders.length;
                $scope.tableHide = false;
                $rs.loadingInProgress = false;
                OrdersService.getOrdersWorkflowSteps(ClusterService.clusterId).then(function() {
                    const OrdersWorkflowSteps = OrdersService.ordersWorkflowSteps;

                    for (let i = 0, len = $scope.orders.length; i < len; i++) {
                        const orderStatusObj = _.find(OrdersWorkflowSteps, function(item) {
                            return item.order_id == $scope.orders[i].id
                        });
                        if (orderStatusObj) {
                            $scope.orders[i].workflow_step_color = orderStatusObj.color;
                            $scope.orders[i].workflow_step_name = orderStatusObj.name;

                        } else {
                            $scope.orders[i].workflow_step_color = "";
                            $scope.orders[i].workflow_step_name = "";
                        }
                    }

                }, function() {
                    $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
                    $rs.loadingInProgress = false;
                });
                calculate();
            }, function() {});
            OrdersService.getOrderFlightsCounts(ClusterService.clusterIds, salespersonId).then(function(ordersFlightsCounts) {
                $scope.flightCounts = ordersFlightsCounts;
                $scope.flightCountsReceived = true;
                if ($scope.ordersActive) {
                    updateOrdersWithFlightCounts();
                }
            }, function() {
                $scope.flightCounts = [];
                $scope.flightCountsReceived = true;
                $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
            });
        }
    };

    const updateOrdersWithFlightCounts = function() {
        for (let i = 0, len = $scope.orders.length; i < len; i++) {
            const flightCountsForOrder = _.find($scope.flightCounts, function(item, key) {
                return (key == $scope.orders[i].id);
            });
            if (flightCountsForOrder) {
                // Make sure either no counts, or both counts, are shown, since otherwise we won't know whether the
                // one count is a current or historical count.
                if (flightCountsForOrder.current_count) {
                    $scope.orders[i].current_count = flightCountsForOrder.current_count;
                    if (!flightCountsForOrder.historical_count) {
                        $scope.orders[i].historical_count = 0;
                    }
                }
                if (flightCountsForOrder.historical_count) {
                    $scope.orders[i].historical_count = flightCountsForOrder.historical_count;
                    if (!flightCountsForOrder.current_count) {
                        $scope.orders[i].current_count = 0;
                    }
                }
                $scope.orders[i].line_counts = flightCountsForOrder.hasOwnProperty('line_counts') ? flightCountsForOrder.line_counts : {};
            }
            $scope.orders[i].line_counts = {};
        }
    };

    $scope.delegate = {
        onApplyFilter(filter) {
            getOrdersList(filter)
        },
        onResetFilter() {
            const filter = $scope.showArchivedOrders ? 'archived' : 'open';
            getOrdersList(filter)
        }
    }

    let searchOrders = function(clusterIds, searchField, searchText, pageName) {
        $scope.orders = [];
        $scope.lineItemsShowingFlights = [];
        if ($scope.cachedPartialDataPresent) {
            // If we searched on data that returned partial line items or flights, then clear the cache.  We might
            // still have new partial data here, but it might be differently partial!
            $scope.cachedAssociatedLineItems = [];
            $scope.cachedAssociatedFlights = [];
            $scope.associatedFlights = [];
            $scope.cachedPartialDataPresent = false;
        }
        $scope.ordersActive = false;
        OrdersService.searchOrders(clusterIds, searchField, searchText, pageName).then(function() {
            for (let i = 0, len = OrdersService.ordersList.length; i < len; i++) {
                const orderId = OrdersService.ordersList[i].id;
                $scope.orders.push({
                    "id": parseInt(orderId),
                    "status_name": OrdersService.ordersList[i].status_name,
                    "order_name": OrdersService.ordersList[i].order_name,
                    "salesRegion": OrdersService.ordersList[i].sales_region,
                    "clientName": OrdersService.ordersList[i].client,
                    "salesperson": OrdersService.ordersList[i].salesperson,
                    "start_date": OrdersService.ordersList[i].start_date,
                    "end_date": OrdersService.ordersList[i].end_date,
                    "submit_user_name": OrdersService.ordersList[i].submit_user_name,
                    "create_date": OrdersService.ordersList[i].created_at,
                    "has_due_tasks": OrdersService.ordersList[i].has_due_tasks,
                    "has_escalated_tasks": OrdersService.ordersList[i].has_escalated_tasks,
                    "use_flights_as_placements": OrdersService.ordersList[i].use_flights_as_placements
                });
                if (!$scope.associatedFlights.hasOwnProperty(orderId)) {
                    $scope.associatedFlights[orderId] = [];
                    $scope.cachedAssociatedFlightTasks[orderId] = [];
                    $scope.cachedAssociatedFlights[orderId] = [];
                }
                // See if we searched on line item or flight service platform id, in which case we want to automatically
                // expand the returned line items and/or flights. The line item and flight data are already present,
                // but we'll still need to fetch the tasks.
                let lineItems = OrdersService.ordersList[i].associatedLineItems;
                if (lineItems) {
                    // Remember we have partial line item data so that we can clear the cache later when we clear the search
                    // box.
                    $scope.cachedPartialDataPresent = true;
                    setTimeout(() => {
                        const chevronElementId = '#order_chevron_' + orderId;
                        let chevronElement = $(chevronElementId);
                        chevronElement.parent().parent(':last').siblings().slideToggle(400);
                        chevronElement.removeClass('fa-chevron-right');
                        chevronElement.addClass('fa-chevron-down');
                    });
                    processLineItems(lineItems, orderId);
                    $scope.associatedLineItems[orderId] = [...lineItems];  // Clone
                    $scope.cachedAssociatedLineItems[orderId] = [...lineItems];  // Clone
                    TaskService.getOpenTaskAssignmentsForOrder(orderId).then(function(taskAssignments) {
                        $scope.cachedAssociatedOrderTasks[orderId] = taskAssignments;
                        buildAssociatedTasks(orderId);
                    }, function() {
                        $ioAlert.show('Error getting order tasks!', 'Something went wrong here. Please refresh the page to try again', 'error');
                    });
                }
            }

            OrdersService.getOrdersWorkflowSteps(ClusterService.clusterId).then(function() {
                var OrdersWorkflowSteps = OrdersService.ordersWorkflowSteps;

                for (var i = 0, len = $scope.orders.length; i < len; i++) {
                    var orderStatusObj = _.find(OrdersWorkflowSteps, function(item) {
                        return item.order_id == $scope.orders[i].id
                    });
                    if (orderStatusObj) {
                        $scope.orders[i].workflow_step_color = orderStatusObj.color;
                        $scope.orders[i].workflow_step_name = orderStatusObj.name;

                    } else {
                        $scope.orders[i].workflow_step_color = "";
                        $scope.orders[i].workflow_step_name = "";

                    }
                }
            }, function() {
                $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
                $rs.loadingInProgress = false;
            });
            calculate();
            $rs.loadingInProgress = false;
            $scope.tableHide = false;
            $scope.ordersActive = true;
            if ($scope.flightCountsReceived) {
                updateOrdersWithFlightCounts();
            }
        }, function() {
            $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
            $rs.loadingInProgress = false;
        });
    };

    $scope.navigateToOrder = function(order) {
        $state.go('viewOrder', {
            id: order.id,
            order: order,
            lineItems: order.lineItems,
            detailedOrder: true
        });
    };

    $scope.navigateToPlacements = function(orderId, lineItemId) {
        if (lineItemId) {
            $state.go('placementManager',
                {
                    fromPage: 'orderDetails',
                    orderId: orderId,
                    lineItemId: lineItemId
                });
        } else {
            $state.go('placementManager',
                {
                    fromPage: 'orderDetails',
                    orderId: orderId
                });
        }
    };

    let buildAssociatedTasks = function(orderId) {
        $scope.associatedTasks[orderId] = [];
        $scope.associatedTasks[orderId] = $scope.associatedTasks[orderId].concat($scope.cachedAssociatedOrderTasks[orderId]);
        $scope.associatedTasks[orderId] = $scope.associatedTasks[orderId].concat($scope.cachedAssociatedLineItemTasks[orderId]);
        // Add flight tasks only if their corresponding flights are currently shown.
        _.each($scope.lineItemsShowingFlights, function(val, key) {
            // Key is the line item id, and val is true or false (true if flights for this line item id are shown).
            if (val) {
                if ($scope.cachedAssociatedFlightTasks[orderId].hasOwnProperty(key)) {
                    $scope.associatedTasks[orderId] = $scope.associatedTasks[orderId].concat($scope.cachedAssociatedFlightTasks[orderId][key]);
                }
            }
        });
        $scope.associatedTasks[orderId] = _.sortBy($scope.associatedTasks[orderId], 'id').reverse();
    }

    $scope.toggle = function(eve, order) {
        $(eve.target).parent().parent(':last').siblings().slideToggle(400);
        if ($(eve.target).attr('class').indexOf('fa-chevron-right') > 0) {
            $(eve.target).removeClass('fa-chevron-right');
            $(eve.target).addClass('fa-chevron-down');
            if ($scope.cachedAssociatedLineItems.hasOwnProperty(order.id)) {
                $scope.associatedLineItems[order.id] = $scope.cachedAssociatedLineItems[order.id];
                buildAssociatedTasks(order.id);
                return;
            }
            OrdersService.getLineItems(order.id).then(function(data) {
                if (data) {
                    processLineItems(data, order.id);
                    $scope.associatedLineItems[order.id] = data;
                    $scope.cachedAssociatedLineItems[order.id] = data;  // In case we toggle it back on later.
                }
            }, function() {
                $ioAlert.show('Error getting line item list!', 'Something went wrong here. Please refresh the page to try again', 'error');
            });
            TaskService.getOpenTaskAssignmentsForOrder(order.id).then(function(taskAssignments) {
                $scope.cachedAssociatedOrderTasks[order.id] = taskAssignments;
                buildAssociatedTasks(order.id);
            }, function() {
                $ioAlert.show('Error getting order tasks!', 'Something went wrong here. Please refresh the page to try again', 'error');
            });
        } else {
            $(eve.target).removeClass('fa-chevron-down');
            $(eve.target).addClass('fa-chevron-right');
        }
    };

    function processLineItems(data, orderId) {
        let itemsProcessed = 0;
        $scope.cachedAssociatedLineItemTasks[orderId] = [];
        for (let i = 0, len = data.length; i < len; i++) {
            TaskService.getOpenTaskAssignmentsForLineItem(data[i].id).then(function (taskAssignments) {
                itemsProcessed++;
                $scope.cachedAssociatedLineItemTasks[orderId] = $scope.cachedAssociatedLineItemTasks[orderId].concat(taskAssignments);
                if (itemsProcessed == len) {
                    buildAssociatedTasks(orderId);
                };
            }, function () {
                itemsProcessed++;
                if (itemsProcessed == len) {
                    buildAssociatedTasks(orderId);
                }
                $ioAlert.show('Error getting line item tasks!', 'Something went wrong here. Please refresh the page to try again', 'error');
            });
        }
        for (let i = 0, len = data.length; i < len; i++) {
            let lineItem = data[i];
            if (lineItem.campaign_name) {
                if (_.isArray(lineItem.campaign_name)) {
                    lineItem.campaign_name = lineItem.campaign_name[1];
                } else {
                    lineItem.campaign_name = lineItem.campaign_name;
                }
            } else {
                lineItem.campaign_name = '';
            }

            // If we searched on flight service platform id, we want to automatically expand the returned line items and
            // flights. The line item and flight data are already present, but we'll still need to fetch the tasks.
            let flights = lineItem.associatedFlights;
            if (flights) {
                setTimeout(() => {
                    const chevronElementId = '#line_item_chevron_' + lineItem.id;
                    let chevronElement = $(chevronElementId);
                    chevronElement.parent().parent(':last').siblings().slideToggle(400);
                    chevronElement.removeClass('fa-chevron-right');
                    chevronElement.addClass('fa-chevron-down');
                });
                processFlights(flights, orderId, lineItem.id);
                $scope.lineItemsShowingFlights[lineItem.id] = true;
                $scope.associatedFlights[orderId][lineItem.id] = [...flights];  // Clone
                $scope.cachedAssociatedFlights[orderId][lineItem.id] = [...flights];  // Clone
            }

            // Assign flight counts for this line item (as already obtained from the backend together with the orders list).
            const orderCounts = $scope.flightCounts.hasOwnProperty(orderId) ? $scope.flightCounts[orderId] : {};
            const all_line_item_counts = orderCounts.hasOwnProperty('line_counts') ? orderCounts.line_counts : {};
            const line_item_counts = all_line_item_counts.hasOwnProperty(lineItem.id) ? all_line_item_counts[lineItem.id] : {};
            if (line_item_counts) {
                // Make sure either no counts, or both counts, are shown, since otherwise we won't know whether the
                // one count is a current or historical count.
                if (line_item_counts.current_count) {
                    lineItem.current_count = line_item_counts.current_count;
                    if (!line_item_counts.historical_count) {
                        lineItem.historical_count = 0;
                    }
                }
                if (line_item_counts.historical_count) {
                    lineItem.historical_count = line_item_counts.historical_count;
                    if (!line_item_counts.current_count) {
                        lineItem.current_count = 0;
                    }
                }
            }
        }
    }

    function processFlights(data, orderId, lineItemId) {
        let itemsProcessed = 0;
        $scope.cachedAssociatedFlightTasks[orderId][lineItemId] = [];
        for (let i = 0, len = data.length; i < len; i++) {
            TaskService.getOpenTaskAssignmentsForFlight(data[i].id).then(function (taskAssignments) {
                itemsProcessed++;
                $scope.cachedAssociatedFlightTasks[orderId][lineItemId] = $scope.cachedAssociatedFlightTasks[orderId][lineItemId].concat(taskAssignments);
                if (itemsProcessed == len) {
                    buildAssociatedTasks(orderId);
                }
            }, function () {
                itemsProcessed++;
                if (itemsProcessed == len) {
                    buildAssociatedTasks(orderId);
                }
                $ioAlert.show('Error getting flight tasks!', 'Something went wrong here. Please refresh the page to try again', 'error');
            });
        }
    }

    $scope.toggleFlights = function(event, lineItem, order) {
        $(event.target).parent().parent(':last').siblings().slideToggle(400);
        if ($(event.target).attr('class').indexOf('fa-chevron-right') > 0) {
            $(event.target).removeClass('fa-chevron-right');
            $(event.target).addClass('fa-chevron-down');
            $scope.lineItemsShowingFlights[lineItem.id] = true;
            if ($scope.cachedAssociatedFlights[order.id].hasOwnProperty(lineItem.id)) {
                $scope.associatedFlights[order.id][lineItem.id] = $scope.cachedAssociatedFlights[order.id][lineItem.id];
                buildAssociatedTasks(order.id);
                return;
            }
            OrdersService.getFlights(lineItem.id).then(function(data) {
                if (data) {
                    processFlights(data, order.id, lineItem.id);
                    $scope.associatedFlights[order.id][lineItem.id] = data;
                    $scope.cachedAssociatedFlights[order.id][lineItem.id] = data;  // In case we toggle it back on later.
                }
            }, function() {
                $ioAlert.show('Error getting flight list!', 'Something went wrong here. Please refresh the page to try again', 'error');
            });
        } else {
            $(event.target).removeClass('fa-chevron-down');
            $(event.target).addClass('fa-chevron-right');
            $scope.lineItemsShowingFlights[lineItem.id] = false;
            buildAssociatedTasks(order.id);
        }
    };

    $scope.navigateToTask = function(orderId, taskId) {
        $state.go('viewOrderTask', {
            orderId: orderId,
            taskId: taskId,
            fromPage: 'detailedOrders'
        });
    };

    $scope.navigateToLineItem = function(lineItemId) {
        $state.go('viewLineItem', {
            lineItemId: lineItemId
        });
    };

    $scope.navigateToFlight = function(lineItemId, flightId) {
        $state.go('viewLineItem', {
            lineItemId: lineItemId,
            flightId: flightId
        });
    };

    $scope.search = function() {
        if ($scope.searchObj.searchField && $scope.searchObj.searchText) {
            searchOrders(ClusterService.clusterIds, $scope.searchObj.searchField, $scope.searchObj.searchText, $state.current.name);
        }
    };

    $scope.$watch('searchObj.searchText', function(nV, oV) {
        if (!nV && oV) {
            $scope.loadOrders();
        }
    });

    $scope.loadOrders = function() {
        $rs.loadingInProgress = true;
        $rs.loadertitle = "Fetching Detailed Open Orders";
        $scope.tableHide = true;
        if ($scope.searchObj.searchText && $scope.searchObj.searchField) {
            searchOrders(ClusterService.clusterIds, $scope.searchObj.searchField, $scope.searchObj.searchText, $state.current.name)
        } else {
            $scope.showArchivedOrders = false;
            getOrdersList('open');
        }
    };

    $scope.loadArchivedOrders = function() {
        $rs.loadingInProgress = true;
        $rs.loadertitle = "Fetching Detailed Archived Orders";
        $scope.tableHide = true;
        $scope.showArchivedOrders = true;
        getOrdersList('archived');
    };

    $scope.viewCards = function() {
        $state.go('detailedOrders_card', {});
    };
    $scope.viewTable = function() {
        $state.go('detailedOrders', {});
    };

    function hideLineItem_helper() {
        if($rs.userRole.user_type === "superadmin" && $rs.userRole.permissions !== "sales" && $rs.userRole.permissions !== "sales,admin"){
            $scope.showDetailedOrderLineItems = true;
        }else{
            $scope.showDetailedOrderLineItems = !($rs.lineItemHide && $rs.lineItemHide.value === "1");
        }
    }

    // Get Instance setting only once and set it to rootScope value
    function getInstanceSettings_lineItem() {
        FormService.getInstanceSettings("line_item_hidden").then(function(data) {
            if (data && data.id) {
                $rs.lineItemHide = {
                    "setting_type": data.setting_type,
                    "id": data.id,
                    "value": data.value
                };
            } else {
                $rs.lineItemHide = {};
            }
            hideLineItem_helper();
        }, function(error) {
            $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
            $scope.showDetailedOrderLineItems = true;
        });
    };

    function getInstanceSettings_comments() {
        FormService.getInstanceSettings("role_sales_hide_sidebar").then(function(data) {
            if (data && data.id) {
                $scope.showDetailedOrderComments = !(data.value === "1");
            } else {
                $scope.showDetailedOrderComments = true;
            }
        }, function(error) {
            $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
            $scope.showDetailedOrderComments = true;
        });
    }

    var init = function() {
        if ($rs.lineItemHide) {
            hideLineItem_helper();
        } else {
            getInstanceSettings_lineItem();
        }

        //show only for user_type superadmin and sales, salesmanager roles
        if($rs.userRole.user_type === "superadmin" && $rs.userRole.permissions !== "sales" && $rs.userRole.permissions !== "sales,admin"){
            $scope.showDetailedOrderComments = true;
        }else{
            getInstanceSettings_comments();
        }

        // todo: remove when we have a landing page for vendors
        if ($rs.userRole.permissions && $rs.userRole.permissions.indexOf('vendor') > -1) {
            $state.go('myTasks');
        } else {
            ClusterService.initCluster($rs.userRole, 'multiSelectWithAllClusters');
            DateRangeService.init();
        }
        if($rs.userRole.permissions == "sales_viewer" || $rs.userRole.permissions == 'viewer'){
            $scope.showOrdersServerSearch = false;
        }
        if ($rs.userRole.permissions == 'adops' ||
            $rs.userRole.permissions == 'adops,admin' ||
            $rs.userRole.permissions == 'superadmin') {
            $scope.allowPlacementManager = true;  // Allowed for Adops, Adops Manager and SuperAdmin user roles
        }
    };

    if ($rs.userRole && $rs.userRole.id) {
        init();
    } else {
        UserService.getCurrentUserRole().then(function() {
            $rs.userRole = UserService.userRole;
            init();
        }, function() {});
    }
}

OrderFormController.$inject = ['$rootScope', '$scope', '$timeout', '$stateParams', '$state', '$modal', 'OrdersService', 'UserService', 'CommentService', 'ClusterService', 'DateRangeService', 'FormService', 'MediaService', '$ioAlert', '$filter', 'ModalService', 'creativesService','ProductService'];

function OrderFormController($rs, $scope, $timeout, $stateParams, $state, $modal, OrdersService, UserService, CommentService, ClusterService, DateRangeService, FormService, MediaService, $ioAlert, $filter, ModalService, creativesService, ProductService) {

    $scope.users = {};
    $scope.orders = {};
    $scope.order = null;
    $scope.orderId = 0;
    $scope.orderStatusId = null;
    $scope.existingComments = [];
    $scope.creatives = null;
    $scope.clusters = null;
    $scope.properties = null;
    $scope.ruleSet = [];
    $scope.validationErrors = [];
    $scope.isAdmin = false;
    $scope.isEditing = false;
    $scope.loadComplete = false;
    $scope.hasCreatives = false;
    $scope.realCreativeFilenames = [];
    $scope.creativesReceived = false;
    $scope.clientReceived = false;
    $scope.showCommentSection = true;
    $scope.hasForm = false;
    $scope.userId = {};
    $scope.audienceLookupTypeIds = [];

    // Dynamic form
    $scope.model = {};
    $scope.schema = {};
    $scope.form = [];
    $scope.entireForm = [];
    $scope.formParentChildInfo = {};
    $scope.formFieldMap = {};
    $scope.formFields = null;
    $scope.savedRequiredCreativeFieldIds = [];
    $scope.client = null;
    $scope.addCommentRequired = true;
    $scope.waitForOrderCustomFileUpload = false;

    // Need to set editing state prior to order async load
    if ($stateParams.id) {
        $scope.isEditing = true;
        $scope.orderText = "Update Order";
    } else {
        $scope.orderText = "Create Order";
        if ($stateParams.audienceId) {
            $scope.initAudienceId = $stateParams.audienceId;
        }
        if ($stateParams.clientId) {
            $scope.initClientId = $stateParams.clientId;
        }
    }
    let clusterId = null;


    let destroyClusterSelector = $rs.$on('ClusterChanged', function() {
        if (!$stateParams.id && $scope.loadComplete) {
            if (clusterId && clusterId == ClusterService.clusterIds[0]) {
                return;
            }
            $scope.model = {};
            $scope.schema = {};
            $scope.form = [];
            $scope.entireForm = [];
            $scope.formFieldMap = {};
            $scope.formFields = null;
            loadForm();
        }
    });

    $scope.$on('$destroy', function() {
        destroyClusterSelector();
    });

    const loadForm = function(newClientId = null, newModel = null) {
        $scope.hasForm = false;
        $scope.creativesReceived = false;
        $scope.clientReceived = false;
        if ($scope.isEditing) {
            clusterId = $scope.order.cluster_id[0];
            ClusterService.initCluster($rs.userRole, 'disabled', $scope.order.cluster_id[0]);
        } else {
            clusterId = ClusterService.clusterIds[0];
        }

        FormService.getOrderFormFieldGroups(clusterId).then(function(fieldGroups) {
            $scope.formFieldGroups = [];
            if (fieldGroups) {
                _.each(fieldGroups, function(group) {
                    group.group_display_order = parseInt(group.group_display_order);
                    group.hasActiveFields = false;
                });
                $scope.formFieldGroups = _.sortBy(fieldGroups, [function(o) {
                    return o.group_display_order;
                }]);
            }

            let clientId = newClientId;
            if (!clientId && $scope.order && $scope.order.client_id && $scope.order.client_id.length > 0) {
                clientId = $scope.order.client_id[0];
            }

            FormService.getOrderFormFields(clusterId, false, true, clientId).then(function() {
                $scope.formFields = FormService.formFields;

                _.each($scope.formFieldGroups, function(formFieldGroup){
                    let formGroupFields = _.filter($scope.formFields, {group_id: formFieldGroup.id, active: "1"});
                    formFieldGroup.hasActiveFields = (formGroupFields.length>0); 
                });
                $scope.formFieldGroups = _.filter($scope.formFieldGroups, function(item){
                    return (item.hasActiveFields == true);
                });

                if (!$scope.formFields) {
                    $scope.formFields = [];
                } else if (!$scope.isEditing) {
                    // Remove status_name field
                    let statusIndex = -1;
                    if ($scope.formFields && $scope.formFields.length) {
                        for (let i = 0; i < $scope.formFields.length; i++) {
                            if ($scope.formFields[i].field_name == 'status_name') {
                                statusIndex = i;
                                break;
                            }
                            if ($scope.formFields[i].field_name == 'sales_region_id') {
                                // The ClusterService seems to have two different formats for the selectedClusters property.
                                // Sometimes it's a single object and other times it's an array with an object inside
                                if (ClusterService.pageProperties.selectedClusters.name) {
                                    $scope.model[$scope.formFields[i].field_name] = ClusterService.pageProperties.selectedClusters.name;
                                } else if (ClusterService.pageProperties.selectedClusters[0].name) {
                                    $scope.model[$scope.formFields[i].field_name] = ClusterService.pageProperties.selectedClusters[0].name;
                                }
                            }
                        }
                    }

                    if (statusIndex > -1) {
                        $scope.formFields.splice(statusIndex, 1);
                    }
                }

                if ($scope.isEditing) {
                    getCreatives(true, newModel);  // Indicate that it should invoke loadFormPart2() when ready.
                } else {
                    $scope.creativesReceived = true;
                }

                if (clientId) {
                    UserService.getClient(clientId).then(function(success) {
                        $scope.client = UserService.client;
                        // We need to set cascading values before invoking buildForm so that multiselect fields
                        // are initialized properly.
                        $scope.clientReceived = true;
                        loadFormPart2(newModel);
                    }, function(error) {
                        $ioAlert.show('Error!', 'An error occurred getting the client information for client_id ' + clientId, 'error');
                        $scope.clientReceived = true;
                        loadFormPart2(newModel);
                    });
                } else {
                    $scope.clientReceived = true;
                    loadFormPart2(newModel);
                }
            }, function() {
                console.log("error loading order form fields for business unit: " + clusterId);
                $scope.loadComplete = true;
            });
        }, function() {
            console.log("error loading order form field groups for business unit: " + clusterId);
            $scope.loadComplete = true;
        });
    };

    function loadFormPart2(newModel = null) {
        // Wait for both creatives and the client info from the backend before building the schema, since both are
        // needed, and are fetched in parallel.
        if ($scope.creativesReceived && $scope.clientReceived) {
            $scope.creativesReceived = false;
            $scope.clientReceived = false;
            buildSchema($scope.formFields, newModel);
            setCascadingValues();
            buildForm($scope.formFieldGroups, $scope.formFields);
            registerForAudienceChanges();
            registerForClientChanges();
            $scope.loadComplete = true;
            $scope.hasForm = true;
        }
    }

    function setCascadingValues() {
        for (let i = 0; i < $scope.formFields.length; i++) {
            const field = $scope.formFields[i];
            // Note that we check if the model's value is equal to false (type boolean, value false) so that a checkbox
            // value will be cascaded if it's form value is initially false.  We do not know if it was specifically set
            // to false by the user, of if it was never set, but at least it will be set to true if the client cascading
            // source had set it.  Not perfect, but probably good enough.  To fix this, we'd need to track whether or not
            // the user had set or reset the checkbox, and it's a bit complicated.  Not an issue for new orders.
            if (field.cascading_field_name && !$scope.model[field.field_name + '_override_cascade']) {
                let cascadingValue = ($scope.client ? $scope.client[field.cascading_field_name] : null),
                    modelValue;
                if (field.data_type == "date" && cascadingValue) {
                    let dt = new Date(cascadingValue);
                    modelValue = new Date(dt.getUTCFullYear(), dt.getUTCMonth(), dt.getUTCDate());
                } else if (field.input_type == "multiselect") {
                    modelValue = cascadingValue ? transformCascadingArray(cascadingValue) : [];
                    if (!_.isArray(modelValue)) {
                        // This could happen if we are cascading from a select to a multiselect.
                        modelValue = [modelValue];
                    }
                    // We also need to set the form item's select_models since we might be invoked after the form has
                    // been built (if the client is changed).
                    let multiselectItem = undefined;
                    for (let i=0, len=$scope.form.length; i < len; i++) {
                        const items = $scope.form[i].items;
                        multiselectItem = _.find(items, {field_name: field.field_name});
                        if (multiselectItem) {
                            break;
                        }
                    }
                    if (modelValue && multiselectItem) {
                        // Reset the select_models, and then push each value.
                        // Note that we check if the item to add to the multiselect list is part of the schema, since
                        // some elements of the schema may have been pruned if there is parent-child relationship, and
                        // the parent selection causes only a subset of the values to be valid/active on the child field.
                        multiselectItem.select_models = [];
                        modelValue.forEach(
                            itemName => {
                                if (multiselectItem.schema.items.some(x => x.value == itemName)) {
                                    multiselectItem.select_models.push({value: itemName, label: itemName}) }
                            });
                    }
                } else if (field.input_type == "checkbox") {
                    modelValue = (cascadingValue === true || cascadingValue == "1")
                } else {
                    modelValue = cascadingValue ? transformCascadingArray(cascadingValue) : cascadingValue;
                    if (_.isArray(modelValue)) {
                        modelValue = _.join(modelValue, ', ')
                    }
                }
                $scope.model[field.field_name] = modelValue;
            }
        }
    }

    function transformCascadingArray(val) {
        if (!_.isArray(val)) {
            return val
        }

        if (_.isArray(val) && !_.isArray(val[0])) {
            return val[1];
        }

        return _.map(val, (lookupContentPair) => {
            let valueIndex = 1
            return lookupContentPair[valueIndex]
        });
    }

    function registerForAudienceChanges() {
        const audienceField = $scope.formFields.find(field => $scope.audienceLookupTypeIds.contains(field.lookup_type_id));
        if (audienceField) {
            let audienceItem = undefined;
            for (let i = 0, len = $scope.form.length; i < len; i++) {
                const items = $scope.form[i].items;
                audienceItem = _.find(items, {field_name: audienceField.field_name});
                if (audienceItem) {
                    break;
                }
            }
            if (audienceItem && audienceItem.onChange != onAudienceIdChange) {
                // Save the previous onChange function already associated with the audience item, so it can be invoked too
                // by our own onChange implementation.
                $scope.previousAudienceOnChangeFunction = audienceItem.onChange;
                audienceItem.onChange = onAudienceIdChange;
            }
        }
    }

    function registerForClientChanges() {
        // Note that we could optimize this by doing nothing if there are no cascading fields in the form, or if they
        // are all already filled, but let's keep things simple(r).
        let clientIdItem = undefined;
        for (let i=0, len=$scope.form.length; i < len; i++) {
            const items = $scope.form[i].items;
            clientIdItem = _.find(items, {field_name: 'client_id'});
            if (clientIdItem) {
                break;
            }
        }
        if (clientIdItem) {
            // Save the previous onChange function already associated with the client item, so it can be invoked too
            // by our own onChange implementation.
            $scope.previousOnChangeFunction = clientIdItem.onChange;
            clientIdItem.onChange = onClientIdChange;
        }
        // If not found, nothing to do, there will be nothing to cascade from an inexisting client!
    }

    function onAudienceIdChange(modelValue, modifiedForm) {
        // First, invoke any onChange method that was already associated with this item.
        if ($scope.previousAudienceOnChangeFunction) {
            $scope.previousAudienceOnChangeFunction(modelValue, modifiedForm);
        }
        const audienceField = $scope.formFields.find(field => $scope.audienceLookupTypeIds.contains(field.lookup_type_id));
        if (audienceField) {
            let lookupContent = _.find(audienceField.lookup_content, {lookup_value: modelValue});
            if (lookupContent) {
                const audienceId = lookupContent.id;
                FormService.getAudience(audienceId).then(function (audience) {
                    if (audience && audience.error === false) {
                        $scope.model['__audience_macro_mappings'] = audience.data?.macro_columns || [];
                        $scope.model['__audience_match_mappings'] = audience.data?.mappings || [];
                        reloadForAudience(false);
                    } else {
                        $ioAlert.show('Error!', 'Error fetching audience data for audience id '+audienceId, 'error');
                        reloadForAudience(true);
                    }
                }, function () {
                    $ioAlert.show('Error!', 'Error fetching audience data for audience '+audienceId, 'error');
                    reloadForAudience(true);
                });
            } else {
                reloadForAudience(true);
            }
        } else {
            reloadForAudience(true);
        }
    }

    function reloadForAudience(clear = false) {
        if (clear) {
            $scope.model['__audience_macro_mappings'] = [];
            $scope.model['__audience_match_mappings'] = [];
        }
        let currentModel = _.clone($scope.model);
        let clientField = _.find($scope.formFields, {field_name: 'client_id'});
        let clientId = null;
        if (clientField) {
            let lookupContent = _.find(clientField.lookup_content, {lookup_value: currentModel['client_id']});
            if (lookupContent) {
                clientId = lookupContent.id;
            }
        }
        $scope.model = {};
        $scope.schema = {};
        $scope.form = [];
        $scope.entireForm = [];
        $scope.formFieldMap = {};
        $scope.formFields = null;
        $scope.initClientId = null;
        $scope.initAudienceId = null;
        loadForm(clientId, currentModel);
    }

    function onClientIdChange(modelValue, modifiedForm) {
        // First, invoke any onChange method that was already associated with this item.
        if ($scope.previousOnChangeFunction) {
            $scope.previousOnChangeFunction(modelValue, modifiedForm);
        }

        // Then do our thing: fetch the new client and update any cascaded values (reload the whole form, in case
        // any lookup contents have changed due to the client change).
        // Note that if there is no new client, we won't clear any previous cascaded values.
        if (modelValue) {
            // The modelValue is the client name.  We need to fetch the id.  It's in the formFields, as follows:
            let clientField =  _.find($scope.formFields, {field_name: 'client_id'});
            if (clientField) {
                let lookupContent = _.find(clientField.lookup_content, {lookup_value: modelValue});
                if (lookupContent) {
                    const clientId = lookupContent.id;
                    let currentModel = _.clone($scope.model);
                    // Set the new client id into the model we're going to preserve through the form reload. Form reload
                    // is necessary in case something (like audiences) changes in the form due to the client change.
                    currentModel['client_id'] = modelValue;
                    currentModel['__audience_macro_mappings'] = [];
                    currentModel['__audience_match_mappings'] = [];
                    // If we have an audience field, clear its value because it may not be valid anymore after we've fetched
                    // the form fields again.
                    const audienceField = $scope.formFields.find(field => $scope.audienceLookupTypeIds.contains(field.lookup_type_id));
                    if (audienceField) {
                        currentModel[audienceField.field_name] = undefined;
                    }
                    $scope.model = {};
                    $scope.schema = {};
                    $scope.form = [];
                    $scope.entireForm = [];
                    $scope.formFieldMap = {};
                    $scope.formFields = null;
                    $scope.initClientId = null; // We've chosen another one, so invalidate client that was passed as param
                    $scope.initAudienceId = null;
                    loadForm(clientId, currentModel);
                } else {
                    console.log('Error: cannot find client_id for client name ' + modelValue);
                }
            } else {
                console.log('Error: cannot find client_id form field for client name ' + modelValue);
            }
        }
    }

    const updateForm = function(dataSource, editArray) {
        let order = angular.copy(dataSource);
        for (let i = 0, len = editArray.length; i < len; i++) {
            for (let j in order) {
                if (order.hasOwnProperty(j)) {
                    if (editArray[i].prop == j && editArray[i].type == 'boolean') {
                        order[j] = (order[j] == 1);
                    } else if (editArray[i].prop == j && editArray[i].type == 'array') {
                        let selectArr = [];
                        const arrLen = order[j].length;

                        if (arrLen == 2 && typeof order[j][1] == 'string') {
                            selectArr.push(order[j][1]);
                        } else {
                            for (let k = 0; k < arrLen; k++) {
                                if (order[j][k] && order[j][k].length == 1) {
                                    selectArr.push(String(order[j][k][0]).replace("CUST_", ""));
                                }else if (order[j][k]) {
                                    selectArr.push(order[j][k][1]);
                                }
                            }
                        }
                        order[j] = selectArr;
                    } else if (editArray[i].prop == j && editArray[i].type == 'string' && order[j] && typeof order[j] == 'object') {
                        order[j] = order[j][1];
                    }
                }
            }
        }
        $scope.model = order;

        // DFM hack to make status not editable
        if ($scope.model.status_id && Array.isArray($scope.model.status_id)) {
            $scope.model.status_id = $scope.model.status_id[1];
        }
    };

    // Dynamic form schema
    const buildSchema = function(formFields, newModel = null) {
        // Remember the required creative field ids, so we can later restore their required flag.
        $scope.savedRequiredCreativeFieldIds = FormService.getRequiredCreativeFieldIds(formFields);
        FormService.buildDynamicFormSchema($scope.schema, $scope.formFieldMap, formFields, $scope.isEditing, $scope.order, $scope.creatives, $scope.realCreativeFilenames, !$scope.isEditing);

        if ($scope.isEditing) {
            FormService.addCommentSchema($scope.schema);
            let editArray = [];
            for (let x in $scope.schema.properties) {
                if ($scope.schema.properties.hasOwnProperty(x)) {
                    editArray.push({
                        "prop": x,
                        "type": $scope.schema.properties[x].type
                    });
                }
            }
            if (!newModel) {
                updateForm($scope.order, editArray);
            }
        }
        if (newModel) {
            $scope.model = newModel;
        }
    };

    const resolvePredefinedValue = function(key) {
        let val = null;
        const keyArray = key.split('_');
        const objectName = keyArray.shift();
        let prop = keyArray[0];

        // Need to rebuild object properties containing underscores
        if (keyArray.length > 1) {
            prop = keyArray.join('_');
        }

        switch (objectName) {
            case 'order':
                val = ($scope.order && $scope.order[prop]) ? $scope.order[prop] : 'NA';
                break;

            case 'lineItem':
                val = ($scope.lineItem && $scope.lineItem[prop]) ? $scope.lineItem[prop] : 'NA';
                break;
        }

        return val;
    };

    $scope.calculate = function(data) {
        if(data === undefined) {
            calQueueExc();
            return;
        }
        let dataArray = data.split(',');
        let fieldId = dataArray.shift();
        let formFieldsLen = $scope.formFields.length;
        let errorFieldsArr = [];
        let calculateFields = {
            'fieldId': parseInt(fieldId)
        };

        let getFamilyNestedCondition = function(field) {
            let condition = true;
            if(field.parentField){
                let modelValue = $scope.model[field.parentField.field_name];
                let isConditionValueAnArray = Array.isArray(field.conditionValue);


                if (isConditionValueAnArray && field.parentField.input_type == 'checkbox' && _.isBoolean(modelValue)) {
                    modelValue = modelValue === true ? '1' : '0'
                }

                if (Array.isArray(modelValue)) {
                    condition = isConditionValueAnArray ?
                        modelValue.some(oneVal => field.conditionValue.includes(oneVal)) :
                        modelValue.includes(field.conditionValue)
                } else {
                    condition = isConditionValueAnArray ?
                        field.conditionValue.includes(modelValue) :
                        modelValue == (field.conditionValue);
                }
                condition = getFamilyNestedCondition(field.parentField) && condition;
            }
            return condition;
        }

        const numericTypes = ['integer', 'decimal', 'currency', 'percentage'];

        for (let i = 0; i < formFieldsLen; i++) {
            for (let j = 0, len = dataArray.length; j < len; j++) {
                if (parseInt(dataArray[j]) == $scope.formFields[i].id) {
                    const parameterName = 'ff' + $scope.formFields[i].id;
                    const field = $scope.formFields[i];
                    const condition = getFamilyNestedCondition(field);
                    const valueOfFieldInFormula = $scope.model[$scope.formFields[i].field_name];
                    if (valueOfFieldInFormula && condition) {
                        if ($scope.formFields[i].lookup_content) {
                            let formValue = valueOfFieldInFormula;
                            formValue = $scope.formFields[i].input_type == 'select2' ? formValue.text : formValue;
                            let lookupContentIds = FormService.getLookupContentIds(
                                $scope.formFields[i].field_name,
                                $scope.formFields[i].lookup_content,
                                formValue, false, $scope.model,
                                $scope.formParentChildInfo);
                            if (Array.isArray(lookupContentIds)) {
                                lookupContentIds = lookupContentIds.length === 0 ? "" : lookupContentIds;
                                calculateFields[parameterName + "[]"] = lookupContentIds;
                            } else {
                                calculateFields[parameterName] = lookupContentIds;
                            }
                            // we do not need to handle symbol or format for ids in the formula
                        } else {
                            calculateFields[parameterName] = valueOfFieldInFormula;
                            // we need to handle symbol or format for numbers in the formula
                            if ($scope.formFields[i].data_type == "currency" &&
                                calculateFields[parameterName].contains("$")) {
                                calculateFields[parameterName] = calculateFields[parameterName].replace(/\$/g, '');
                            }
                            if ($scope.formFields[i].data_type == "percentage" &&
                                calculateFields[parameterName].contains('%')) {
                                calculateFields[parameterName] = calculateFields[parameterName].replace(/\%/g, '');
                            }
                            if (numericTypes.includes($scope.formFields[i].data_type)) {
                                calculateFields[parameterName] = calculateFields[parameterName].replace(/,/g, '');
                            }
                            if ($scope.formFields[i].data_type == "date") {
                                calculateFields[parameterName] = $filter('date')(calculateFields[parameterName], 'yyyy-MM-dd');
                            }
                            if ($scope.formFields[i].input_type == "timeselect") {
                                if (typeof valueOfFieldInFormula == 'object') {
                                    calculateFields[parameterName] = valueOfFieldInFormula.toLocaleTimeString();
                                }
                            }
                        }
                    } else {
                        calculateFields[parameterName] = 0;
                    }
                } else {
                    var predefinedValue = resolvePredefinedValue(dataArray[j]);

                    if (predefinedValue) {
                        var pname = 'ff' + dataArray[j];
                        calculateFields[pname] = predefinedValue;
                    }
                }
            }
        }

        if ($scope.order) {
            calculateFields['orderId'] = $scope.order.id;
        }
        FormService.calculateOrder(calculateFields).then(function(success) {
            if (success) {
                if (angular.isUndefined(success.value) || success.status === 'fail') {
                    if (angular.isUndefined(success.message)) {
                        $ioAlert.show('', "Please make sure all required fields are filled in.", 'error');
                    } else {
                        $ioAlert.show('', success.message, 'error');
                    }
                    success.value = '';
                    if (success.message) {
                        console.log(success.message);
                    }
                }

                $scope.formFields.forEach((field) => {
                    if (parseInt(fieldId) == field.id) {
                        switch (field.data_type) {
                            case 'currency':
                                $scope.model[field.field_name] = FormService.currencyFormating(
                                    String(success.value),
                                    true
                                );
                                break;
                            case 'integer':
                                $scope.model[field.field_name] = FormService.numberFormating(String(success.value), 0);
                                break;
                            case 'decimal':
                                $scope.model[field.field_name] = FormService.numberFormating(String(success.value));
                                break;
                            case 'percentage':
                                $scope.model[field.field_name] = String(success.value) + '%';
                                break;
                            case 'date':
                                $scope.model[field.field_name] = FormService.convertStringToDate(String(success.value));
                                break;
                            default:
                                $scope.model[field.field_name] = String(success.value);
                        }
                    }
                });
            }
            calQueueExc();
        }, function(error) {
            $ioAlert.show("Error!", "An error occurred calculating the fields.", 'error');
            $rs.loadingInProgress = false;
            calQueueExc();
        });
    };


    function calQueueExc() {
        if ($scope.calQueue.length > 0) {
            var fieldIds = $scope.calQueue.shift();
            $scope.calculate(fieldIds);
        }else if($scope.calQueue.length == 0){
            $rs.loadingInProgress = false;
        }
    }

    $scope.calculateAllFields = function() {
        $rs.loadingInProgress = true;
        $rs.loadertitle = "Fetching Calculate Details";
        $scope.calQueue = [];
        for (let i = 0, len = $scope.entireForm.length; i < len; i++) {
            if ($scope.entireForm[i].items) {
                for (let j = 0, size = $scope.entireForm[i].items.length; j < size; j++) {
                    if ($scope.entireForm[i].items[j].fieldIds != undefined) {
                        //$scope.calculate($scope.form[i].items[j].fieldIds);
                        const item = $scope.entireForm[i].items[j];
                        if(item.condition) {
                            const parent_name = item.conditionInfo.parent_name;
                            const lookupValue = item.conditionInfo.lookup_value;
                            const input_type = item.conditionInfo.input_type;

                            const isModelParentNameUndefined = $scope.model[parent_name] === undefined;
                            const isInputNotACheckbox = input_type != "checkbox";
                            const isLookupValueNotIncludedInParent = checkIfLookupValueIsNotIncludedInParent(lookupValue, $scope.model[parent_name]);

                            if (isModelParentNameUndefined || (isInputNotACheckbox && isLookupValueNotIncludedInParent)){
                                continue;
                            }
                        }
                        if ($scope.entireForm[i].items[j].showOverride) {
                            if (!$scope.entireForm[i].items[j].override)
                                $scope.calQueue.push($scope.entireForm[i].items[j].fieldIds);
                        } else {
                            $scope.calQueue.push($scope.entireForm[i].items[j].fieldIds);
                        }
                    }
                }
            }
        }
        $scope.calQueue = FormService.reorderCalQueue($scope.calQueue);
        calQueueExc();
    };

    function checkIfLookupValueIsNotIncludedInParent(lookupValue, parent) {
        if (!(parent && _.isFunction(parent.includes))) {
            return true;
        }

        if (Array.isArray(lookupValue)) {
            let returned = true;
            lookupValue.forEach(function(val) {
                returned = returned && !parent.includes(val);
            })

            return returned;
        }

        return !parent.includes(lookupValue);
    }

    // Dynamic form structure
    const buildForm = function(formFieldGroups, formFields) {
        const clientId = $scope.client ? $scope.client.id : null;
        FormService.buildDynamicForm($scope.model, $scope.entireForm, $scope.formParentChildInfo, $scope.schema, $scope.formFieldMap,
            formFieldGroups, formFields, $scope.isEditing, clientId, null, $scope.order, $rs.userRole, 'order');

        if ($scope.initAudienceId) {
            const audienceField = $scope.formFields.find(field => $scope.audienceLookupTypeIds.contains(field.lookup_type_id));
            if (audienceField && audienceField.lookup_content) {
                const lookupContent = audienceField.lookup_content.find(content => content.id == $scope.initAudienceId);
                if (lookupContent) {
                    $scope.model[audienceField.field_name] = lookupContent.lookup_value;
                }
            }
        }

        if ($scope.initClientId) {
            const clientField = $scope.formFields.find(field => field.field_name == 'client_id');
            if (clientField && clientField.lookup_content) {
                const lookupContent = clientField.lookup_content.find(content => content.id == $scope.initClientId);
                if (lookupContent) {
                    $scope.model['client_id'] = lookupContent.lookup_value;
                    UserService.getClient($scope.initClientId).then(function(success) {
                        $scope.client = UserService.client;
                        setCascadingValues();
                    }, function(error) {
                        console.log('Error: cannot fetch client info for client_id=' + clientId + ': ' + error);
                    });
                }
            }
        }

        _.each($scope.entireForm, function (section) {
            if (section.type == 'hidden') {
                return;
            }
            $scope.form.push(section);
        });

        $scope.$broadcast('schemaFormRedraw');

        if ($scope.isEditing) {
            $scope.form.push(FormService.getCommentInputSection(null, $scope.addCommentRequired, $scope.order.cluster_id[0]));
        }

        const controlText = ($scope.isEditing) ? "Update Order" : "Create Order";
        $scope.form.push(FormService.getSubmitControl(controlText, "formObj", true, $scope.isEditing));
    };

    var getOrder = function() {
        $scope.editLoader = true;
        $scope.loadFail = true;
        OrdersService.getOrder($scope.orderId).then(function() {
            $scope.order = OrdersService.order;
            // Editing the order
            if ($scope.order) {
                $scope.lineItems = $scope.order.lineItems;
                $scope.orderStatusId = $scope.order.status_id[0];
                $scope.order.orderType = angular.copy($scope.order.order_type_id);
                $scope.order.orderTypeId = $scope.order.orderType[0];
                setDataTableOptions();
                getOrderComments();
                loadForm();
            }
        }, function() {
            console.log("Error getting order for id: " + $scope.orderId);
        });
    };

    const getCreatives = function(fromForm = false, newModel = null) {
        MediaService.getOrderCreatives($scope.order.id).then(function() {
            $scope.creatives = MediaService.creatives;
            if ($scope.creatives) {
                $scope.hasCreatives = true;
            }
            if (fromForm) {
                $scope.creativesReceived = true;
                loadFormPart2(newModel);
            }
        }, function() {
            console.log("Error getting creatives for order: " + $scope.order.id);
            if (fromForm) {
                $scope.creativesReceived = true;
                loadFormPart2(newModel);
            }
        });
    };

    const setDataTableOptions = function() {
        $timeout(function() {
            let options = $.core.datatable.defaultOptions('line item');
            options.iActions = 3;
            options.hasFixedHeader = false;
            options.isIoTool = true;
            $.core.datatable.build($('#line-items-table'), options);

            if ($scope.order) {
                $('a.clientToggler').trigger('click');
            }
        });
    };

    var getOrderComments = function() {
        CommentService.getOrderComments($scope.order.id).then(function() {
            if (CommentService.comments) {
                $scope.existingComments = CommentService.comments;
            }
        }, function() {
            console.log("Error getting comments for order: " + $scope.order.id);
        });
    };

    var attachCreatives = function(id, selectedCreatives, type) {
        var lastCreativeId = selectedCreatives[selectedCreatives.length - 1].id;
        for (var i = 0, len = selectedCreatives.length; i < len; i++) {
            var obj = {
                "creativeId": selectedCreatives[i].id,
                "orderId": id
            };
            creativesService.assignToOrder(obj).then(function(response) {
                if (response && response.errors) {
                    for (var j = 0, len = response.errors.length; j < len; j++) {
                        $ioAlert.show('Error', response.errors[j]);
                    }
                    $scope.model.creativelibrary = null;
                }
                /*
                Call Get creatives after all the requests are completed
                */
                if (response.creative && lastCreativeId == response.creative.creative_id) {
                    $scope.model.creativelibrary = null;
                }
                $scope.showOrder();
            }, function(error) {
                $ioAlert.show('ERROR!', 'Attaching creative failed. Please try again later.', 'error');
            });
        }
    };

    // Add or update order
    $scope.submitForm = function(orderForm) {
        var returnObj = FormService.validateCustomWidgets($scope.formFields, $scope.model);
        var isFormWidgetsValid = !returnObj.validateWidgets.includes(false);
        var dayPartingValidation = FormService.validateDayPartingWidget($scope.formFields, $scope.model);
        $scope.model = returnObj.model;

        FormService.restoreRequiredCreativeFieldIds($scope.formFields, $scope.savedRequiredCreativeFieldIds);
        let hasRequiredCreativeFields = FormService.formHasRequiredCreativeFields($scope.formFields, $scope.model);
        // When adding (i.e. not editing), validation is done via the standard form required field validation.
        const creativeRequiredFieldsValid = ($scope.isEditing && hasRequiredCreativeFields) ?
            FormService.validateCreativesPresent($scope.formFields, $scope.model, $scope.realCreativeFilenames) : true;

        $scope.$broadcast('schemaFormValidate');
        if (orderForm.$valid && returnObj.isValid && isFormWidgetsValid && dayPartingValidation && creativeRequiredFieldsValid) {
            $scope.form[$scope.form.length - 1].isSubmitted = true;
            var orderData = FormService.harvestData($scope.formFields, $scope.entireForm, $scope.model, 'order', $scope.formParentChildInfo);
            if ($scope.isEditing) {
                orderData.id = $scope.order.id;
                orderData.statusId = $scope.orderStatusId;
            }
            // Setup a default cluster
            orderData.clusterId = ClusterService.clusterIds[0];
            if ($scope.validationErrors.length == 0) {
                if ($scope.isEditing) {
                    if ($scope.addCommentRequired && !$scope.model.comment) {
                        $ioAlert.show("Error", 'A comment is required when editing an order', "error");
                        $scope.form[$scope.form.length - 1].isSubmitted = false;
                        return;
                    }
                    orderData.__comment__ = $scope.model.comment;
                    updateOrder(orderData);
                } else {
                    addOrder(orderData);
                }
            } else {
                for (var x = 0; x < $scope.validationErrors.length; x++) {
                    $ioAlert.show("Error", $scope.validationErrors[x], "error");
                }
                $scope.form[$scope.form.length - 1].isSubmitted = false;
                $scope.validationErrors = [];
            }
        } else if(!dayPartingValidation){
            $ioAlert.show("Validation Error!", "Please check the day parting time slots", "error");
        } else if (!creativeRequiredFieldsValid) {
            $ioAlert.show("Validation Error!", "Please add in the required creative", 'error');
        } else {
            $ioAlert.show('Error!', 'All mandatory fields have not been populated', 'error');
            $scope.form[$scope.form.length - 1].isSubmitted = false;
        }
    };

    const addOrder = function(orderData) {
        OrdersService.addOrder(orderData).then(function() {
            if (OrdersService.errors) {
                for (let x = 0; x < OrdersService.errors.length; x++) {
                    $ioAlert.show("Error", OrdersService.errors[x], "error");
                }
                $scope.form[$scope.form.length - 1].isSubmitted = false;
                OrdersService.errors = null;
            } else {
                $scope.orderId = OrdersService.order.id;
                $scope.order = OrdersService.order;
                let hasFileUpload = false;

                if ($scope.model.comment) {
                    addComment();
                }

                _.each($scope.model, function(itemVal, prop){
                    let fieldObj = _.find($scope.formFields, {field_name: prop});
                    if (fieldObj && fieldObj.input_type == "file" && $scope.model[prop]) {
                        hasFileUpload = true;
                        const adGroupMoreInfo = { 'fieldId': fieldObj.id, 'fieldType': 'file', 'entityType': 'order' };
                        addCreativeAssets($scope.order.id, $scope.model[prop], true, adGroupMoreInfo);
                    } else if (fieldObj && fieldObj.input_type == "creative" && $scope.model[prop]) {
                        hasFileUpload = true;
                        if ($scope.model[prop].media && $scope.model[prop].media.length > 0) {
                            addCreativeAssets(OrdersService.order.id, $scope.model[prop].media, $scope.model[prop].library);
                        }
                        if ($scope.model[prop].selectedImages && $scope.model[prop].selectedImages.length > 0) {
                            attachCreatives(OrdersService.order.id, $scope.model[prop].selectedImages)
                        }
                    } else if (fieldObj && fieldObj.input_type == "custom_file" && $scope.model[prop]) {
                        $scope.uploadCustomFile(prop);
                    }
                });

                $scope.showOrder();
            }
        }, function() {
            $ioAlert.show('Error!', 'Error Creating new Order, Please try again', 'error');
            $scope.form[$scope.form.length - 1].isSubmitted = false;
        });
    }

    const updateOrder = function(orderData) {
        let uploadingFiles;
        let deletedFiles;
        let hasFileOrCreativeModifications;
        [uploadingFiles, deletedFiles] =
            FormService.computeUploadingAndDeletedFiles($scope.model, $scope.form, $scope.formFields);

        OrdersService.updateOrder(orderData).then(function() {
            if (OrdersService.errors) {
                for (let x = 0; x < OrdersService.errors.length; x++) {
                    $ioAlert.show("Error", OrdersService.errors[x], "error");
                }
                $scope.form[$scope.form.length - 1].isSubmitted = false;
                OrdersService.errors = null;
            } else {
                if (OrdersService.warnings) {
                    _.each(OrdersService.warnings, function(warn) {
                        $ioAlert.show("Warning!", warn, "warn");
                    });
                    OrdersService.warnings = null;
                }
                $scope.order = OrdersService.order;

                let hasFileUpload = false;

                _.each($scope.model, function(itemVal, prop){
                    const fieldObj = _.find($scope.formFields, {field_name: prop});
                    if (fieldObj && fieldObj.input_type == "file" && $scope.model[prop]) {
                        hasFileUpload = true;
                        let adGroupMoreInfo = {
                            fieldId: fieldObj.id,
                            fieldType: fieldObj.input_type,
                            entityType: 'order',
                        };
                        if (deletedFiles[prop].length > 0) {
                            deleteCreativeAssets(OrdersService.order.id, deletedFiles[prop], adGroupMoreInfo);
                        }
                        if (uploadingFiles[prop].length > 0) {
                            addCreativeAssets($scope.order.id, uploadingFiles[prop], false, adGroupMoreInfo);
                        }
                    } else if (fieldObj && fieldObj.input_type == "creative" && $scope.model[prop]) {
                        hasFileUpload = true;
                        if ($scope.model[prop].media && $scope.model[prop].media.length > 0) {
                            addCreativeAssets(OrdersService.order.id, $scope.model[prop].media, $scope.model[prop].library);
                        }
                        if ($scope.model[prop].selectedImages && $scope.model[prop].selectedImages.length > 0) {
                            attachCreatives(OrdersService.order.id, $scope.model[prop].selectedImages)
                        }
                    } else if (fieldObj && fieldObj.input_type == "custom_file" && $scope.model[prop]) {
                            $scope.uploadCustomFile(prop);
                    }
                });

                orderData.__comment__ = ''
                $scope.showOrder();
            }
        }, function() {
            $ioAlert.show('Error!', 'Error updating Order, Please try again', 'error');
            $scope.form[$scope.form.length - 1].isSubmitted = false;
        });
    }

    // TODO REMOVE AFTER HARVEST DATA IS TESTED
    var getParameterName = function(field) {
        var parameterName = null;

        switch (field.field_name) {
            case 'client_id':
                parameterName = 'clientId';
                break;
            case 'order_type_id':
                parameterName = 'orderTypeId';
                break;
            case 'salesperson_id':
                parameterName = 'salespersonId';
                break;
            case 'status_id':
                parameterName = 'statusId';
                break;
            default:
                parameterName = 'ff' + field.id;
        }
        return parameterName;
    };

    var addComment = function() {
        const params = {
            'tableName': 'order',
            'tableRowId': $scope.order.id,
            'comment': $scope.model.comment,
            'skipWorkflow': '1',
        };
        CommentService.addOrderComment(params).then(function() {},
            function() {
                console.log("Error saving comment for order: " + $scope.order.id);
            });
    };

    let deleteCreativeAssets = function(orderId, creativeFileObjs, adGroupMoreInfo) {
        let orderAttrIds = [];
        _.each(creativeFileObjs, function(fileObj){
            orderAttrIds.push(fileObj.attr_id);
        });
        let params = {
            'orderAttrIds': orderAttrIds.toString(),
        }
        if (orderAttrIds.length > 0) {
            OrdersService.removeOrderFileAttrsAndCreatives(params).then(function(response) {
                $ioAlert.show("", "File was removed successfully.", 'success');
            }, function(response) {
                console.log(response);
                $ioAlert.show("Error!", "An error occurred deleting the order attrs.", 'error');
            });
        }
    };

    var addCreativeAssets = function(orderId, creativeFiles, showOrder, adGroupMoreInfo = undefined) {
        var libraryCreative = "yes";

        angular.forEach(creativeFiles, function(file, index) {
            if (index == 0) {
                $ioAlert.show('', "Creatives are being uploaded, please don't refresh the page. You can check the status in the notifications tab", 'success');
            }
            creativesService.getImgSignature({
                orderId: orderId,
                fileName: file.name
            }).then(function(success) {
                let additionalParams = {
                    "from": "order",
                    "orderId": orderId,
                    "libraryCreative": libraryCreative,
                    "file_name": file.name,
                    "file_size": file.size
                };
                if (adGroupMoreInfo) {
                    additionalParams.adGroupMoreInfo = adGroupMoreInfo;
                }
                $rs.$broadcast('cloudinaryImageUpload', success, file, additionalParams);
            }, function(error) {
                const details = JSON.stringify(error);
                $ioAlert.show('Error!', 'Could not get img signature. ' + details, 'error');
                console.log(error);
            });

        });

        if (showOrder) {
            $scope.showOrder();
        }
    };

    $scope.deleteCreative = function(creative) {
        var modalInstance = $modal.open(
            ModalService.returnModalType('deleteCreative', {
                data: {
                    filename: creative.file_name
                }
            })
        );
        modalInstance.result.then(function(selectedItem) {
            if (selectedItem == 'ok') {
                MediaService.deleteCreative(creative.id).then(function(success) {
                    if (success.id) {
                        // Remove creative from scope
                        for (let i = 0; i < $scope.creatives.length; i++) {
                            if ($scope.creatives[i].creative_id == success.id) {
                                $scope.creatives.splice(i, 1);
                            }
                        }
                        // Remove it from our list of "real" creatives (i.e. creatives for "creative" and not "file"
                        // form fields), so that if they're empty, and if we have required creative fields on the form,
                        // we validate that a new one has been added during the edit.  If there was a real creative on
                        // the order, we do not require a creative to be added again when editing it even if the creative
                        // is a required field.
                        for (let i = 0; i < $scope.realCreativeFilenames.length; i++) {
                            if ($scope.realCreativeFilenames[i] == creative.file_name) {
                                $scope.realCreativeFilenames.splice(i, 1);
                            }
                        }
                        success.updated_fields.forEach((field) => {
                            $scope.model[field.field_name] = field.field_value;
                        });
                        $ioAlert.show("", "Successfully deleted " + creative.file_name, "success");
                    } else {
                        $ioAlert.show("Error!", "An error occurred deleting the creative media.", 'error');
                    }
                }, function() {
                    $ioAlert.show("Error!", "An error occurred deleting the creative media.", 'error');
                });
            }
        }, function() {});
    };

    $scope.uploadCustomFile = function(prop) {
        $scope.waitForOrderCustomFileUpload = true;
        FormService.processCustomFile('order', $scope.order.id, prop, $scope.model[prop], 0).then(function() {
            $scope.waitForOrderCustomFileUpload = false;
            $scope.showOrder();
        }, function() {
            $scope.waitForOrderCustomFileUpload = false;
            $scope.showOrder();
        });
    };

    $scope.showEditView = function(orderId) {
        $state.go('editOrder', {
            id: orderId,
            message: 'new'
        });
    };

    $scope.showAddLineItem = function() {
        $state.go('addLineItem', {
            orderId: $scope.order.id,
            order: $scope.order
        });
    };

    $scope.showEditLineItem = function(lineItem) {
        $state.go('editLineItem', {
            orderId: lineItem.order_id,
            lineItemId: lineItem.id
        });
    };

    $scope.showOrder = function() {
        if (!$scope.waitForOrderCustomFileUpload) {
            if ($scope.orderId) {
                $state.go('viewOrder', {
                    id: $scope.orderId
                });
            } else {
                $state.go('orders');
            }
        }
    };

    var showAlerts = function() {
        switch ($stateParams.message) {
            case 'new':
                $ioAlert.show('', 'Order created successfully', 'success');
                break;
            case 'editLineItem':
                $ioAlert.show('', 'Line Item updated successfully', 'success');
                break;
            case 'newLineItem':
                $ioAlert.show('', 'Line Item created successfully', 'success');
                break;
        }
    };

    const initClientBeforeForm = function(clientId) {
        UserService.getClient(clientId).then(function(success) {
            $scope.client = UserService.client;
            // We need to set cascading values before invoking buildForm so that multiselect fields
            // are initialized properly.
            $scope.clientReceived = true;
            
            ClusterService.initCluster($rs.userRole, 'disabled', $scope.client.cluster_id).then(function() {
                $scope.cluster = ClusterService.pageProperties.selectedClusters;
                OrdersService.getLookupTypesForContentKey('audience').then(function (data) {
                    $scope.audienceLookupTypeIds = data.lookupTypes;
                    loadForm();
                }, function () {
                    loadForm();
                });
            });
        }, function(error) {
            $ioAlert.show('Error!', 'An error occurred getting the client information for client_id ' + clientId, 'error');
        });
    };

    var initForm = function() {
        if ($stateParams.id) {
            $scope.orderId = $stateParams.id;
            OrdersService.getLookupTypesForContentKey('audience').then(function (data) {
                $scope.audienceLookupTypeIds = data.lookupTypes;
                getOrder();
            }, function () {
                getOrder();
            });
        } else if ($scope.initClientId) {
            initClientBeforeForm($scope.initClientId);
        } else {
            ClusterService.initCluster($rs.userRole, 'singleSelect').then(function() {
                $scope.cluster = ClusterService.pageProperties.selectedClusters;
                OrdersService.getLookupTypesForContentKey('audience').then(function (data) {
                    $scope.audienceLookupTypeIds = data.lookupTypes;
                    loadForm();
                }, function () {
                    loadForm();
                });
            });
            DateRangeService.init();
        }
        if($rs.userRole.user_type === "superadmin" && $rs.userRole.permissions !== "sales" && $rs.userRole.permissions !== "sales,admin"){
            $scope.showCommentSection = true;
        }else{
            $scope.showCommentSection = !($rs.lineItemHide && $rs.lineItemHide.value === "1");
        }

        if ($rs.userRole.permissions && $rs.userRole.permissions.indexOf("admin") > -1) {
            $scope.isAdmin = true;
        }

        if ($stateParams.message) {
            showAlerts();
        }

        $('.toggler').click(function(e) {
            e.preventDefault();
            var $icon = $(this).find('span.icon');

            if ($icon.hasClass('icon-caret-right')) {
                $icon.removeClass('icon-caret-right').addClass('icon-caret-down');
            } else {
                $icon.removeClass('icon-caret-down').addClass('icon-caret-right');
            }
        });
    };

    var init = function() {
        // get instance settings
        let params = [
            'add_comment_is_required_for_editing',
        ];
        FormService.getInstanceSettingArray(params).then(function(data) {
            if (data && data.instance_settings) {
                let comment_required = data.instance_settings.add_comment_is_required_for_editing;
                $scope.addCommentRequired = (comment_required === "1");
            }
            initForm();
        }, function(error) {
            $ioAlert.show('Error!', 'Cannot load add_comment_is_required_for_editing instance setting', error);
            initForm();
        });
    };

    if ($rs.userRole && $rs.userRole.id) {
        init();
    } else {
        UserService.getCurrentUserRole().then(function() {
            $rs.userRole = UserService.userRole;
            init();
        }, function() {});
    }

}

OrderViewController.$inject = [
    '$rootScope', '$scope', '$timeout', '$stateParams', '$state', '$confirm',
    '$window', 'OrdersService', 'ClusterService', 'DateRangeService', 'FormService',
    'DisplayService', 'UserService', 'CommentService', '$ioAlert', 'TaskService',
    'MediaService', 'ModalService', '$modal', 'creativesService', 'CurrentUser', 'AppConfig',
    'UserFactory', 'StatusService'
];

function OrderViewController(
    $rs, $scope, $timeout, $stateParams, $state, $confirm, $window, OrdersService,
    ClusterService, DateRangeService, FormService, DisplayService, UserService,
    CommentService, $ioAlert, TaskService, MediaService, ModalService, $modal,
    creativesService, CurrentUser, AppConfig, UserFactory, StatusService) {
    const STATUS_LINEITEM_PENDING_SUBMISSION = 8;
    const STATUS_LINEITEM_COMPLETE = 13;
    const STATUS_LINEITEM_CLOSED = 14;
    const STATUS_LINEITEM_CANCELLED = 32;
    const TASK_NAME_COMPLETE_ORDER = 'complete_order';
    const STATUS_ORDER_PENDING_CANCEL = 29;
    const STATUS_ORDER_CANCEL = 31;
    const STATUS_ORDER_COMPLETE = 6;
    const STATUS_ORDER_CLOSED = 7;
    const WORKFLOW_TYPE_ORDER = 1;
    const watcher_authorized_roles = [
        'superadmin',
        'ad_operations_manager',
        'ad_operations',
        'sales_manager',
    ];

    $scope.order = null;
    $scope.orderNonEditable = false;
    $scope.disableClone = false;
    $scope.client = null;
    $scope.orderNotificationUsers = null;
    $scope.lineItems = null;
    $scope.creatives = null;
    $scope.formFields = null;
    $scope.formFieldGroups = null;
    $scope.displayGroups = {};
    $scope.restrictEdit = false;
    $scope.hasCreatives = false;
    $scope.fromMyQueues = false;
    $scope.loadComplete = false;
    $scope.showStartOver = false;
    $scope.associatedTasks = [];
    $scope.showAssociatedTasks = false;
    $scope.orderCompletionTask = null;
    $scope.showOnDemandTasks = false;
    $scope.duplicateOnDemandTasks = false;
    $scope.allowDeleteComments = false;
    $scope.selectedFile = [];
    $scope.checkAll = {
        value: false
    };
    $scope.orderAttrs = {};
    $scope.showCancel = false;
    $scope.hideLineItemFilterButton = false;
    $scope.selectedLineItems = [];
    $scope.processLineItems = [];
    $scope.historyTasks = [];
    $scope.showTaskProgress = false;
    $scope.showHistoryTaskProgress = false;
    $scope.flightsEnabled = false;
    $scope.creativeLineItems = [];
    $scope.showCreatives = false;
    $scope.currentUserId = $rs.userRole.id;
    $scope.processDownloadAll = false;
    $scope.startOrderWatcherForMeHide = true;
    $scope.model = {
        quickComment: null,
        onDemandTask: null,
        addOnDemandTask: null,
        addOnDemandTaskToggle: false
    };
    $scope.watcherUsers = [];
    $scope.commentFilter = {
        items: [], 
        search: ""
    };

    if ($stateParams.taskId) {
        $scope.taskId = $stateParams.taskId;
        $scope.orderId = $stateParams.orderId;
        $scope.hasTaskId = true;

        if ($stateParams.myQueues) {
            $scope.fromMyQueues = true;
        }
    } else {
        $scope.hasTaskId = false;
    }

    if ($rs.userRole.permissions && $rs.userRole.permissions.indexOf("admin") > -1) {
        $scope.allowDeleteComments = true;
    }
    $scope.showComments = true;
    $scope.showLineItem = true;
    $scope.goals = null;
    $scope.hasGoals = false;
    $scope.showArchivedLineItems = false;
    $scope.searchLineItemsObj = {};
    $scope.searchLineItemsObj.searchText = '';
    $scope.searchLineItemsObj.searchField = 'flight id';
    $scope.allowed_order_statuses = [];

    $scope.openExceptionLog = function (exception_log_id) {
        var modalInstance = $modal.open(
            ModalService.returnModalType('commonLog', {
                data: {
                    title: 'Exception Log',
                    logType: 'exceptionLog',
                    id: exception_log_id,
                }
            })
        );
    };

    $scope.openGoals = async function(goal) {
        let path = AppConfig.TA_URL;
        if (goal.is_old === "0" || !goal.is_old) {
            if (!$rs.user.user.use_nui) {
                await UserFactory.updateAppPreference({use_nui: true});
            }
            window.location = `${AppConfig.NUI_PATH}#/sup/goals?id=${goal.id}`;
        } else {
            const start_date = moment.unix(goal.start).utc().format('YYYY-MM-DD');
            const end_date = moment.unix(goal.end).utc().format('YYYY-MM-DD');
            $window.open(path + `#/goals?start_date=${start_date}&end_date=${end_date}`, '_self');
        }
    };
    $scope.orderSubmit = false;

    function setLineItemDeleteable(lineItem) {
        lineItem.deleteable = (lineItem.status_id == STATUS_LINEITEM_PENDING_SUBMISSION);
    }

    $scope.upload = function(creativeFiles) {
        if (creativeFiles.length) {
            angular.forEach(creativeFiles, function(file, index) {
                if (!(/^[A-Za-z0-9_.@()-\s]+\.[^.]+$/i.test(file.name))) {
                    console.log('Warning, filename contains metacharacters.');
                }
                if (index == 0) {
                    $ioAlert.show('', 'Creatives are being uploaded dont refresh the page. You can check the status in the notifications tab', 'success');
                }
                creativesService.getImgSignature({
                    orderId: $scope.order.id,
                    fileName: file.name
                }).then(function(success) {
                    var additionalParams = {
                        "from": "order",
                        "orderId": $scope.order.id,
                        "libraryCreative": "no",
                        "file_name": file.name,
                        "file_size": file.size,
                        "section": "comments"
                    };
                    $rs.$broadcast('cloudinaryImageUpload', success, file, additionalParams);
                    if (index === creativeFiles.length - 1) {
                        $scope.uploadMedia = {};
                    }
                }, function(error) {
                    const details = JSON.stringify(error);
                    $ioAlert.show('Error!', 'Could not get img signature.' + details, 'error');
                    console.log(error);
                });
            });
        }
    };

    $scope.addOrderFileToCreativeLibrary = function(creative_id) {
        const params = {
            'id': creative_id,
            'orderId': $scope.order.id,
            'libraryCreative': 'yes',
        }
        $rs.loadingInProgress = true;
        MediaService.updateCreative(params).then(function(creative) {
            const index = $scope.creatives.findIndex(x => x.creative_id == creative.id);
            $scope.creatives[index].shared = creative.shared;
            $ioAlert.show('', 'File was added to creative library successfully.', 'success');
            $rs.loadingInProgress = false;
        }, function(error) {
            $ioAlert.show('error', error);
            console.log("Error adding file to creative library with creative_id: " + creative_id);
            $rs.loadingInProgress = false;
        });
    }

    // Hack to remove any previous listener, otherwise they add up every time you load that page.  Could not destroy
    // it when destroying the scope because it is needed to finish loading the creatives after having left the page, so
    // in this case $destroy runs too early...
    $rs.$$listeners['commentsUploadSuccess'] = [];
    $rs.$$listenerCount['commentsUploadSuccess'] = 0;
    $rs.$on('commentsUploadSuccess', function() {
        getCreatives();
    });

    $rs.$$listeners['orderUploadSuccess'] = [];
    $rs.$$listenerCount['orderUploadSuccess'] = 0;
    $rs.$on("orderUploadSuccess", function(event, successObj, additionalParams){
        if (!additionalParams?.adGroupMoreInfo) {
            return;
        }
        let moreInfo = additionalParams.adGroupMoreInfo;
        moreInfo.orderId = additionalParams.orderId;
        switch (moreInfo.entityType) {
            case 'order':
                orderFileUpload(moreInfo, successObj, additionalParams.uploadFileIdx);
                break;
            default:
        }
    });

    const orderFileUpload = function(moreInfo, successObj, uploadFileIdx) {
        if (moreInfo.fieldId) {
            const orderAttrObj = {
                orderId: moreInfo.orderId,
                formFieldId: moreInfo.fieldId,
                attrValue: successObj.id,
            }
            OrdersService.addOrderAttribute(orderAttrObj).then(function() {
                updateOrderFileFieldAttrs(moreInfo.orderId, moreInfo);
                getCreatives();
            }, function() {
                $ioAlert.show("Error!", "An error occurred adding the order attr.", 'error');
            });
        }
    };

    const updateOrderFileFieldAttrs = function(orderId, adGroupMoreInfo) {
        if (!adGroupMoreInfo) {
            return;
        }
        OrdersService.getOrderFileFieldAttrs(orderId, adGroupMoreInfo.fieldId).then(function(fieldInfo) {
            if (!fieldInfo.attr_value) {
                return;
            }

            // JSON.stringify($scope.displayGroups) == '{}' means
            // order view update slower than file upload, then we won't
            // update displayGroups
            if (JSON.stringify($scope.displayGroups) !== '{}') {
                let conditionId = "0";
                if (fieldInfo.hideForCondition) {
                    switch(fieldInfo.hideForConditionType) {
                        case "Order Type":
                            conditionId = $scope.order ? $scope.order.order_type_id[0] : "0";
                            break;
                        case "Role Type":
                            conditionId = $rs.userRole.role_id;
                            break;
                    }
                }

                updateFieldForOrderView(fieldInfo, $scope.displayGroups, conditionId);
            }

            if ($scope.order) {
                $scope.order[fieldInfo.field_name] = fieldInfo.attr_value;
            }
        }, function() {
            console.log("Error getting order field attributes for order: " + orderId);
        });
    };

    const updateFieldForOrderView = function(fieldInfo, displayGroups, conditionId) {
        let group = displayGroups[fieldInfo.group_id];
        if (group) {
            let field = group.data.find((data) => data.formFieldName == fieldInfo.field_name);
            if (field) { // update the field for existing group
                let displayPair = DisplayService.applyTransformation(fieldInfo, fieldInfo.attr_value);
                field[fieldInfo.display_name] = displayPair[fieldInfo.display_name];
            } else { // add the field for existing group
                let displayPair = DisplayService.applyTransformation(fieldInfo, fieldInfo.attr_value);
                displayPair.formFieldType = fieldInfo.input_type;
                displayPair.formFieldName = fieldInfo.field_name;
                group.data.push(displayPair);
            }
        } else { // add the group together with the field
            const hide = fieldInfo.hideForCondition && fieldInfo.hideForCondition.includes(conditionId);
            if (!hide) {
                let section = {};
                section.title = fieldInfo.group_name;
                section.groupId = fieldInfo.id;
                section.data = [];
                let displayPair = DisplayService.applyTransformation(fieldInfo, fieldInfo.attr_value);
                displayPair.formFieldType = fieldInfo.input_type;
                displayPair.formFieldName = fieldInfo.field_name;
                section.data.push(displayPair);
                displayGroups[fieldInfo.group_id] = section;
            }
        }
    }

    var getOnDemandTasks = function() {
        TaskService.getOnDemandTasksForOrder($scope.order.cluster_id[0]).then(function() {
            $scope.onDemandTasks = TaskService.onDemandTasks;
            if (TaskService.onDemandTasks && TaskService.onDemandTasks.length > 0) {
                $scope.showOnDemandTasks = true;
                // Determine if there are duplicate tasks.  If it is the case, we will show additional information
                // (the workflow action name, for lack of anything better) to help tell them apart.
                const definitionsArray = $scope.onDemandTasks.map(task => task.definition);  // Extract only definitions and put in an array
                $scope.duplicateOnDemandTasks = new Set(definitionsArray).size !== definitionsArray.length;  // A set does not contain duplicate values...
            }
        }, function() {
            $ioAlert.show('Error getting on demand Tasks!', 'Something went wrong here. Please refresh the page to try again', 'error');
        });
    };

    let uploadSuccess = $rs.$on('lineItemUploadSuccess', function(event, creative) {
        if (!$scope.order) {
            return;
        }
        $scope.creativeLineItems.push(creative);
        OrdersService.getOrder($scope.order.id).then(function() {
            $scope.order = OrdersService.order;
            $scope.lineItems = $scope.order.lineItems;
            if ($scope.lineItems.length > 0 && $scope.creativeLineItems.length > 0) {
                for (var k = 0, klen = $scope.creativeLineItems.length; klen > k; k++) {
                    for (var j = 0, jlen = $scope.lineItems.length; jlen > j; j++) {
                        if ($scope.creativeLineItems[k].line_item_id == $scope.lineItems[j].id) {
                            $scope.lineItems[j].has_creative = true;
                            break;
                        }
                    }
                }
            }
            setIndexForOrderCreatives();
            filterVisibleLineItems();
        }, function() {
            $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
        });
    });

    $scope.$on('$destroy', function() {
        uploadSuccess();
    });

    let computeBudgetGuardrailTotals = function() {
        if ($scope.order.hasOwnProperty('total_budget') && $scope.order.hasOwnProperty('allocated_budget')) {
            $scope.formatted_order_total_budget =  FormService.currencyFormating($scope.order.total_budget, true);
            $scope.formatted_order_allocated_budget = FormService.currencyFormating(''+$scope.order.allocated_budget, true);
            $scope.budget_guardrails_underallocated_order_budget = Math.abs($scope.order.allocated_budget - $scope.order.total_budget) > 1;
        }
    };

    $scope.switchLineItemsArchivedStatus = function() {
        $scope.showArchivedLineItems = !$scope.showArchivedLineItems;
        filterVisibleLineItems();
    }

    function filterVisibleLineItems() {
        // Clear/destroy the datatable as we're rebuilding it with new data
        if ($.fn.DataTable.isDataTable('#line-items-table')) {
            let lineItemsDatatable = $('#line-items-table').DataTable();
            if (lineItemsDatatable) {
                lineItemsDatatable.clear();
                lineItemsDatatable.destroy();
            }
        }

        $scope.visibleLineItems = [];
        _.each($scope.lineItems, function(lineItem) {
            if ($scope.showArchivedLineItems === isArchived(lineItem)) {
                $scope.visibleLineItems.push(lineItem);
            }
        });
        setDataTableOptions();
    }

    $scope.openOrderInGAM = function () {
        var OrderLink = 'https://admanager.google.com/' + $scope.order.network_id + '#delivery/order/order_overview/order_id=' + $scope.order.dfp_order_id;
        $window.open(OrderLink);
    };

    function isArchived(lineItem) {
        if ($scope.hideLineItemFilterButton) {
            // If the disable_archived_line_items_button instance setting is set to true (1), then we hide the toggle
            // button (that toggles between Archived and Open line items) and always display all line items except for
            // closed ones.  See TO-4029, Entercom's request...
            return lineItem.status_id == STATUS_LINEITEM_CLOSED;
        } else {
            return (lineItem.status_id == STATUS_LINEITEM_COMPLETE ||
                lineItem.status_id == STATUS_LINEITEM_CLOSED ||
                lineItem.status_id == STATUS_LINEITEM_CANCELLED);
        }
    }

    let getInstanceSettingThenGetOrder = function(orderId) {
        // Fetch this instance setting before the order, since we need its value by the time we get the order,
        // so that the line items list is filtered properly.
        FormService.getInstanceSettings('disable_archived_line_items_button').then(function(data) {
            if (data && data.id) {
                $scope.hideLineItemFilterButton = (data.value === "1");
            } else {
                $scope.hideLineItemFilterButton = false;
            }
            getOrder(orderId);
        }, function(error) {
            $ioAlert.show('Error!', 'Cannot load disable_archived_line_items_button instance setting', error);
            $scope.hideLineItemFilterButton = false;
            // Continue anyway, with the instance setting's default value.
            getOrder(orderId);
        });
    }

    let getOrder = function(orderId) {
        OrdersService.getOrder(orderId).then(function() {
            $scope.order = OrdersService.order;
            if ($scope.order) {
                ClusterService.initCluster($rs.userRole, 'disabled', $scope.order.cluster_id[0]);
                DateRangeService.init();
                $scope.lineItems = $scope.order.lineItems;
                $scope.allLineItems = angular.copy($scope.lineItems); // Save for later in case we search a subset, and then need to revert
                computeBudgetGuardrailTotals();
                showAudienceStatusPopup();
                getGoals(orderId);
                $rs.$broadcast('fetchTaggingUsers', $scope.order.cluster_id[0]);

                if (!$scope.order.editable) {
                    $scope.restrictEdit = true;
                }

                if ($scope.order.status_id[0] == STATUS_ORDER_PENDING_CANCEL || $scope.order.status_id[0] == STATUS_ORDER_CANCEL) {
                    $scope.showCancel = true;
                }

                if (($scope.order.status_id[0] == STATUS_ORDER_COMPLETE && !$scope.enableEditingForCompletedEntities) ||
                    $scope.order.status_id[0] == STATUS_ORDER_CANCEL ||
                    $scope.order.status_id[0] == STATUS_ORDER_CLOSED) {
                    $scope.orderNonEditable = true;
                }

                processLineItemsList();

                $scope.audience_macro_mappings = $scope.order.hasOwnProperty('__audience_macro_mappings') ? $scope.order.__audience_macro_mappings : [];
                $scope.audience_match_mappings = $scope.order.hasOwnProperty('__audience_match_mappings') ? $scope.order.__audience_match_mappings : [];

                if ($scope.order && $scope.order.client_id && $scope.order.client_id.length > 0) {
                    let clientId = $scope.order.client_id[0];
                    UserService.getClient(clientId).then(function (success) {
                        $scope.client = UserService.client;
                        loadDisplay();
                    }, function (error) {
                        console.log('Error: cannot fetch client info for client_id=' + clientId + ': ' + error);
                        loadDisplay();
                    });
                } else {
                    loadDisplay();
                }
            } else {
                $ioAlert.show('Error!', 'Something went wrong to navigate to view order', 'error');
                $state.go('orders');
            }
        }, function(error) {
            if (error && error.code === 403) {
                $scope.loadFail = true;
                $scope.loadComplete = false;
                $scope.error = error;
            } else {
                $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
            }
        });
    };

    let processLineItemsList = function() {
        if ($scope.lineItems.length > 0 && $scope.creativeLineItems.length > 0) {
            for (let k = 0, klen = $scope.creativeLineItems.length; klen > k; k++) {
                for (let j = 0, jlen = $scope.lineItems.length; jlen > j; j++) {
                    if ($scope.creativeLineItems[k].line_item_id == $scope.lineItems[j].id) {
                        $scope.lineItems[j].has_creative = true;
                        break;
                    }
                }
            }
        }

        for (let i = 0; i < $scope.lineItems.length; i++) {
            let lineItem = $scope.lineItems[i];
            setLineItemDeleteable(lineItem);

            if (lineItem.hasOwnProperty('__enable_flights') && lineItem.__enable_flights == 1) {
                $scope.flightsEnabled = true;  // It's okay never to revert to false if the line items list changes.
            }

            // Convert select-element arrays into single values for display
            for (let prop in lineItem) {
                if (lineItem.hasOwnProperty(prop)) {
                    let fieldValue = lineItem[prop];

                    if (fieldValue && typeof fieldValue === 'object' && fieldValue.constructor === Array) {
                        if (Array.isArray(fieldValue[0])) {
                            let valueString = "";
                            _.each (fieldValue, function(value, index) {
                                if(index == 0) {
                                    valueString = valueString.concat(value[1]);
                                } else {
                                    valueString = valueString.concat(", " + value[1]);
                                }
                            })
                            lineItem[prop] = valueString;
                        } else {
                            lineItem[prop] = fieldValue[1];
                        }
                    }
                }
            }
        }
        filterVisibleLineItems();
    }

    const showAudienceStatusPopup = function() {
        if ($scope.order.hasOwnProperty('__audience_status')) {
            if ($scope.order['__audience_status'] != 'audience_current') {
                $ioAlert.show('Error!', "Audience is not in 'Ready' Status.", 'error');
            }
        }
    };

    var getGoals = function(orderId) {
        OrdersService.getGoalsForOrder(orderId).then(function(goals) {
            if (goals && goals.length !== 0) {
                $scope.goals = goals;
                $scope.hasGoals = true;
            }
        }, function(error) {
            console.log("Error getting goals for order");
        })
    };

    var hiddenByParent = function(field) {
        if (field.parent_id) {
            let parent_field = null;
            for (let i = 0; i < $scope.formFields.length; i++) {
                if ($scope.formFields[i]['id'] == field.parent_id) {
                    parent_field = $scope.formFields[i];
                    break;
                }
            }

            if (parent_field && $scope.order.hasOwnProperty(parent_field.field_name)) {
                let parentValues = $scope.order[parent_field.field_name];
                if (Array.isArray(parentValues)) {
                    if (Array.isArray(parentValues[0])) {
                        for (let i in parentValues) {
                            if (field.parent_trigger_id.indexOf(parentValues[i][0]) != -1) { // multi select
                                return false;
                            }
                        }
                    } else if (field.parent_trigger_id.indexOf(parentValues[0]) != -1) { // single select
                        return false;
                    }
                } else if (field.parent_trigger_id.indexOf(parentValues) != -1) { // checkbox
                    return false;
                }
                return true;
            } else {
                return true;
            }
        }
        return false;
    }

    const buildDisplayData = function () {
        let formFieldGroups = $scope.formFieldGroups;
        for (let x = 0; x < formFieldGroups.length; x++) {
            let formFieldGroup = formFieldGroups[x];
            let section = {};
            section.title = formFieldGroup.group_name;
            section.data = [];
            section.displayOrder = formFieldGroup.group_display_order;

            if(formFieldGroup.hideForCondition) {
                let conditionId = "0";
                switch(formFieldGroup.hideForConditionType) {
                    case "Order Type":
                        conditionId = $scope.order ? $scope.order.order_type_id[0] : "0";
                        break;
                    case "Role Type":
                        conditionId = $rs.userRole.role_id;
                        break;
                }
                if(formFieldGroup.hideForCondition.includes(conditionId)) {
                    continue;
                }
            }
            $scope.displayGroups[formFieldGroup.id] = section;
        }

        if ($scope.formFields && $scope.formFields.length) {
            for (let i = 0; i < $scope.formFields.length; i++) {
                const formField = $scope.formFields[i];
                if (!hiddenByParent(formField)) {
                    if (formField.input_type === "readonly") {
                        if (formField.default_value) {
                            $scope.order[formField.field_name] = formField.default_value;
                        } else if (formField.cascading_field_name) {
                            let cascadingValue = $scope.client ? $scope.client[formField.cascading_field_name] : null;
                            if (cascadingValue != undefined) {
                                $scope.order[formField.field_name] = cascadingValue;
                            }
                        }
                    }
                    // Note that custom-macro-mapping widgets are always shown
                    if ($scope.order.hasOwnProperty(formField.field_name) ||
                        (formField.input_type == "creative" && $scope.creatives && $scope.creatives.length > 0) ||
                        (formField.input_type == "custom-macro-mapping")) {
                        let group = $scope.displayGroups[formField.group_id];
                        if (group) {
                            let displayPair = DisplayService.applyTransformation(
                                formField,
                                $scope.order.hasOwnProperty(formField.field_name) ? $scope.order[formField.field_name] : null,
                                $scope.creatives);
                            displayPair.formFieldType = ['hyperlink'].includes(formField.data_type) ?
                                formField.data_type : formField.input_type;
                            displayPair.formFieldName = formField.field_name;
                            group.data.push(displayPair);
                        }
                    }
                } else {
                    delete $scope.order[formField.field_name];
                }
            }
        }

        // Remove empty sections from groups
        for (let index in $scope.displayGroups) {
            if ($scope.displayGroups.hasOwnProperty(index)) {
                if ($scope.displayGroups[index].data.length == 0) {
                    delete $scope.displayGroups[index];
                }
            }
        }
    }

    var getOrderActivity = function() {
        OrdersService.getActivity('order', $scope.order.id).then(function(success) {
            $scope.orderActivity = OrdersService.orderActivity;
            let auditArray = {};
            if ($scope.orderActivity != null) {
                for (let i = 0, len = $scope.orderActivity.length; i < len; i++) {
                    if ($scope.orderActivity[i].action?.startsWith('update')) {
                        let jsonObj = JSON.parse($scope.orderActivity[i].delta);
                        let keys = Object.keys(jsonObj);
                        keys.forEach(function(value) {
                            let obj = {};
                            obj.field = value;
                            obj.before = jsonObj[value].before;
                            obj.after = jsonObj[value].after;
                            obj.date = $scope.orderActivity[i].created_at;
                            obj.userName = $scope.orderActivity[i].first_name + " " + $scope.orderActivity[i].last_name;
                            if (auditArray[value]) {
                                auditArray[value].push(obj);
                            } else {
                                auditArray[value] = [obj];
                            }
                        });
                    }
                }
                $scope.orderActivity = auditArray;

                if ($scope.formFields && $scope.formFields.length) {
                    for (let i = 0; i < $scope.formFields.length; i++) {
                        let formField = $scope.formFields[i];
                        if(!$scope.order.hasOwnProperty(formField.field_name) &&
                            $scope.orderActivity.hasOwnProperty(formField.field_name) &&
                            !hiddenByParent(formField)) {
                            let group = $scope.displayGroups[formField.group_id];
                            if (group) {
                                let displayPair = {};
                                displayPair[formField.display_name] = "";
                                displayPair.formFieldType = formField.input_type;
                                displayPair.formFieldName = formField.field_name;
                                group.data.push(displayPair);
                            }
                        }
                    }
                }

                // Show the field with empty value when it has a activity history
                _.each($scope.displayGroups, function(groupObj) {
                    _.each(groupObj.data, function(item) {
                        if ($scope.orderActivity[item.formFieldName]) {
                            item.isEdited = true;
                            item.fieldAuditHistory = $scope.orderActivity[item.formFieldName];
                        } else {
                            item.isEdited = false;
                        };
                    });
                });

                FormService.getTaskFormFieldsForOrder($scope.order.id).then(function (data) {
                    if (!data.formFields || data.formFields.constructor.name !== 'Array') {
                        return
                    }

                    let group = {
                        title: 'Task Information',
                        data: [],
                    };
                    data.formFields.forEach(function (formField) {
                        let attrValue = $scope.order[formField.field_name]
                        if (attrValue) {
                            let displayPair = DisplayService.applyTransformation(formField, attrValue);
                            displayPair.formFieldType = ['hyperlink'].includes(formField.data_type) ?
                                formField.data_type : formField.input_type;
                            displayPair.formFieldName = formField.field_name;
                            group.data.push(displayPair);
                        }
                    })

                    if (group.data.length === 0) {
                        return;
                    }

                    $scope.displayGroups['__task__'] = group;
                });
            }
        }, function(error) {
            console.log("Error getting line item activity for line item: " + $scope.lineItemId);
        });
    };

    $scope.showChanges = function(changesObj) {
        var list = changesObj.fieldAuditHistory;
        $modal.open(ModalService.returnModalType('lineItemChanges', { data: { list: list } }));
    };

    $scope.loadingStep = false;
    $scope.stepError = false;

    var getWorkflowSteps = function(orderId) {
        $scope.loadingStep = true;
        OrdersService.getStepsForOrder(orderId).then(function(success) {
            if ($scope.order.workflow_step_id) {
                for (var i = 0; i < success.length; i++) {
                    success[i].isCurrentStep = (success[i].id == $scope.order.workflow_step_id);
                }
                if ($scope.order.completed_workflow_steps && $scope.order.completed_workflow_steps.length) {
                    _.each($scope.order.completed_workflow_steps, function(completed) {
                        _.each(success, function(steps) {
                            if (steps.id == completed.id) {
                                steps.completedStep = true;
                            }
                        });
                    });
                }


            } else {
                $scope.stepError = true;
            }

            $scope.order.steps = success;
            $scope.loadingStep = false;

        }, function() {
            $scope.loadingStep = false;
            $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
        });

    };

    var getOrderNotificationUsers = function() {
        OrdersService.getOrderNotificationUsers($scope.order.id).then(function() {
            $scope.orderNotificationUsers = OrdersService.orderNotificationUsers;
        }, function() {
            $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
        });
    };

    let getLineItems = function(orderId) {
        OrdersService.getLineItems(orderId).then(function() {
            $scope.lineItems = OrdersService.lineItems;
            $scope.allLineItems = angular.copy($scope.lineItems);
            processLineItemsList();
        }, function() {
            $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
        });
    };

    var getCreatives = function() {
        // Checking if $scope.order is set in case we got a file upload success event after we navigated away from the page.
        if ($scope.order) {
            MediaService.getOrderCreatives($scope.order.id).then(function () {
                $scope.creatives = MediaService.creatives;
                $scope.hasCreatives = ($scope.creatives && $scope.creatives.length != 0);
            }, function () {
                console.log("Error getting creatives for line item: " + $scope.lineItemId);
            });
        }
    };

    var setDefaultActiveSideTab = function() {
        if ($scope.user.isAuthorizedToListOrderComments()) {
            $scope.sideActiveTab = 'comments';
        } else if ($scope.user.isAuthorizedToListOrderTasks()) {
            $scope.sideActiveTab = 'tasks';
        }
    };

    var loadDisplay = function() {
        $rs.loadingInProgress = true;
        $rs.loadertitle = "Fetching Order Information";

        var clusterId = $scope.order.cluster_id[0];

        FormService.getOrderFormFieldGroups(clusterId).then(function(formFieldGroups) {
            $scope.formFieldGroups = formFieldGroups;
            _.each($scope.formFieldGroups, function(group) {
                group.group_display_order = parseInt(group.group_display_order);
            });

            FormService.getOrderFormFields(clusterId, false, false).then(function() {
                // We need creative data before building the display data, so we have to wait for this one too
                // before we go on.
                MediaService.getOrderCreatives($scope.order.id).then(function () {
                    $scope.creatives = MediaService.creatives;
                    $scope.hasCreatives = ($scope.creatives && $scope.creatives.length != 0);
                    finishLoadDisplay();
                }, function () {
                    console.log("Error getting creatives for line item: " + $scope.lineItemId);
                    finishLoadDisplay();
                });
            }, function() {
                console.log("Error getting form fields for order: " + $scope.order.id);
                $rs.loadingInProgress = false;
            });
        }, function() {
            console.log("error getting form field groups for order: " + $scope.order.id);
            $rs.loadingInProgress = false;
        });
    };

    const finishLoadDisplay = function () {
        $scope.formFields = FormService.formFields;
        buildDisplayData();
        getOrderActivity();
        $rs.loadingInProgress = false;
        $scope.loadComplete = true;
        setDataTableOptions();
        $scope.showTasks(false);
        $scope.getExistingComments();
        getOnDemandTasks();
        getOrderNotificationUsers();
        getWorkflowSteps($scope.order.id);
        setIndexForOrderCreatives();
        setDefaultActiveSideTab();
        getOrderWatchers();
    }

    $scope.getExistingComments = function() {
        CommentService.getOrderComments($scope.order.id, $scope.commentFilter.search).then(function() {
            $scope.order.comments = CommentService.comments;
        }, function() {
            console.log("Error getting comments for order: " + $scope.order.id);
        });
    };

    var setIndexForOrderCreatives = function() {
        for (var i = 0, len = $scope.order.creatives.length; i < len; i++) {
            $scope.order.creatives[i].index = 'C' + i;
        }
    };

    $scope.selectAll = function() {
        for (var i = 0, len = $scope.order.creatives.length; i < len; i++) {
            if ($scope.checkAll.value) {
                $scope.selectedFile[$scope.order.creatives[i].index] = true;
            } else {
                $scope.selectedFile[$scope.order.creatives[i].index] = false;
            }
        }
    };

    $scope.download_all = function() {
        var files = [];
        var idx = 0;
        angular.forEach($scope.order.creatives, function(creative) {
            if ($scope.selectedFile[creative.index]) {
                files[idx] = {};
                files[idx].download = creative.secure_url;
                files[idx].filename = creative.file_name;
                idx++;
            }
        });
        download_files(files);
    };

    //download a list of files
    function download_files(files) {
        if (files.length > 0) {
            $scope.processDownloadAll = true;
        }
        for (var i = 0, len = files.length; i < len; i++) {
            forceDownload(files[i].download, files[i].filename, i);
        }
    }

    function forceDownload(url, fileName, index) {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url, true);
        xhr.responseType = "blob";
        xhr.onload = function() {
            var urlCreator = window.URL || window.webkitURL;
            var imageUrl = urlCreator.createObjectURL(this.response);
            var tag = document.createElement('a');
            tag.href = imageUrl;
            tag.download = fileName;
            document.body.appendChild(tag);
            tag.click();
            document.body.removeChild(tag);
        };
        xhr.send();
        stopProcessDownloadAll(index);
    }

    function stopProcessDownloadAll() {
        $scope.processDownloadAll = false;
        for (var i = 0, len = $scope.order.creatives.length; i < len; i++) {
            $scope.selectedFile[$scope.order.creatives[i].index] = false;
        }
        $scope.checkAll.value = false;
    }

    $scope.commentSubmitDisabled = false;
    $scope.addComment = function() {
        if ($scope.model.quickComment) {
            if ($scope.showOnDemandTasks && $scope.model.addOnDemandTask && !$scope.model.onDemandTask) {
                $ioAlert.show('Error!', 'Please select a task', 'error');
                return;
            }
            $scope.commentSubmitDisabled = true;

            const params = {
                'tableName': 'order',
                'tableRowId': $scope.order.id,
                'comment': $scope.model.quickComment,
                'skipWorkflow': '0',
                'addOnDemandTask': $scope.model.addOnDemandTask,
                'onDemandTask': $scope.model.onDemandTask,
            };
            CommentService.addOrderComment(params).then(function() {
                $scope.model.quickComment = null;
                $scope.model.onDemandTask = null; // clear task select field
                $scope.model.addOnDemandTask = false; // clear checkbox
                $scope.model.addOnDemandTaskToggle = false; // hide task select field
                $scope.commentSubmitDisabled = false;
                $scope.showTaskProgress = false;
                $scope.getExistingComments();
            }, function() {
                $scope.commentSubmitDisabled = false;
                console.log("Error saving comment for order: " + $scope.order.id);
            });
        } else {
            $ioAlert.show('Error!', 'Please enter a comment.', 'error');
        }
    };

    var setDataTableOptions = function() {
        $timeout(function() {
            var options = $.core.datatable.defaultOptions('line item');
            options.iActions = 3;
            options.hasFixedHeader = false;
            options.isIoTool = true;
            let index = $('#line-items-table').find('th.mixed-numeric').index();
            options.naturalColumnIndex = index;
            $.core.datatable.build($('#line-items-table'), options);
            options = $.core.datatable.defaultOptions('creative');
            options.hasFixedHeader = false;
            options.isIoTool = true;
            $.core.datatable.build($('#creatives-table'), options);

            if ($scope.order) {
                $('a.clientToggler').trigger('click');
            }
        });
    };

    var setOrderCompletionTask = function(taskAssignments) {
        for (var x = 0; x < taskAssignments.length; x++) {
            var orderTask = taskAssignments[x];

            if (orderTask.name == TASK_NAME_COMPLETE_ORDER &&
                orderTask.user_id && $rs.userRole.id == orderTask.user_id) {
                $scope.orderCompletionTask = orderTask;
            }
        }
    };

    var getOrderWatchers = function () {
        OrdersService.getOrderWatchersList($scope.order.id).then(function (orderWatchers) {
            $scope.orderWatchersList = orderWatchers;
            checkOrderWatcherForMeHide();
        }, function () {
            $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
        });
    };

    var saveOrderWatcherUsers = function (orderWatcherUsers) {
        if (orderWatcherUsers) {
            var watcherUserIds = [];
            _.each(orderWatcherUsers, function (userObj) {
                watcherUserIds.push(userObj.id);
            });
            OrdersService.updateOrderWatchersList($scope.order.id, watcherUserIds).then(function (orderWatchers) {
                if (orderWatchers) {
                    $scope.orderWatchersList = orderWatchers;
                    $ioAlert.show('', 'Watchers updated successfully', 'success');
                } else {
                    $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again.', 'error');
                }
                checkOrderWatcherForMeHide();
            }, function () {
                console.log("Error saving Watchers for order: " + $scope.order.id);
            });
        }
    };

    var checkOrderWatcherForMeHide = function () {
        $scope.startOrderWatcherForMeHide = true;
        if ($scope.orderWatchersList) {
            _.each($scope.orderWatchersList, function (watcherObj) {
                if ($rs.userRole.id == watcherObj.user_id) {
                    $scope.startOrderWatcherForMeHide = false;
                }
            });
        }
    }

    $scope.addOrderWatcherUsersModel = function (watcher) {
        if ($scope.order && $rs.userRole && watcher_authorized_roles.includes($rs.userRole.predefined_role_key)) {
            if (watcher == 'start') {

                OrdersService.addOrderWatcher($scope.order.id, $rs.userRole.id).then(function (orderWatcher) {
                    if (orderWatcher) {
                        $scope.orderWatchersList.push(orderWatcher);
                        $ioAlert.show('', 'Watcher added successfully', 'success');
                    } else {
                        $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again.', 'error');
                    }
                    checkOrderWatcherForMeHide();
                }, function () {
                    console.log("Error saving Watchers for order: " + $scope.order.id);
                });
            } else if (watcher == 'stop') {

                var orderWatcher = _.find($scope.orderWatchersList, {user_id: $rs.userRole.id});
                OrdersService.deleteOrderWatcher(orderWatcher.id).then(function (orderWatcherId) {
                    if (orderWatcherId) {
                        var orderWatcherIndex = _.findIndex($scope.orderWatchersList, {id: orderWatcherId});
                        if(orderWatcherIndex>=0){
                            $scope.orderWatchersList.splice(orderWatcherIndex, 1);
                        }
                        $ioAlert.show('', 'Watcher removed successfully', 'success');
                    }else {
                        $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again.', 'error');
                    }
                    checkOrderWatcherForMeHide();
                }, function () {
                    console.log("Error deleting Watchers for order: " + $scope.order.id);
                });
            } else {
                UserService.getUsersForCluster($scope.order.cluster_id[0]).then(function () {
                    $scope.watcherUsers = UserService.users;
                    var modalInstance = $modal.open(
                        ModalService.returnModalType('orderWatchersListModel', {
                            data: {
                                users: $scope.watcherUsers,
                                orderWatchers: $scope.orderWatchersList,
                            }
                        })
                    );
                    modalInstance.result.then(function (modalObj) {
                        saveOrderWatcherUsers(modalObj.selectedUsers);
                    }, function () {
                        
                    });
                }, function () {
                    console.log("Error getting Watchers.");
                });
            }
        } else {
            $ioAlert.show('Error!', 'You are not allowed to add watchers.', 'error');
            // we need to know what happen to user role when the user cannot add watchers.
            console.log($rs.userRole);
        }
    };

    var getWatcherUsers = function(){
        UserService.getUsers($scope.order.cluster_id[0]).then(function () {
            $scope.watcherUsers = UserService.users;
        }, function () {
            console.log("Error getting Watchers.");
        });
    }

    var saveCampaignManager = function(selectedUser, lineItem) {
        if (selectedUser && lineItem) {
            OrdersService.addCampaignManager(lineItem.id, selectedUser.id).then(function() {
                lineItem.campaign_manager_name = selectedUser.first_name + ' ' + selectedUser.last_name + ' (' + selectedUser.email + ')';
                lineItem.campaign_manager_id = selectedUser.id;
            }, function() {
                $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again.', 'error');
            });
        }
    };

    $scope.assignCampaignManager = function(lineItem) {
        if (lineItem) {
            UserService.getCampaignManagers($scope.order.cluster_id[0]).then(function() {
                var users = {};
                var selectedItem = [];
                angular.forEach(UserService.users, function(user, key) {
                    if (user.email) {
                        users[key] = [];
                        users[key].first_name = user.first_name;
                        users[key].last_name = user.last_name;
                        users[key].email = user.email;
                        users[key].id = user.id;
                        if (user.id == lineItem.campaign_manager_id) {
                            selectedItem = users[key];
                        }
                    }
                });

                var modalInstance = $modal.open(
                    ModalService.returnModalType('assignCampaignManager', {
                        data: {
                            items: users,
                            selectedItem: selectedItem,
                            lineItem: lineItem,
                            scope: $scope
                        }
                    })
                );
                modalInstance.result.then(function(selectedItem) {
                    saveCampaignManager(selectedItem, lineItem);
                }, function() {});
            }, function() {
                console.log("Error getting campaign manager users.");
            });
        }
    };

    var removeCampaignManager = function(lineItem) {
        if (lineItem) {
            OrdersService.removeCampaignManager(lineItem.id, lineItem.campaign_manager_id).then(function() {
                lineItem.campaign_manager_name = '';
                lineItem.campaign_manager_id = null;
            }, function() {
                $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again.', 'error');
            });
        }
    };

    $scope.showUnassignConfirm = function(lineItem) {
        $confirm.open({
            size: 'sm',
            text: 'Are you sure you want to unassign the campaign manager?'
        }).then(function() {
            removeCampaignManager(lineItem);
        }, function() {});
    };

    $scope.completeTask = function() {
        $rs.loadingInProgress = true;
        $scope.orderSubmit = true;
        TaskService.completeTaskAssignment($scope.orderCompletionTask.id).then(function() {
            if (TaskService.errors) {
                for (var x = 0; x < TaskService.errors.length; x++) {
                    $ioAlert.show("Error", TaskService.errors[x], "error");
                }
                TaskService.errors = null;
            } else {
                $ioAlert.show('', 'Order submitted successfully', 'success');
                $window.location.reload();
            }
            $rs.loadingInProgress = false;
            $scope.orderSubmit = false;
        }, function() {
            $rs.loadingInProgress = false;
            $scope.orderSubmit = false;
            $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again.', 'error');
        });
    };

    $scope.navigateToTask = function(taskId) {
        $state.go('viewOrderTask', {
            orderId: $stateParams.id,
            taskId: taskId,
            fromPage: 'order'
        });
    };

    $scope.auditHistory = function() {
        $state.go('auditHistory', {
            type: 'order',
            id: $scope.order.id
        });
    };

    $scope.workflowLogHistory = function() {
        $state.go('workflowLogHistory', {
            type: 'order',
            id: $scope.order.id
        });
    };

    $scope.naturalSortByIdDesc = function(a, b) {
        return FormService.naturalSortByIdDesc(a, b);
    }

    $scope.showTasks = function(showAll) {
        if (showAll) {
            if ($scope.showHistoryTaskProgress === false) {
                $scope.showHistoryTaskProgress = true;
                TaskService.getTaskAssignmentsForOrder($scope.order.id).then(function(taskAssignments) {
                    $scope.historyTasks = taskAssignments;
                    let lineItems = $scope.order.lineItems;
                    let lineItemTaskAssignments = [];
                    let queryCount = 0;
                    for (var i = 0, len = lineItems.length; i < len; i++) {
                        TaskService.getTaskAssignmentsForLineItem(lineItems[i].id).then(function(taskAssignments) {
                            lineItemTaskAssignments = lineItemTaskAssignments.concat(taskAssignments);
                            queryCount++;
                            if (queryCount >= len) {
                                $scope.showHistoryTaskProgress = false;
                                lineItemTaskAssignments = mergeLineItemSharedTasks(lineItemTaskAssignments);
                                $scope.historyTasks = $scope.historyTasks.concat(lineItemTaskAssignments);
                            }
                        }, function() {
                            $ioAlert.show('Error getting Tasks!', 'Something went wrong here. Please refresh the page to try again', 'error');
                            queryCount++;
                            if (queryCount >= len) {
                                $scope.showHistoryTaskProgress = false;
                                lineItemTaskAssignments = mergeLineItemSharedTasks(lineItemTaskAssignments);
                                $scope.historyTasks = $scope.historyTasks.concat(lineItemTaskAssignments);
                            }
                        });
                    }
                }, function() {
                    $ioAlert.show('Error getting Tasks!', 'Something went wrong here. Please refresh the page to try again', 'error');
                });
            }
        } else {
            if ($scope.showTaskProgress === false) {
                $scope.showTaskProgress = true;
                var taskAssignmentsArr = [];
                TaskService.getOpenTaskAssignmentsForOrder($scope.order.id).then(function(taskAssignments) {
                    if (!$scope.orderCompletionTask) {
                        setOrderCompletionTask(taskAssignments);
                    }
                    var lineItems = $scope.order.lineItems;
                    if (lineItems.length) {
                        $scope.associatedTasks = [];
                        taskAssignmentsArr = taskAssignments;
                        var count = 0;
                        for (var x = 0, len = lineItems.length; x < len; x++) {
                            (function(indexPos) {
                                TaskService.getOpenTaskAssignmentsForLineItem(lineItems[indexPos].id).then(function(taskAssignments) {
                                    taskAssignmentsArr = taskAssignmentsArr.concat(taskAssignments);
                                    count++;
                                    if (count > len - 1) {
                                        $scope.showTaskProgress = false;
                                        parseLineItemsHelper(taskAssignmentsArr);
                                    }
                                }, function() {
                                    $ioAlert.show('Error getting Tasks!', 'Something went wrong here. Please refresh the page to try again', 'error');
                                    count++;
                                    if (count > len - 1) {
                                        $scope.showTaskProgress = false;
                                        parseLineItemsHelper(taskAssignmentsArr);
                                    }
                                });
                            }(x));
                        }
                    } else {
                        $scope.associatedTasks = taskAssignments;
                        calculateDueDates($scope.associatedTasks);
                    }
                }, function() {
                    $ioAlert.show('Error getting Tasks!', 'Something went wrong here. Please refresh the page to try again', 'error');
                });
            }
        }
    };

    var getCommentFilterItems = function() {
        TaskService.getTasksList().then(function(response) {
            $scope.commentFilter.items = [];
            if(response){
                _.each(response, function(item){
                    if(item.reference_table == "order"){
                        $scope.commentFilter.items.push({id: item.id, definition: item.definition});
                    }
                });
            }
        }, function() {
            $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
        });
    };

    // Helper function to merge duplicate task id
    function parseLineItemsHelper(associatedTasks) {
        var uniqArr = _.uniqBy(associatedTasks, 'id');
        _.each(uniqArr, function(arr) {
            arr.lineItemIds = [];
            _.each(associatedTasks, function(task) {
                if (task.id == arr.id) {
                    if (arr.lineItemIds.indexOf(task.line_item_id) == -1) {
                        arr.lineItemIds.push(task.line_item_id);
                        arr.lineItemIds.sort();
                    }
                }
            })
        });
        $scope.associatedTasks = $scope.associatedTasks.concat(uniqArr);
        calculateDueDates($scope.associatedTasks);
    }

    // Helper function to merge lineItem shared tasks
    function mergeLineItemSharedTasks(lineItemTasks) {
        let uniqArr = _.uniqBy(lineItemTasks, 'id');
        _.each(uniqArr, function(arr) {
            arr.lineItemIds = [];
            _.each(lineItemTasks, function(task) {
                if (task.id == arr.id) {
                    if (arr.lineItemIds.indexOf(task.line_item_id) == -1) {
                        arr.lineItemIds.push(task.line_item_id);
                        arr.lineItemIds.sort();
                    }
                }
            })
        });
        return uniqArr;
    }

    $scope.showEditOrder = function() {
        $state.go('editOrder', {
            id: $scope.order.id
        });
    };

    $scope.showOrdersList = function() {
        if ($stateParams.detailedOrder) {
            $state.go('detailedOrders');
        } else {
            $state.go('orders');
        }
    };

    $scope.showCancelOrderConfirm = function() {
        $confirm.open({
            size: 'sm',
            text: 'Are you sure to cancel this order?'
        }).then(function() {
            cancelOrder()
        }, function() {});
    };

    $scope.showMyTasksList = function() {
        if ($stateParams.taskDetail) {
            $state.go('myTasksView', {
                taskId: $scope.taskId
            });
        } else {
            $state.go('myTasks');
        }
    };

    $scope.showMyQueuesList = function() {
        $state.go('myQueues', {
            'queueId': $stateParams.myQueues
        });
    };

    $scope.showViewLineItem = function(lineItem) {
        if ($scope.hasTaskId === true) {
            if ($stateParams.myQueues) {
                // From MyQueues
                $state.go('myQueuesOrderLineItem', {
                    taskId: $scope.taskId,
                    orderId: $scope.orderId,
                    lineItemId: lineItem.id,
                    myQueues: true
                })
            } else {
                // From My Tasks
                $state.go('myTasksOrderLineItem', {
                    taskId: $scope.taskId,
                    orderId: $scope.orderId,
                    lineItemId: lineItem.id
                })
            }
        } else {
            $state.go('viewLineItem', {
                lineItemId: lineItem.id
            });
        }
    };

    $scope.showAddLineItem = function() {
        $state.go('addLineItem', {
            orderId: $scope.order.id,
            order: $scope.order
        });
    };

    $scope.showAddLineItemBtn = function(order) {
        let show = $scope.user.isAuthorizedToCreateOrderLineItem(order) && !$scope.orderNonEditable;
        if ($scope.allowed_order_statuses.length > 0 && order &&
            $scope.allowed_order_statuses.indexOf(parseInt(order.status_id[0])) == -1) {
            show = false;
        }

        return show;
    }

    /*
     * Workflow start over function
     */
    $scope.workflowStartOver = function() {
        $confirm.open({
            size: 'sm',
            text: 'You are about to reset this workflow. Are you sure you want to proceed?'
        }).then(function() {
            // workflow_type_id and will always be 1 for order workflows
            OrdersService.workflowReset(WORKFLOW_TYPE_ORDER, $scope.order.id).then(function() {
                $ioAlert.show('', 'Workflow reset successfull', 'success');
                $scope.activateCommentsTab();
                getOrder($scope.order.id);
                $scope.showHistoryTaskProgress = false;
                $scope.showTaskProgress = false;
            }, function(error) {
                $ioAlert.show('Error!', error, 'error');
            });
        }, function() {})
    }

    $scope.showAssociationHistory = function() {
        $state.go('associationHistory',
            {
                id: $scope.order.id,
                fromPage: 'order'
            });
    };

    $scope.showEditLineItem = function(lineItem) {
        $state.go('editLineItem', {
            orderId: lineItem.order_id,
            lineItemId: lineItem.id
        });
    };

    $scope.copyLineItem = function(lineItem) {
        var modalInstance = $modal.open(
            ModalService.returnModalType('copyLineItem', { data: { lineItem: lineItem, budgetManagementEnabled: $scope.budgetManagementEnabled } })
        );

        modalInstance.result.then(function(options) {
            if (options.action === 'copyTo') {
                copyToLineItem(lineItem, options);
            } else {
                copyLineItem(lineItem, options);
            }
        }, function() {});
    };

    var copyLineItem = function(lineItem, options) {
        OrdersService.copyLineItem(lineItem.id, $scope.order.id, options.clearTotalBudget).then(function() {
            if (OrdersService.errors) {
                for (var x = 0; x < OrdersService.errors.length; x++) {
                    $ioAlert.show("Error", OrdersService.errors[x], "error");
                }
                OrdersService.errors = null;
            } else {
                if(options.isCopyingFlights) {
                    copyFlightsToLineItem(lineItem.id, OrdersService.newLineItemId, options.clearTotalBudget);
                }
                if (options.isCopyingUploadedFiles) {
                    copyLineItemUploadedFiles(lineItem.id, OrdersService.newLineItemId);
                } else {
                    getLineItems($scope.order.id);
                }
                $ioAlert.show('', 'Line item copied successfully', 'success');
            }
        }, function() {
            $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again.', 'error');
        });
    };

    var copyLineItemUploadedFiles = function(srcLineItemId, destLineItemId) {
        MediaService.cloneLineItemCreatives(srcLineItemId, destLineItemId).then(function() {
            if (MediaService.errors) {
                for (var x = 0; x < MediaService.errors.length; x++) {
                    $ioAlert.show("Error", MediaService.errors[x], "error");
                }
                MediaService.errors = null;
            } else {
                getLineItems($scope.order.id);
                $ioAlert.show('', 'Line item uploads copied successfully', 'success');
            }
        }, function() {
            $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again.', 'error');
        });
    };

    var copyToLineItem = function(lineItem, options) {
        OrdersService.copyToLineItem(lineItem.id, options.targetLineItemId, options.clearTotalBudget).then(function() {
            if (OrdersService.errors) {
                for (var x = 0; x < OrdersService.errors.length; x++) {
                    $ioAlert.show("Error", OrdersService.errors[x], "error");
                }
                OrdersService.errors = null;
            } else {
                getLineItems($scope.order.id);
                $ioAlert.show('', 'Line item copied successfully', 'success');
            }
            if(options.isCopyingFlights) {
                copyFlightsToLineItem(lineItem.id, options.targetLineItemId, options.clearTotalBudget);
            }
        }, function() {
            if (OrdersService.errors) {
                for (var x = 0; x < OrdersService.errors.length; x++) {
                    $ioAlert.show("Error", OrdersService.errors[x], "error");
                }
                OrdersService.errors = null;
            } else {
                $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again.', 'error');
            }
        })
    };

    var copyFlightsToLineItem = function(srcLineItemId, destLineItemId, clearTotalBudget) {
        OrdersService.copyFlightsToLineItem(srcLineItemId, destLineItemId, clearTotalBudget).then(function() {
            if (OrdersService.errors) {
                for (var x = 0; x < OrdersService.errors.length; x++) {
                    $ioAlert.show("Error", OrdersService.errors[x], "error");
                }
                OrdersService.errors = null;
            } else {
                $ioAlert.show('', 'Flights copied successfully', 'success');
            }
        }, function() {
            if (OrdersService.errors) {
                for (var x = 0; x < OrdersService.errors.length; x++) {
                    $ioAlert.show("Error", OrdersService.errors[x], "error");
                }
                OrdersService.errors = null;
            } else {
                $ioAlert.show('Error!', 'Failed to copy flights from line_item# ' + srcLineItemId + '.', 'error');
            }
        });
    };

    $scope.showDeleteConfirm = function(lineItem) {
        $confirm.open({
            size: 'sm',
            text: 'Are you sure you want to delete this line item?'
        }).then(function() {
            deleteLineItem(lineItem);
        }, function() {});
    };

    var deleteLineItem = function(lineItem) {
        OrdersService.closeLineItem(lineItem.id).then(function() {
            getLineItems($scope.order.id);
            $ioAlert.show('', 'Line item deleted successfully', 'success');
        }, function() {
            $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again.', 'error');
        });
    };

    var cancelOrder = function() {
        OrdersService.getCancelReasons('order').then(function() {

            var data = OrdersService.cancelReasons;
            var modalInstance = $modal.open(
                ModalService.returnModalType('cancelReason', {
                    data: {
                        items: data,
                        scope: $scope,
                        type: 'Order'
                    }
                })
            );
            modalInstance.result.then(function(cancelReason) {
                OrdersService.cancelOrder($scope.order.id, cancelReason.id, cancelReason.text).then(function() {
                    OrdersService.getOrder($scope.order.id).then(function() {
                        $scope.order = OrdersService.order;
                        getOrder($stateParams.id);
                        $ioAlert.show('', 'Order cancellation request submitted', 'success');
                    });
                }, function() {
                    $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again.', 'error');
                });
            });
        }, function() {
            console.log("Error getting cancellation reasons");
        });
    };

    $scope.editCancelReason = function() {
        OrdersService.getCancelReasons('order').then(function() {

            var data = OrdersService.cancelReasons;
            var modalInstance = $modal.open(
                ModalService.returnModalType('cancelReason', {
                    data: {
                        items: data,
                        scope: $scope,
                        type: 'Order',
                        cancelReason: $scope.order.cancel_order_reason,
                        cancelReasonText: $scope.order.cancel_order_reason_text
                    }
                })
            );
            modalInstance.result.then(function(cancelReason) {
                OrdersService.editCancelReason($scope.order.id, 'order', cancelReason.id, cancelReason.text).then(function() {
                    OrdersService.getOrder($scope.order.id).then(function() {
                        $scope.order = OrdersService.order;
                        $ioAlert.show('', 'Order cancellation reason updated', 'success');
                    });

                }, function() {
                    $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again.', 'error');
                });
            });
        }, function() {
            console.log("Error getting cancellation reasons");
        });
    };

    $scope.deleteComment = function(commentId) {
        $confirm.open({
            size: 'sm',
            text: 'Are you sure you want to delete this Comment?'
        }).then(function() {
            CommentService.deleteComment(commentId).then(function() {
                $scope.getExistingComments();
                $ioAlert.show('Comment deleted', 'Comment has been successfully deleted', 'success');
            }, function() {
                console.log("error deleting comment");
                $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
            });
        }, function() {})
    };

    $scope.selectLineItem = function(lineItemId) {
        if ($scope.selectedLineItems.indexOf(lineItemId) !== -1) {
            $scope.selectedLineItems.splice($scope.selectedLineItems.indexOf(lineItemId), 1);
        } else {
            $scope.selectedLineItems.push(lineItemId);
        }
    };

    $scope.bulkAssignCampaignManager = function() {
        if ($scope.selectedLineItems.length > 0) {
            UserService.getCampaignManagers($scope.order.cluster_id[0]).then(function() {
                var users = [];
                var lineItem = null;
                var selectedItem = [];
                angular.forEach(UserService.users, function(user, key) {
                    if (user.email) {
                        users[key] = [];
                        users[key].first_name = user.first_name;
                        users[key].last_name = user.last_name;
                        users[key].email = user.email;
                        users[key].id = user.id;
                    }
                });
                var modalInstance = $modal.open(
                    ModalService.returnModalType('assignCampaignManager', {
                        data: {
                            items: users,
                            selectedItem: selectedItem,
                            lineItem: lineItem,
                            scope: $scope
                        }
                    })
                );
                modalInstance.result.then(function(selectedUser) {
                    if (selectedUser) {
                        $scope.processLineItems = [];
                        for (var i = 0, len = $scope.selectedLineItems.length; i < len; i++) {
                            OrdersService.addCampaignManager($scope.selectedLineItems[i], selectedUser.id).then(function() {
                                for (var j = 0, len = $scope.lineItems.length; j < len; j++) {
                                    if ($scope.lineItems[j].id == OrdersService.lineItem.id) {
                                        $scope.lineItems[j].campaign_manager_name = selectedUser.first_name + ' ' + selectedUser.last_name + ' (' + selectedUser.email + ')';
                                        $scope.lineItems[j].campaign_manager_id = selectedUser.id;
                                        break;
                                    }
                                }
                                $scope.processLineItems.push(OrdersService.lineItem.id);
                                if ($scope.processLineItems.length === $scope.selectedLineItems.length) {
                                    $scope.selectedLineItems = [];
                                    $ioAlert.show('Bulk Assign', 'Bulk assign has been completed successfully', 'success');
                                    $scope.processLineItems = [];
                                }
                            }, function() {
                                $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again.', 'error');
                            });
                        }
                    }
                }, function() {});
            }, function() {
                console.log("Error getting campaign manager users.");
            });
        }
    };

    $scope.bulkStatusChange = function(action){
        var modalInstance = $modal.open(
            ModalService.returnModalType('bulkStatusChangeLineItems', {
                data: {
                    selectedItems: $scope.selectedLineItems,
                    action: action,
                    scope: $scope
                }
            })
        );
        modalInstance.result.then(function(status) {
            if(status){
                var d = status;
            }
        }, function() {});
    };

    $scope.showBulkUnassignConfirm = function() {
        $confirm.open({
            size: 'sm',
            text: 'Are you sure you want to unassign the campaign manager?'
        }).then(function() {
            $scope.processLineItems = [];
            for (var i = 0, len = $scope.selectedLineItems.length; i < len; i++) {
                var lineItem = _.find($scope.lineItems, {
                    id: $scope.selectedLineItems[i]
                });
                if (lineItem.campaign_manager_id) {
                    $scope.processLineItems.push($scope.selectedLineItems[i]);
                    OrdersService.removeCampaignManager(lineItem.id, lineItem.campaign_manager_id).then(function(lineItem) {
                        for (var i = 0, len = $scope.lineItems.length; i < len; i++) {
                            if ($scope.lineItems[i].id == lineItem.id) {
                                $scope.lineItems[i].campaign_manager_name = '';
                                $scope.lineItems[i].campaign_manager_id = null;
                                break;
                            }
                        }
                        $scope.processLineItems.splice($scope.processLineItems.indexOf(lineItem.id), 1);
                        if ($scope.processLineItems.length == 0) {
                            $scope.selectedLineItems = [];
                            $ioAlert.show('Bulk Unassign', 'Bulk unassign has been completed successfully', 'success');
                            $scope.processLineItems = [];
                        }
                    }, function() {
                        $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again.', 'error');
                    });
                }
            }
            $scope.selectedLineItems = [];
        }, function() {});
    };

    var applyPermissions = function() {
        if ($rs.userRole.permissions.indexOf("adops") > -1 ||
            $rs.userRole.permissions == 'superadmin' ||
            $rs.userRole.permissions == 'sales,admin') {
            $scope.isAdmin = true;
        }

        if ($rs.userRole.permissions == 'adops,admin' ||
            $rs.userRole.permissions == 'superadmin') {
            $scope.showStartOver = true;
        }
    };

    /*
     * Helper function to calculate no of due dates task due dates
     */
    var calculateDueDates = function(tasks) {
        $scope.escalatedTasks = 0;
        $scope.overDueTasks = 0;
        _.each(tasks, function(task) {
            // Only count order tasks, as we take the line item (and underlying flight) counts from the line item data itself.
            if (task.line_item_id === undefined) {
                if (task.due_date_flag == 1) {
                    $scope.overDueTasks++;
                } else if (task.escalate_date_flag == 1) {
                    $scope.escalatedTasks++;
                }
            }
        });

        _.each($scope.lineItems, function(lineItem) {
            // Add line item and flight task counts
            $scope.overDueTasks += parseInt(lineItem.line_item_due_task_count);
            $scope.overDueTasks += parseInt(lineItem.flight_due_task_count);
            $scope.escalatedTasks += parseInt(lineItem.line_item_escalated_task_count);
            $scope.escalatedTasks += parseInt(lineItem.flight_escalated_task_count);
        })
    };

    $scope.searchLineItems = function() {
        if (!$scope.searchLineItemsObj.searchField || !$scope.searchLineItemsObj.searchText) {
            // Revert to the initial list we had.
            $scope.lineItems = angular.copy($scope.allLineItems);
            processLineItemsList();
            return;
        }
        OrdersService.searchLineItems($scope.order.id, $scope.searchLineItemsObj.searchField, $scope.searchLineItemsObj.searchText).then(function(lineItems) {
            if (lineItems || lineItems == []) {
                $scope.lineItems = lineItems;
            } else {
                $ioAlert.show('Error', 'Error searching line items, displaying all line items', 'error');
                $scope.lineItems = angular.copy($scope.allLineItems);
            }
            processLineItemsList();
        }, function() {
            $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
        });
    };

    $scope.$watch('searchLineItemsObj.searchText', function(newV, oldV) {
        if (!newV && oldV) {
            // Search value cleared, display all line items
            $scope.lineItems = angular.copy($scope.allLineItems);
            processLineItemsList();
        }
    });

    $scope.activateTab = function(tab) {
        $scope.sideActiveTab = tab;
    };

    $scope.activateCommentsTab = function() {
        $scope.activateTab('comments');
    };

    $scope.activateTasksTab = function() {
        $scope.activateTab('tasks');
    };

    $scope.activateTasksHistoryTab = function() {
        $scope.activateTab('tasks-history');
    };

    $scope.isTabActive = function(tab) {
        return $scope.sideActiveTab === tab;
    };

    $scope.isCommentsTabActive = function() {
        return $scope.isTabActive('comments')
    };

    $scope.isTasksTabActive = function() {
        return $scope.isTabActive('tasks');
    };

    $scope.isTasksHistoryTabActive = function() {
        return $scope.isTabActive('tasks-history');
    };

    function hideLineItem_helper() {
        if($rs.userRole.user_type === "superadmin" && $rs.userRole.permissions !== "sales" && $rs.userRole.permissions !== "sales,admin"){
            $scope.showLineItem = true;
        }else{
            $scope.showLineItem = !($rs.lineItemHide && $rs.lineItemHide.value === "1");
        }
    }

    // Get Instance setting only once and set it to rootScope value
    function getInstanceSettings_lineItem() {
        FormService.getInstanceSettings("line_item_hidden").then(function(data) {
            if (data && data.id) {
                $rs.lineItemHide = {
                    "setting_type": data.setting_type,
                    "id": data.id,
                    "value": data.value
                };
            } else {
                $rs.lineItemHide = {};
            }
            hideLineItem_helper();
        }, function(error) {
            $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
            $scope.showLineItem = true;
        });
    };

    function getInstanceSettings_comments() {
        FormService.getInstanceSettings("role_sales_hide_sidebar").then(function(data) {
            if (data && data.id) {
                $scope.showComments = !(data.value === "1");
            } else {
                $scope.showComments = true;
            }
        }, function(error) {
            $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
            $scope.showComments = true;
        });
    }

    function getInstanceSettingsBudgetManagement() {
        FormService.getInstanceSettings("budget_management").then(function(data) {
            if (data && data.id) {
                $scope.budgetManagementEnabled = (data.value === "1");
            } else {
                $scope.budgetManagementEnabled = false;
            }
        }, function() {
            // Silently ignore
            $scope.budgetManagementEnabled = false;
        });
    }

    function getInstanceSettingsEnableEditCompletedEntities() {
        FormService.getInstanceSettings("enable_editing_for_completed_entities").then(function(data) {
            if (data && data.id) {
                $scope.enableEditingForCompletedEntities = (data.value === "1");
            } else {
                $scope.enableEditingForCompletedEntities = false;
            }
        }, function() {
            // Silently ignore
            $scope.enableEditingForCompletedEntities = false;
        });
    }

    function getInstanceSettingsOrderStatusControlAdding() {
        FormService.getInstanceSettings("order_statuses_control_adding").then(function(data) {
            try {
                $scope.allowed_order_statuses = JSON.parse(data.value);
            } catch(error) {
                $scope.allowed_order_statuses = [];
            }
        }, function(error) {
            console.log(error);
            $scope.allowed_order_statuses = [];
        });
    }

    const initInstanceSettings = function() {
        let params = [
            'disable_clone',
        ];
        FormService.getInstanceSettingArray(params).then(function(data) {
            if (data && data.instance_settings) {
                const disableClone = data.instance_settings.disable_clone;
                $scope.disableClone = (disableClone === "1");

            } else {
                $ioAlert.show('Error!', 'Cannot find any instance setting', 'error');
            }
        }, function(error) {
            console.log(error);
            $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
        });
    }

    var init = function() {
        applyPermissions();
        if ($scope.order) {
            $scope.activateCommentsTab();
            loadDisplay();
            ClusterService.initCluster($rs.userRole, 'disabled', $scope.order.cluster_id[0]);
            DateRangeService.init();

            if ($rs.lineItemHide) {
                hideLineItem_helper();
            } else {
                getInstanceSettings_lineItem();
            }

            //show only for user_type superadmin and sales, salesmanager roles
            if($rs.userRole.user_type === "superadmin" && $rs.userRole.permissions !== "sales" && $rs.userRole.permissions !== "sales,admin"){
                $scope.showComments = true;
            }else{
                getInstanceSettings_comments();
            }

        } else if ($stateParams.id) {
            getInstanceSettingThenGetOrder($stateParams.id);

            if ($rs.lineItemHide) {
                hideLineItem_helper();
            } else {
                getInstanceSettings_lineItem();
            }

            //show only for user_type superadmin and sales, salesmanager roles
            if($rs.userRole.user_type === "superadmin" && $rs.userRole.permissions !== "sales" && $rs.userRole.permissions !== "sales,admin"){
                $scope.showComments = true;
            }else{
                getInstanceSettings_comments();
            }

        } else {
            $state.go('orders');
        }

        $('.toggler').click(function(e) {
            e.preventDefault();
            var $icon = $(this).find('span.icon');

            if ($icon.hasClass('icon-caret-right')) {
                $icon.removeClass('icon-caret-right').addClass('icon-caret-down');
            } else {
                $icon.removeClass('icon-caret-down').addClass('icon-caret-right');
            }
        });
        getInstanceSettingsBudgetManagement();
        getInstanceSettingsEnableEditCompletedEntities();
        getCommentFilterItems();
        getInstanceSettingsOrderStatusControlAdding();
        initInstanceSettings();
    };

    CurrentUser.get().then(function(user) {
        $scope.user = user;
        init();
    })
}

CancelReasonController.$inject = ['$scope', 'OrdersService', '$modal', '$rootScope', 'ModalService', 'FormService', '$ioAlert'];

function CancelReasonController($scope, OrdersService, $modal, $rs, ModalService, FormService, $ioAlert) {
    $scope.cancelReasons = [];

    $rs.loadingInProgress = true;
    $rs.loadertitle = "Fetching cancellation Reasons";
    $scope.sortReverse = false;
    $scope.sortType = 'type';
    $scope.disableBtnAction = false;

    $scope.addOrEditCancelReason = function(cancelReason) {
        $scope.isNew = (cancelReason == null);

        var modalObj = $modal.open(
            ModalService.returnModalType('createCancelReason', {
                data: {
                    cancelReason: cancelReason
                }
            })
        );

        modalObj.result.then(function(formObj) {
            if ($scope.isNew) {
                OrdersService.addCancelReason(formObj).then(function() {
                    $scope.cancelReasons.push(OrdersService.cancelReason);
                }, function() {
                    console.log("error saving Cancel Reason");
                });
            } else {
                formObj.id = cancelReason.id;

                OrdersService.updateCancelReason(formObj).then(function() {
                    for (var x = 0; x < $scope.cancelReasons.length; x++) {
                        if ($scope.cancelReasons[x].id == OrdersService.cancelReason.id) {
                            $scope.cancelReasons[x] = OrdersService.cancelReason;
                            break;
                        }
                    }
                }, function() {
                    console.log("error updating Cancel Reason");
                });
            }
        }, function() {})
    };

    $scope.importCancellationReasons = function() {
        if ($scope.uploadMedia.file && $scope.uploadMedia.file.length) {
            OrdersService.importCancellationReasons($scope.uploadMedia.file).then(function (data) {
                if (!data.hasOwnProperty('errors')) {
                    $ioAlert.show('', 'Cancellation reasons imported successfully', 'success');
                init();
                } else {
                    for (let i = 0, len = data.errors.length; i < len; i++) {
                        $ioAlert.show('ERROR!', data.errors[i], 'error');
                    }
                    console.log(data.errors);
                }

            }, function (error) {
                $ioAlert.show('Error!', 'Error importing cancellation reasons: ' + error, 'error');
            });
        }
    };

    $scope.exportCancellationReasons = function () {
        OrdersService.exportCancellationReasons().then(function(response) {
            if (!response.data.hasOwnProperty('errors')) {
                const csvString = response.data;
                const a = $('<a/>', {
                    style: 'display:none',
                    href: 'data:application/octet-stream;base64,' + btoa(unescape(encodeURIComponent(csvString))),
                    download: 'cancellation_reasons.csv'
                }).appendTo('body');
                a[0].click();
                a.remove();
                $ioAlert.show('', 'File exported successfully', 'success');
            } else {
                for (let i = 0, len = response.data.errors.length; i < len; i++) {
                    $ioAlert.show('ERROR!', response.data.errors[i], 'error');
                }
                console.log(response.data.errors);
            }

        }, function(error) {
            $ioAlert.show('Error!', 'Error exporting cancellation reasons: ' + error, 'error');
        });
    };

    /*
    * Handle get instance lock configuration
    */
    var getInstanceLockConfig = function() {
        FormService.getInstanceLockConfig().then(function(data) {
            if(data.instanceLocked) {
                $scope.disableBtnAction = true;
            } else {
                $scope.disableBtnAction = false;
            }

        }, function(error) {
            $ioAlert.show('Error!', 'Something went wrong here. Please refresh the page to try again', 'error');
        });
    }

    var init = function() {
        OrdersService.getCancelReasons('all').then(function() {
            $scope.cancelReasons = OrdersService.cancelReasons;
            $rs.loadingInProgress = false;
        }, function() {
            console.log("error loading cancel reasons");
            $rs.loadingInProgress = false;
        });
        getInstanceLockConfig();
    };

    init();
}
