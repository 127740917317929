'use strict';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';

import exportBuilderAdminListHtmlUrl from './exportbuilder.admin.list.html';
import exportBuilderAdminListItemHtmlUrl from './exportbuilder.admin.list.item.html';
import exportBuilderAdminNewReportModalHtmlUrl from './exportbuilder.admin.newreport.modal.html';
import {
    $ExportBuilderAdminNewReportModalEvents,
    ExportBuilderNewReportContext
} from 'coreModules/exportbuilder/exportbuilder.constants';

angular.module('exportbuilder.admin.components', [])
    .component('exportBuilderAdminList', {
        bindings: {
        },
        templateUrl: exportBuilderAdminListHtmlUrl,
        controller: ExportBuilderListController,
        controllerAs: 'vm'
    })
    .component('exportBuilderAdminListItem', {
        bindings: {
            isTemplate: '<',
            report: '<',
            didCopyReport: '<',
            didDeleteReport: '<'
        },
        templateUrl: exportBuilderAdminListItemHtmlUrl,
        controller: ExportBuilderListItemController,
        controllerAs: 'vm'
    })
    .component('exportBuilderAdminNewReportModal', {
        bindings: false,
        templateUrl: exportBuilderAdminNewReportModalHtmlUrl,
        controller: ExportBuilderAdminNewReportModalController,
        controllerAs: 'vm'
    });

/**
 * @ngInject
 */
function ExportBuilderListController(
    $state,
    PubSub,
    $timeout,
    ExportBuilderResource,
    ExportBuilderDashboardService,
    ExportBuilderService,
    ExportBuilderModelFactory,
    UIFactory,
    ExportBuilderRouterState,
    $ExportBuilderAdminNewReportModalEvents,
    AppFactory,
    UserType,
    ReportTemplateType,
    ExportBuilderNewReportAdminService
) {
    var vm = this;

    /**
     * @type {ReportStudioGalleryState}
     */
    vm.state = ExportBuilderModelFactory.getReportStudioGalleryState();

    /**
     * @type {ReportStudioGalleryData}
     */
    vm.data = ExportBuilderModelFactory.getReportStudioGalleryData();

    /**
     * @type {ReportStudioGalleryIsotopeOptions}
     */
    vm.isotopeOptions = ExportBuilderModelFactory.getReportStudioGalleryIsotopeOptions();
    vm.isotopeOptions.sortBy = null;
    vm.isAscending = true;
    vm.sortBy = 'title';
    /**
     * @type {number|string}
     */
    vm.numberOfResults = vm.data.numberOfResultsOptions[0].value;
    
    /**
     * @type {number}
     */
    vm.pageNumber = 1;
    
    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.onNewReportClicked = onNewReportClicked;
    vm.isLoading = isLoading;
    vm.didCopyReport = didCopyReport;
    vm.didDeleteReport = didDeleteReport;
    vm.filterReportsBy = filterReportsBy;
    vm.clickOnSearch = clickOnSearch;
    vm.searchReports = searchReports;
    vm.cancelSearch = cancelSearch;
    vm.cancelFilter = cancelFilter;
    vm.makeSearchInactive = makeSearchInactive;
    vm.getReportDataFilters = getReportDataFilters;
    vm.isReportListEmpty = isReportListEmpty;
    vm.showEmptyListPlaceholder = showEmptyListPlaceholder;
    vm.showNotFoundPlaceHolder = showNotFoundPlaceHolder;
    vm.onNumberOfResultsChanged = onNumberOfResultsChanged;
    vm.onPreviousPageClicked = onPreviousPageClicked;
    vm.onNextPageClicked = onNextPageClicked;
    vm.isNextPageDisabled = isNextPageDisabled;
    vm.isPreviousPageDisabled = isPreviousPageDisabled;
    vm.onSortOptionsChanged = onSortOptionsChanged;
    vm.onOrderOptionsChanged = onOrderOptionsChanged;
    vm.refreshReports = _.debounce(_getReports, 500);

    function $onInit() {
        _getReports();
        _registerEvents();
    }

    function $onDestroy() {

        _unregisterEvents();
    }

    /**
     * Show loader binding
     * @returns {boolean|*}
     */
    function isLoading() {
        return vm.loading || _.isNil(vm.isotopeOptions.items);
    }

    /**
     * Create a new report
     */
    function onNewReportClicked() {
        const context = AppFactory.getUser().type === UserType.AGENT
            ? ExportBuilderNewReportContext.REPORTSTUDIO_AGENT
            : ExportBuilderNewReportContext.REPORTSTUDIO;
        PubSub.emit($ExportBuilderAdminNewReportModalEvents.INIT_MODAL, { context });
    }

    var _filterFn = {
        reports: function(element) {
            var $reportItem = $(element);
            var textSearchValue = vm.isotopeOptions.searchValue
                ? vm.isotopeOptions.searchValue.classify()
                : '';

            var applyCurrentFilters = vm.isotopeOptions.currentFilter !== vm.data.noFilterString
                ? $reportItem.hasClass(vm.isotopeOptions.currentFilter)
                : true;
            var applyTextSearchFilter = $reportItem.attr('data-searchable-values').contains(textSearchValue);

            return applyTextSearchFilter && applyCurrentFilters;
        }
    };

    function filterReportsBy(filter) {
        vm.isotopeOptions.currentFilter = filter;
    }

    /**
     * @param report
     * @returns {string}
     */
    function getReportDataFilters(report) {
        var filters = '';
        if (report.is_private) {
            filters += ' private';
        }
        return filters;
    }

    /**
     * @returns {boolean}
     */
    function isReportListEmpty() {
        return !vm.isotopeOptions.items.length;
    }

    function showEmptyListPlaceholder() {
        if (vm.isotopeOptions.currentFilter === '.private') {
            return false
        } else {
            return isReportListEmpty()
                && _.isEmpty(vm.isotopeOptions.searchValue)
                && vm.pageNumber === 1;
        }
    }

    function showNotFoundPlaceHolder() {
        if (vm.isotopeOptions.currentFilter === '.private') {
            return isReportListEmpty() || !vm.isotopeOptions.getFilteredCount()
        } else if (!isReportListEmpty()) {
            return !vm.isotopeOptions.getFilteredCount()
                && !_.isNil(vm.isotopeOptions.searchValue);
        } else {
            return isReportListEmpty() && (!_.isEmpty(vm.isotopeOptions.searchValue) || vm.pageNumber > 1)
        }
    }
    
    function onNumberOfResultsChanged() {
        vm.pageNumber = 1;
        vm.refreshReports();
    }
    
    function onPreviousPageClicked() {
        if (vm.loading || vm.pageNumber <= 1) {
            return;
        }
        
        vm.pageNumber -= 1;
    
        _getReports();
    }
    
    function onNextPageClicked() {
        if (vm.loading || !vm.isotopeOptions.items.length) {
            return;
        }
        
        vm.pageNumber += 1;
    
        _getReports();
    }
    
    function onSortOptionsChanged() {
        vm.refreshReports();
    }
    
    function onOrderOptionsChanged() {
        vm.refreshReports();
    }
    
    /**
     */
    function clickOnSearch($event) {
        angular.element($event.currentTarget).find('input').focus();
        vm.state.isTextSearchActive = true;
    }

    function searchReports() {
        vm.pageNumber = 1;
        vm.refreshReports();
    }

    function cancelSearch() {
        vm.isotopeOptions.searchValue = null;
        $timeout(function() {
            vm.state.isTextSearchActive = false;
        }, 0);
        
        vm.refreshReports();
    }

    function cancelFilter() {
        vm.pageNumber = 1;
        filterReportsBy(vm.data.noFilterString);
        cancelSearch();
    }

    function didCopyReport(report) {
        vm.isotopeOptions.items.push(report);
    }

    async function _onNewReportTemplateClicked(report) {
        let promise = null;
        var language = ExportBuilderNewReportAdminService.getSelectedLanguage();
        if (report.is_in_library) {
            promise = ExportBuilderService.useLibraryTemplate(report, language);
        } else {
            promise = ExportBuilderService.copyPredefinedReportTemplate(report, language)
        }
        
        const newReport = await promise;
        await $state.go(ExportBuilderRouterState.EXPORT_BUILDER_STUDIO, {id: newReport.id});
        PubSub.$emit($ExportBuilderAdminNewReportModalEvents.CLOSE_MODAL);
    }

    /**
     * Delete callback
     * @param report
     */
    function didDeleteReport(report) {
        _.remove(vm.isotopeOptions.items, {id: report.id});
    }

    /**
     * @private
     */
    function _getReports() {
        vm.loading = true;
        ExportBuilderService.getReports(_buildParams())
            .then(function (reports) {
                vm.isotopeOptions.items = reports;
            })
            .catch(function () {
                vm.isotopeOptions.items = [];
            })
            .finally(function () {
                vm.loading = false;
            });
    }

    function makeSearchInactive() {
        if (_.isEmpty(vm.isotopeOptions.searchValue)) {
            vm.state.isTextSearchActive = false;
        }
    }
    
    function isPreviousPageDisabled() {
        return vm.numberOfResults === 'all' || vm.pageNumber === 1;
    }
    
    function isNextPageDisabled() {
        return vm.numberOfResults === 'all';
    }

    function _registerEvents() {
        PubSub.on($ExportBuilderAdminNewReportModalEvents.DID_SELECT_PREDEFINED_TEMPLATE, _onNewReportTemplateClicked);
    }

    function _unregisterEvents() {
        PubSub.off($ExportBuilderAdminNewReportModalEvents.DID_SELECT_PREDEFINED_TEMPLATE, _onNewReportTemplateClicked);
    }
    
    function _buildParams() {
        const params = {
            is_predefined: false,
            type: '!proposal',
            is_in_library: false,
            count: true,
            q: vm.isotopeOptions.searchValue,
            sort: `${vm.isAscending ? '' : '-'}${vm.sortBy}`,
        };
        
        if (vm.numberOfResults !== 'all') {
            params.page = `${vm.pageNumber - 1},${vm.numberOfResults}`;
        }
        
        return params;
    }
}

/**
 * @ngInject
 */
function ExportBuilderListItemController(
    $timeout,
    $state,
    PubSub,
    AppFactory,
    $ExportBuilderQuickExportModalEvents,
    ExportBuilderService,
    ExportBuilderModelFactory,
    ExportBuilderDashboardService,
    ExportBuilderRouterState,
    ExportBuilderAdminDataService,
    DataSourceFactory,
    UIFactory,
    gettextCatalog
) {
    var vm = this;
    vm.classes = [];

    vm.loaderText = gettextCatalog.getString('Loading');
    vm.showSpinner = false;
    vm.viewReportButtonTitle;

    /**
     * @type {ReportStudioNewReportData}
     */
    vm.newReportData = ExportBuilderModelFactory.getReportStudioNewReportData();

    vm.$onInit = $onInit;
    vm.isLoading = isLoading;
    vm.getDataSourceIconClass = getDataSourceIconClass;
    vm.getDataSourceIconStyle = getDataSourceIconStyle;
    vm.getDataSourceTooltip = getDataSourceTooltip;
    vm.onContainerClicked = onContainerClicked;
    vm.onReportItemClicked = onReportItemClicked;
    vm.onTemplateItemClicked = onTemplateItemClicked;
    vm.onDeleteReportClicked = onDeleteReportClicked;
    vm.onCopyClicked = onCopyClicked;
    vm.onQuickExportClicked = onQuickExportClicked;
    vm.onRefreshThumbnailClicked = onRefreshThumbnailClicked;

    function $onInit() {
        vm.viewReportButtonTitle = vm.report.can_be_edited ? gettextCatalog.getString('Edit Report') : gettextCatalog.getString('View Report');
    }

    /**
     * Show loader binding
     * @returns {boolean}
     */
    function isLoading() {
        return vm.showSpinner;
    }

    /**
     * @param dataSource
     */
    function getDataSourceIconClass(dataSource) {
        return 'icon ' + DataSourceFactory.getDataSourceIcon(dataSource);
    }

    /**
     * @param dataSource
     */
    function getDataSourceIconStyle(dataSource) {
        return {color: '#fff', backgroundColor:  dataSource.is_connected ? DataSourceFactory.getDataSourceColor(dataSource) : '#cac6c6'};
    }

    /**
     * @param dataSource
     * @returns {string}
     */
    function getDataSourceTooltip(dataSource) {
        var text = dataSource.name;
        if (!dataSource.is_connected) {
            text += ' ' + gettextCatalog.getString('(Not Connected)');
        }
        return text;
    }

    /**
     * @param report
     */
    function onCopyClicked(report) {
        if  (isLoading()) { return; }

        vm.loaderIcon = 'icomoon-brush-pen';
        vm.loaderText = gettextCatalog.getString('Copying report');
        vm.showSpinner = true;
        ExportBuilderService.copyReport(report)
            .then(function (newReport) {
                vm.showSpinner = false;
                vm.didCopyReport && vm.didCopyReport(newReport)
            });
    }

    /**
     * Opens quick export modal
     * @param report
     */
    function onQuickExportClicked(report) {
        if  (isLoading()) { return; }

        ExportBuilderAdminDataService.setQuickExportReport(report);
        PubSub.emit($ExportBuilderQuickExportModalEvents.OPEN);
    }

    /**
     * Refresh report thumbnail
     */
    function onRefreshThumbnailClicked(report) {
        if  (isLoading()) { return; }

        vm.loaderText = gettextCatalog.getString('Generating thumbnail');
        vm.loaderIcon = 'icomoon-image';
        vm.showSpinner = true;
        ExportBuilderService.refreshThumbnail(report)
            .then(function (newReport) {
                vm.showSpinner = false;
                report.metadata.thumbnail_metadata = newReport.metadata.thumbnail_metadata
            })
    }

    function onContainerClicked(report) {
        if  (isLoading()) { return; }

        if (report.is_predefined || vm.isTemplate) {
            onTemplateItemClicked(report);
        } else {
            onReportItemClicked(report);
        }
    }

    /**
     * Opens the report
     * @param report
     */
    function onReportItemClicked(report) {
        if  (isLoading()) { return; }

        vm.loaderText = gettextCatalog.getString('Loading');
        vm.loaderIcon = 'icomoon-brush-pen';
        vm.showSpinner = true;
        $state.go(ExportBuilderRouterState.EXPORT_BUILDER_STUDIO, {id: report.id});
    }

    /**
     * Opens the report
     * @param report
     */
    function onTemplateItemClicked(report) {
        if  (isLoading()) { return; }

        vm.loaderText = gettextCatalog.getString('Building');
        vm.loaderIcon = 'icomoon-brush-pen';
        vm.showSpinner = true;

        PubSub.emit($ExportBuilderAdminNewReportModalEvents.DID_SELECT_PREDEFINED_TEMPLATE, report);
    }

    /**
     * Delete the report
     * @param report
     */
    function onDeleteReportClicked(report) {
        if  (isLoading()) { return; }

        UIFactory.confirmDelete({
            title: gettextCatalog.getString("Are you sure?"),
            text: gettextCatalog.getString("You will not be able to undo this action!"),
            showCancelButton: true,
            confirmButtonColor: "#d9534f",
            confirmButtonText: gettextCatalog.getString("Yes, delete it!"),
            closeOnConfirm: true,
            callback: function(isConfirm){
                if (isConfirm) {
                    vm.loaderIcon = 'icomoon-trash';
                    vm.loaderText = gettextCatalog.getString('Deleting report');
                    vm.showSpinner = true;
                    ExportBuilderService.deleteReport(report)
                        .then(function () {
                            vm.classes.push('delete-item');
                            $timeout(function () {
                                vm.didDeleteReport && vm.didDeleteReport(report);
                            }, 300);
                        })
                        .finally(function () {
                            vm.showSpinner = false;
                        });
                }
            }
        });
    }
}

/**
 * @ngInject
 */
function ExportBuilderAdminNewReportModalController(
    $timeout,
    $state,
    PubSub,
    UIFactory,
    ReportTemplateType,
    UserFactory,
    AppFactory,
    AppModelFactory,
    ExportBuilderModelFactory,
    ExportBuilderService,
    ExportBuilderDashboardService,
    ExportBuilderRouterState,
    $ExportBuilderAdminNewReportModalEvents,
    gettextCatalog,
    ExportBuilderNewReportAdminService
) {
    var vm = this;
    vm.canChangeLanguagesForReports = !!AppFactory.getUser().isModuleAvailable('multi_language_reports');
    vm.userHasLanguage = !!AppFactory.getUser().reportLanguage;
    vm.selectOptions = AppModelFactory.getSelectOptions({
        label: gettextCatalog.getString('language'),
        getDataCall: async () => UserFactory.getColumnValues('report_language'),
        formatData: data => {
            vm.languages = data.map(datum => ({id: datum.key, text: datum.value}));
            vm.language = _.find(vm.languages, {id: AppFactory.getUser().reportLanguage}) || null;
        }
    });

    /**
     * @type {ReportStudioNewReportState}
     */
    vm.state = ExportBuilderModelFactory.getReportStudioNewReportState();

    /**
     * @type {ReportStudioNewReportData}
     */
    vm.data = ExportBuilderModelFactory.getReportStudioNewReportData();

    /**
     * @type {ReportStudioGalleryIsotopeOptions}
     */
    vm.isotopeOptions = ExportBuilderModelFactory.getReportStudioGalleryIsotopeOptions({
        sortBy: 'title',
        sortAscending: true
    });

    /**
     * @type {ReportStudioGalleryIsotopeOptions}
     */
    vm.customTemplateIsotopeOptions = ExportBuilderModelFactory.getReportStudioGalleryIsotopeOptions({
        sortBy: 'title',
        sortAscending: true
    });

    vm.$onInit = $onInit;
    vm.$onDestroy = $onDestroy;
    vm.getPortalTitle = getPortalTitle;
    vm.getPortalSubTitle = getPortalSubTitle;
    vm.backToPortal = backToPortal;
    vm.newBlankReport = newBlankReport;
    vm.selectDesignTemplate = selectDesignTemplate;
    vm.selectReportTemplate = selectReportTemplate;
    vm.isSelectingTemplate = isSelectingTemplate;
    vm.isLoadingTemplates = isLoadingTemplates;
    vm.showBackToPortalButton = showBackToPortalButton;
    vm.onClose = onClose;
    vm.onPortalSubTitleClicked = onPortalSubTitleClicked;
    vm.hasCustomDesignTemplates = hasCustomDesignTemplates;
    vm.hasPredefinedDesignTemplates = hasPredefinedDesignTemplates;
    vm.cancelSearch = cancelSearch;
    vm.clickOnSearch = clickOnSearch;
    vm.makeSearchInactive = makeSearchInactive;
    vm.searchReports = searchReports;


    function $onInit() {
        _registerEvents();
    }

    function $onDestroy() {
        _onCloseModal();
        _unregisterEvents();
    }

    function newBlankReport(language = null) {
        _setNewReportLanguage(language);
        vm.state.isCreatingBlankReport = true;
        ExportBuilderDashboardService.createEmptyReport()
            .then(function(report) {
                $state.go(ExportBuilderRouterState.EXPORT_BUILDER_STUDIO, {id: report.id}).then(function() {
                    _hideModal();
                });
            })
            .catch(function() {
                vm.state.isCreatingBlankReport = false;
            });
    }

    function _setNewReportLanguage(language =  null){
        if (!_.isNil(language)) {
            ExportBuilderNewReportAdminService.setSelectedLanguage(language);
        }
    }

    function selectDesignTemplate(language = null) {
        _setNewReportLanguage(language);
        vm.state.isSelectingDesignTemplate = true;
        _getExportDesignTemplates();
    }

    function selectLibraryTemplate() {
        vm.state.isSelectingDesignTemplate = true;
        _getExportLibraryTemplates();
    }

    function selectReportTemplate(language = null) {
        _setNewReportLanguage(language);
        vm.state.isSelectingReportTemplate = true;
        _getReportTemplates();
    }

    function makeSearchInactive() {
        if (_.isEmpty(vm.searchValue)) {
            vm.state.isTextSearchActive = false;
        }
    }

    /**
     */
    function clickOnSearch($event) {
        angular.element($event.currentTarget).find('input').focus();
        vm.state.isTextSearchActive = true;
    }

    function cancelSearch() {
        vm.customTemplateIsotopeOptions.searchValue = null;
        vm.isotopeOptions.searchValue = null;
        vm.searchValue = null;
        $timeout(function() {
            vm.state.isTextSearchActive = false;
        }, 0);
    }

    var _filterPredefinedFn = {
        reports: function(element) {
            return _filterReports(element, vm.isotopeOptions);
        }
    };

    var _filterCustomFn = {
        reports: function(element) {
            return _filterReports(element, vm.customTemplateIsotopeOptions);
        }
    };

    function _filterReports(element, isotopeOptions) {
        var $reportItem = $(element);
        var textSearchValue = isotopeOptions.searchValue
            ? isotopeOptions.searchValue.classify()
            : '';

        return $reportItem.data('searchableValues').contains(textSearchValue);
    }

    function searchReports() {
        vm.customTemplateIsotopeOptions.searchValue = vm.searchValue;
        vm.isotopeOptions.searchValue = vm.searchValue;

        $timeout(function () {
            vm.customTemplateIsotopeOptions = _.extend(vm.customTemplateIsotopeOptions, {
                filter: _filterCustomFn['reports']
            });
            vm.isotopeOptions = _.extend(vm.isotopeOptions, {
                filter: _filterPredefinedFn['reports']
            });
        }, 100);
    }

    /**
     * @returns {boolean}
     */
    function isSelectingTemplate() {
        return vm.state.isSelectingReportTemplate || vm.state.isSelectingDesignTemplate || vm.state.isSelectingLibraryTemplate;
    }

    function isLoadingTemplates() {
        return isSelectingTemplate() && vm.loadingTemplates
    }

    function showBackToPortalButton() {
        if (vm.state.hideBackButton) {
            return false;
        }
        return !isSelectingTemplate();
    }

    function backToPortal() {
        cancelSearch();
        vm.isotopeOptions.items = [];
        vm.customTemplateIsotopeOptions.items = [];
        vm.state.isSelectingDesignTemplate = false;
        vm.state.isSelectingReportTemplate = false;
        vm.state.isSelectingLibraryTemplate = false;
    }

    function getPortalTitle() {
        if (vm.state.isSelectingDesignTemplate) {
            return gettextCatalog.getString('Select a design template');
        } else if (vm.state.isSelectingReportTemplate) {
            return gettextCatalog.getString('Select a report template');
        }  else if (vm.state.isSelectingLibraryTemplate) {
            return gettextCatalog.getString('Select a template');
        } else {
            return gettextCatalog.getString('Choose a report type');
        }
    }
    
    function getPortalSubTitle() {
        if (vm.state.context) {
            return gettextCatalog.getString('or continue without a template')
        }

        return '';
    }

    function hasCustomDesignTemplates() {
        return !_.isEmpty(vm.customTemplateIsotopeOptions.items)
    }

    function hasPredefinedDesignTemplates() {
        return !_.isEmpty(vm.isotopeOptions.items)
    }

    function onClose() {
        _resetModal();
    }
    
    function onPortalSubTitleClicked() {
        PubSub.emit($ExportBuilderAdminNewReportModalEvents.DID_SELECT_PREDEFINED_TEMPLATE, null);
        _onCloseModal();
    }

    function _init(event = {}) {
        const { context } = event;
        
        _resetModal();
        _showModal();
        _setContext(context);
        
        vm.state.isActive = true;
    }
    
    function _setContext(context) {
        vm.state.context = context;
        vm.state.customTemplatesTitle = 'Library Templates';
        if (context === ExportBuilderNewReportContext.DASHBOARD) {
            vm.state.hideBackButton = true;
            vm.state.showSkipButton = true;
            vm.state.isSelectingDesignTemplate = true;
            vm.state.showBlankTemplateOption = false;
            vm.state.showReportTemplateOption = false;
            vm.state.customTemplatesTitle = 'User Templates';
            _getExportDesignTemplates();
        }
        if (context === ExportBuilderNewReportContext.REPORTSTUDIO_AGENT) {
            vm.state.showSkipButton = false;
            vm.state.isSelectingLibraryTemplate = true;
            vm.state.showBlankTemplateOption = false;
            vm.state.showReportTemplateOption = false;
            vm.state.customTemplatesTitle = 'Library Templates';
            _getExportLibraryTemplates();
        }
    }

    /**
     * @private
     */
    async function _getReportTemplates() {
        vm.loadingTemplates = true;
        const predefinedReportsPromise = ExportBuilderService.getReports({
            is_predefined: true,
            type: ReportTemplateType.TYPE_REPORT,
            sort: 'title'
        })
            .then(function (reports) {
                vm.isotopeOptions.items = reports;
            });
        
        const customReportsPromise = ExportBuilderService.getReports({
            is_predefined: false,
            is_in_library: true,
            is_template: true,
            type: ReportTemplateType.TYPE_REPORT,
            sort: 'title'
        })
          .then(function (reports) {
              vm.customTemplateIsotopeOptions.items = reports;
          });
        
        await Promise.all([predefinedReportsPromise, customReportsPromise]);
        vm.loadingTemplates = false;
    }

    /**
     * @private
     */
    function _getDesignTemplates() {
        vm.loadingTemplates = true;
        ExportBuilderService.getReports({
            is_predefined: true,
            type: ReportTemplateType.TYPE_DESIGN,
            sort: 'title'
        })
            .then(function (reports) {
                vm.isotopeOptions.items = reports;
            })
            .finally(() => {
                vm.loadingTemplates = false;
            });
    }

    /**
     * @private
     */
    async function _getExportDesignTemplates() {
        vm.loadingTemplates = true;
        const predefinedReportsPromise = ExportBuilderService.getReports({
            is_predefined: true,
            type: ReportTemplateType.TYPE_DESIGN,
            sort: 'title'
        })
          .then(function (reports) {
              vm.isotopeOptions.items = reports;
          });
    
        const customReportsPromise = ExportBuilderService.getReports({
            is_predefined: false,
            is_in_library: true,
            is_template: true,
            type: ReportTemplateType.TYPE_DESIGN,
            sort: 'title'
        })
          .then(function (reports) {
              vm.customTemplateIsotopeOptions.items = reports;
          });
    
        await Promise.all([predefinedReportsPromise, customReportsPromise]);
        vm.loadingTemplates = false;
    }
    
    /**
     * @private
     */
    async function _getExportLibraryTemplates() {
        vm.loadingTemplates = true;
        try {
            const data = await ExportBuilderService.getExportLibraryTemplates();
            vm.isotopeOptions.items = data.predefined;
            vm.customTemplateIsotopeOptions.items = data.library;
        } catch (e) {
            UIFactory.notify.showError(e);
        } finally {
            vm.loadingTemplates = false;
        }
    }

    function _showModal() {
        UIFactory.showModal(vm.data.modalId);
    }

    function _hideModal() {
        UIFactory.hideModal(vm.data.modalId);
    }

    function _onCloseModal() {
        _hideModal();
        _resetModal();
    }

    function _resetModal() {
        vm.state = ExportBuilderModelFactory.getReportStudioNewReportState();
        vm.data = ExportBuilderModelFactory.getReportStudioNewReportData();
        vm.isotopeOptions = ExportBuilderModelFactory.getReportStudioGalleryIsotopeOptions();
        vm.customTemplateIsotopeOptions = ExportBuilderModelFactory.getReportStudioGalleryIsotopeOptions();
    }

    /**
     * @private
     */
    function _registerEvents() {
        PubSub.on($ExportBuilderAdminNewReportModalEvents.INIT_MODAL, _init);
        PubSub.on($ExportBuilderAdminNewReportModalEvents.CLOSE_MODAL, _onCloseModal);
    }

    /**
     * @private
     */
    function _unregisterEvents() {
        PubSub.off($ExportBuilderAdminNewReportModalEvents.INIT_MODAL, _init);
        PubSub.off($ExportBuilderAdminNewReportModalEvents.CLOSE_MODAL, _onCloseModal);
    }
}